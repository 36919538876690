import React from 'react';
import { Route, Outlet } from 'react-router-dom';
import UserRoute from 'components/routes/UserRoute';
import Survey from './Survey';
import SurveyPageLayout from 'components/survey/SurveyPageLayout';
import SurveyWelcome from 'components/survey/SurveyWelcome';
import MyFiles from 'components/survey/MyFiles';
import NewFile from 'components/survey/NewFile';
import SurveyPageNav from 'components/survey/SurveyPageNav';
import FileDocuments from 'components/survey/FileDocuments';
import FileChecklist from 'components/survey/FileChecklist';
import FileSummary from 'components/survey/FileSummary';
import FileTrustAccount from 'components/trustAccount/FileTrustAccount';

const routes = [
  <Route key="files" path="/files" element={<UserRoute><Survey><Outlet/></Survey></UserRoute>}>
    <Route path="new" element={<NewFile/>} />
    <Route key="files" path=":surveyId/:fileId" element={<SurveyPageNav><Outlet/></SurveyPageNav>}>
      <Route path="documents" element={<FileDocuments />} />
      <Route path="checklist" element={<FileChecklist />} />
      <Route path="trust-account" element={<FileTrustAccount />} />
      <Route path="summary" element={<FileSummary />} />
      <Route path=":pageNumber" element={<SurveyPageLayout />} />
      <Route path="" element={<SurveyWelcome />} />
    </Route>
    <Route index element={<MyFiles />} />
  </Route>
];
export default routes;
