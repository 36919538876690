import React from 'react';
import ButtonPrimary from './Button';

export default function Confirm({open, text, onConfirm, onCancel}) {
  return (
    <>
        {open &&
            <div className="bg-white absolute border border-green rounded p-4">
                {text}
                <div className="flex gap-2">
                    <ButtonPrimary onClick={onConfirm}>Confirm</ButtonPrimary>
                    <ButtonPrimary onClick={onCancel}>Cancel</ButtonPrimary>
                </div>
            </div>
        }
    </>
  )
}
