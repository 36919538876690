import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Message from 'components/common/Message';
import ParentContext from 'contexts/ParentContext';
import SurveyContext from 'contexts/SurveyContext';
import React, { useContext, useMemo } from 'react';
import { getCalculatedValue } from 'selectors/formSelectors';

const SurveyText = ({label, description, questionKey, type, formStyles, calculatedLabel}) => {
  const { file} = useContext(SurveyContext);
  const {prevRows} = useContext(ParentContext);

  const calculatedLabelValue = useMemo(() => {
    return calculatedLabel && file && file.answers && getCalculatedValue(file, calculatedLabel, prevRows);
  }, [calculatedLabel, file, prevRows]);

  switch(type) {
    case "warning":
       return (
        <Message className="text-center"><FontAwesomeIcon icon={faExclamationTriangle}/> {calculatedLabelValue || label}</Message>
      );
    case "error":
       return (
        <Message className="text-center" negative><FontAwesomeIcon icon={faExclamationTriangle}/> {calculatedLabelValue || label}</Message>
      );
    default:
      return (
        <div className="grow-0">
          <p className={`text-xl ${formStyles}`}>{calculatedLabelValue || label}</p>
        </div>
      );
  }
};

export default SurveyText;