import React, {useContext, useEffect, useMemo, useState} from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import ChecklistBlock from './ChecklistBlock';

const GET_CHECKLIST = gql`
  query GetChecklistAnswer($fileId: String, $surveyId: String) {
    checklistAnswer(fileId: $fileId, surveyId: $surveyId) {
      id,
      answers,
      notes,
      template{
        id,
        name,
        default,
        firmId,
        userId,
        phases{
          title,
          dateCalculation,
          icon,
          blocks{
            title, 
            color,
            items
          } 
        }
      },
    }
  }
`;

const UPDATE_CHECKLIST_ANSWERS = gql`
  mutation UpdateChecklistAnswers($id: String!, $answers: [String], $notes: JSONObject) {
    updateChecklistAnswers(id: $id, answers: $answers, notes: $notes) {
      id
    }
  }
`;

export default function MiniChecklist({phase, fileId, surveyId, refetch}) {
  const [checklist, setChecklist] = useState();
  const [updateChecklistAnswersMutation, { loading }] = useMutation(UPDATE_CHECKLIST_ANSWERS);
  
  const { data } = useQuery(GET_CHECKLIST,{
    variables: {fileId, surveyId, phase},
    fetchPolicy: 'no-cache',
    skip: !fileId,
    onCompleted: (data) => {
      setChecklist(data.checklistAnswer);
    }
  });

  const block = useMemo(() => {
    if (checklist) {
      let foundPhase = checklist.template.phases.find(currentPhase => phase.includes(currentPhase.title));
      if (!foundPhase) return {};
      for (let block of foundPhase.blocks) {
        for (let item of block.items) {
          if (!checklist.answers.includes(item)) {
            return block;
          }
        }
      }
      return foundPhase.blocks.at(-1);
    } else {
      return {};
    }
  }, [checklist]);

  const handleUpdateAnswers = (answer, i) => {
    let answers = [...checklist.answers];
    let removed = false;
    answers = checklist.answers.filter(ans => {
      if (ans === answer) {
        removed = true;
        return false;
      }
      return true;
    });
    if (!removed) {
      answers.push(answer);
    }
    updateChecklistAnswersMutation({variables: {
      answers, id: checklist.id, surveyId
    }})
    .then((res) => {
      refetch()
      setChecklist({...checklist, answers});
    });
  }

  const handleUpdateNotes = (note, item) => {
    let newNotes = {...checklist.notes, [item]: note};
    updateChecklistAnswersMutation({variables: {
      notes: newNotes, id: checklist.id, surveyId
    }})
    .then((res) => {
      setChecklist({...checklist, notes: newNotes});
    });
  }


  return (
    <div className="flex flex-col gap-2 w-full">
      {checklist &&
        <ChecklistBlock 
          answers={checklist.answers} 
          notes={checklist.notes}
          updateNotes={handleUpdateNotes}
          updateAnswers={handleUpdateAnswers} 
          block={block}
        />}
    </div>
  )
}
