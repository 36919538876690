import React from 'react'
import { useQuery, gql } from "@apollo/client";
import List from './List';

const GET_FIRMS = gql`
  query GetFirms {
    firms {
      id,
      name,
      subdomain
    }
  }
`;

export default function FIrmSelect({onChange}) {
  const { loading, error, data } = useQuery(GET_FIRMS);
  const options = data ? data.firms.map(firm => ({value: firm.id, name: firm.name})) : [];
  return (
    <List options={options} onChange={onChange}/>
  )
}
