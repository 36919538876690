import React, {useEffect, useState, useCallback, useContext} from 'react'
import _ from 'lodash';
import {
    Input,
    TotalInput,
    ArrayInput,
    Checkboxes,
    CheckList,
    Radios,
    List,
    LineItem,
    CalculatedInput,
    Total,
    Table,
    HideQuestion,
    LTSAcredentialsInput,
    RequestInput,
    CategorizerInput,
    MortgageInput,
    SurveyText,
    AnswerTemplateInput
} from 'components/surveyInputs';
import BusinessInput from 'components/surveyInputs/BusinessInput';
import ClientInput from 'components/surveyInputs/ClientInput';
import TrustAccountInput from 'components/trustAccount/TrustAccountInput';
import TrustAccountLineItemsInput from 'components/trustAccount/TrustAccountLineItemsInput';
import PathTooltip from 'components/surveyInputs/PathTooltip';
import AutoCompleteAddressInput from 'components/surveyInputs/AutoCompleteAddressInput';
import FCTcredentialsInput from 'components/surveyInputs/FCTcredentialsInput';
import FCTTitleInsurcneInput from 'components/surveyInputs/FCTTitleInsuranceInput';
import LinkInput from 'components/surveyInputs/LinkInput';
import CopyInput from 'components/surveyInputs/CopyInput';
import PropertyTaxUpload from 'components/surveyInputs/PropertyTaxUpload';
import MLSContractUpload from 'components/surveyInputs/MLSContractUpload';
import StewartTitlecredentialsInput from 'components/surveyInputs/StewartTitlecredentialsInput';
import StewartTitleInsurcneInput from 'components/surveyInputs/StewartTitleInsuranceInput';

export default function useSurveyPage(page, showPaths) {
    const [pageComponents, setPageComponents] = useState();
    const getInputs = useCallback((questions, isReadOnly) => {
        let pageComponents = [];
        const questionSchema = {};
        for (let [index, {question}] of questions.entries()) {
            switch(question?.kind) {
                case "Checkbox":
                    pageComponents.push(
                        <HideQuestion key={question.key + index} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <Checkboxes 
                                options={question.options}
                                label={question.label}
                                hideLabel={question.hideLabel}
                                questionKey={question.key}
                                readOnly={question.readOnly || isReadOnly}
                                questionSchema={questionSchema}
                                description={question.description}
                            />
                            {showPaths && !question.hidden && <PathTooltip questionKey={question.key}/>}
                        </HideQuestion>
                    )
                    break;
                case "CheckList":
                    pageComponents.push(
                        <HideQuestion key={question.key + index} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <CheckList 
                                options={question.options}
                                label={question.label}
                                multiple={question.multiple}
                                hideLabel={question.hideLabel}
                                questionKey={question.key}
                                setUnselected={question.setUnselected}
                                readOnly={question.readOnly || isReadOnly}
                                description={question.description}
                            />
                            {showPaths && !question.hidden && <PathTooltip questionKey={question.key}/>}
                        </HideQuestion>
                    )
                    break;
                case "Business":
                    const subBusinessInputs = question.questions && getInputs(question.questions, isReadOnly);
                    pageComponents.push(
                        <HideQuestion key={question.key + index} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <BusinessInput 
                                type={question.type}
                                label={question.label}
                                calculatedLabel={question.calculatedLabel}
                                hideLabel={question.hideLabel}
                                questionKey={question.key}
                                readOnly={question.readOnly || isReadOnly}
                                description={question.description}
                                multiple={question.multiple}
                                questions={subBusinessInputs}
                            />
                            {showPaths && !question.hidden && <PathTooltip questionKey={question.key}/>}
                        </HideQuestion>
                    )
                    break;
                case "TrustAccount":
                    pageComponents.push(
                        <HideQuestion key={question.key} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <TrustAccountInput 
                                type={question.type}
                                label={question.label}
                                hideLabel={question.hideLabel}
                                questionKey={question.key}
                                readOnly={question.readOnly || isReadOnly}
                                description={question.description}
                            />
                            {showPaths && !question.hidden && <PathTooltip questionKey={question.key}/>}
                        </HideQuestion>
                    )
                    break;
                case "TrustAccountLineItems":
                    const trustAccountItems = getInputs(question.questions, isReadOnly);
                    pageComponents.push(
                        <HideQuestion key={question.key} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <TrustAccountLineItemsInput
                                questionKey={question.key}
                                readOnly={question.readOnly || isReadOnly}
                                description={question.description}
                                date={question.date}
                                mapping={question.mapping}
                                questions={question.questions}
                                showTrustCalculation={question.showTrustCalculation}
                                hideTrustCalculation={question.hideTrustCalculation}
                            >
                                {trustAccountItems}
                            </TrustAccountLineItemsInput>
                        </HideQuestion>
                    )
                    break;
                case "Person":
                    const subPersonInputs = question.questions && getInputs(question.questions, isReadOnly);
                    pageComponents.push(
                        <HideQuestion key={question.key + index} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <ClientInput 
                                type={question.type}
                                label={question.label}
                                hideLabel={question.hideLabel}
                                questionKey={question.key}
                                readOnly={question.readOnly || isReadOnly}
                                description={question.description}
                                multiple={question.multiple}
                                copyAddress={question.copyAddress}
                                copyPropertyAddress={question.copyPropertyAddress}
                                questions={subPersonInputs}
                            />
                            {showPaths && !question.hidden && <PathTooltip questionKey={question.key}/>}
                        </HideQuestion>
                    )
                    break;
                case "Mortgage":
                    const subMortgageInputs = question.questions && getInputs(question.questions, isReadOnly);
                    pageComponents.push(
                        <HideQuestion key={question.key + index} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <MortgageInput 
                                type={question.type}
                                label={question.label}
                                hideLabel={question.hideLabel}
                                questionKey={question.key}
                                readOnly={question.readOnly || isReadOnly}
                                description={question.description}
                                multiple={question.multiple}
                                questions={subMortgageInputs}
                            />
                            {showPaths && !question.hidden && <PathTooltip questionKey={question.key}/>}
                        </HideQuestion>
                    )
                    break;
                case "Request":
                    pageComponents.push(
                        <HideQuestion key={question.key + index} keepDataOnHide={question.keepDataOnHide} questionKey={question.key}  hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <RequestInput 
                                questionKey={question.key}
                                request={question.request}
                                label={question.label}
                                description={question.description}
                                variables={question.variables}
                                automatic={question.automatic}
                                tooltip={question.tooltip}
                            />
                        </HideQuestion>
                    )
                    break;
                case "LTSAcredentials":
                    pageComponents.push(
                        <HideQuestion key={question.key + index} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <LTSAcredentialsInput 
                                forRequests={question.forRequests}
                            />
                        </HideQuestion>
                    )
                    break;
                case "FCTcredentials":
                    pageComponents.push(
                        <HideQuestion key={question.key + index} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <FCTcredentialsInput
                                titleInsurancePath={question.titleInsurancePath}
                                questionKey={question.key}
                            />
                        </HideQuestion>
                    )
                    break;
                case "FCTTitleInsurance":
                    pageComponents.push(
                        <HideQuestion key={question.key + index} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <FCTTitleInsurcneInput
                                questionKey={question.key}
                                tokenIdPath={question.tokenIdPath}
                            />
                        </HideQuestion>
                    )
                    break;
                case "StewartTitlecredentials":
                    pageComponents.push(
                        <HideQuestion key={question.key + index} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <StewartTitlecredentialsInput
                                titleInsurancePath={question.titleInsurancePath}
                                questionKey={question.key}
                            />
                        </HideQuestion>
                    )
                    break;
                case "StewartTitleInsurance":
                    pageComponents.push(
                        <HideQuestion key={question.key + index} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <StewartTitleInsurcneInput
                                questionKey={question.key}
                                tokenIdPath={question.tokenIdPath}
                            />
                        </HideQuestion>
                    )
                    break;
                case "AnswerTemplate":
                    pageComponents.push(
                        <HideQuestion key={question.key + index} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <AnswerTemplateInput 
                                paths={question.paths}
                                showSave={question.showSave}
                                questionKey={question.key}
                                description={question.description}
                                label={question.label}
                                defaults={question.defaults}
                            />
                        </HideQuestion>
                    )
                    break;
                case "Categorizer":
                    pageComponents.push(
                        <HideQuestion key={question.key + index} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <CategorizerInput 
                                 type={question.type}
                                 label={question.label}
                                 item={question.item}
                                 description={question.description}
                                 questionKey={question.key}
                                 readOnly={question.readOnly || isReadOnly}
                                 mapping={question.mapping}
                                 options={question.options}
                            />
                        </HideQuestion>
                    )
                    break;
                case "List":
                    pageComponents.push(
                        <HideQuestion key={question.key + index} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            {question.dropDown ?
                            <List
                                text={question.label}
                                calculatedLabel={question.calculatedLabel}
                                hideLabel={question.hideLabel}
                                hidden={question.hidden}
                                questionKey={question.key}
                                options={question.options}
                                readOnly={question.readOnly || isReadOnly}
                                tooltip={question.tooltip}
                                formStyles={question.formStyles}
                                query={question.query}
                                other={question.other}
                                defaultValue={question.defaultValue}
                            />
                            :
                            <Radios
                                text={question.label}
                                calculatedLabel={question.calculatedLabel}
                                hideLabel={question.hideLabel}
                                questionKey={question.key}
                                hidden={question.hidden}
                                options={question.options}
                                readOnly={question.readOnly || isReadOnly}
                                description={question.description}
                                other={question.other}
                                defaultValue={question.defaultValue}
                            />}
                            {showPaths && !question.hidden && <PathTooltip questionKey={question.key}/>}
                        </HideQuestion>
                    )
                    break;
                case "Array":
                    const arrayItem = getInputs([question.questionRef], isReadOnly);
                    pageComponents.push(
                        <HideQuestion key={question.key + index} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <ArrayInput 
                                title={question.label} 
                                hideLabel={question.hideLabel}
                                questionRef={question.questionRef} 
                                readOnly={question.readOnly || isReadOnly}
                                questionKey={question.key} 
                                arrayItem={arrayItem}
                                description={question.description}
                                seed={question.seed}
                                hideTotal={question.hideTotal}
                                formStyles={question.formStyles}
                                hidden={question.hidden}
                            />
                        </HideQuestion>
                    )
                    break;
                case "LineItem":
                    const subInputs = getInputs(question.questions, isReadOnly);
                    pageComponents.push(
                        <HideQuestion key={question.key + index} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <LineItem
                                questions={subInputs}
                                label={question.label}
                                hideLabel={question.hideLabel}
                                description={question.description}
                                questionKey={question.key}
                                formStyles={question.formStyles}
                                skipPath={question.skipPath}
                                skipParentPath={question.skipParentPath}
                            />
                        </HideQuestion>
                    )
                    break;
                case "Table":
                    const tableInputs = getInputs(question.questions, isReadOnly);
                    pageComponents.push(
                        <HideQuestion key={question.key + index} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <Table
                                title={question.label}
                                calculatedLabel={question.calculatedLabel}
                                hideLabel={question.hideLabel}
                                same={question.same}
                                collapsible={question.collapsible}
                                description={question.description}
                                formStyles={question.formStyles}
                                questionKey={question.key}
                                skipPath={question.skipPath}
                                skipParentPath={question.skipParentPath}
                                subtle={question.subtle}
                                hidden={question.hidden}
                                hideTotal={question.hideTotal}
                            >
                                {tableInputs}
                            </Table>
                        </HideQuestion>
                    )
                    break;
                case "Calculated":
                    pageComponents.push(
                        <HideQuestion key={question.key + question.showCalculation} keepDataOnHide={question.keepDataOnHide} cache={question.cache} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <Total hidden={question.hidden} formStyles={question.formStyles}>
                                <CalculatedInput
                                    questionKey={question.key} 
                                    name={question.key}
                                    title={question.label}
                                    hideLabel={question.hideLabel}
                                    calculation={question.calculation}
                                    description={question.description}
                                    writable={question.writable}
                                    formStyles={question.formStyles}
                                    total={question.total}
                                    cache={question.cache}
                                    skipPath={question.skipPath}
                                    span={question.span}
                                    type={question.type}
                                    options={question.options}
                                    skipParentPath={question.skipParentPath}
                                    softCache={question.softCache}
                                />
                                {question.total &&
                                    <TotalInput
                                        key={question.total}
                                        type={question.type}
                                        total={question.total}
                                        hideLabel={question.hideLabel}
                                        questionKey={question.key}
                                        formStyles={question.formStyles}
                                    />
                                }
                            </Total>
                            {showPaths && !question.hidden && <PathTooltip questionKey={question.key}/>}
                        </HideQuestion>
                    )
                    break;
                case "Text":
                    pageComponents.push(
                        <HideQuestion key={question.key + index} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <SurveyText
                                label={question.label}
                                calculatedLabel={question.calculatedLabel}
                                description={question.description}
                                questionKey={question.key}
                                type={question.type}
                                formStyles={question.formStyles}
                            />
                        </HideQuestion>
                    )
                    break;
                case "AutoCompleteAddress":
                    pageComponents.push(
                        <HideQuestion key={question.key + index + showPaths.toString()} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <AutoCompleteAddressInput
                                questionKey={question.key}
                                title={question.label}
                                hideLabel={question.hideLabel}
                                calculatedLabel={question.calculatedLabel}
                                query={question.query}
                                readOnly={question.readOnly || isReadOnly}
                                formStyles={question.formStyles}
                                description={question.description}
                                hideTooltip={question.hideIfEmpty}
                                defaultValue={question.defaultValue}
                                hidden={question.hidden}
                                mapping={question.mapping}
                            />
                            {showPaths && !question.hidden && <PathTooltip questionKey={question.key}/>}
                        </HideQuestion>
                    )
                    break;
                case "Link":
                    pageComponents.push(
                        <HideQuestion key={question.key + index + showPaths.toString()} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <LinkInput
                                questionKey={question.key}
                                title={question.label}
                                hideLabel={question.hideLabel}
                                calculatedLabel={question.calculatedLabel}
                                readOnly={question.readOnly || isReadOnly}
                                formStyles={question.formStyles}
                                description={question.description}
                                hideTooltip={question.hideIfEmpty}
                                url={question.url}
                            />
                            {showPaths && !question.hidden && <PathTooltip questionKey={question.key}/>}
                        </HideQuestion>
                    )
                    break;
                case "Copy":
                    pageComponents.push(
                        <HideQuestion key={question.key + index + showPaths.toString()} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <CopyInput
                                questionKey={question.key}
                                title={question.label}
                                hideLabel={question.hideLabel}
                                calculatedLabel={question.calculatedLabel}
                                readOnly={question.readOnly || isReadOnly}
                                formStyles={question.formStyles}
                                description={question.description}
                                hideTooltip={question.hideIfEmpty}
                                value={question.value}
                            />
                            {showPaths && !question.hidden && <PathTooltip questionKey={question.key}/>}
                        </HideQuestion>
                    )
                    break;
                case "PropertyTaxUpload":
                    pageComponents.push(
                        <HideQuestion key={question.key + index + showPaths.toString()} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <PropertyTaxUpload
                                questionKey={question.key}
                                title={question.label}
                                hideLabel={question.hideLabel}
                                calculatedLabel={question.calculatedLabel}
                                readOnly={question.readOnly || isReadOnly}
                                formStyles={question.formStyles}
                                description={question.description}
                                hideTooltip={question.hideIfEmpty}
                                value={question.value}
                            />
                            {showPaths && !question.hidden && <PathTooltip questionKey={question.key}/>}
                        </HideQuestion>
                    )
                    break;
                case "MLSContractUpload":
                    pageComponents.push(
                        <HideQuestion key={question.key + index + showPaths.toString()} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <MLSContractUpload
                                questionKey={question.key}
                                title={question.label}
                                hideLabel={question.hideLabel}
                                calculatedLabel={question.calculatedLabel}
                                readOnly={question.readOnly || isReadOnly}
                                formStyles={question.formStyles}
                                description={question.description}
                                hideTooltip={question.hideIfEmpty}
                                value={question.value}
                            />
                            {showPaths && !question.hidden && <PathTooltip questionKey={question.key}/>}
                        </HideQuestion>
                    )
                    break;
                default:
                    pageComponents.push(
                        <HideQuestion key={question.key + index + showPaths?.toString()} keepDataOnHide={question.keepDataOnHide} questionKey={question.key} hideCalculation={question.hideCalculation} showCalculation={question.showCalculation}>
                            <Total formStyles={question.formStyles}>
                                <Input
                                    type={question.type}
                                    questionKey={question.key}
                                    title={question.label}
                                    hideLabel={question.hideLabel}
                                    total={question.total}
                                    calculatedLabel={question.calculatedLabel}
                                    query={question.query}
                                    readOnly={question.readOnly || isReadOnly}
                                    pattern={question.pattern}
                                    patternErrorMessage={question.patternErrorMessage}
                                    inputSymbol={question.inputSymbol}
                                    formStyles={question.formStyles}
                                    description={question.description}
                                    hideTooltip={question.hideIfEmpty}
                                    defaultValue={question.defaultValue}
                                    hidden={question.hidden}
                                    skipPath={question.skipPath}
                                    skipParentPath={question.skipParentPath}
                                />
                                {question.total &&
                                    <TotalInput
                                        key={question.total}
                                        type={question.type}
                                        total={question.total}
                                        hideLabel={question.hideLabel}
                                        questionKey={question.key}
                                        formStyles={question.formStyles}
                                    />
                                }
                            </Total>
                            {showPaths && !question.hidden && <PathTooltip questionKey={question.key}/>}
                        </HideQuestion>
                    )
            }     
        }
        return pageComponents;
    },[showPaths]);

    useEffect(() => {
        if (page && page.questions) {
            const isReadOnly = false;
            setPageComponents(getInputs(page.questions, isReadOnly));
        }
    }, [page, getInputs]);

    return {
        pageComponents
    };
}
