import React, {useContext, useMemo, useState} from 'react'
import AppContext from 'contexts/AppContext';
import { gql, useQuery } from '@apollo/client';

const GET_MORTGAGES = gql`
  query GetMortgages($filters: JSONObject) {
    mortgages(filters: $filters) {
      id,
      name,
    }
  }
`;

export default function useMortgageChoices(startSelected, skip, multiple) {
  const { currentUser } = useContext(AppContext);
  const { loading, error, data } = useQuery(GET_MORTGAGES, {
    skip: skip,
    variables: {filters: {limit: 200}}
  });
  const mortgageChoices = useMemo(() => {
    return (data?.mortgages || []).map((mortgage) => {
      return {name: mortgage.name, value: mortgage.id};
    });
  }, [data]);
  
  const [mortgageId, setMortgageId] = useState(startSelected ? mortgageChoices[0] && mortgageChoices[0].value : null );
  const [mortgageIds, setMortgageIds] = useState(startSelected ? mortgageChoices[0] ? [mortgageChoices[0].value] : [] : []);


  return { mortgageChoices, mortgageId, setMortgageId, mortgageIds, setMortgageIds};
}
