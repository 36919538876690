import React, {useContext} from 'react';
import CalendarContext from 'contexts/CalendarContext';
import { sameDay } from 'helpers/calendar';

const letterDayLookup = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', "Saturday"];

export default function WeekDay({dayOfWeek, currentDate}) {
  const {setDate, date, monthEvents, colorMode} = useContext(CalendarContext);
  const todaysEvents = monthEvents.filter(event => sameDay(new Date(event.date + "T00:00"), currentDate));
  return (
    <div 
      className={`
        w-1/7 min-w-8 relative cursor-pointer overflow-hidden
        ${date.getDate() === currentDate.getDate() ? 'z-10 scale-125 bg-light-light-grey text-black border border-b-2 border-transparent-dark-grey border-b-primary font-md shadow-lg' : "border"}
        hover:bg-light-grey hover:font-md hover:scale-125 hover:z-10
      `}
      // TODO ifs this so it uses date obj instead of calendar date
      onClick={() => setDate(new Date(currentDate))}
    >
      <div className="mt-2x-full"/>
        <div className="flex flex-col pt-1 items-center gap-1 absolute top-0 left-0 right-0 bottom-0 px-0.5">
          <small>{letterDayLookup[dayOfWeek].slice(0,3)}</small>
          <div className={`
            divide-y w-full
            flex flex-col gap-px
            ${date.getDate() !== currentDate.getDate() && 'opacity-50'}
          `}>
            {todaysEvents.map((event,i) => <div 
              key={i+(colorMode ? event.user.color : event.colorOne)} 
              className={`flex bg-white rounded text-white overflow-hidden ${!event.colorTwo && event.percentage == 1 ? 'opacity-30' : ''}`}
              style={{border: `1px solid ${event.colorOne}`, backgroundImage: `linear-gradient(to right, ${colorMode ? event.user?.color : event.colorOne} ${(Number(event.percentage) || Number(event.percentage) === 0) ? 100 - event.percentage*100 : '100'}%, ${colorMode ? event.user.color : (event.colorTwo || event.colorOne)})`}}
            >
                <span className="text-xxs font-medium whitespace-nowrap hover:animate-ticker">{event.icon}{event.name}</span>
              </div>
            )}
          </div>
        </div>
    </div>
  )
}
