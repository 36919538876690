import React, {useContext, createContext, useState} from 'react';
import { Link } from 'react-router-dom';
import AppContext from 'contexts/AppContext';
import ActivePageContext from 'contexts/ActivePageContext';
import { FullWidthPage } from 'components/common/Layout';

export default function AdminNav({children}) {
  const [activePage, setActivePage] = useState();
  const {currentUser} = useContext(AppContext); 
  return (
    <div className="flex flex-col grow">
        <FullWidthPage className="border-b border-transparent-dark-grey bg-transparent-grey items-center px-3 flex justify-between">
            <Link className="no-underline text-primary text-lg font-medium" to="/admin">
                Admin
            </Link>
            <div className='flex gap-2 no-underline'>
              {
                ["admin", "super-admin"].includes(currentUser.type) &&
                <>
                  <Link className={`no-underline py-3 ${activePage == 'mortgage' ? ' text-dark-grey border-b border-dark-grey' : 'text-med-grey'}`} to="mortgages">Mortgages</Link>
                  <Link className={`no-underline py-3 ${activePage == 'business' ? ' text-dark-grey border-b border-dark-grey' : 'text-med-grey'}`} to="businesses">Businesses</Link>
                  <Link className={`no-underline py-3 ${activePage == 'document' ? ' text-dark-grey border-b border-dark-grey' : 'text-med-grey'}`} to="documents">Documents</Link>
                  <Link className={`no-underline py-3 ${activePage == 'firms' ? 'text-dark-grey border-b border-dark-grey' : 'text-med-grey'}`} to="firms">Firms</Link>
                </>
              }
              {
                "super-admin" === currentUser.type &&
                <Link className={`no-underline py-3 ${activePage == 'bug-reports' ? ' text-dark-grey border-b border-dark-grey' : 'text-med-grey'}`} to="bug-reports">Bugs</Link>
              }
              <Link className={`no-underline py-3 ${activePage ? 'text-med-grey' : 'text-dark-grey border-b border-dark-grey'}`} to="users">Users</Link>
              {
                currentUser.firmAdmin && 
                <>
                  <Link className={`no-underline py-3 ${activePage == 'firm' ? 'text-dark-grey border-b border-dark-grey' : 'text-med-grey'}`} to="firm">Firm</Link>
                  <Link className={`no-underline py-3 ${activePage == 'payments' ? 'text-dark-grey border-b border-dark-grey' : 'text-med-grey'}`} to="payments">Payments</Link>
                </>
              }
            </div>
        </FullWidthPage>
        <div>
          <ActivePageContext.Provider value={{setActivePage}}>
            {children}
          </ActivePageContext.Provider>
        </div>
    </div>
  )
}
