import React, { useContext } from 'react';
import { PaddedPage } from 'components/common/Layout';
import { Link } from 'react-router-dom';
import appContext from 'contexts/AppContext';

const NotAuthorized = () => {
  const { online } = useContext(appContext);
  return (
    <PaddedPage className="flex flex-col gap-2">
      <h2>PRIVACY POLICY</h2>
      <p>We at ConveyMe are committed to protecting your privacy. There are various ways that you might interact with ConveyMe, and the information you provide when doing so allows us to improve our services.
      </p><p>
      This policy applies to ConveyMe Software Inc. (“ConveyMe”) and covers our activities as a web based conveyancing software platform (the “Site”). 
      </p><p>
      This policy explains:
      </p>
      <ul className="list-disc pl-4">
        <li>
        What information we collect, and why we collect it;
        </li>
        <li>
        How we use that information;  
        </li>
        <li>
        How we protect that information;
        </li>
        <li>
        How you can control your information, including accessing, updating and deleting what we store; and
        </li>
        <li>
        How we share information collected.
        </li>
      </ul> 

      <h3>ACCEPTANCE</h3>
      <p>
      You should review this policy carefully, and be sure you understand it, prior to using the Site.  Your use of the Site is deemed to be acceptance of this policy.  If you do not agree to this policy, you should not use, and should immediately terminate your use of, the Site.  For purposes of this policy, accessing the Site only to review this policy is not deemed to be use of the Site.
      </p>

      <h3>INFORMATION WE COLLECT</h3>

      <h3><i>Information Voluntarily Provided </i></h3>
      <p>We may collect or record basic personal data which you voluntarily provide through completing forms on the Site, through questions you send to us, or through other means of communication between you and us. The categories of personal information you provide may include: 
      ‍</p>
      <ul className="list-disc pl-4">
        <li>
        first and last name;
        </li>
        <li>
        job title and company name;
        </li>
        <li>
        email address;
        </li>
        <li>
        phone number mailing address;
        </li>
        <li>
        password to register with us;
        </li>
        <li>
        any other id entifier that permits us to contact you.
        </li>
      </ul>

      <h3><i>Information from Site Visits  </i></h3>
      <p>
        We collect, store and use information about your visits to the Sites. This includes the following information: 
      </p>
      <ul className="list-disc pl-4">
        <li>
        technical information, including the Internet protocol (IP) address, browser type internet service provider, device identifier, your login information, time zone setting, browser plug-in types and versions, operating system and platform, and geographical location; and
        </li>
        <li>
        information about your visits and use of the Site, including the full Uniform Resource Locators (URL), clickstream to, through and from the Site, pages you viewed and searched for, page response times, length of visits to certain pages, referral source/exit pages, page interaction information (such as scrolling, clicks and mouse-overs), and Site navigation and search terms used.
        </li>
      </ul>
      ‍

      <h3><i>ConveyMe as Data Controller </i></h3>
      <p>
      As a data controller, we will only use your personal data in compliance with applicable Canadian law, including under the GDPR if applicable, we may be required to have a legal basis for processing your personal data. Note that we may process your personal data for more than one legal basis.
      </p>

      <h3><i>Purposes for which we will Process Information</i></h3>
      <ul className="list-disc pl-4">
        <li>
        To provide you with information and materials that you request from us. It is in our legitimate interests to respond to your queries and provide any information and materials requested in order to generate and develop business. To ensure we offer an efficient service, we consider this use to be proportionate and will not be prejudicial or detrimental to you.
        </li>
        To personalize our services and products and the Sites to you.  It is in our legitimate interests to improve the Site in order to enhance your experience on the Site, to facilitate system administration and better our services. We consider this use to be proportionate and will not be prejudicial or detrimental to you.
        <li>
        To update you on services, products and benefits we offer.
        </li>
        <li>
        For direct marketing sent by email to new contacts (i.e. individuals who we have not previously engaged with), we need your consent to send you unsolicited direct marketing.
        </li>
        <li>
        To send you information regarding changes to our policies, other terms and conditions and other administrative information. It is in our legitimate interests to ensure that any changes to our policies, other terms and administrative information are communicated to you.  We consider this use to be necessary for our legitimate interests and will not be prejudicial or detrimental to you.
        </li>
        <li>
        To administer the Sites including troubleshooting, data analysis, testing, research, statistical and survey purposes;
        </li>
      </ul>

      <p>
      For all these categories, it is in our legitimate interests to continually monitor and improve our services and your experience of the Sites and to have network security. We consider this use to be necessary for our legitimate interests and will not be prejudicial or detrimental to you.
      </p>
      <ul className="list-disc pl-4">
        <li>
        To administer the Sites including troubleshooting, data analysis, testing, research, statistical and survey purposes;
        </li>
        <li>
        To help keep the Sites safe and secure.
        </li>
        <li>
        To measure or understand the effectiveness of any marketing we provide to you and others, and to deliver relevant marketing to you. 
        </li>
        <li>
        To enforce the terms and conditions and any contracts entered into with you. 
        </li>
      </ul>

      <h3>UPDATING YOUR INFORMATION AND OPTING OUT</h3>
      <p>If you do not wish to provide us with your personal data and processing such data is necessary for the performance of a contract with you and to fulfil our contractual obligations to you, we may not be able to perform our obligations under the contract between us. Where you provide consent, you can withdraw your consent at any time and free of charge, but without affecting the lawfulness of processing based on consent before its withdrawal.  No withdrawal of consent will be effective until we receive it and have had a reasonable period of time to act on it.  You can update your details or change your privacy preferences by contacting us through <a href="mailto:support@conveyme.ca">support@conveyme.ca</a>.
      ‍</p>
      <p>To review, correct, update, delete, object or otherwise limit our use of your personal data that has been provided to us, or request portability and/or details of your personal data that is held by us, please contact us by email through <a href="mailto:support@conveyme.ca">support@conveyme.ca</a> and clearly describe your request.
      </p>
      <p>If you have registered for an account with us, you can help to ensure that your personal data is accurate and up to date by logging into your account and updating your personal data.
      </p>
      <p>You may unsubscribe from marketing communications at any time by clicking the “Unsubscribe” button available at the bottom of any electronic communication we may send to you. You may also unsubscribe from any medium of communication by contacting us through <a href="mailto:support@conveyme.ca">support@conveyme.ca</a>.
      </p>
      <h3>CONVEYME AS DATA PROCESSOR </h3>
      <p>In certain cases, we also operate as a data processor and we collect, process and transfer personal data on behalf of our business customers in the provision of our services and products. In these circumstances, ConveyMe is acting as a data processor and our business customers remain the data controller in respect of personal data they provide to us.
      </p>
      <p>Our business customers remain the data controllers with respect to any personal data that they provide to us for our provision of services. To the extent that we are acting as data processor, we act in accordance with the instructions of such customers regarding the collection, processing, storage, deletion and transfer of customer data, as well as other matters such as the provision of access to and rectification of personal data. We will only use such personal data for the purposes of providing the services and products for which our business customers have engaged us.
      </p>
      <p>Our business customers are responsible for ensuring that these individuals’ privacy is respected, including communicating to the individuals in their own privacy policies who their personal data is being shared with and processed by. Where conveyMe is acting as a data processor, we will refer any request from an individual for access to personal data which we hold about them to our customer. We will not usually respond directly to the request.
      </p>
      <p>As a data processor, we may share personal data where instructed by our business customer. Where authorized by the business customer, we may also share personal data with third party service providers who work with us and who are subject to security and confidentiality obligations.
      </p>
      <p>We will retain personal data which we process on behalf of our customers for as long as appropriate to provide services and products to our customers and in accordance with any agreement with our customers or as permitted by applicable law.
      </p>
      <h3>DISCLOSURE OF YOUR PERSONAL DATA TO THIRD PARTIES</h3>

      <p>We may share your personal data with affiliates or contractors as appropriate to carry out the purposes for which the information was supplied or collected (i.e. to provide the services and products you have requested from us) or as otherwise provided in this policy. Personal data will also be shared with our third-party service providers and business partners who assist with the running of the Sites and our services and products (including Land Title Survey Authority, hosting providers, email service providers and payment processing partners). Our third-party service providers and business partners are subject to security and confidentiality obligations and are only permitted to process your personal data for specified purposes and in accordance with our instructions.
      </p>
      <p>In addition, we may disclose personal data about you when we believe that such use or disclosure is reasonably appropriate to: comply with any legal or regulatory obligation; enforce the terms of our agreements; establish, exercise or defend the rights of ConveyMe, our staff, customers or others; protect our rights, property, safety or vital interests, or the rights, property, safety or vital interests of our users or other third parties; and implement the purchase of all or substantially all of our assets, a merger, or other similar transaction that results in a change of control. 
      </p>
      <h3>SECURITY OF YOUR PERSONAL DATA</h3>

      <p>The security of your personal data is important to us. We follow generally accepted industry standards to protect the personal data received by us. We use commercially reasonable measures to safeguard personal data, which measures are appropriate to the type of information maintained, and follow applicable laws regarding the safeguarding of any such information under our control. No method of transmission over the Internet, or method of electronic storage, can be 100% secure. Therefore, we cannot guarantee the absolute security of your personal data. The Internet by its nature is a public forum, and we encourage you to use caution when disclosing information online. Often, you are in the best situation to protect yourself online. You are responsible for protecting your username and password from third party access, and for selecting passwords that are secure.
      </p>
      <h3>DATA RETENTION: HOW LONG WE KEEP YOUR PERSONAL DATA</h3>
      <p>We will retain personal data which we process on behalf of our customers for as long as appropriate to provide services and products to our customers in accordance with any agreement in place with our customers and for other legitimate purposes. When you contact us, we may keep a record of personal data contained in your communication to help solve any issues that you might be facing. Your personal data may be retained for as long as appropriate to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirement, and for other legitimate purposes.  In determining how long we will retain personal data, we will consider all relevant factors.
      </p>
      <h3>YOUR RIGHTS</h3>
      <p>You may request from us access to, correction of, blocking of and/or deletion of your personal data in line with applicable law. You may also withdraw your consent for us to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. Where your personal data is processed by us with your consent or for the performance of a contract by automated means, we will, to the extent required by applicable law, provide to you, or a third party you have chosen, your personal data in our standard export format upon request.
      </p>
      <h3>RESPONDING TO REQUESTS</h3>
      <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights) under applicable law. This is a security measure to protect personal data from being disclosed to any person who has no right to receive it.  For example, where a user has forgotten their password, we will require such extra security measures. We may also contact you to ask for further information in relation to your request to speed up our response. We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated. You will not have to pay a fee to access your personal data (or to exercise any of your other rights) under applicable law. However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances. Also, please note that we may refuse a request for blocking and/or deletion where continued processing is necessary to comply with a legal obligation or necessary for the establishment, exercise or defence of legal claims or for other purposes permitted by applicable law.
      </p>
      <h3>USE OF COOKIES</h3>
      <p>We may collect data in connection with your use of the Site using small files commonly known as “cookies”. A cookie is a small amount of data which often includes a unique identifier that is sent to your computer, mobile phone or other device from the Site and is stored on your device’s browser or hard drive. We may also collect data that your browser sends to us, such as your IP address, browser type, location, language, access time and referring website addresses. Such data may be used to analyze trends, to administer the Site, to track your movements around the Site and to gather demographic data about our visitor base as a whole. The data gathered by these cookies is in the form of aggregated anonymous data.
      </p>
      <p>By continuing to browse the Site, you are agreeing to our use of cookies.
      </p>
      <p>If you don’t want us to use cookies when you use the Site, you can set your browser to not accept cookies or notify you when you receive a cookie, giving you the chance to decide whether to accept it or decline at any time. However, if you block cookies some of the features on the Site may not function as a result.  For example, third party cookies used for load balancing, caching, content management, and third-party authentication will affect site functioning if disabled.
      </p>

      <p>You can find more information about how to do manage cookies for all the commonly used internet browsers by visiting <a href="www.allaboutcookies.org">www.allaboutcookies.org</a>. This website will also explain how you can delete cookies which are already stored on your device.
      </p>
      <p>We also note that the Site may use Google Analytics, a web analytics service provided by Google Inc. (“Google”). Google Analytics uses “cookies”, which are text files placed on your computer, to help the Site analyze how users use the Site. The information generated by the cookie about your use of the Site (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the Site, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of the Site. By using the Site, you consent to the processing of data about you by Google in the manner and for the purposes set out above.
      </p>
      <h3>IP ADDRESSES AND AGGREGATE INFORMATION</h3>
      <p>An Internet Protocol (“IP”) address is associated with your computer’s connection to the internet. We may use your IP address to help diagnose problems with our server, to administer the Site and to maintain contact with you as you navigate through the Site. Your computer’s IP address also may be used to provide you with information based upon your navigation through the Site. Aggregate information is used to measure the visitors’ interest in, and use of, various areas of the Site and the various programs that we administer. We will rely upon aggregate information, which is information that does not identify you, such as statistical and navigational information. With this aggregate information, we may undertake statistical and other summary analyses of the visitors’ behaviours and characteristics. Although we may share this aggregate information with third parties, none of this information will allow anyone to identify you, or to determine anything else personal about you. 
      </p>
      <h3>LINKS TO THIRD PARTIES</h3>
      <p>Our Site may contain links to third party websites. Examples of these links include: Title insurance companies and municipal property taxing authorities. These third-party websites are operated by companies that are outside of our control, and your activities at those third-party websites will be governed by the policies and practices of those third parties. ConveyMe does not in any way endorse or make any representations about such third-party websites and applications. As such, ConveyMe is not responsible for the privacy practices or content of such third-party websites and applications that are subject to their own privacy policies. If you choose to access such links, we encourage you to review all third-party site privacy policies before submitting any of your personal data.
      </p>
      <h3>COMPLAINTS</h3>
      <p>If we receive formal written requests or complaints, we will follow up with the party making the request or complaint. To the extent required by applicable law, where we would not properly address your request, you have the right to lodge a complaint with the competent data protection authority in your locality.
      </p>
      <p>Otherwise, any complaint by you regarding personal data or otherwise relating to this policy must first be submitted to ConveyMe by email to <a href="mailto:support@conveyme.ca">support@conveyme.ca</a> and ConveyMe must be given a reasonable opportunity of not less than 30 days to investigate and respond to your complaint.  Upon completing such investigation and responding, we and you must then, in good faith, attempt to promptly resolve any remaining aspects of your complaint.  If any aspect of your complaint remains unresolved after an additional reasonable period of not less than 30 days, you may commence arbitration proceedings in connection with the unresolved portion of your complaint only in a venue located in the province of British Columbia. You consent to any such venue in British Columbia being a proper venue for such arbitration, and waive any right to object to such venue for inconvenience or otherwise.
      </p>
      <h3>CONTACTING US</h3>
      <p>We have appointed a Data Protection Contact who you can reach out to about any queries you may have in relation to this policy. If you have any questions about this policy or your information, or to exercise any of your rights as described in this policy or under applicable laws, you can contact us as follows:
      </p>
      <div>
        <p>ConveyMe Software Inc.</p>
        <p>1220 Duchess Avenue,</p>
        <p>West Vancouver, BC V7T 1H4</p>
        Email: <a href="mailto:support@conveyme.ca">support@conveyme.ca</a>
      </div>

      <h3>CHANGES TO THIS POLICY</h3>
      <p>We may change this policy from time to time. If this policy changes, the revised policy will be posted at the “Privacy Policy” link on the Site’s home page. In the event that the change is significant or material, we will notify you of such a change by revising the link on the home page to read “Newly Revised Privacy Policy.” Please check this policy on the site frequently. Your continued use of the Site constitutes acceptance of such changes in this policy, except where further steps are required by applicable law. This policy was last updated on the date set out at the end of the policy.
      </p>
      <h3>LAST UPDATED: <span className="font-normal">May 3, 2022</span></h3>

    </PaddedPage>
  );
};

export default NotAuthorized;
