import React from 'react';
import ButtonRoot from './Shared/ButtonRoot';

const RoundedButton = ({className, white, ...rest}) => {
  return (
    <ButtonRoot 
      className={`
        py-4
        px-12
        font-semibold 
        rounded-3xl
        hover:no-underline
        ${white ? 'bg-white text-dark-grey ' : 'bg-primary text-white'} 
        ${className}
      `}
      {...rest}
    />
  );
};

export default RoundedButton;
