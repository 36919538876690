import React from 'react'

export default function LoadingText({color, random, className}) {

  return (
    <span className={`animate-pulse rounded ${color ? `bg-${color} text-${color} opacity-10` : 'bg-light-light-grey text-light-light-grey'} ` + className}>
      { random ? "-----".repeat(Math.random()*10 + 1) : "-- ---- ----"}
    </span>
  )
}
