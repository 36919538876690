import axios from 'axios';
import { PaddedPage } from 'components/common/Layout';
import Loading from 'components/common/Loading';
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

export default function ConfirmEmail() {
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (params.authUUID) {
      return axios.post(`/confirm/email`, {authUUID: params.authUUID})
      .then(res => {
        console.log("res", res);
        navigate('/login?confirm=true');
      })
      .catch(err => {
        navigate('/login?confirm=true');
      })
    }
  }, [params.authUUID, navigate]); 
  return (
    <PaddedPage><Loading/></PaddedPage>
  )
}
