import React, {useContext, useEffect, useState} from 'react';
import useSurvey from 'hooks/useSurvey';
import useSurveyPage from 'hooks/useSurveyPage';
import useSurveyFile from 'hooks/useSurveyFile';
import SurveyContext from 'contexts/SurveyContext';
import useSurveyPages from 'hooks/useSurveyPages';
import BugContext from 'contexts/BugContext';

const SurveyProvider = ({ children, fileId, surveyId, currentPage }) => {
  const {updateBugContext} = useContext(BugContext);
  const { survey } = useSurvey(surveyId);
  const [showPaths, setShowPaths] = useState(false);
  const [manualLoading, setManualLoading] = useState(false);
  const { 
    file, 
    updateFile, 
    updateAnswers, 
    newFile, 
    errors, 
    updateErrors, 
    updateTotalsAndCalculations,
    updateCalculation,
    loading,
    totals 
  } = useSurveyFile(fileId, survey);
  const { pages } = useSurveyPages(survey, file);

  useEffect(() => {
    updateBugContext({fileId, surveyId});
    return () => {
      updateBugContext({fileId: null, surveyId: null});
    }
  }, [fileId, surveyId, updateBugContext]);

  const page = pages && pages[currentPage - 1];
  const { pageComponents } = useSurveyPage(page, showPaths);
  const pageLength = pages && pages.length;
  const [surveyNav, setSurveyNav] = useState();
  return (
    <SurveyContext.Provider value={{ 
      pageComponents, 
      currentPage, 
      pageLength,
      page,
      pages,
      file,
      showPaths,
      updateFile,
      updateAnswers,
      updateErrors,
      newFile,
      setShowPaths,
      errors, 
      surveyId,
      fileId,
      surveyNav,
      setSurveyNav,
      loading: loading || manualLoading,
      setLoading: setManualLoading,
      totals,
      updateTotalsAndCalculations,
      updateCalculation
      }} 
    >
      {children}
    </SurveyContext.Provider>
  );
};

export default SurveyProvider;
