import BugReports from './BugReports';
import BugReport from './BugReport';
import React from 'react';
import { Route } from 'react-router-dom';

const routes = [
  <Route key="bug-report" path="bug-reports/:id" element={<BugReport/>} />,
  <Route key="bug-reports" path="bug-reports" element={<BugReports/>} />,
];

export default routes;
