import { gql, useMutation, useQuery } from '@apollo/client';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullWidthPage } from 'components/common/Layout';
import Input, { Checkbox, List } from 'components/inputs';
import SurveyContext from 'contexts/SurveyContext';
import useExportCSV from 'hooks/useExportCSV';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import { formatCurrency } from 'selectors/formSelectors';
import TrustAccountInput from './TrustAccountInput';
import DownloadCheque from './DownloadCheque';
import useTrustAccountChoices from 'hooks/useTrustAccountChoices';
import DocumentGroup from 'components/documents/DocumentGroup';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import ButtonPrimary from 'components/common/Button';
import ActivePageContext from 'contexts/ActivePageContext';

// get trust account items for this file
const GET_TRUST_ACCOUNT_ITEMS = gql`
  query GetTrustAccountItems($filters: JSONObject, $firmId: String, $fileId: String, $trustAccountId: String) {
    trustAccountItems(filters: $filters, firmId: $firmId, fileId: $fileId, trustAccountId: $trustAccountId) {
      id,
      firmId,
      name,
      value,
      calculation,
      date,
      chequeDepositNumber,
      surveyId,
      payee,
      fileId,
      trustAccountId,
      type,
      cleared,
      void,
      payee,
      file {
        fileNumber
      }
    }
  }
`;

const GET_DOCUMENTS = gql`
  query GetDocuments($firmId: String, $type: String, $filters: JSONObject) {
    documents(firmId: $firmId, type: $type, filters: $filters) {
      id,
      name,
      category,
      firmId,
      type
    }
  }
`;

const GET_TRUST_ACCOUNT = gql`
  query GetTrustAccount($id: String) {
    trustAccount(id: $id) {
      id,
      name,
      transitNumber,
      accountNumber,
      institutionNumber,
      chequeNumber,
      firmId,
      bank {
        id,
        companyName,
        email,
        phoneNumber,
        fax,
        type,
        addressLineOne,
        addressLineTwo,
        city,
        province,
        postalCode,
        incorporationNumber,
        gstNumber,
      }
    }
  }
`;

export default function ChequesCleared({filters}) {
  const {setActivePage, trustAccountId, from, to, firmId, setExportCSV} = useContext(ActivePageContext);
  const {trustAccountChoices} = useTrustAccountChoices(true, firmId);
  const [selectedTrustAccount, setSelectedTrustAccount] = useState();
  const [newLineItem, setNewLineItem] = useState();
  const { loading, error, data, refetch } = useQuery(GET_TRUST_ACCOUNT_ITEMS, {
    variables: {firmId, trustAccountId, filters: {clearedFrom: from, clearedTo: to}},
    fetchPolicy:'no-cache',
    onCompleted: (res) => {
      setSelectedTrustAccount(res.trustAccountItems[0]?.trustAccountId)
    }
  });
  setActivePage("chequesCleared")
  const { loading:loadingDocs, error:errorDocs, data:dataDocs } = useQuery(GET_DOCUMENTS, {
    variables: {firmId, type: "trust", filters: {search: "Cleared"}},
    fetchPolicy:'no-cache'
  });
  const { loading:loadingTrust, error:errorTrust, data:dataTrust } = useQuery(GET_TRUST_ACCOUNT, {
    variables: {id: selectedTrustAccount},
    skip: !selectedTrustAccount,
    fetchPolicy:'no-cache'
  });
  const [startingBalance, setStartingBalance] = useState(0);

  const filteredItems = useMemo(() => {
    let filteredData = data?.trustAccountItems.filter(item => ((item.value < 0) && !item.void && item.cleared));
    return filteredData?.sort((a,b) => new Date(a.date) - new Date(b.date));
  }, [data, filters]);

  const totals = useMemo(() => {
    return [...(filteredItems || []), newLineItem].reduce((prev, current) => {
      if(!current) return prev;
      if (current.void) {
        prev.void += Number(current.value);
        prev.balances.push((prev.balances.at(-1) || 0));
        prev.totalBalances.push((prev.totalBalances.at(-1) || 0));
        return prev;
      };
      current.value = current.value || 0;
      if (current.value > 0) {
        prev.in += Number(current.value);
        prev.balances.push((prev.balances.at(-1) || 0) + (current.cleared ? Number(current.value) : 0));
        prev.totalBalances.push((prev.totalBalances.at(-1) || 0) + Number(current.value));
      } else {
        prev.out += Number(current.value) * -1;
        if (current.cleared) {
          prev.clearedOut += Number(current.value) * -1;
        }
        prev.balances.push((prev.balances.at(-1) || 0) + (current.cleared ? Number(current.value) : 0));
        prev.totalBalances.push((prev.totalBalances.at(-1) || 0) + Number(current.value));
      }
      if (current.type === 'cheque') {
        prev.cheques += Math.abs(Number(current.value));
        if (current.value < 0) {
          prev.chequesOut += Math.abs(Number(current.value));
        }
      } else if (current.type === 'eft' && current.value < 0) {
        prev.efts += Math.abs(Number(current.value));
      } else if (current.type === 'bank draft') {
        prev.bankDrafts += Math.abs(Number(current.value));
        if (current.value < 0) {
          prev.bankDraftsOut += Math.abs(Number(current.value));
        }
      } else if (current.type === 'transfers' || current.type === 'file transfer') {
        prev.transfers += Math.abs(Number(current.value));
        if (current.value < 0) {
          prev.transfersOut += Math.abs(Number(current.value));
        }
      }
      return prev;
    }, {in: 0, out: 0, void: 0, bankDrafts: 0, bankDraftsOut: 0, transfers: 0, transfersOut: 0, efts: 0, cheques: 0, clearedOut: 0, chequesOut: 0, totalBalances: [Number(startingBalance)], balances: [Number(startingBalance)]}) || {in: 0, out: 0, balances: [], totalBalances: []};
  }, [data, startingBalance, newLineItem]);

  return (
    <div className="flex flex-col gap-4 pb-12">
      <div className="bg-light-grey rounded p-2">
        <table className="w-full bg-light-grey border-spacing-2">
          <tbody>
            <tr className="border-b border-dark-grey">
              <th>File Matter</th>
              <th>Description</th>
              <th>Date Deposited</th>
              <th>Cleared</th>
              <th>Void/Cancelled</th>
              <th>Cheque/Deposit Number</th>
              <th>Type</th>
              <th>TrustAccountID</th>
              <th>In</th>
              <th>Out</th>
              <th>Balance</th>
              <th></th>
            </tr>
            <tr className="border-t border-med-grey">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="text-right"></td>
              <td className="text-right"></td>
              <td className="text-right flex items-center justify-center">$<Input className="bg-transparent text-right w-24" type="number" valueOverride={startingBalance} onChange={setStartingBalance}/></td>
              <td></td>
            </tr>
            {filteredItems?.map((item, i) => (
              <tr key={i} className={`group relative ${i%2 === 0 ? 'bg-transparent-grey' : ''}`}>
                <td className="p-1"><Link to={`/files/${item.surveyId}/${item.fileId}/trust-account`}>{item.file?.fileNumber}</Link></td>
                <td className="p-1">{item.name}</td>
                <td className="p-1 text-center"><Input disabled={true} className="bg-transparent" type="date" valueOverride={new Date(item.date).toISOString().split('T')[0]} /></td>
                <td className="p-1 text-center"><Input disabled={true} min={new Date(item.date).toISOString().split('T')[0]} className="bg-transparent" type="date" valueOverride={item.cleared && new Date(item.cleared)?.toISOString().split('T')[0]} /></td>
                <td className="p-1 text-center"><Checkbox readOnly={true} key={i + item.void} className="bg-transparent" valueOverride={item.void} initialValue={item.void} /></td>
                <td className="p-1 text-center"><Input disabled={true} className="w-20 text-center bg-transparent shadow-none" valueOverride={item.chequeDepositNumber || ''} /></td>
                <td className="p-1 text-center">
                  <List
                    key={i}
                    className="bg-transparent"
                    readOnly={true}
                    options={[
                      {name: "-", value: ''},
                      {name: "Bank Draft", value: 'bank draft'},
                      {name: "Cheque", value: 'cheque'},
                      {name: "EFT", value: 'eft'},
                      {name: "Account Transfer", value: 'transfers'},
                      {name: "File Transfer", value: 'file transfer'},
                      {name: "Float", value: 'float'},
                    ]}
                    valueOverride={item.type}
                  />             
                </td>
                <td className="p-1 text-center">
                  <List
                    key={i + item.trustAccountId}
                    readOnly={true}
                    className="bg-transparent"
                    options={(trustAccountChoices || [])}
                    valueOverride={item.trustAccountId}
                  />
                </td>
                <td className={`text-right p-1`}><Input type="number" className={`bg-transparent w-32 text-right ${item.void ? 'line-through' : ''}`} valueOverride={item.value > 0 ?  formatCurrency(item.value) : 0}/></td>
                <td className={`text-right p-1`}><Input type="number" className={`bg-transparent w-32 text-right ${item.void ? 'line-through' : ''}`} valueOverride={item.value < 0 ? formatCurrency(item.value * -1) : 0}/></td>
                {/* <td className={`text-right p-1 ${item.void ? 'line-through' : ''}`}>{item.value > 0 ? formatCurrency(item.value) : ''}</td>
                <td className={`text-right p-1 ${item.void ? 'line-through' : ''}`}>{item.value < 0 ? formatCurrency(item.value * -1) : ''}</td> */}
                <td className={`text-right ${item.cleared ? '' : 'text-med-grey'}`}>{formatCurrency(totals.balances[i+1])}</td>
              </tr>
            ))}
            <tr className="border-t border-med-grey">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="text-right pt-2">{formatCurrency(totals.in)}</td>
              <td className="text-right pt-2">{formatCurrency(totals.out)}</td>
              <td className="text-right pt-2">{formatCurrency(totals.balances.at(-1))}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      {dataDocs && 
        <div className="relative">
          <DocumentGroup
            // zip={zip} 
            title="Trust Documents" 
            documents={dataDocs?.documents}
            extraData={{
              trustAccount: dataTrust?.trustAccount,
              trustAccountItems: data?.trustAccountItems,
              trustAccountItemsOut: data?.trustAccountItems.filter(item => item.value < 0),
              trustAccountItemsIn: data?.trustAccountItems.filter(item => item.value > 0),
              totals: totals,
              to: to,
              from: from
            }}
          />
        </div>
      }
    </div>
  )
}
