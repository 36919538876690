import React, {useContext, useMemo} from 'react';
import SurveyContext from 'contexts/SurveyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { pageValid } from 'selectors/formSelectors';
import { Link } from 'react-router-dom';


export default function SurveyChapterItem({page, pageNumber, currentPage}) {
  const { file, fileId, surveyId } = useContext(SurveyContext);
  const filled = useMemo(() => {
    return pageValid(file, page);
  }, [file, page])
  return (
    <Link className="no-underline hover:bg-light-grey border border-grey" to={`/files/${surveyId}/${fileId}/${pageNumber + 1}`}>
      <div className={`py-2 px-4 flex gap-2 justify-between items-center ${pageNumber + 1 === currentPage && 'bg-dark-grey text-white'}`}>
            {page.title}
          { filled &&
          <FontAwesomeIcon className="text-green" icon={faCircleCheck} />
        }
      </div>
    </Link>
  )
}
