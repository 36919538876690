import _ from 'lodash';
import helperCalculations from 'helpers/calculation';
import converter from 'number-to-words';

const addSurveyValue = (answers, path, value) => {
  const newAnswers = _.cloneDeep(answers);
  path = path.replaceAll(']', '');
  let keys = path.split(/\.|\[/);
  let current = newAnswers;
  for (let [i, key] of keys.entries()) {
    if (i == keys.length -1) {
      if (value === undefined) {
        delete current[key];
      } else {
        current[key] = value;
      }
    } else {
      if (!current[key]) {
        if(Number(keys[i + 1]) || keys[i + 1] == 0) {
          current[key] = [];
        } else {
          current[key] = {};
        }
      }
      current = current[key];
    }
  }
  return newAnswers;
}

const getSurveyValue = (answers, path) => {
  path = 'answers.' + path;
  try {
    return toSurveyValue(eval(path));
  } catch(e) {
    return undefined;
  }
}

const addSurveyTotal = (totals, total, path) => {
  totals = totals || {};
  if (!totals[total]) {
    totals = {...totals, [total]: []}
  }
  if (!totals[total].includes(path)) {
    totals = {...totals, [total]: [...totals[total], path]};
  }
  return totals;
}

const getTotalValue = (answers, totals, totalName) => {
  let paths = totals && totals[totalName];
  if (!paths) {
    return null;
  }
  let total = 0;
  for (let path of paths) {
    let value = Number(getSurveyValue(answers, path));
    total += value ? Math.round(value *100)/100 : 0;
  }
  return total;
}

const formatCurrency = (value) => {
  value = (Math.round(Number(value)*100)/100) || 0;
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
}

const numbersToWords = (value) => {
  return converter.toWords(value);
}

const getCalculatedValue = (file, calculation, prevRows, updateFile, log = false) => {
  const {answers, tokens, requests, questions} = file || {};
  const {
    securtityDepositInterest,
    timeToDays,
    daysInQuarter,
    daysLeftBiAnnual,
    daysDoneBiAnnual,
    daysFromStartOfQuarter,
    daysLeftInQuarter,
    percentOfMonthLeft,
    percentOfMonth,
    daysInYear,
    daysBetween,
    countryLookup
  } = helperCalculations;
  let templog = false;
  if (calculation === 'requests.getTitleSummary[$1].response.titleSummaries[0].titleNumber') {
    templog = true;
  }
  for (let i = 0; i < (prevRows || []).length; i++) {
    calculation = calculation.replace(new RegExp('\\$' + (i+1), 'g'), prevRows[i]);
  }
  try {
    return toSurveyValue(eval(`(${calculation})`));
  } catch (e) {
    log && console.log("error", e, calculation);
    return undefined;
  }
}

const toSurveyValue = (val) => {
  if (Number(val)) {
    return val;
  } else if (val == 'true') {
    return true;
  } else if (val == 'false') {
    return false;
  } else {
    return val;
  }
}

// const getNestedVariables = (nesting, queryVaraibles) => {
//   if(!nesting) return [];
//   if(!queryVaraibles) return nesting.nestedVariables;
//   let newNested = [];
//   if (nesting.nestedVariables.length < 1) {
//     for (let valueGroup of queryVaraibles) {
//       newNested.push(valueGroup[0]);
//     }
//   } else {
//     for (let [index, nested] of nesting.nestedVariables.entries()) {
//       if(queryVaraibles[index]){
//         if (queryVaraibles[index][nested]) {
//           newNested.push(queryVaraibles[index][nested]);
//         } else {
//           return null;
//         }
//       } else {
//         newNested.push(nested);
//       }
//     }
//   }
//   return newNested;
// }

const getQuestionTotals = (questions, totals, file, prevPath) => {
  let path = prevPath ? prevPath + (prevPath.at(-1) !== "." ? "." : "") : "";
  for(let {question} of questions) {
    let currentPath = path + (question.skipPath ? '' : (question.key || _.camelCase(question.label)));
    if (question.showCalculation) {
      if (!getCalculatedValue(file, question.showCalculation)) continue;
    } else if (question.hideCalculation) {
      if (!getCalculatedValue(file, question.hideCalculation)) continue;
    }
    if (question.questions) {
      // console.log("test", question.questions, totals, answers, currentPath);
      if (question.same) {
        for (let [i, currentQuestion] of question.questions.entries()) {
          // calculations = getQuestionCalculations([currentQuestion], calculations, file, totals, `${currentPath}[${i}]`, prevRows ? [...prevRows, i] : [i]);
          totals = getQuestionTotals(question.questions, totals, file, `${currentPath}[${i}]`);
        }
      } else {
        totals = getQuestionTotals(question.questions, totals, file, currentPath);
      }
    } else if (question.questionRef) {
        const currentAnswers = getSurveyValue(file.answers, currentPath) || [];
        for (let i = 0; i < currentAnswers.length; i++) {
          let currentQuestion = question.questionRef.question;
          let subPath = `${currentPath}[${i}].${currentQuestion.key || _.camelCase(currentQuestion.label)}`
          if(currentQuestion.questions) {
            totals = getQuestionTotals(currentQuestion.questions, totals, file, subPath);
          } else if (currentQuestion.total) {
            totals = addSurveyTotal(totals, currentQuestion.total, subPath);
          }
        }
    } else if (question.total) {
      totals = addSurveyTotal(totals, question.total, currentPath);
    } 
  };
  return totals;
}

const getSurveyTotals = (pages, file) => {
  let totals = {};
  if (!file || !file.answers) return totals;
  for(let page of pages) {
    totals = getQuestionTotals(page.questions, totals, file);
  }
  return totals;
}

const getQuestionCalculations = (questions, calculations, file, totals, prevPath, prevRows) => {
  let path = prevPath ? prevPath + (prevPath.at(-1) !== '.' ? "." : '') : "";
  for(let {question} of questions) {
    let currentPath = path + (question.skipPath ? '' : (question.key ||  _.camelCase(question.label)));
    if (question.cache) continue;
    if (question.showCalculation) {
      if (!getCalculatedValue(file, question.showCalculation)) continue;
    } else if (question.hideCalculation) {
      if (!getCalculatedValue(file, question.hideCalculation)) continue;
    }
    if (question.questions) {
      if (question.same) {
        for (let [i, currentQuestion] of question.questions.entries()) {
          calculations = getQuestionCalculations([currentQuestion], calculations, file, totals, `${currentPath}[${i}]`, prevRows ? [...prevRows, i] : [i]);
        }
      } else {
        calculations = getQuestionCalculations(question.questions, calculations, file, totals, currentPath, prevRows);
      }
    } else if (question.questionRef) {
      let answers = getSurveyValue(file.answers, currentPath);
      for (let i = 0; i < (answers || []).length; i ++) {
        calculations = getQuestionCalculations([question.questionRef], calculations, file, totals, `${currentPath}[${i}]`, prevRows ? [...prevRows, i] : [i]);
      }
    } else if (question.calculation) {
      if (!question.writable) {
        calculations = addSurveyValue(calculations, currentPath, getCalculatedValue(file, question.calculation, prevRows));
      }
      // calculations[currentPath] = getCalculatedValue(file, question.calculation);
    }
    if (question.total) {
      calculations[question.total] = getTotalValue(file.answers, totals, question.total);;
    }
  };
  return calculations;
}
const getSurveyCalculations = (pages, file, totals) => {
  let calculations = {};
  for(let page of pages) {
    calculations = getQuestionCalculations(page.questions, calculations, file, totals);
  }
  return calculations;
}

const getQuestionErrorMessages = (questions, errorMessages, file, pageTitle, prevPath, prevRows, nested) => {
  let path = prevPath ? prevPath + "." : "";
  for(let {question} of questions) {
    let currentPath = path + (question.skipPath ? '' : (question.key ||  _.camelCase(question.label)));
    if (question.questions) {
      if (question.same) {
        for (let [i, currentQuestion] of question.questions.entries()) {
          errorMessages = getQuestionErrorMessages([currentQuestion], errorMessages, file, pageTitle, `${currentPath}[${i}]`, prevRows ? [...prevRows, i] : [i], (question.label && !question.hideLabel && !nested) ? ` in ${question.label}`  : nested);
        }
      } else {
        errorMessages = getQuestionErrorMessages(question.questions, errorMessages, file, pageTitle, currentPath, prevRows,  (question.label && !question.hideLabel && !nested) ? ` in ${question.label}`  : nested);
      }
      if(question.kind === 'Business') {
        errorMessages[`answers.${currentPath}.companyName`] = `Company Name in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.email`] = `Email in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.phoneNumber`] = `Phone Number in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.fax`] = `Fax in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.addressLineOne`] = `Address Line One in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.addressLineTwo`] = `Address Line Two in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.gstNumber`] = `GST Number in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.incorporationNumber`] = `Incorporation Number in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.province`] = `Province in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.city`] = `City in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.postalCode`] = `Postal Code in ${nested || question.label} on ${pageTitle} page`;
      
      } else if(question.kind === 'Person') {
        errorMessages[`answers.${currentPath}.firstName`] = `First Name in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.lastName`] = `Last Name in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.middleName`] = `Middle Name in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.title`] = `Title in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.gender`] = `Gender in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.canadianCitizen`] = `Canadian Citizen in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.email`] = `Email in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.phoneNumber`] = `Phone Number in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.addressLineOne`] = `Address Line One in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.addressLineTwo`] = `Address Line Two in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.province`] = `Province in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.city`] = `City in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.country`] = `Country in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.postalCode`] = `Postal Code in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.occupation`] = `Occupation in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.dateOfBirth`] = `Date Of Birth in ${nested || question.label} on ${pageTitle} page`;
        errorMessages[`answers.${currentPath}.sin`] = `SIN in ${nested || question.label} on ${pageTitle} page`;
      }
    } else if (question.questionRef) {
      let answers = getSurveyValue(file.answers, currentPath);
      for (let i = 0; i < (answers || []).length; i ++) {
        errorMessages = getQuestionErrorMessages([question.questionRef], errorMessages, file, pageTitle, `${currentPath}[${i}]`, prevRows ? [...prevRows, i] : [i],  (question.label && !question.hideLabel && !nested) ? ` in ${question.label}`  : nested);
      }
    } else if (question.label) {
      errorMessages[`answers.${currentPath}`] = `${question.label}${nested || ''} on ${pageTitle} page`;
    }
  };
  return errorMessages;
}
const getSurveyErrorMessages = (pages, file) => {
  let errorMessages = {};
  for(let page of pages) {
    errorMessages = getQuestionErrorMessages(page.questions, errorMessages, file, page.title);
  }
  return errorMessages;
}

const isFieldEmpty = (answer) => {
  if (Array.isArray(answer)) {
    for (let subAnswer of answer) {
      if (!isFieldEmpty(subAnswer)) return false;
    }
  } else if (answer && typeof answer === 'object') {
    let answerCpy = {...answer};
    // if empty answer was submitted with survey it will have been assigned an id
    delete answerCpy._id;
    return isFieldEmpty(Object.values(answerCpy));
  } else if (answer) return false
  return true;
}

const pageValid = (file, page) => {
  if (!file || !file.answers) return;
  for (let {question} of page.questions) {
    let paths = pathDig(question, null, file);
    for (let path of paths) {
      let value = getSurveyValue(file.answers, path);
      if (value === undefined || value === "" || value === null) {
        return false;
      }
    }
  }
  return true;
}

const pathDig = (question, path, file) => {
  let currentPath = path ? path + "." : "";
  currentPath = currentPath +  (question.key || _.camelCase(question.label));
  let paths = [];
  if (question.showCalculation) {
    if (!getCalculatedValue(file, question.showCalculation)) return paths;
  } else if (question.hideCalculation) {
      if (!getCalculatedValue(file, question.hideCalculation)) return paths;
  }
  if (question.kind === "Array") {
    // TODO add required check to arrays
    // return [currentPath + '[0]'];
    return paths;
  } else if (question.kind === "List") {
    for (let option of question.options) {
      if(!option.value) {
        return paths;
      }
    }
    return [...paths, currentPath]
  } else if (question.kind === "Calculated"){
    if (question.required) {
      return [...paths, currentPath];
    }
    return paths;
  } else if (["Request", "Text", "LTSAcredentials", "Categorizer", "AnswerTemplate"].includes(question.kind)) {
    return paths;
  } else if (question.questionRef) {
    return pathDig(question.questionRef.question, currentPath, file);
  } else if (question.questions) {
    if ((["Business", "Person", "Mortgage"].includes(question.kind))) {
      paths = [...paths, currentPath]
    }
    let startPath = currentPath;
    // for (let [i, {question:currentQuestion}] of Object.entries(question.questions)) {
    //   if (question.same === true) {
    //     currentPath = `${startPath}[${i}]`
    //   }
    //   paths = [...paths, ...pathDig(currentQuestion, question.skipPath ? path : currentPath, file)]
    // }
    return paths;
  }
  return [currentPath];
}

const pathMaker = (key, parentPath, row) => {
  if (parentPath) {
    return `${parentPath}${((row || ((row !== null) && Number(row) === 0)) ? `[${row}]` : '')}${key && `.${key}`}`
  } else {
    return key;
  }
}

export {
  addSurveyValue,
  getSurveyValue,
  addSurveyTotal,
  getTotalValue,
  getCalculatedValue,
  // getNestedVariables,
  formatCurrency,
  numbersToWords,
  getSurveyTotals,
  getSurveyErrorMessages,
  isFieldEmpty,
  pageValid,
  getSurveyCalculations,
  pathMaker
}