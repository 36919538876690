import React from 'react';
import { FullWidthPage } from 'components/common/Layout';
import ReccentSurveyFiles from 'components/survey/ReccentSurveyFiles';
import Calendar from 'components/calendar';

const HomePage = () => {
  return (
    <FullWidthPage bottom>
      <h1 className="mt-4">Home Page</h1>
      <div className="flex flex-col">
        <div className="py-4">
          <ReccentSurveyFiles/>
        </div>
        <div className="py-4">
          <Calendar/>
        </div>
      </div>
    </FullWidthPage>
  );
};

export default HomePage;
