import React from 'react';
import { Route, Outlet } from 'react-router-dom';
import AdminRoute from 'components/routes/AdminRoute';
import DocumentRoutes from './document';
import UserRoutes from './user';
import FirmRoutes from './firm';
import BusinessRoutes from './businesses';
import MortgageRoutes from './mortgage';
import AdminNav from './AdminNav';
import AdminHome from './AdminHome';
import Users from './user/Users';
import BugReportRoutes from './bugReports';
import PaymentsRoutes from './payments';

const routes = [
  <Route key="admin" path="/admin" element={<AdminRoute><AdminNav><Outlet/></AdminNav></AdminRoute>}>
    {[
      ...DocumentRoutes,
      ...UserRoutes,
      ...FirmRoutes,
      ...BusinessRoutes,
      ...MortgageRoutes,
      ...BugReportRoutes,
      ...PaymentsRoutes
    ]}
    <Route key="adminindex" path="/admin" element={<Users/>}/>
  </Route>
];

export default routes;
