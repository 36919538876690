import React from 'react';
import { Route } from 'react-router-dom';
import TermsAndConditions from './TermsAndConditions.jsx';
import PrivacyPolicy from './PrivacyPolicy.jsx';

const routes = [
  <Route key="terms-and-conditions" path="/terms-and-conditions" element={<TermsAndConditions/>}/>,
  <Route key="privacy" path="/privacy" element={<PrivacyPolicy/>}/>
];

export default routes;
