import React from 'react';

const FullWidthPage = ({ children, className, top, bottom }) => {
  return (
    <div className={`
      sm:px-2
      md:px-4 
      lg:px-6 
      xl:px-8
      ${top && `
      sm:pt-2
      md:pt-4 
      lg:pt-6 
      xl:pt-8
      `}
      ${bottom && `
      sm:pb-2
      md:pb-4 
      lg:pb-6 
      xl:pb-8
      `}
      ${className}
      `}
    >
      {children}
    </div>
  );
};

export default FullWidthPage;

