// window.autoCompleteSearchBar = null;
// window.initAutoComplete = (input) => {
//   try{
//     const options = {
//       componentRestrictions: { country: 'can' }
//     };

//     let test = window.google && new window.google.maps.places.Autocomplete(window.autoCompleteSearchBar || input, options);
//     // autocomplete && autocomplete.setFields([, "name"]);
//     console.log("test", test, window.google);
//   }catch (e) {
//     console.log('error', e);
//   }
// };

// export default function locationAutocomplete(input) {
//   window.autoCompleteSearchBar = input;
//   window.initAutoComplete(input);
// }
// firm, notary public, relator, broker

const typeLookup = {
  firm: ['lawyer'],
  'notary public': ['lawyer'],
  relator: ['real_estate_agency'],
  broker: ['bank'],
  bank: ['bank'],
}

window.autoCompleteSearchBar = null;
window.initAutoComplete = (input, options) => {
  try{
    options = {
      componentRestrictions: { country: 'can' },
      fields: ['name', 'address_components', 'international_phone_number'],
      ...options
    };
    return window.google && new window.google.maps.places.Autocomplete(window.autoCompleteSearchBar || input, options);
    // autocomplete && autocomplete.setFields([, "name"]);
  }catch (e) {
    console.log('error', e);
  }
};
export default function locationAutocomplete(input, inTypes) {
  window.autoCompleteSearchBar = input;
  let types = [];
  for(let type of (inTypes || [])) {
    let lookup = typeLookup[type];
    if (lookup) types = [...types, ...lookup];
  }
  let options = {
    types
  };
  return window.initAutoComplete?.(input, options);
}
