import { useMutation, useQuery } from '@apollo/client';
import { faFile } from '@fortawesome/free-regular-svg-icons'
import { faCircleMinus, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ButtonPrimary from 'components/common/Button';
import Loading from 'components/common/Loading';
import Input, { List } from 'components/inputs';
import gql from 'graphql-tag';
import React, { useState } from 'react'
import { useMemo } from 'react';
import _ from "lodash";
import ConfirmNotice from 'components/notices/ConfirmNotice';

const GET_DOCUMENT_GROUP_TEMPLATES = gql`
  query GetDocumentGroupTemplates($firmId: String, $surveyId: String) {
    documentGroupTemplates(firmId: $firmId, surveyId: $surveyId) {
      id,
      name,
      documents
    }
  }
`;

const ADD_DOCUMENT_GROUP_TEMPLATE = gql`
  mutation AddDocumentGroupTemplate($firmId: String, $name: String!, $surveyId: String!, $documents: [String]!) {
    newDocumentGroupTemplate(firmId: $firmId, name: $name, surveyId: $surveyId, documents: $documents) {
      id
    }
  }
`;

const UPDATE_DOCUMENT_GROUP_TEMPLATE = gql`
  mutation updateDocumentGroupTemplate($id: String!, $documents: [String]!) {
    updateDocumentGroupTemplate(id: $id, documents: $documents) {
      id
    }
  }
`;

const REMOVE_TEMPLATE = gql`
  mutation removeDocumentGroupTemplate($id: String!) {
    removeDocumentGroupTemplate(id: $id) {
      id
    }
  }
`;

export default function DocumentGroupTempltes({setEnabled, enabled, surveyId, firmId}) {
  const { data:templateData, refetch } = useQuery(GET_DOCUMENT_GROUP_TEMPLATES, {
    variables: { surveyId, firmId }, 
    skip: !surveyId,
    fetchPolicy:'no-cache',
  });
  const [selected, setSelected] = useState("");
  const [showSaveUi, setShowSaveUi] = useState();
  const [showDeleteNotice, setShowDeleteNotice] = useState(false);
  const [showUpdateNotice, setShowUpdateNotice] = useState();
  const [name, setName] = useState();
  const [addDocumentGroupTemplateMutation, { loading:addTemplateLoad }] = useMutation(ADD_DOCUMENT_GROUP_TEMPLATE);
  const [updateDocumentGroupTemplateMutation, { loading:updateTemplateLoad }] = useMutation(UPDATE_DOCUMENT_GROUP_TEMPLATE);
  const [removeGroupTemplateMutation, { loading:removeTemplateLoad }] = useMutation(REMOVE_TEMPLATE);

  const lookup = useMemo(() => {
    return templateData ? templateData.documentGroupTemplates.reduce((prev, current) => {
      prev[current.id] = current.documents;
      return prev;
    }, {}) : {};
  }, [templateData]);

  const options = useMemo(() => {
    let options = [...(templateData ? templateData.documentGroupTemplates.map(template => ({name: template.name, value: template.id})) : []), {name: 'none', value: ""}];
    for (let option of options) {
      if (_(lookup[option?.value]).difference(enabled).length === 0) {
        setSelected(option.value);
      }
    }
    return options;
  }, [templateData, lookup])

  const handleSetEnabled = () => {
    setEnabled(lookup[selected]);
  }

  const saveTemplate = () => {
    addDocumentGroupTemplateMutation({variables: {firmId, surveyId, name, documents: enabled}})
    .then((res) => {
      refetch();
      setSelected(res.data?.newDocumentGroupTemplate?.id);
      setShowSaveUi(false);
    })
    .catch(err => {
      refetch();
      setSelected("");
      setShowSaveUi(false);
    })
  }

  const updateTemplate = () => {
    updateDocumentGroupTemplateMutation({variables: {id: selected, documents: enabled}})
    .then((res) => {
      refetch();
      setShowSaveUi(false);
    })
    .catch(err => {
      console.log("err:", err);
      refetch();
      setShowSaveUi(false);
    })
  }
  const deleteTemplate = () => {
    removeGroupTemplateMutation({variables: {id: selected }})
    .then((res) => {
      refetch();
      setShowSaveUi(false);
      setShowDeleteNotice(false)
      setSelected("");
    })
    .catch(err => {
      console.log("err:", err);
      refetch();
      setShowSaveUi(false);
      setShowDeleteNotice(false)
      setSelected("");
    })
  }

  return (
    <div className="w-full">
      {showUpdateNotice && <ConfirmNotice
        title={`Are you sure?`}
        message={`This will overwrite the template, changing the saved document group. To load the document group click the load button.`}
        onConfirm={updateTemplate}
        onClose={() => setShowUpdateNotice(false)}
      /> }
      {showDeleteNotice && <ConfirmNotice
        title={`Are you sure?`}
        message={`This will permanently delete this saved template.`}
        onConfirm={deleteTemplate}
        onClose={() => setShowDeleteNotice(false)}
      /> }
      <div className={`rounded border p-4 flex flex-col gap-2 bg-light-light-grey ${showSaveUi ? 'border-green' : "border-grey"} w-full`}>
        <h2><FontAwesomeIcon icon={faFile}/> Document Group Templates</h2>
        {/* <p>get docs</p> */}
        <div className="flex flex-col w-full">
          <label>Select Template</label>
          <div className="flex justify-between w-full">
            <div className="flex gap-2 items-center">
              <List options={options} valueOverride={selected} onChange={(val) => setSelected(val)} />
              <FontAwesomeIcon icon={faCirclePlus} className="text-green cursor-pointer" onClick={() => setShowSaveUi(!showSaveUi)}/>
            </div>
            {
              selected &&
              <div className="flex gap-2 items-center">
                <ButtonPrimary className="self-end" disabled={updateTemplateLoad || !selected} onClick={() => setShowUpdateNotice(true)}>{(updateTemplateLoad || !selected) ? <Loading/> : "Update"}</ButtonPrimary>
                <ButtonPrimary className="self-end text-green border border-green" disabled={!selected} onClick={handleSetEnabled}>{(!selected) ? <Loading/> : "Load"}</ButtonPrimary>
                <FontAwesomeIcon icon={faCircleMinus} className="text-red cursor-pointer" onClick={() => setShowDeleteNotice(true)}/>
              </div>
            }
          </div>
        </div>
      </div>
        {
          showSaveUi &&
          <div className="rounded-b -mt-1 border p-4 flex flex-col gap-2 bg-white border-green border-t-light-grey w-full">
            <h2>Save current document selection as a Template?</h2>
            <p className="text-sm">The currently selected documents will be saved and loadable in future surveys.</p>
            <div className="flex justify-between w-full">
              <div className="flex flex-col">
                <label>Name</label>
                <Input valueOverride={name} onChange={setName} />
              </div>
              <ButtonPrimary className="self-end" disabled={!name || addTemplateLoad} onClick={saveTemplate}>{addTemplateLoad ? <Loading/> : "Save"}</ButtonPrimary>
            </div>
          </div>
        }
    </div>
  )
}
