import React from 'react';
import { useQuery, useMutation, gql } from "@apollo/client";
import { Link, useParams } from 'react-router-dom';
import useFirmChoices from 'hooks/useFirmChoices';
import { List } from 'components/inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsUpDownLeftRight, faEnvelopeOpenText, faUser } from '@fortawesome/free-solid-svg-icons';
import LetterheadDocPreview from './LetterheadDocPreview';
import { FullWidthPage } from 'components/common/Layout';

const GET_LETTERHEAD = gql`
  query GetLetterhead($id: String) {
    letterhead(id: $id) {
      id,
      name,
      url,
      type,
      previewUrl,
      firm {
        name,
        id
      },
      user {
        username
      }
    }
  }
`;

export default function Show() {
  const params = useParams();
  const { loading, error, data } = useQuery(GET_LETTERHEAD, {
    variables: { id: params.id }, 
  });
  return (
    data ?
    <FullWidthPage botttom className="pt-4 flex flex-col gap-2 pb-8">
      <div className="flex flex-col gap-2">
        <div className="flex gap-2 items-center">
          <h1><FontAwesomeIcon className="" icon={faEnvelopeOpenText} /> {data.letterhead.name}
          </h1>
          {data.letterhead.firm && data.letterhead.firm.name && <div className="rounded bg-primary text-white px-3 font-bold">{data.letterhead.firm.name}</div>}
        </div>
        <div className="flex items-center gap-2">
          <small><FontAwesomeIcon className="" icon={faArrowsUpDownLeftRight} /> {data.letterhead.type || "Letterhead"}</small>
          <small><FontAwesomeIcon className="" icon={faUser} /> {data.letterhead.user.username}</small>
        </div>
      </div>
      <div>
        <h2>Image</h2>
        <hr></hr>
      </div>
      <img className="h-12 self-start" src={data.letterhead.previewUrl} alt="letterhead preview small"/>
      <LetterheadDocPreview previewImage={data.letterhead.url} type={data.letterhead.type}/> 
    </FullWidthPage>
    :
    <>
    </>
  )
}
