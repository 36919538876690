import React, { useState, useEffect, useContext } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeCardSetupForm from './StripeCardSetupForm';
import { useMutation, gql } from "@apollo/client";
import AppContext from 'contexts/AppContext';
import SurveyContext from 'contexts/SurveyContext';
import { useNavigate } from 'react-router-dom';
import ProductStripeCardSetupForm from './ProductStripeCardSetupForm';

const INITIALIZE = gql`
  mutation GetProductPaymentIntent($priceId: String!, $firmId: String!) {
    getProductPaymentIntent(priceId: $priceId, firmId: $firmId) {
      paymentIntent {
        id,
        amount,
        status,
        client_secret,
        metadata
      },
      paymentMethods
    }
  }
`;

const CONFIRMPAYMENT = gql`
  mutation ConfirmProductPaymentIntent($paymentIntentId: String!, $paymentMethodId: String) {
    confirmProductPaymentIntent(paymentIntentId: $paymentIntentId, paymentMethodId: $paymentMethodId) {
      status
    }
  }
`;

const REMOVE_PAYMENT_METHOD = gql`
  mutation RemovePaymentMethod($id: String!) {
    removePaymentMethod(id: $id) {
      status
    }
  }
`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function ProductPaymentForm({priceId, firmId, onSuccess, product}) {
  const {setLoading, loading} = useContext(AppContext);
  const [paymentIntent, setPaymentIntent] = useState();
  const [paymentMethods, setPaymentMethods] = useState();
  const [code, setCode] = useState();
  const navigate = useNavigate();
  const [initializeMutation, { loading:initializeLoading }] = useMutation(INITIALIZE);
  const [confirmPaymentMutation, { loading:confirmPaymentLoading, error:setPaymentError }] = useMutation(CONFIRMPAYMENT);
  const [removePaymentMethodMutation, { loading:removePaymentMethodLoading, error:removePaymentMethodError }] = useMutation(REMOVE_PAYMENT_METHOD);

  if (confirmPaymentLoading && !loading) {
    // setLoading(true);
  } else if (!confirmPaymentLoading && loading) {
    // setLoading(false);
  }
  
  useEffect(() => {
    initializeMutation({ variables: { firmId: firmId, priceId: priceId } })
      .then(({data}) => {
          setPaymentIntent(data.getProductPaymentIntent.paymentIntent);
          if (data.getProductPaymentIntent.paymentMethods && Array.isArray(data.getProductPaymentIntent.paymentMethods) && data.getProductPaymentIntent.paymentMethods.length > 0) {
            setPaymentMethods(data.getProductPaymentIntent.paymentMethods);
          }
      })
      .catch((error) => {
        console.log("error for getting secret", error.message);
        // TODO HANDLE REAL ERROR MESSAGE
      })
  }, [priceId, firmId]);
  
  const removePaymentMethod = (method) => {
    removePaymentMethodMutation({ variables: {  id: method.id} })
      .then(() => {
        setPaymentMethods(methods => methods.filter(current => current.id !== method.id))
      })
      .catch((err) => {
        console.log("payment error", err);
      })
  }

  const handleSuccess = (paymentMethodId) => {
    confirmPaymentMutation({ variables: {  paymentIntentId: paymentIntent.id, paymentMethodId} })
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        console.log("payment error", err);
      })
  };

  return (
    <div className="bg-white rounded p-4 border-t">
      {paymentIntent && <Elements stripe={stripePromise} options={{clientSecret: paymentIntent.client_secret}}>
        <ProductStripeCardSetupForm 
          submitLoading={confirmPaymentLoading} 
          paymentMethods={paymentMethods} 
          setPaymentMethods={setPaymentMethods} 
          removePaymentMethod={removePaymentMethod}
          paymentIntent={paymentIntent} 
          handleSuccess={handleSuccess}
          product={product}
        />
      </Elements>}
    </div>
  );
}

export default ProductPaymentForm;

