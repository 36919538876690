import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug, faTimes } from '@fortawesome/free-solid-svg-icons';
import ButtonPrimary from 'components/common/Button';
import Transparent from 'components/notices/Transparent';
import {Input} from 'components/inputs';
import Loading from 'components/common/Loading';
import { gql, useMutation } from '@apollo/client';
import BugContext from 'contexts/BugContext';

const CREATE_BUG_REPORT = gql`
  mutation NewBugReport($fileId: String, $surveyId: String, $description: String!, $error: JSON, $page: String) {
    newBugReport(fileId: $fileId, surveyId: $surveyId, description: $description, error: $error, page: $page) {
        id
    }
  }
`;

export default function BugReport({onClose, onConfirm}) {
  let [open, setOpen] = useState(true);
  const {bugContextInfo} = useContext(BugContext);
  const [description, setDescription] = useState("");
  const [createBugReportMutation, { loading }] = useMutation(CREATE_BUG_REPORT);

  const close = () => {
    setOpen(false);
    onClose();
  }
  //TODO fix error from requestflagsprovider
  const confirm = () => {
    createBugReportMutation({variables: {...bugContextInfo, description, page: window.location.href}})
      .then((res) => {
        setOpen(false);
        onConfirm();
      });
  }

  return (
    <>
      {
        open &&
        <Transparent>
          <div onClick={close} className="absolute flex justify-center items-center w-full h-full">
            <div onClick={(e) => e.stopPropagation()} className="bg-white rounded border max-w-7/8 w-160 shadow-md">
              <div className={`flex justify-between relative px-4 py-2 bg-red`}>
                <h2 className="text-white">
                  <FontAwesomeIcon onClick={close} className="" icon={faBug} />
                  &nbsp;
                  Bug Report
                </h2>
                <FontAwesomeIcon onClick={close} className="text-white cursor-pointer absolute top-1 right-2" icon={faTimes} />
              </div>
              <div className="p-4 bg-whiter py-8 flex flex-col gap-2">
                <h2>Tell us what went wrong</h2>
                <small>
                    Give us as much detail as possible. Try to recount what actions lead to this bug, and describe what went wrong.
                </small>
                <small>
                    We will automatically collect information on who you are, what file you may have been working on, and what page you are on when you submit this bug report, to better help us fix this issue.
                </small>
                <small>
                  We will look into this immediately and report back to you as soon as possible. 
                </small>
                <div className="flex flex-col">
                    <label>Description</label>
                    <Input type="textarea" onChange={setDescription} valueOverride={description}/>
                </div>
              </div>
              <div className="p-4 bg-whiter flex justify-end border-t gap-2 py-2">
                <ButtonPrimary onClick={close} >Close</ButtonPrimary>
                <ButtonPrimary className="border-red text-red" disabled={loading} onClick={confirm} >{loading ? <Loading/> : "Submit"}</ButtonPrimary>
              </div>
            </div>
          </div>
        </Transparent>
      }
    </>
  )
}
