import React, {useContext, useEffect, useMemo, useState} from 'react';
import {getCalculatedValue, getSurveyValue, pathMaker} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import Radios from 'components/inputs/Radios';
import ParentContext from 'contexts/ParentContext';

const SurveyRadios = ({ 
    type, 
    questionKey, 
    text, 
    options,
    hideLabel, 
    total, 
    query,
    hidden,
    required,
    calculatedLabel,
    collapsible,
    readOnly, 
    inputSymbol,
    other,
    defaultValue,
    formStyles, // TODO change to input style
    description
}) => {
    const { file, updateAnswers, updateErrors } = useContext(SurveyContext);
    const {row, parentPath, prevRows} = useContext(ParentContext);
    const path = useMemo(() => {
        return pathMaker(questionKey, parentPath, row);
    }, [questionKey, parentPath, row]);
    const [ value, setValue ] = useState('');
    const [ error, setError ] = useState();
    const checkError = (val) => {
        if (required && !val) {
            setError("Field Required!");
            updateErrors(path, true);
            return true;
        }
        updateErrors(path, false);
        return false;
    }
    const updateValue = (val) => {
        if (!checkError(val)) {
            updateAnswers(path, val);
        }
    }

    const surveyValue = useMemo(() => {
        let value = file && getSurveyValue(file.answers, path);
        if ((defaultValue !== undefined) && ((value === undefined) || (!options.map(option => option.value).includes(value)) )) {
            updateValue(defaultValue);
        }
        return value;
    }, [file, path]);

    useEffect(() => {
        setValue(surveyValue);
    }, [surveyValue, setValue]);

    const calculatedLabelValue = useMemo(() => {
        return calculatedLabel && file && file.answers && getCalculatedValue(file, calculatedLabel, prevRows);
    }, [calculatedLabel]);

    return (
        <div className={`flex flex-col gap-2 ${hidden && 'hidden'}`}>
            {!hideLabel && (text || calculatedLabel) && (
                description ? 
                    <label>
                        {calculatedLabelValue || text}
                        <Tooltip message={description} />
                    </label>
                :
                    <label>
                        {calculatedLabelValue || text}
                    </label>
            )}
            <Radios
                other={other}
                className={formStyles} 
                readOnly={readOnly} 
                options={options} 
                valueOverride={value} 
                onChange={updateValue} 
            />
            {error && <p>{error}</p>}
        </div>
    )
};

export default SurveyRadios;