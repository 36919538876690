import { faClock, faNoteSticky, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faChevronUp, faNotesMedical, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonPrimary from 'components/common/Button';
import Input, { IconSelector } from 'components/inputs';
import ColorSelector from 'components/inputs/ColorSelector';
import React, { useState } from 'react'

export default function ChecklistPhase({phase, answers, notes, updateNotes, updateAnswers, updatePhase, removePhase, edit}) {
  const [show, setShow] = useState(true);
  const [editNote, setEditNote] = useState({});

  const addBlock = () => {
    updatePhase({...phase, blocks: [...phase.blocks, {color: "#464646", items: []}]});
  }

  const removeBlock = (block, i) => {
    updatePhase({...phase, blocks: phase.blocks.filter((block, index) => index !== i)});
  }

  const addItem = (block, i) => {
    let newBlocks = [...phase.blocks];
    newBlocks[i] = {...newBlocks[i], items: [...newBlocks[i].items, ""]};
    updatePhase({...phase, blocks: newBlocks});
  }

  const removeItem = (itemIndex, blockIndex) => {
    let newBlocks = [...phase.blocks];
    newBlocks[blockIndex] = {...newBlocks[blockIndex], items: newBlocks[blockIndex].items.filter((item,index) => index !== itemIndex)};
    updatePhase({...phase, blocks: newBlocks});
  }

  const updatePhaseInfo = (info) => {
    updatePhase({...phase, ...info});
  }

  const updateBlockInfo = (change, blockIndex) => {
    let newBlocks = [...phase.blocks];
    newBlocks[blockIndex] = {...newBlocks[blockIndex], ...change};
    updatePhase({...phase, blocks: newBlocks});
  }

  const updateItem = (name, itemIndex, blockIndex) => {
    let newBlocks = [...phase.blocks];
    let newItems = [...newBlocks[blockIndex].items]
    newItems[itemIndex] = name;
    newBlocks[blockIndex] = {...newBlocks[blockIndex], items: newItems};
    updatePhase({...phase, blocks: newBlocks});
  }

  const updateNote = (note, item) => {
    updateNotes(note, item);
  }

  notes = notes || {};
  return (
    <div>
      <div className="flex gap-1 flex-col">
        <div 
          onClick={() => setShow(!show)}
          className="flex justify-between bg-light-grey p-2 rounded items-center cursor-pointer"
        >
          <div className='flex gap-1'>
            <h3>
              {edit ?
                <div className="flex gap-2">
                  <IconSelector initialValue={phase.icon} small={true} onChange={(icon) => updatePhaseInfo({icon})}/>
                  <Input className="text-xl font-medium" valueOverride={phase.title} onChange={(title) => updatePhaseInfo({title})} />
                </div>
                : 
                `${phase.icon} ${phase.title}`
              }
            </h3>
            <div className="rounded bg-primary px-1 flex items-center text-xxs gap-1 font-bold text-white">
              <FontAwesomeIcon icon={faClock}/>
              {edit ?
                <Input className="font-medium bg-primary border-primary" valueOverride={phase.dateCalculation} onChange={(dateCalculation) => updatePhaseInfo({dateCalculation})} />
                :
                phase.dateCalculation?.split('.').at(-1)
              }
            </div>
          </div>
          <div className="flex gap-2">
            <FontAwesomeIcon icon={show ? faChevronUp :faChevronDown}/>
            {edit && <FontAwesomeIcon className="mr-1 text-bg-dark cursor-pointer" onClick={removePhase} icon={faTimesCircle}/>}
          </div>
        </div>
        <div className={`flex-col gap-4 p-4 ${(show || edit) ? 'flex' : 'hidden'}`}>
          {
            phase.blocks.map((block, i) => (
              <div key={i}>
                <div>
                  <div className="rounded border p-0.5 flex justify-between items-center" style={{backgroundColor: block.color}}>
                    <div className="flex gap-2">
                      <h4 
                        className="font-bold drop-shadow-lg rounded-l px-2 bg-white inline-block"
                        style={{color: block.color}}
                      >
                        {
                          edit ?
                            <Input className="text-lg font-medium" valueOverride={block.title} onChange={(title) => updateBlockInfo({title}, i)} />
                          :
                          block.title
                        }
                      </h4>
                      {edit && <ColorSelector small={true} initialValue={block.color} onChange={(color) => updateBlockInfo({color}, i)} />}
                    </div>
                    {edit && <FontAwesomeIcon className="mr-1 text-white cursor-pointer" onClick={() => removeBlock(block,i)} icon={faTimesCircle}/>}
                  </div>
                </div>
                <div className='flex flex-col gap-1 pt-2 items-start'>
                  {block.items.map((item, itemIndex) => (
                    <React.Fragment>
                      <div 
                        key={itemIndex} 
                        className='flex gap-1'
                      >
                        <input 
                          className="cursor-pointer" 
                          type="checkbox" 
                          id={item} 
                          name={item} 
                          value={item}
                          checked={answers.includes(item)}
                          onClick={() => updateAnswers(item, itemIndex)}
                        />
                        {
                          edit ?
                          <Input className="text-base font-medium" valueOverride={item} onChange={(name) => updateItem(name, itemIndex, i)} />
                          :
                          <label className="cursor-pointer" htmlFor={item}>{item}</label>
                        }
                        {edit &&
                          <FontAwesomeIcon className="mr-1 text-bg-dark cursor-pointer" onClick={() => removeItem(itemIndex,i)} icon={faTimesCircle}/>
                        }
                        {!edit && !notes[item] &&
                          <FontAwesomeIcon className="mr-1 text-xs text-bg-dark cursor-pointer" onClick={(e) => (e.stopPropagation() || setEditNote({...editNote, [item]: !editNote[item]}))} icon={faNoteSticky}/>
                        }
                      </div>
                      {!edit && (notes[item] || editNote[item]) &&
                        <div className="rounded bg-light-grey p-1 px-2">
                          <sup><FontAwesomeIcon className="cursor-pointer mr-1" onClick={() => setEditNote({...editNote, [item]: !editNote[item]})} icon={faNoteSticky}/></sup>
                          {editNote[item] ? 
                            <Input className="text-base font-medium" valueOverride={notes[item]} onBlur={(note) => updateNote(note, item)} />
                            :  
                            notes[item]
                          }
                        </div>
                      }
                    </React.Fragment>
                  ))}
                  {edit && <ButtonPrimary onClick={() => addItem(block, i)} ><FontAwesomeIcon icon={faPlusCircle}/> New Item</ButtonPrimary>}
                </div>
              </div>
            ))
          }
          {edit && <ButtonPrimary onClick={addBlock} ><FontAwesomeIcon icon={faPlusCircle}/> New Block</ButtonPrimary>}
        </div>
      </div>
    </div>
  )
}
