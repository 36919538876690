import Input from 'components/inputs/Input';
import Checkboxes from 'components/inputs/Checkboxes';
import Checkbox from 'components/inputs/Checkbox';
import Radios from 'components/inputs/Radios';
import List from 'components/inputs/List';
import Total from 'components/inputs/Total';
import Label from 'components/inputs/Label';
import IconSelector from './IconSelector';
import SurveySelector from './SurveySelector';

export  {
    Input,
    Checkboxes,
    Radios,
    List,
    Total,
    Label,
    Checkbox,
    IconSelector,
    SurveySelector
}

export default Input;