import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import appContext from 'contexts/AppContext';
import { Link } from 'react-router-dom';
import UserNav from './UserNav';
import AnonNav from './AnonNav';
import Logo from 'assets/images/logo/logo.png';
import CommonNav from './CommonNav';
import LoadBar from 'components/notices/LoadBar';


const NavBar = ({ childRef }) => {
  const location = useLocation();
  const { currentUser } = useContext(appContext);
  const [top, setTop] = useState(true);
  const [toggleMenu, setToggleMenu] = useState(true);

  useEffect(() => {
    if (location.pathname === '/') {
      const handleScroll = () => {
        var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
        setTop(scrollTop < 200);
      };
      window.addEventListener('scroll', handleScroll);
      return (() => window.removeEventListener('scroll', handleScroll));
    } else {
      setTop(true);
    }
  }, [location.pathname]);

  return (
    <div className="z-40">
      <div ref={childRef} className="sticky bg-white p-4 px-8 border-b items-center flex justify-between z-30"> 
        <div className="flex items-center gap-8">
          <Link to="/" className="flex items-center no-underline">
            <div className="bg-contain bg-no-repeat w-10 h-6 md:w-20 md:h-12" style={{backgroundImage: `url(${Logo})`}}/>
          </Link>
          {currentUser && <CommonNav/> }
        </div>
        {currentUser ?
          <UserNav/>
          :
          <AnonNav/>
        }
      </div>
      <LoadBar/>
    </div>
  );
};

export default NavBar;
