import React from 'react';
import { Outlet, Route } from 'react-router-dom';
import PaymentsNav from './PaymentsNav';
import Payments from './Payments';
import AdminRoute from 'components/routes/AdminRoute';

const routes = [
  <Route key="payments" path="payments" element={<AdminRoute><PaymentsNav><Outlet/></PaymentsNav></AdminRoute>} >
    <Route path="" element={<Payments/>} />
  </Route>,
];
export default routes;
