import { useState, useEffect, useCallback } from 'react';

const useLoading = () => {
  const [loading, setLoading] = useState({});

  const addLoading = (load) => {
    setLoading({...loading, [load]: true});
  };

  const removeLoading = (load) => {
    let newLoading = {...loading};
    delete newLoading[load];
    setLoading(newLoading);

  }

  return {
    loading: Object.entries(loading).length > 0,
    addLoading,
    removeLoading,
  }
};

export default useLoading;
