import React from 'react';
import { PaddedPage } from 'components/common/Layout';
import Preview from './Preview';
import CrossSectionJumbo from './CardsJumbo';
import oval from 'assets/images/landing/oval.jpg';
import screenshot from 'assets/images/landing/appscreenshot.png'
import logo from 'assets/images/logo/logo-letters.png';
import cityNight from 'assets/images/landing/city-night.png';
import clock from 'assets/images/landing/clock.png';
import lock from 'assets/images/landing/lock.png';
import coins from 'assets/images/landing/coins.png';
import laptop from 'assets/images/landing/laptop.png';
import ButtonPrimary from 'components/common/Button';
import RoundedButton from 'components/common/Button/RoundedButton';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div className="flex flex-col">
    <Preview/>
    <PaddedPage className="self-center">
      <div className="flex flex-col items-center gap-6 text-center">
        <div>
          <img alt="conveyme logo" className="max-w-84" src={logo}/>
          <h1 id="whoweare" className="jumbo text-primary">Who We Are</h1>
        </div>
        <p className="text-med-grey text-2xl font-med">
          At ConveyMe, we work with you to optimize your conveyancing practice. As a local BC born company, our team is always here to support your business so that together we can focus on what's important and grow.
        </p>
        <Link to="/sign-up">
          <RoundedButton>Start Now</RoundedButton>
        </Link>
      </div>
    </PaddedPage>
    <PaddedPage className="self-center w-full">
      <div className="flex flex-col items-center pt-8 text-center w-full">
        <div>
          <h1 className="jumbo text-primary">Why Choose Us</h1>
        </div>
        <div className="flex items-center gap-8 text-left mt-8 self-start">
          <img className="rounded-full" alt="lock" src={lock}/>
          <div>
            <h1>More Security</h1>
            <ul className="list-disc pl-4 max-w-100">
              <li>Proactive threat protection, automated security</li>
              <li>All client data hosted in Canada to ensure compliance with Privacy Legislation</li>
            </ul>
          </div>
        </div>
        <div className="flex items-center gap-8 text-right flex-row-reverse self-end">
          <img className="rounded-full" alt="coins" src={coins}/>
          <div className="text-left">
            <h1>More Affordable</h1>
            <ul className="list-disc pl-4 text-left max-w-100">
              <li>Always committed  to keep your costs down</li>
              <li>Local based</li>
            </ul>
          </div>
        </div>
        <div className="flex items-center gap-8 text-left self-start">
          <img className="rounded-full" alt="clock" src={clock}/>
          <div>
            <h1>Faster</h1>
            <ul className="list-disc pl-4 max-w-100">
              <li>Latest technologies utilized to reduce manual typing so you save more time than any other software.</li>
            </ul>
          </div>
        </div>
      </div>
    </PaddedPage>
    <PaddedPage className="self-center">
      <div className="flex w-full">
        <img src={laptop} alt="laptop" className="w-1/2"/>
        <div className="w-1/2 flex items-center justify-center">
          <div className="flex flex-col gap-4 max-w-100">
            <h1>
              Generate documents securely, affordably, instantly
            </h1>
            <Link to="/about">
              <RoundedButton>Learn More</RoundedButton>
            </Link>
          </div>
        </div>
      </div>
    </PaddedPage>
    <div className="bg-cover bg-center-20 bg-no-repeat h-screen flex justify-center" style={{backgroundImage: `url(${cityNight})`}}>
      <PaddedPage className="flex justify-center">
        <div className="flex flex-col gap-8 items-center">
          <h1 className="text-white jumbo text-center">Let's start rethinking the way we do conveyancing, together.</h1>
          <Link to="/sign-up">
            <RoundedButton className="bg-white text-dark-grey" white>Get Started</RoundedButton>
          </Link>
        </div>
      </PaddedPage>
    </div>
  </div>
  );
};

export default LandingPage;
