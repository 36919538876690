import React, {useState, useContext} from 'react';
import { PaddedPage } from 'components/common/Layout';
import {Input, Label} from 'components/inputs';
import {ButtonPrimary} from 'components/common/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, gql } from "@apollo/client";
import AppContext from 'contexts/AppContext';

const SIGNUP = gql`
  mutation Signup($email: String!, $username: String!, $inviteId: String, $firstName: String!, $lastName: String!, $password: String!) {
    signup(email: $email, firstName: $firstName, lastName: $lastName, password: $password, username: $username, inviteId: $inviteId) {
      id
    }
  }
`;

const SignUp = ({inviteId}) => {
  const {addLoading, loading, removeLoading} = useContext(AppContext);
  const [email, setEmail] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [firstName, setFirstname] = useState();
  const [lastName, setLastname] = useState();
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [signupMutation, { loading: signupLoading }] = useMutation(SIGNUP);

  if (signupLoading && !loading) {
    addLoading();
  } else if (!signupLoading && loading) {
    removeLoading();
  }
  const valid = () => {
    return email && firstName && lastName && password && username && (password === confirmPassword)
  }

  const signUp = () => {
    if (valid()) {
      signupMutation({ variables: { email, firstName, lastName, password, username, inviteId } })
        .then(res => {
          navigate('/signup-success');
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  return (
    <PaddedPage className="flex justify-center self-center">
      <div className="flex flex-col justify-center gap-4 max-w-84">
        <h1>Sign Up</h1>
        <div className="flex flex-col">
          <Label text="username"/>
          <Input onChange={setUsername}/>
        </div>
        <div className="flex flex-col">
          <Label text="email"/>
          <Input onChange={setEmail}/>
        </div>
        <div className="flex flex-col">
          <Label text="First Name"/>
          <Input onChange={setFirstname}/>
        </div>
        <div className="flex flex-col">
          <Label text="Last Name"/>
          <Input onChange={setLastname}/>
        </div>
        {/* {
          <div className="flex flex-col">
            <Label text={<span>Invite Code <small>(optinal)</small></span>}/>
            <Input className="font-bold" onBlur={setInviteId} error={inviteError} readOnly={params.invite} valueOverride={inviteId}/>
          </div>
        } */}
        <div className="flex flex-col">
          <Label text="password"/>
          <Input type="password" onChange={setPassword}/>
        </div>
        <div className="flex flex-col">
          <Label text="confirm password"/>
          <Input type="password" onChange={setConfirmPassword}/>
        </div>
        <p>
          Already have an account? Login <Link to="/login">Here</Link>
        </p>
        <p>
          To register your firm reach out to us at <a href="mailto:support@conveyme.ca"><span className="text-sm">support@conveyme.ca</span></a>
        </p>
        <ButtonPrimary disabled={loading || !valid()} onClick={signUp}>Submit</ButtonPrimary>
      </div>
    </PaddedPage>
  );
};

export default SignUp;
