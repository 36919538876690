import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import appContext from 'contexts/AppContext';

const AppAdminRoute = ({ children, path }) => {
  const { currentUser } = useContext(appContext);
  const location = useLocation();
  if (!currentUser || !["admin", "super-admin"].includes(currentUser.type)) {
    return  <Navigate to={{
      pathname:'/login',
      state: {
        message: 'You are not authorized to do that',
        from: location.pathname || path
      }
    }}/>
  }

  return children;
};

export default AppAdminRoute;
