import { PaddedPage } from 'components/common/Layout'
import React from 'react';
import LetterheadList from 'components/letterheads/LetterheadList';

export default function All() {
  return (
    <PaddedPage noBottom className="pb-10 flex flex-col gap-6">
      <LetterheadList/>
    </PaddedPage>
  )
}
