import React, {useContext, useState} from 'react';
import { useMutation, gql } from "@apollo/client";
import { Input } from 'components/inputs';
import ButtonPrimary from 'components/common/Button';
import { List } from 'components/inputs';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleLeft } from '@fortawesome/free-solid-svg-icons';
import Message from 'components/common/Message';
import useFirmChoices from 'hooks/useFirmChoices';
import AppContext from 'contexts/AppContext';
import LetterheadDocPreview from './LetterheadDocPreview';
import { FullWidthPage, PaddedPage } from 'components/common/Layout';

const NEW_LETTERHEAD = gql`
  mutation newLetterhead($name: String!, $image: Upload, $firmId: String, $type: String) {
    newLetterhead(name: $name, image: $image, firmId: $firmId, type: $type) {
      id,
      name
    }
  }
`;

export default function New({firmId}) {
  const {firmChoices, firmId:selectedFirm, setFirmId} = useFirmChoices(false, {name: "", value: ""});
	const [name, setName] = useState();
  const [type, setType] = useState();
	const [file, setFile] = useState();
  const {addLoading, removeLoading} = useContext(AppContext);
  const navigate = useNavigate();
  const [previewImage, setPreviewImage] = useState();
	const [newLetterheadMutation, { loading, error }] = useMutation(NEW_LETTERHEAD);
  
  if (error) {
    removeLoading('letterhead');
  }

	const valid = () => {
		return name && file;
	}

	const onFileChange = (event) => {
		setFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
	}

	const onSubmit = () => {
    addLoading('letterhead');
		newLetterheadMutation({
			variables: {
				name,
				image: file,
				firmId: firmId || selectedFirm,
        type
			}
		})
    .then(res => {
      removeLoading('letterhead');
      navigate("/letterheads");
    })
	}

  return (
    <FullWidthPage botttom className="pt-4 flex flex-col gap-2 pb-8">
    {/* <div className="p-4 flex flex-col gap-2"> */}
      {!firmId && <Link to={-1}><FontAwesomeIcon className="text-2xl" icon={faCircleLeft} /></Link>}
      <h2>Add Letterhead</h2>
      <div className="flex flex-col gap-2 items-start"> 
        <div className="flex flex-col">
          <label>Name</label>
          <Input valueOverride={name} onChange={setName}/>
        </div>
        {!firmId && firmChoices.length > 1 &&
          <div className="flex flex-col">	
            <label>Firm</label>
            <List onChange={setFirmId} options={firmChoices}/>
          </div>
        }
        <div className="flex flex-col">	
          <label>Type</label>
          <List onChange={setType} options={[
            {name: "Letterhead", value: ""},
            {name: "Footer", value: "footer"},
            {name: "Sidebar", value: "sidebar"}
          ]}/>
        </div>
        <div className="flex flex-col">
          <label>File</label>
          <input type="file" onChange={onFileChange} />
          {
            previewImage &&
            <img className="h-12 mt-2 self-start" src={previewImage} alt="letterhead preview small"/>
          }			
        </div>
        <ButtonPrimary className="my-4" disabled={loading || !valid()} onClick={onSubmit}> 
          Add Letterhead!
        </ButtonPrimary> 
        {
          previewImage && <LetterheadDocPreview previewImage={previewImage} type={type}/> 
        }
      </div> 
    </FullWidthPage>
  )
}
