import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faFile, faEnvelope, faMoneyCheck } from '@fortawesome/free-solid-svg-icons';
import ltsaLogo from "assets/images/survey/ltsalogo.svg";
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';

export default function AdminDocument({document}) {
  return (
    <div className={`rounded flex justify-between cursor-pointer flex border-l-8 ${document.type === "ltsa" ? "border-green" : document.type === "mortgage" ? "border-orange" :"border-light-grey"} shadow-md hover:shadow-lg p-2 items-center gap-2`}>
        <div className="flex items-center gap-2">
          {
            document.type === "ltsa" ?
            <img src={ltsaLogo} className="h-6" alt="ltsa logo"/>
            : ["mortgage", "pdf"].includes(document.type) ?
            <FontAwesomeIcon className="" icon={faFilePdf} />
            :
            ["cheque"].includes(document.type) ?
            <FontAwesomeIcon className="" icon={faMoneyCheck} />
            :
            <FontAwesomeIcon className="" icon={faFile} />
          }
          <strong>{document.name}</strong>
        </div>
        <div className="flex items-center gap-2">
          {document.firm && 
            <div className={`rounded px-2 bg-green text-sm text-white font-bold`}>
              {document.firm.name}
            </div>
          }
          {document.category && 
            <div className={`rounded px-2 bg-med-grey text-sm text-white font-bold`}>
              {document.category}
            </div>
          }
          {document.surveys.map((survey, i) => (
            <div key={i} className={`rounded px-2 bg-primary text-sm text-white font-bold`}>
              {survey.name}
            </div>
          ))}
        </div>
    </div>
  )
}
