import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react';
import { getSurveyValue, pathMaker } from 'selectors/formSelectors';
import Table from './Table';
import SurveyContext from 'contexts/SurveyContext';
import ButtonPrimary from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import ParentContext from 'contexts/ParentContext';
import _ from 'lodash';

const ArrayInput = ({title, arrayItem, questionRef, hideLabel, readOnly, questionKey, seed, description, hideTotal, formStyles, hidden}) => {
    const [fresh, setFresh] = useState(true);
    const [freshKeys, setFreshKeys] = useState(0);
    const [cleanup, setCleanup] = useState({});
    let {file, updateAnswers, updateCalculation} = useContext(SurveyContext);
    const {row, parentPath, prevRows} = useContext(ParentContext);
    const path = useMemo(() => {
        return pathMaker(questionKey, parentPath, row);
    }, [questionKey, parentPath, row]);
    let [tempRows, setTempRows] = useState([]);
    let [cache, setCache] = useState(getSurveyValue(file.answers, path));

    const addRow = useCallback(() => {
        setTempRows((rows) => [...rows, true]);   
    }, [setTempRows]);

    const removeTempRow = useCallback(() => {
        setTempRows((rows) => rows.splice(0,-1));   
    }, [setTempRows]);

    const arrayOfValues = useMemo(() => {
        if (file && file.answers) {
            let surveyValue = getSurveyValue(file.answers, path);
            if (!surveyValue || surveyValue.length === 0) {
                setTempRows([true]);
                setCache();
                return [];
            }
            // if (Array.isArray(surveyValue) && surveyValue.length < 1 && seed && fresh) {
            //     setFresh(false);
            //     const initialValue = [JSON.parse(seed)];
            //     updateAnswers(path, initialValue);
            //     return initialValue;
            // }
            if (_.isEqual(surveyValue, cache)) {
                return cache;
            } else {
                if (surveyValue.length > (cache || []).length) {
                    removeTempRow();
                }
                setCache(surveyValue);
            }
            return surveyValue;
        }
        return  []
    }, [file, path, removeTempRow]);

    let rows = useMemo(() => {
        return [...arrayOfValues, ...tempRows].map((row, i) => (
            <ParentContext.Provider key={`${i}-${freshKeys}`} value={{row: i, parentPath: path, prevRows: [...prevRows, i], subscribeCleanup: (key,func) => setCleanup(cleanup => ({...cleanup, [key]: func}))}}>
                {arrayItem}
            </ParentContext.Provider>
        ));
    }, [arrayOfValues, tempRows, arrayItem, path, prevRows])

    const removeRow = useCallback((index) => {
        if (!index && index !== 0 && tempRows.length > 0) {
            return setTempRows(tempRows.slice(0, -1));
        }
        setFreshKeys(freshKeys + 1);
        if (index || index === 0) {
            let newCleanup = {...cleanup};
            for (let key in cleanup) {
                if (key.includes(`(${index})`) && cleanup[key]) {
                    cleanup[key]();
                    delete newCleanup[key];
                }
            }
            if (Object.keys(newCleanup).length !== Object.keys(cleanup).length){
                setCleanup(newCleanup);
            }
            if (index < arrayOfValues.length) {
                let update = [...arrayOfValues]
                update.splice(index, 1);
                updateCalculation(path, undefined);
                updateAnswers(path, update, true, true);
            }
        } else if (arrayOfValues.length > 0) {
            updateCalculation(path, undefined);
            updateAnswers(path, [...arrayOfValues.slice(0,-1)], true, true);
        }
        setTempRows([]);
    }, [arrayOfValues, cleanup, freshKeys, path, tempRows, updateAnswers, updateCalculation]);

    return (
    <div className={`flex flex-col gap-2 array w-full mb-2 ${formStyles} ${hidden && 'hidden'}`}>
        <Table title={title} description={description} hideLabel={hideLabel} formStyles={formStyles} same hideLineItemLabel hideTotal={hideTotal}>
            {rows.map((row, i) => 
                    <div key={i} className="row relative flex items-center justify-end gap-2 flex-row-reverse">
                        <FontAwesomeIcon 
                            className={`grow-0 remove-icon ${title ? '' : 'pt-0'} cursor-pointer text-red text-xs font-bold`}
                            onClick={() => removeRow(i)} 
                            icon={faCircleMinus}
                        />
                        {row}
                    </div>
            )}
        </Table>
        {!readOnly &&
            <div className="flex self-end gap-2 -mt-2">
                <ButtonPrimary onClick={() => addRow()}>
                    Add Row
                </ButtonPrimary>
                <ButtonPrimary onClick={() => removeRow()}>
                    Remove Row
                </ButtonPrimary>
            </div>
        }
    </div>
  );
};

export default ArrayInput;