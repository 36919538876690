import React, {useMemo, useState} from 'react'
import { useQuery, gql } from "@apollo/client";
import { Link } from 'react-router-dom';
import LetterheadItem from './LetterheadItem';
import useFirmChoices from 'hooks/useFirmChoices';
import { List } from 'components/inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import Tooltip from 'components/common/Tooltip';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

const GET_LETTERHEADS = gql`
  query GetLetterheads($firmId: String, $type: String) {
    letterheads(firmId: $firmId, type: $type) {
      id,
      name,
      url,
      previewUrl,
      type,
      firmId
    }
  }
`;

export default function LetterheadList() {
  const {firmChoices, firmId, setFirmId} = useFirmChoices(null, null, true);
  const { loading, error, data, refetch } = useQuery(GET_LETTERHEADS, {
    variables: { firmId }, 
    fetchPolicy:'no-cache'
  });

  const letterheads = useMemo(() => {
    return (data?.letterheads || []).filter(lh => !lh.type);
  }, [data]);

  const footers = useMemo(() => {
    return (data?.letterheads || []).filter(lh => lh.type === 'footer');
  }, [data]);

  const sidebars = useMemo(() => {
    return (data?.letterheads || []).filter(lh => lh.type === 'sidebar');
  }, [data]);

  return (
    <div className="flex flex-col gap-4">
      <div>
        <div className="flex gap-2 items-end">
          <h2>
            Letterheads <Link to="/letterheads/new"><FontAwesomeIcon className="text-green" icon={faCirclePlus} /></Link>
          </h2>
          {
            firmChoices.length > 1 && <List valueOverride={firmId} onChange={setFirmId} className="text-xs py-0.5" options={firmChoices}/>
          }
          <Tooltip 
            icon={faCircleQuestion} 
            className={"self-start"}
            color="blue" 
            message={<span>
              Open a document with your letterhead, take a screen capture, crop and upload
            </span>}
          /> 
        </div>
        <hr/>
      </div>
      <div className="flex flex-col gap-6">
        <div>
          <h3>Letterheads</h3>
          <div className="flex flex-col gap-2">
            {letterheads.map(letterhead => (
              <LetterheadItem letterhead={letterhead} refetch={refetch}/>
            ))}
            {
              letterheads.length < 1 &&
              <small className="text-med-grey">No Letterheads</small>
            }
          </div>
        </div>
        <div>
          <h3>Footers</h3>
          <div className="flex flex-col gap-2">
            {footers.map(letterhead => (
              <LetterheadItem letterhead={letterhead} refetch={refetch}/>
            ))}
            {
              footers.length < 1 &&
              <small className="text-med-grey">No Footers</small>
            }
          </div>
        </div>
        <div>
          <h3>Sidebars</h3>
          <div className="flex flex-col gap-2">
            {sidebars.map(letterhead => (
              <LetterheadItem letterhead={letterhead} refetch={refetch}/>
            ))}
            {
              sidebars.length < 1 &&
              <small className="text-med-grey">No Sidebars</small>
            }
          </div>
        </div>
      </div>
    
    </div>
  )
}
