import React, {useContext, useEffect, useState, useMemo} from 'react';
import {getSurveyValue, pathMaker} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import Input from 'components/inputs/Input';
import ParentContext from 'contexts/ParentContext';
import ButtonPrimary from 'components/common/Button';

const ButtonInput = ({ 
    type, 
    questionKey, 
    title, 
    hideLabel, 
    total, 
    query,
    required,
    collapsible,
    isReadOnly, 
    inputSymbol,
    formStyles, // TODO change to input style
    description
}) => {
    const { file, updateAnswers, updateErrors, updateTotalsAndCalculations } = useContext(SurveyContext);
    const {row, parentPath} = useContext(ParentContext);
    const path = useMemo(() => {
        return pathMaker(questionKey, parentPath, row);
    }, [questionKey, parentPath, row]);
    const [ error, setError ] = useState();

    const surveyValue = useMemo(() => {
        return file && getSurveyValue(file.answers, path);
    }, [file, path]);
    
    const checkError = (val) => {
        if (required && !val) {
            setError("Field Required!");
            updateErrors(path, true);
            return true;
        }
        updateErrors(path, false);
        return false;
    }
    const updateValue = (val) => {
        if (!checkError(val)) {
            updateAnswers(path, val);
            total && updateTotalsAndCalculations();
        }
    }
    return (
        <ButtonPrimary>{title}</ButtonPrimary>
    )
};

export default ButtonInput;