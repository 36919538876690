import React, { useState } from 'react';
import BugContext from 'contexts/BugContext';
import { useCallback } from 'react';

const BugProvider = ({ children }) => {
  const [bugContextInfo, setBugContextInfo] = useState({
    fileId: null,
    surveyId: null,
    userId: null,
    error: null,
  })

  const updateBugContext = useCallback((info) => {
    setBugContextInfo((bugContextInfo) => ({...bugContextInfo, ...info}));
  }, []);

  return (
    <BugContext.Provider value={{ 
        bugContextInfo, 
        updateBugContext
    }} >
    {children}
    </BugContext.Provider>
  );
};

export default BugProvider;
