import React, {useState} from 'react'

export default function Checkbox({text, initialValue, onChange, readOnly}) {
  const [value, setValue] = useState(initialValue || false);
  const updateValue = (val) => {
    setValue(val);
    onChange(val);
  }

  return (
    <form>
      <input 
        type="checkbox"   
        name={text} 
        id={text}
        className="cursor-pointer"
        value={value}
        checked={value}
        disabled={readOnly}
        onChange={() => updateValue(!value)}
      />
      &nbsp;
      <label htmlFor={text}>{text}</label>
    </form>
  )
}
