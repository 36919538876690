import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SurveyContext from 'contexts/SurveyContext';
import { FullWidthPage } from 'components/common/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft, faCircleCheck, faFileLines, faFolderOpen, faPaste } from '@fortawesome/free-solid-svg-icons';
import ButtonPrimary from 'components/common/Button';
import SurveyPageChapters from './SurveyPageChapters';
import LoadingText from 'components/loading/LoadingText';
import LoadingPage from 'components/loading/LoadingPage';

export default function SurveyPage({first, last}) {
  const { pageComponents, fileId, surveyId, currentPage, page, pages, errors, setSurveyNav } = useContext(SurveyContext);
  useEffect(() => {setSurveyNav('survey')}, [setSurveyNav]);

  return (
    <FullWidthPage className="flex fustify-between relative pb-0" top>
      <SurveyPageChapters first={first} last={last} />
      <div className="flex flex-col grow gap-7 md:pl-8 pb-8 items-start scroller overflow-x-scroll w-full">

        <h1>{page ? page.title : <LoadingText/>}</h1>
        {pageComponents || <LoadingPage/>}
        <br></br>
        <div className="self-end">
          <Link className="" to={last ? `/files/${surveyId}/${fileId}/summary` : `/files/${surveyId}/${fileId}/${currentPage + 1}`}>
            <ButtonPrimary>
              Next <FontAwesomeIcon icon={faArrowRight} />
            </ButtonPrimary>
          </Link>
        </div>
      </div>
    </FullWidthPage>
  )
}
