import React, {useContext, useState, useMemo, useCallback, useRef} from 'react';
import {getCalculatedValue, getSurveyValue, pathMaker} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import Input from 'components/inputs/Input';
import ParentContext from 'contexts/ParentContext';
import locationAutocomplete from 'helpers/locationAutocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faCopy } from '@fortawesome/free-regular-svg-icons';

const CopyInput = ({ 
  type, 
  questionKey, 
  title, 
  hideLabel, 
  hidden, 
  required,
  calculatedLabel,
  readOnly, 
  info,
  formStyles,
  description,
  value
}) => {
  const { file, updateAnswers, updateErrors, updateTotalsAndCalculations } = useContext(SurveyContext);
  const {row, parentPath, prevRows} = useContext(ParentContext);
  const [copied, setCopied] = useState();
  const path = useMemo(() => {
    return pathMaker(questionKey, parentPath, row);
  }, [questionKey, parentPath, row]);

  const calculatedLabelValue = useMemo(() => {
      return calculatedLabel && file && file.answers && getCalculatedValue(file, calculatedLabel, prevRows);
  }, [calculatedLabel]);

  const surveyValue = useMemo(() => {
    return file && getSurveyValue(file.answers, value);
  }, [file, path]);

  const copy = () => {
    setCopied(!copied);
    navigator.clipboard.writeText(surveyValue);
  }

  return (
      <div className={`flex flex-col justify-between input grow-0 ${hidden ? 'hidden' : ''}`}>
          {!hideLabel && (title || calculatedLabelValue) && (
              description ? 
                  <label>
                      {title || calculatedLabelValue}
                      <Tooltip message={description} />
                  </label>
              :
                  <label>
                      {title || calculatedLabelValue}
                  </label>
          )}
          <div className="flex text-med-grey hover:text-dark-grey cursor-pointer relative" onClick={copy}>
            <div className="font-bold p-2 border bg-white rounded-l flex gap-2 min-w-32 justify-center items-center">{surveyValue || <span className="text-grey">-</span>}</div>
            <div className="font-bold p-2 bg-transparent-grey rounded-r"><FontAwesomeIcon icon={faClipboard}/></div>
            {
                copied &&
               <div className="animate-disapear font-bold p-1 text-sm text-green rounded absolute bg-white -top-1 z-10 -right-6">
                 Copied!
               </div>
            }
          </div>
      </div>
  )
};

export default CopyInput;