import React from 'react';
import { FullWidthPage } from 'components/common/Layout';
import Calendar from 'components/calendar';

const CalendarPage = () => {
  return (
    <FullWidthPage bottom className="mt-4">
      <Calendar large={true} filtersOpen={true}/>
    </FullWidthPage>
  );
};

export default CalendarPage;
