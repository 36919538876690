import React, { useContext } from 'react';
import { PaddedPage } from 'components/common/Layout';
import StewartTitleResources from 'components/surveyInputs/StewartTitleResources';

const StewartTitleTools = () => {
  return (
    <PaddedPage noBottom className="flex flex-col gap-4 pb-20">
      <StewartTitleResources/>
    </PaddedPage>
  );
};

export default StewartTitleTools;
