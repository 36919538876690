import React, {useState, useRef, useEffect, useContext, useMemo} from 'react';
import Input from 'components/inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faUser } from '@fortawesome/free-solid-svg-icons';
import AddClient from './AddClient';
import { useQuery, useMutation, gql } from "@apollo/client";
import Client from 'components/client/Client';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import {getSurveyValue, pathMaker} from 'selectors/formSelectors';
import { typeToLabel } from 'helpers/general';
import ButtonPrimary from 'components/common/Button';
import ParentContext from 'contexts/ParentContext';

const GET_CLIENTS = gql`
  query GetClients($search: String, $type: [String], $firmId: String) {
    clients(search: $search, type: $type, firmId: $firmId) {
      id,
      firstName,
      lastName,
      title,
      gender,
      middleName,
      email,
      phoneNumber,
      firmId,
      addressLineOne,
      addressLineTwo,
      city,
      country,
      province,
      postalCode,
      occupation,
      dateOfBirth,
      canadianCitizen,
      sin,
      type,
      stewartTitleLawyerId
      linkedUser {
        username
      }
    }
  }
`;

export default function ClientInput({hideLabel, label, description, type, questionKey, multiple, copyAddress, copyPropertyAddress, questions, valueOverride, onSelect, removable, mini, firmId}) {
  const [search, setSearch] = useState();
  const { file, updateAnswers, updateErrors } = useContext(SurveyContext);
  const searchRef = useRef();
  const {row, parentPath, prevRows} = useContext(ParentContext);
  const { loading, error, data, refetch } = useQuery(GET_CLIENTS, {
    fetchPolicy: 'no-cache',
    variables: {search, firmId: file && file.firmId, type},
    skip: !search || search === ' ' ? true : false
  });
  const [selected, setSelected] = useState([]);
  const [showNew, setShowNew] = useState();
  const path = useMemo(() => {
    return pathMaker(questionKey, parentPath, row);
  }, [questionKey, parentPath, row]);

  const handleShowNew = (val) => {
    setShowNew(val);
    if (val) {
      setSearch('');
    } else {
      setSearch(' ');
    }
  }
  const handleSelect = (val, refreshSearch = false) => {
    if (multiple) {
      let newValue = [];
      let replace = false;
      for (let value of selected) {
        if (value.id === val.id) {
          newValue.push({...value, ...val});
          replace = true;
        } else {
          newValue.push(value);
        }
      }
      if (!replace) newValue.push(val);
      setSelected(newValue);
      if (onSelect) {
        onSelect(newValue);
      } else {
        updateAnswers && updateAnswers(path, newValue)
      }
    } else {
      setSelected([val]);
      if (onSelect) {
        onSelect(val);
      } else {
        updateAnswers && updateAnswers(path, val)
      }
    }
    setShowNew(false);
    setSearch('');
    if (refreshSearch) refetch();
  }

  const handleRemove = (i) => {
    let newValue = null;
    if (multiple) {
      newValue = [...selected];
      newValue.splice(i, 1);
    } else {
      newValue = [];
    }
    if (onSelect) {
      setSelected(newValue)
      onSelect(multiple ? newValue : newValue[0]);
    } else {
      updateAnswers && updateAnswers(path, multiple ? newValue : newValue[0]);
    }
  }
  const surveyValue = useMemo(() => {
    return file && getSurveyValue(file.answers, path);
  }, [file, path]);

  useEffect(() => {
    let value = valueOverride ?  (multiple ? valueOverride.map(client => ({...client})) : {...valueOverride})  : surveyValue;
    setSelected(multiple ? value || [] : (value ? [value] : []));
  }, [surveyValue, multiple, valueOverride]);

  useEffect(() => {
    if (!showNew && search === " ") {
      searchRef.current.focus();
    }
  }, [search, showNew])

  const options = data ? 
    data.clients.map(client => (
      <div 
        key={client.id}
        className="hover:bg-light-light-grey rounded cursor-pointer p-2 flex justify-between items-center"
        onClick={() => handleSelect(client)}
      >
        {client.firstName} {client.lastName}
        {client.linkedUser && <FontAwesomeIcon className="text-green" icon={faUser}/>}
      </div>
    )) 
  : [];
  
  const getPropertyAddress = () => {
    return file && getSurveyValue(file.answers, 'addressDetails');
  }

  return (
    <div className="flex flex-col justify-start gap-2 items-start">
      {!hideLabel && label && (
        description ? 
            <label>
                {label}
                <Tooltip message={description} />
            </label>
        :
            <label>
                {label}
            </label>
      )}
      {
        showNew &&
        <AddClient copyAddress={(multiple && copyAddress) ? selected[0] : null} copyPropertyAddress={copyPropertyAddress ? getPropertyAddress() : null} firmId={firmId || file.firmId} type={type} close={() => handleShowNew(false)} onCreate={handleSelect}/>
      }
      {
        !showNew && (selected.length < 1 || multiple) &&
        <div className={`flex flex-col ${showNew && 'hidden'}`}>
          <div className="relative">
            <Input placeholder={`Find ${typeToLabel(type) || "Client"}`} valueOverride={search} forwardRef={searchRef} className={`pl-8 ${search && 'rounded-b-none'}`} onChange={setSearch}/>
            <FontAwesomeIcon  className={`absolute top-2 left-1`} icon={faMagnifyingGlass}/>
          </div>
          {search &&
            <div className="flex flex-col border border-t-none bg-white p-4 gap-2 shadow-md rounded-b">
              <div onClick={() => handleShowNew(true)} className="border border-green self-start text-green rounded py-1 px-4 cursor-pointer">New {typeToLabel(type) || 'Person'}!</div>
              <hr></hr>
              <div className="flex flex-col max-h-32 overflow-y-scroll scroller">
                {
                  options.length > 0 ?
                  options
                  :
                  <small className="text-light-grey">None Found</small>
                }
              </div>
            </div>
          }
        </div>
      }
      <div className="flex gap-2 flex-wrap">
        {
          selected.map((currentSelected, i) =>
            <ParentContext.Provider key={i} value={{row: multiple ? i : null, parentPath: path, prevRows: multiple ? [...prevRows, i] : prevRows}}>
              <Client firmId={firmId || file?.firmId} type={type} copyAddress={(multiple && copyAddress) ? selected[0] : null} copyPropertyAddress={copyPropertyAddress ? getPropertyAddress() : null} onUpdate={handleSelect} client={currentSelected} onDeselect={(removable || !valueOverride) && (() => handleRemove(i))} mini={mini}>
                {questions}
              </Client>
            </ParentContext.Provider>
          )
        }
      </div>
    </div>
  )
}
