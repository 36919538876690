import React, {useState, useContext, useRef, useCallback, useMemo} from 'react';
import Input, { Label, Radios } from 'components/inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faBuilding, faTimes } from '@fortawesome/free-solid-svg-icons';
import ButtonPrimary from 'components/common/Button';
import { useMutation, gql, useQuery } from "@apollo/client";
import { typeToLabel } from 'helpers/general';
import locationAutocomplete from 'helpers/locationAutocomplete';
import BusinessInput from 'components/surveyInputs/BusinessInput';
import ParentContext from 'contexts/ParentContext';
import { pathMaker } from 'selectors/formSelectors';

const ADD_TRUST_ACCOUNT = gql`
  mutation AddTrustAccount(
    $name: String!,
    $firmId: String,
    $bankName: String,
    $bankId: String,
    $transitNumber: String,
    $accountNumber: String,
    $institutionNumber: String,
    $chequeNumber: String,
  ) {
    createTrustAccount(
      name: $name,
      firmId: $firmId,
      bankName: $bankName,
      transitNumber: $transitNumber,
      accountNumber: $accountNumber,
      institutionNumber: $institutionNumber,
      chequeNumber: $chequeNumber,
      bankId: $bankId
    ) {
      id,
      name,
      firmId,
      bankName,
      bankId
    }
  }
`;

const GET_TRUST_ACCOUNT = gql`
  query GetTrustAccount($id: String!) {
    trustAccount(id: $id) {
      id,
      name,
      bankName,
      transitNumber,
      accountNumber,
      institutionNumber,
      chequeNumber,
      bankId,
      bank {
        id,
        companyName,
        email,
        phoneNumber,
        fax,
        addressLineOne,
        addressLineTwo,
        city,
        province,
        gstNumber,,
        incorporationNumber
        postalCode,
        type,
        public,
      }
    }
  }
`;

const EDIT_TRUST_ACCOUNT = gql`
  mutation UpdateTrustAccount(
    $id: String!,
    $name: String,
    $bankName: String,
    $bankId: String, 
    $transitNumber: String,
    $accountNumber: String,
    $institutionNumber: String,
    $chequeNumber: String,
  ) {
    updateTrustAccount(
      id: $id,
      name: $name,
      bankName: $bankName,
      bankId: $bankId,
      transitNumber: $transitNumber,
      institutionNumber: $institutionNumber,
      accountNumber: $accountNumber,
      chequeNumber: $chequeNumber
    ) {
      id,
      name,
      bankName,
      bankId,
      transitNumber,
      accountNumber,
      institutionNumber,
      chequeNumber,
    }
  }
`;

export default function AddTrustAccount({close, onCreate, questionKey, publicTrustAccount, edit, firmId}) {
  const [newTrustAccount, setNewTrustAccount] = useState(edit || {});
  const [addTrustAccountMutation, { loading }] = useMutation(ADD_TRUST_ACCOUNT);  
  const [editTrustAccountMutation, { loading:editLoading }] = useMutation(EDIT_TRUST_ACCOUNT);  
  const { loading:trustaccountloading, error, data, refetch } = useQuery(GET_TRUST_ACCOUNT, {
    variables: {id: edit?.id},
    skip: !edit?.id,
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      setNewTrustAccount(res.trustAccount);
    }
  });
  const valid = () => {
    return newTrustAccount && newTrustAccount.name;
  }
  
  const addTrustAccount = () => {
    if (valid()) {
      addTrustAccountMutation({variables: {
        ...newTrustAccount,
        firmId
      }})
      .then(res => {
        onCreate(res.data.createTrustAccount, true);
      })
    }
  }
  
  const editTrustAccount = () => {
    if (valid()) {
      editTrustAccountMutation({variables: {...newTrustAccount}})
      .then(res => {
        onCreate(res.data.updateTrustAccount, true);
      })
    }
  }

  return (
    <div className="p-4 border border-transparent-dark-grey rounded flex flex-col bg-transparent-grey gap-4">
      <div className="relative">
        <h3><FontAwesomeIcon className={``} icon={faBuilding}/> {edit ? "Edit" : "New"} TrustAccount</h3>
        {close && <FontAwesomeIcon onClick={close} className={`absolute top-1 right-1 cursor-pointer`} icon={edit ? faTimes : faMagnifyingGlass}/>}
      </div>
      <div className="flex flex-col">
        <Label text="Name"/>
        <Input placeholder="name" valueOverride={newTrustAccount.name} onChange={(name) => setNewTrustAccount({...newTrustAccount, name})} />
      </div>
      <div className="flex flex-col">
        <BusinessInput label="Bank" valueOverride={newTrustAccount.bank} removable={true} onSelect={(bank) => setNewTrustAccount({...newTrustAccount, bankId: bank.id, bankName: bank.companyName, bank})} description="Trust Account for what Bank?" type={["bank", "lender"]} publicBusiness={true}/>
      </div>
      <div className="flex gap-2">
        <div className="flex flex-col">
          <Label text="Transit #"/>
          <Input placeholder="transitNumber" valueOverride={newTrustAccount.transitNumber} onChange={(transitNumber) => setNewTrustAccount({...newTrustAccount, transitNumber})} />
        </div>
        <div className="flex flex-col">
          <Label text="Account #"/>
          <Input placeholder="accountNumber" valueOverride={newTrustAccount.accountNumber} onChange={(accountNumber) => setNewTrustAccount({...newTrustAccount, accountNumber})} />
        </div>
        <div className="flex flex-col">
          <Label text="Institution #"/>
          <Input placeholder="institutionNumber" valueOverride={newTrustAccount.institutionNumber} onChange={(institutionNumber) => setNewTrustAccount({...newTrustAccount, institutionNumber})} />
        </div>
      </div>
      <div className="flex flex-col">
        <Label text="Current Cheque # for auto incrementing"/>
        <Input placeholder="chequeNumber" valueOverride={newTrustAccount.chequeNumber} onChange={(chequeNumber) => setNewTrustAccount({...newTrustAccount, chequeNumber})} />
      </div>
      <div className="flex gap-2"> 
         
      </div>
      <ButtonPrimary disabled={editLoading || loading || !valid()} onClick={edit ? editTrustAccount : addTrustAccount} className="self-start">{edit ? "Update" : "Add"}</ButtonPrimary>
    </div>)
}
