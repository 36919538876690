import React, {useState, useContext} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import AppContext from 'contexts/AppContext';

export default function Dropdown({options, trigger}) {
  const [show, setShow] = useState(false);
  const {currentUser} = useContext(AppContext);

  return (
    <div className="relative">
      <div className="cursor-pointer" onClick={() => setShow(!show)}>
        {trigger}
        <FontAwesomeIcon className="bottom-0 cursor-pointer" icon={faCaretDown}/> 
      </div>
      <div className={`bg-light-light-grey rounded shadow divide-y flex flex-col border right-0 ${show ? 'absolute' : 'hidden'}`}>
          <div className="p-1 px-4 bg-white">
            <p className="whitespace-nowrap text-sm">Logged in as {currentUser.firstName}</p>
          </div>
          <div className="flex flex-col">
            {options.map(option => (
              <div className="p-2 px-4 hover:bg-transparent-grey border-b border-transparent-grey" key={option.text}>
                {option.to ?
                    <Link className="no-underline font-normal hover:font-bold" to={option.to} onClick={() => setShow(false)}>
                        <div onClick={option.action}>
                            {option.text}
                        </div> 
                    </Link>
                  :
                  <div className="cursor-pointer whitespace-nowrap hover:font-bold" onClick={option.action}>
                      {option.text}
                  </div>}
              </div>
            ))}
          </div>
      </div>
    </div>
  )
}
