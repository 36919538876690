import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';

const DELETE_LETTERHEAD = gql`
  mutation DeleteLetterhead($id: String!) {
    deleteLetterhead(id: $id) {
      id,
    }
  }
`;

export default function LetterheadItem({letterhead, refetch}) {
  const [showDelete, setShowDelete] = useState(false);
  const [deleteLetterheadMutation, { loading }] = useMutation(DELETE_LETTERHEAD);

  const handleDelete = () => {
    if (loading) return;
    setShowDelete(false);
    deleteLetterheadMutation({
      variables: {id: letterhead.id}
    })
    .then(res => {
      refetch();
    })

  }
  return (
    <>
      {showDelete && <ConfirmNotice
        title={`Are you sure?`}
        message={`This will delete the "${letterhead.name}" letterhead.`}
        onConfirm={handleDelete}
        onClose={() => setShowDelete(false)}
      /> }
      {
        letterhead.type === 'sidebar' ?
        <div key={letterhead.id} className={`flex flex-col items-center border-t-8 self-start rounded shadow-md border`}>
          <div className={`flex flex p-1 justify-between`}>
            <Link className="no-underline flex flex-col" to={`/letterheads/${letterhead.id}`}>
                <small className="text-primary">#{(letterhead.letterheadNumber || letterhead.id).substring(0,5)}...</small>
                <h3 className="font-medium text-xs w-16 overflow-hidden leading-4">{letterhead.name.substring(0,8)}{letterhead.name.length > 8 ? '..' : ''}</h3>
            </Link>
          </div>
          <div className="flex flex-row">
            {/* <small className="text-primary rotate-90">#{letterhead.letterheadNumber || letterhead.id}</small> */}
            <img className="w-10" alt="letterhead preview" src={letterhead.previewUrl}/>
          </div>
          <FontAwesomeIcon icon={faCircleXmark} onClick={() => setShowDelete(true)} className="m-1 self-center cursor-pointer"/>
        </div>
        :
        <div key={letterhead.id} className={`flex border-l-4 self-start rounded shadow-md border`}>
          <div className="bg-light-grey flex items-center justify-center pr-1"><span></span></div>
          <div className={`flex p-2 gap-8`}>
            <Link className="no-underline flex flex-col" to={`/letterheads/${letterhead.id}`}>
                <small className="text-primary">#{letterhead.letterheadNumber || letterhead.id}</small>
                <h3 className="-mt-1 font-medium">{letterhead.name}</h3>
            </Link>
            <img className="h-10" alt="letterhead preview" src={letterhead.previewUrl}/>
          </div>
          <FontAwesomeIcon icon={faCircleXmark} onClick={() => setShowDelete(true)} className="m-1 self-center cursor-pointer"/>
        </div>
      }
    </>
  )
}
