import { gql, useMutation } from '@apollo/client'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ButtonPrimary from 'components/common/Button'
import Input, { IconSelector, List } from 'components/inputs'
import ColorSelector from 'components/inputs/ColorSelector'
import Transparent from 'components/notices/Transparent'
import FileInput from 'components/surveyInputs/FileInput'
import AppContext from 'contexts/AppContext'
import useFirmChoices from 'hooks/useFirmChoices'
import React, { useContext, useState } from 'react'

const ADD_CALENDAR_ITEM = gql`
  mutation AddCalendarItem($date: String!, $name: String!, $subText: String!, $tag: String, $colorOne: String, $icon: String, $type: String, $fileId: String, $surveyId: String, $firmId: String) {
    newCalendarItem(date: $date, name: $name, subText: $subText, tag: $tag, colorOne: $colorOne, icon: $icon, type: $type, fileId: $fileId, surveyId: $surveyId, firmId: $firmId ) {
      id
    }
  }
`;

export default function NewEventModal({date, close, addEvent}) {
  const {currentUser} = useContext(AppContext);
  const [calendarItem, setCalendarItem] = useState({colorOne: "#EAEAEA", icon: "📌", date: date && new Date(Number(date)).toISOString().split('T')[0], type: "Other"});
  const [addCalendarItemMutation, { loading }] = useMutation(ADD_CALENDAR_ITEM);
  const {firmChoices, firmId, setFirmId} = useFirmChoices(false, {name: "None", value: ""});

  const valid = () => {
    return calendarItem.name && calendarItem.date && calendarItem.subText;
  }

  const addCalendarItem = () => {
    if (!valid()) return;
    addCalendarItemMutation({variables: {...calendarItem, firmId}})
    .then((res) => {
      addEvent({...calendarItem, user: currentUser, id: res.data.newCalendarItem.id});
      close();
    });
  }

  return (
    <Transparent>
      <div onClick={close} className="absolute flex justify-center items-center w-full h-full">
        <div onClick={(e) => e.stopPropagation()} className="bg-white rounded border max-w-7/8 w-160 shadow-md">
          <div className={`flex justify-between relative px-4 py-2 bg-green`}>
            <h2 className="text-white">
              <FontAwesomeIcon onClick={close} className="" icon={faCalendar} />
              &nbsp;
              Add Calendar Item
            </h2>
            <FontAwesomeIcon onClick={close} className="text-white cursor-pointer absolute top-1 right-2" icon={faTimes} />
          </div>
          <div className="p-4 bg-whiter flex flex-col gap-2">
            <div className="flex gap-2 justify-between rounded p-1" style={{backgroundColor: calendarItem.colorOne}}>
              <div className="flex gap-2">
                <IconSelector initialValue={calendarItem.icon} small={true} onChange={(icon) => setCalendarItem({...calendarItem, icon})}/>
                <Input placeHolder="Name" valueOverride={calendarItem.name} onChange={(name) => setCalendarItem({...calendarItem, name})}/>
                <ColorSelector small={true} initialValue={calendarItem.colorOne} onChange={(colorOne) => setCalendarItem({...calendarItem, colorOne})} />
              </div>
              <div className="flex items-center gap-1">
                <label>Date</label>
                <Input type="date" valueOverride={calendarItem.date} onChange={(date) => setCalendarItem({...calendarItem, date})}/>
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-sm">File (optional)</label>
              <FileInput label="test" onChange={(file) => setCalendarItem({...calendarItem, fileId: file && file.id, surveyId: file && file.surveyId})}/>
            </div>
            <div className="flex flex-col">
              <label className="text-sm">Description</label>
              <Input valueOverride={calendarItem.subText} onChange={(subText) => setCalendarItem({...calendarItem, subText})}/>
            </div>
            <div className="flex flex-col">
              <label className="text-sm">Type</label>
              <List onChange={(type) => setCalendarItem({...calendarItem, type})} className="py-0.5" options={[
                {name: "Other", value: "Other"},
                {name: "Builder's Lien Holdback", value: "Builder's Lien Holdback"},
                {name: "Court Ordered Sale", value: "Court Ordered Sale"},
                {name: "D/M Particulars", value: "D/M Particulars"},
                {name: "Discharge of Mortgage", value: "Discharge of Mortgage"},
                {name: "Draw Mortgage", value: "Draw Mortgage"},
                {name: "Meeting", value: "Meeting"},
                {name: "Holdback", value: "Holdback"},
                {name: "NHWC to be sent to bank", value: "NHWC to be sent to bank"},
                {name: "Property Taxes", value: "Property Taxes"},
                {name: "STC", value: "STC"},
                {name: "Transfer", value: "Transfer"},
                {name: "TSJT", value: "TSJT"},
                {name: "T2E", value: "T2E"},
                {name: "X-LOTA", value: "X-LOTA"},
              ]}/>
            </div>
            <div className="flex flex-col">
              <label className="text-sm">Tag</label>
              <Input valueOverride={calendarItem.tag} onChange={(tag) => setCalendarItem({...calendarItem, tag})}/>
            </div>
            {
              firmChoices.length > 1 && 
              <div className="flex flex-col">
                <label className="text-sm">Firm</label>
                <List onChange={setFirmId} className="py-0.5" options={firmChoices}/>
              </div>
            }
          </div>
          <div className="p-4 bg-whiter flex justify-end border-t gap-2 py-2">
            <ButtonPrimary onClick={close}>Close</ButtonPrimary>
            <ButtonPrimary disabled={!valid() || loading} onClick={addCalendarItem}className="" >Add</ButtonPrimary>
          </div>
        </div>
      </div>
    </Transparent>
  )
}
