import React, {useContext, useMemo, useState} from 'react'
import AppContext from 'contexts/AppContext';
import { gql, useQuery } from '@apollo/client';

const GET_USERS = gql`
  query GetUsers($firmId: String!) {
    users(firmId: $firmId) {
      id,
      firstName,
      lastName,
      email
    }
  }
`;

export default function useUserChoices(startSelected, defaultChoice, firmId) {
  const { loading, error, data } = useQuery(GET_USERS, {
    variables: {firmId, filters: {limit: 50}},
    skip: !firmId
  });
  const [userId, setUserId] = useState();

  const userChoices = useMemo(() => {

    return (data?.users || []).reduce((acc, curr) => {
      acc.push({name: `${curr.firstName} ${curr.lastName}`, value: curr.id});
      return acc;
    }, startSelected ? [] : [(defaultChoice || {name: "-", value: ""})]);
  }, [startSelected, defaultChoice, data]);
  
  return { userChoices, userId, setUserId };
}
