import { gql, useMutation, useQuery } from '@apollo/client';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullWidthPage } from 'components/common/Layout';
import Input, { Checkbox, List } from 'components/inputs';
import SurveyContext from 'contexts/SurveyContext';
import useExportCSV from 'hooks/useExportCSV';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import { formatCurrency } from 'selectors/formSelectors';
import TrustAccountInput from './TrustAccountInput';
import ActivePageContext from 'contexts/ActivePageContext';
import { faArrowRight, faCropSimple } from '@fortawesome/free-solid-svg-icons';
import DocumentGroup from 'components/documents/DocumentGroup';

// get trust account items for this file
const GET_TRUST_ACCOUNT_ITEMS = gql`
  query GetTrustAccountItems($filters: JSONObject, $trustAccountId: String, $firmId: String) {
    trustAccountItems(filters: $filters, trustAccountId: $trustAccountId, firmId: $firmId) {
      id,
      name,
      value,
      calculation,
      date,
      chequeDepositNumber,
      surveyId, 
      fileId,
      trustAccountId,
      trustAccount {
        name
      }
      type,
      cleared,
      void,
      file {
        fileNumber,
        completionDate,
        surveyName
      }
    }
  }
`;

const GET_DOCUMENTS = gql`
  query GetDocuments($firmId: String, $type: String, $filters: JSONObject) {
    documents(firmId: $firmId, type: $type, filters: $filters) {
      id,
      name,
      category,
      firmId,
      type
    }
  }
`;

export default function FinalTrustReconciliation({filters}) {
  const {setActivePage, from, to, trustAccountId, firmId} = useContext(ActivePageContext);
  const [statementBalance, setStatementBalance] = useState(0);
  const [outstandingChequesBalance, setOutstandingChequesBalance] = useState(0);
  const [closingBalanceOverride, setClosingBalanceOverride] = useState();
  setActivePage("reconciliation")
  const [accounts, setAccounts] = useState({});
  const { loading, error, data, refetch } = useQuery(GET_TRUST_ACCOUNT_ITEMS, {
    variables: {firmId, trustAccountId, filters: {from, to}},
    fetchPolicy:'no-cache'
  });

  const {data: floatsData } = useQuery(GET_TRUST_ACCOUNT_ITEMS, {
    variables: {firmId, trustAccountId, filters: {type: 'float', to}},
    fetchPolicy:'no-cache'
  });

  const { data: dataNotCleared } = useQuery(GET_TRUST_ACCOUNT_ITEMS, {
    variables: {firmId, trustAccountId, filters: {to: to, cleared: false, void: false}},
    fetchPolicy:'no-cache'
  });

  const { loading:loadingDocs, error:errorDocs, data:dataDocs } = useQuery(GET_DOCUMENTS, {
    variables: {firmId, type: "trust", filters: {search: ['Reconciliation']}},
    fetchPolicy:'no-cache'
  });

  const totals = useMemo(() => {
    let outstandingChequesInSystem = 0;
    let closingBalance = 0;
    let balance = 0;
    for (let item of dataNotCleared?.trustAccountItems || []) {
      if (item.void || (item.value > 0)) continue;
      outstandingChequesInSystem += Math.abs(Number(item.value));
    }
    let trueBankBalance = Number(statementBalance) - Number(outstandingChequesBalance) - Number(outstandingChequesInSystem);
    let cheques = 0;
    let reciepts = 0;
    for (let item of data?.trustAccountItems || []) {
      if (item.void) {

      } else if (item.value < 0) {
        cheques += Math.abs(Number(item.value));
      } else if (item.value > 0 || item.type === "reciept") {
        reciepts += Math.abs(Number(item.value));
      }
    }
    let previousFloatsTotal = floatsData?.trustAccountItems.reduce((prev, curr) =>  (new Date(curr.date) > new Date(from)) ? prev : prev + Number(curr.void ? 0 : curr.value), 0) || 0;
    let floatsTotal = floatsData?.trustAccountItems.reduce((prev, curr) => prev + Number(curr.void ? 0 : curr.value), 0) || 0;
    closingBalance = closingBalanceOverride || Math.round((Number(reciepts) - Number(cheques) + previousFloatsTotal) * 100)/100;
    balance = trueBankBalance - closingBalance;
    return {outstandingChequesInSystem, trueBankBalance, closingBalance, balance, floatsTotal}
  }, [data, dataNotCleared, outstandingChequesBalance, statementBalance, closingBalanceOverride])

  return (
    <div>

      <div className="bg-light-grey rounded p-2">
        <table className="w-full bg-light-grey border-spacing-2 border-b border-dark-grey">
          <tbody>
            <tr className="border-b border-dark-grey">
              {/* <th>No</th>
              <th>Account</th> */}
            </tr>
            <tr className={`group relative bg-transparent-grey`}>
              <td className="p-4">
                Bank Balance from Statement<br></br>
                <small>
                  (Entered from your Bank Statement)
                </small>
              </td>
              <td className="p-1 text-right">$<Input className="bg-transparent text-right w-32" type="number" valueOverride={statementBalance} onChange={setStatementBalance}/></td>
            </tr>
            <tr className={`group relative `}>
              <td className="p-4">
                Less Outstanding Cheques NOT in System<br></br>
                <small>
                (Entered total of all Outstanding Cheques not currently in the system)
                </small>
              </td>
              <td className="p-1 text-right">$<Input className="bg-transparent text-right w-32" type="number" valueOverride={outstandingChequesBalance} onChange={setOutstandingChequesBalance}/></td>
            </tr>
            <tr className={`group relative `}>
              <td className="p-4">
                Less Outstanding Cheques in System
              </td>
              <td className="p-1 text-right">{formatCurrency(totals.outstandingChequesInSystem)}</td>
            </tr>
            <tr className={`group relative bg-transparent-grey`}>
              <td className="p-4">
                Equals True Bank Balance<br></br>
                <small>
                  (Balance – Other System Outstanding – This system Outstanding)
                </small>
              </td>
              <td className="p-1 text-right">{formatCurrency(totals.trueBankBalance)}</td>
            </tr>
            <tr className={`group relative`}>
              <td className="p-4">
                Closing Balance<br></br>
                <small>
                  (Trust Liability Report Closing Balance)<br></br>
                  (Opening Balance for the Next Month/Period)<br></br>
                  (Inclusive of current float of {formatCurrency(totals.floatsTotal)})
                </small>
              </td>
              <td className="p-1 text-right">$<Input className="bg-transparent text-right w-32" type="number" valueOverride={totals.closingBalance} onChange={setClosingBalanceOverride} /></td>
            </tr>
            <tr className={`group relative bg-transparent-grey`}>
              <td className="p-4">
                Balance Is<br></br>
                <small>
                  (True Balance – Trust Liability Closing)
                </small>
              </td>
              <td className="p-1 text-right">{formatCurrency(totals.balance)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {dataDocs && 
        <div className="relative">
          <DocumentGroup
            // zip={zip} 
            title="Trust Documents" 
            documents={dataDocs?.documents}
            extraData={{
              // trustAccount: dataTrust?.trustAccount,
              statementBalance: statementBalance,
              outstandingChequesBalance: outstandingChequesBalance,
              totals: totals,
              to: to,
              from: from,
            }}
          />
        </div>
      }
    </div>
  )
}
