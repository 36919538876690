import React, {useContext, useState} from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCircleLeft, faSquarePen, faCircleInfo, faEnvelope, faBuilding, faGlobe, faBackwardStep, faCode } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import Input, { List } from 'components/inputs';
import ButtonPrimary from 'components/common/Button';
import InviteUser from '../user/InviteUser';
import useFirmChoices from 'hooks/useFirmChoices';
import useCurrentUser from 'hooks/useCurrentUser';
import AppContext from 'contexts/AppContext';
import ActivePageContext from 'contexts/ActivePageContext';
import BusinessInput from 'components/surveyInputs/BusinessInput';
import { Radios } from 'components/inputs';
import { FullWidthPage } from 'components/common/Layout';
import User from 'components/users/User';

const GET_FIRM = gql`
  query GetFirm($id: String!) {
    firm(id: $id) {
      id,
      name,
      email,
      subdomain,
      filePrefix,
      bio,
      stripeDiscountId,
      business {
        id,
        companyName,
        email,
        phoneNumber,
        fax,
        addressLineOne,
        addressLineTwo,
        city,
        province,
        type,
        gstNumber,
        postalCode,
      },
      users {
        id, 
        email,
        username,
        firstName,
        type
      }
      settings,
      stripePortalUrl
    }
  }
`;

const DELETE_FIRM = gql`
  mutation DeleteFirm($id: String!) {
    deleteFirm(id: $id) {
      id
    }
  }
`;

const UPDATE_FIRM = gql`
  mutation UpdateFirm($id: String!, $name: String, $email: String, $subdomain: String, $bio: String, $filePrefix: String!, $stripeDiscountId: String, $settings: JSONObject) {
    updateFirm(id: $id, name: $name, email: $email, subdomain: $subdomain, bio: $bio, filePrefix: $filePrefix, stripeDiscountId: $stripeDiscountId, settings: $settings) {
      id,
      name,
      email,
      subdomain,
      filePrefix,
      bio,
      stripeDiscountId
    }
  }
`;

const REMOVE_FROM_FIRM = gql`
  mutation RemoveFromFirm($firmId: String!, $email: String!) {
    removeFromFirm(firmId: $firmId, email: $email) {
      id
    }
  }
`;

export default function Firm() {
  const navigate = useNavigate();
  const params = useParams();
  const {currentUser} = useContext(AppContext);
  const [showDelete, setShowDelete] = useState(false);
  const [edit, setEdit] = useState(false);
  const {firmChoices, firmId, setFirmId} = useFirmChoices(true);
  const [updateInfo, setUpdateInfo] = useState();
  const {setActivePage} = useContext(ActivePageContext);
  const [showRemoveNotice, setShowRemoveNotiece] = useState();
  const [removeFromFirmMutation, { loading:removeLoading }] = useMutation(REMOVE_FROM_FIRM);
  const { loading, error, data, refetch } = useQuery(GET_FIRM, {
    variables: { id: params.id || firmId },
  });
  const [deleteFirmMutation, { error:deleteError }] = useMutation(DELETE_FIRM);
  const [updateFirmMutation, { error:updateError }] = useMutation(UPDATE_FIRM);
  setActivePage("firm");

  const handleDelete = () => {
    setShowDelete(false);
    deleteFirmMutation({variables: {id: params.id}})
      .then(() => navigate("/admin/firms"));
  }

  const handleUpdate = () => {
    setEdit(false);
    updateFirmMutation({variables: {
      id: params.id || firmId,
      filePrefix: updateInfo.filePrefix || "",
      ...updateInfo,
      settings: {...(data?.firm?.settings || {}), ...(updateInfo.settings || {})}
    }})
      .then(() => refetch());
  }

  const removeFromFirm = () => {
    removeFromFirmMutation({variables: {firmId: params.id || firmId, email: showRemoveNotice}})
    .then(res => {
        setShowRemoveNotiece();
        refetch();
      })
  }

  return (
    data ?
    <>
      {showRemoveNotice && 
        <ConfirmNotice 
          title={`Are you sure you want to remove ${showRemoveNotice} from ${data.firm?.name}?`}
          message={`
            To remove ${showRemoveNotice} type "${showRemoveNotice}" below. 
            They will no longer be able to create or view firm files.
          `}
          confirmText={showRemoveNotice}
          onConfirm={removeFromFirm}
          onClose={() => setShowRemoveNotiece(false)}
        />
      }
    <FullWidthPage bottom className="pt-4">
      {showDelete && <ConfirmNotice 
        title={`Are you sure you want to delete ${data.firm.name}?`}
        message={`To delete this firm type "${data.firm.name}" below`}
        confirmText={data.firm.name}
        onConfirm={handleDelete}
        onClose={() => setShowDelete(false)}
      /> }
      <div className="flex flex-col items-start gap-6 pb-6">
        {["admin", "super-admin"].includes(currentUser.type) && 
          <div className="flex gap-2 items-center">
            <Link to="/admin/firms"><FontAwesomeIcon className="text-2xl" icon={faCircleLeft} /></Link>
            <FontAwesomeIcon onClick={() => setEdit(!edit)}  className="text-xl text-green cursor-pointer" icon={faSquarePen} />
            <FontAwesomeIcon onClick={() => setShowDelete(true)} className="text-xl text-red cursor-pointer" icon={faCircleXmark} />
          </div>
        }
        <div className="flex flex-col gap-2 w-full">
          <div className='flex justify-between w-full items-center'>
            <div className="flex gap-2 items-center">
              <h1>
                <FontAwesomeIcon className="" icon={faBuilding} />&nbsp;
                {edit ? <Input className="text-2xl font-medium" valueOverride={data.firm.name} onChange={(name) => setUpdateInfo({...updateInfo, name})} /> : data.firm.name}
              </h1>
              {
                firmChoices.length > 1 && <List valueOverride={firmId} onChange={setFirmId} className="text-xs py-0.5" options={firmChoices}/>
              }
            </div>
            {data?.firm?.stripePortalUrl && <a href={data.firm.stripePortalUrl}><ButtonPrimary className="text-xs border-dark-grey">Invoice History</ButtonPrimary></a>}
          </div>
          <div className="flex items-center gap-2">
            <small><FontAwesomeIcon className="" icon={faEnvelope} /> {data.firm.email}</small>
            <small><FontAwesomeIcon className="" icon={faGlobe} /> {data.firm.subdomain}</small>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="bg-light-grey rounded p-2">
            <div>
              <FontAwesomeIcon className="" icon={faCircleInfo} /> {edit ? <Input valueOverride={data.firm.bio} onChange={(bio) => setUpdateInfo({...updateInfo, bio})} /> : data.firm.bio}
            </div>
          </div>
          <div className="bg-light-grey rounded p-2">
            <div>
              <FontAwesomeIcon className="" icon={faBackwardStep} /> {edit ? <Input valueOverride={data.firm.filePrefix} onChange={(filePrefix) => setUpdateInfo({...updateInfo, filePrefix})} /> : data.firm.filePrefix}
            </div>
          </div>
          {["admin", "super-admin"].includes(currentUser.type) &&
            <div className="bg-light-grey rounded p-2">
              <div>
                <FontAwesomeIcon className="" icon={faCode} /> {edit ? <Input valueOverride={data.firm.stripeDiscountId} onChange={(stripeDiscountId) => setUpdateInfo({...updateInfo, stripeDiscountId})} /> : data.firm.stripeDiscountId}
              </div>
            </div>
          }
        </div>
        <div className="flex flex-col gap-4 w-full">
          <div>
            <h2>Settings</h2>
            <hr></hr>
          </div>
          <div className="flex flex-col">
            <label>Calendar</label>
            <Radios 
              readOnly={!edit} 
              valueOverride={data?.firm?.settings?.calendar?.colors} 
              onChange={(value) => setUpdateInfo({...updateInfo, settings: {...(updateInfo?.settings || {}), calendar: {...(updateInfo?.settings?.calendar || {}), colors: value}}})}
              options={[{ name: 'User Colors', value: "user" }, { name: 'Checklist Colors', value: '' }]}
            />
          </div>
        </div>
        {
          edit && <ButtonPrimary onClick={handleUpdate}>Update</ButtonPrimary>
        }
        <div className="flex flex-col gap-2 w-full">
          <div>
            <h2>Firm Input for Files</h2>
            <hr></hr>
          </div>
          <BusinessInput valueOverride={data.firm.business} type={["firm", "notary public"]}/>
        </div>
        <div className="w-full flex flex-col gap-2">
          <div>
            <h2>Users</h2>
            <hr></hr>
          </div>
          <div className="flex flex-col gap-1">
            {data?.firm?.users?.map(user => <User key={user.id} user={user} action={() => setShowRemoveNotiece(user.email)} actionName="Remove From Firm"/>)}
          </div>
        </div>
      </div>
      <InviteUser firmId={data && data.firm.id}/>
    </FullWidthPage>
    </>
    :
    <></>
  )
}
