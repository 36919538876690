import React from 'react'
import LoadingText from './LoadingText'
import LoadingIcon from './LoadingIcon'
import LoadingBlock from './LoadingBlock'
import LoadingParagraph from './LoadingParagraph'

export default function LoadingPage({color, random, className}) {

  return (
    <div className="leading-8">
      <LoadingText random/>
      <div className="flex gap-1">
        <LoadingIcon/>
        <div className="flex flex-col">
          <LoadingText random/>
        </div>
      </div>
      <LoadingBlock className="my-4"/>
      <LoadingParagraph className="py-4"/>
      <LoadingText random/>
      <div className="flex gap-1">
        <LoadingIcon/>
        <div className="flex flex-col">
          <LoadingText random/>
        </div>
      </div>
      <LoadingBlock className="my-4"/>
    </div>
  )
}
