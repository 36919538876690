import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faUser, faEnvelope, faBug } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import StatusToggle from 'screens/admin/bugReports/StatusToggle';

export default function BugReport({bugReport, firmId}) {
  return (
    <Link className="no-underline" to={`/admin/bug-reports/${bugReport.id}`}>
      <div className={`rounded cursor-pointer justify-between flex border-l-8 border-light-grey shadow-md hover:shadow-lg p-2 items-center gap-2`}>
          <div className="flex items-center gap-2">
            <FontAwesomeIcon className="" icon={faBug} />
            <strong>{bugReport.id}</strong>
            <div className="flex items-center gap-2 ml-2">
              <small><FontAwesomeIcon className="" icon={faUser} /> {bugReport.user.firstName} {bugReport.user.email}</small>
              {bugReport.fileId && 
                <Link className="no-underline" to={`/files/${bugReport.surveyId}/${bugReport.fileId}/summary`}><small><FontAwesomeIcon className="" icon={faFile} /> File</small></Link>
              }
            </div>
          </div>
          <div className="flex items-center gap-2">
              <StatusToggle bugReport={bugReport} readOnly={true}/>
          </div>
      </div>
    </Link>
  )
}
