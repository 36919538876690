import React, {useContext, useEffect, useMemo, useState} from 'react'
import AppContext from 'contexts/AppContext';
import { gql, useQuery } from '@apollo/client';

const GET_TRUST_ACCOUNTS = gql`
  query GetTrustAccounts($filters: JSONObject, $firmId: String) {
    trustAccounts(filters: $filters, firmId: $firmId) {
      id,
      name,
      transitNumber,
      accountNumber,
      institutionNumber,
      chequeNumber,
      firmId,
      bank {
        id,
        companyName,
        email,
        phoneNumber,
        fax,
        type,
        addressLineOne,
        addressLineTwo,
        city,
        province,
        postalCode,
        incorporationNumber,
        gstNumber,
      }
    }
  }
`;

export default function useTrustAccountChoices(startSelected, firmId) {
  const { currentUser } = useContext(AppContext);
  const { loading: loadingTrustAccounts, error: errorTrustAccounts, data } = useQuery(GET_TRUST_ACCOUNTS, {
    variables: {firmId: firmId},
  });
  const trustAccountChoices = useMemo(() => {
    return (data?.trustAccounts || []).map(account => ({name: account.name, value: account.id}))
  }, [data]);
  const [trustAccountId, setTrustAccountId] = useState(startSelected ? trustAccountChoices[0] && trustAccountChoices[0].value : null );

  useEffect(() => {
    if (startSelected && trustAccountChoices.length > 0) {
      setTrustAccountId(trustAccountChoices[0].value);
    }
  }, [trustAccountChoices]);

  return { trustAccountChoices, trustAccountId, setTrustAccountId, trustAccounts: data?.trustAccounts };
}
