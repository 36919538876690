import React from 'react'
import { PaddedPage } from 'components/common/Layout';
import ButtonPrimary from 'components/common/Button';
import landing from 'assets/images/landing/landing.jpg';
import { Link } from 'react-router-dom';

export default function Preview() {
  return (
    <div className="bg-cover bg-center-20 bg-no-repeat" style={{backgroundImage: `url(${landing})`}}>
       <PaddedPage className="flex h-test" >
          <div className="w-2/3 flex flex-col justify-center items-start gap-8">
              <h1 className="jumbo text-white">
                Conveyancing made easy
              </h1>
              <Link to="/about">
                <ButtonPrimary className="mt-2 p-12 px-10 font-bold border-white bg-white" >Learn more</ButtonPrimary>
              </Link>
          </div>
        </PaddedPage>
    </div>
  )
}
