import React, {useContext, useEffect, useState, useMemo} from 'react';
import {getCalculatedValue, getSurveyValue, pathMaker} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import Input from 'components/inputs/Input';
import ParentContext from 'contexts/ParentContext';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const PathTooltip = ({ 
    questionKey, 
}) => {
    const {row, parentPath, prevRows, showPaths} = useContext(ParentContext);
    const [copied, setCopied] = useState();
    const path = useMemo(() => {
        return pathMaker(questionKey, parentPath, row);
    }, [questionKey, parentPath, row]);

    let copy = () => {
        setCopied(!copied);
        navigator.clipboard.writeText(`*&$[answers.${path}]$&*`)
    }

    return (
        <div onClick={copy} className="relative">
            <Tooltip right message={`answers.${path}`} color="orange" icon={faMagnifyingGlass} />
            {
                copied &&
               <div className="animate-disapear font-bold p-1 text-sm text-green rounded absolute bg-white z-10 -top-1 left-6">
                 Copied!
               </div>
            }
        </div>
    )
};

export default PathTooltip;