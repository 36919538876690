import { gql, useMutation, useQuery } from '@apollo/client';
import ButtonPrimary from 'components/common/Button';
import createDocument, { createBlob } from 'helpers/document';
import React, { useEffect, useRef, useState } from 'react'
import _ from "lodash";
import ChequeDetailsModal from './ChequeDetailsModal';

const GET_DOCUMENT = gql`
  query GetDocument($id: String, $type: String) {
    document(id: $id, type: $type) {
      id,
      name,
      template,
      type,
      info
    }
  }
`;

const GET_TRUST_ACCOUNT = gql`
  query GetTrustAccount($id: String) {
    trustAccount(id: $id) {
      id,
      name,
      transitNumber,
      accountNumber,
      institutionNumber,
      chequeNumber,
      firmId,
      bank {
        id,
        companyName,
        email,
        phoneNumber,
        fax,
        type,
        addressLineOne,
        addressLineTwo,
        city,
        province,
        postalCode,
        incorporationNumber,
        gstNumber,
      }
    }
  }
`;

const GET_FILE = gql`
  query GetFile($surveyId: String, $id: String) {
    file(surveyId: $surveyId, id: $id) {
      fileNumber,
      nickname,
      icon,
      date,
      status,
      answers,
      requests,
      survey {
        name
      },
    }
  }
`;

const UPDATE_TRUST_ACCOUNT_ITEM = gql`
  mutation UpdateTrustAccountItem($id: String!, $trustAccountId: String, $payee: String, $date: String, $chequeDepositNumber: String, $type: String, $cleared: String) {
    updateTrustAccountItem(id: $id, trustAccountId: $trustAccountId, payee: $payee, date: $date, chequeDepositNumber: $chequeDepositNumber, type: $type, cleared: $cleared) {
      id,
      trustAccountId,
      date,
      cleared,
      payee,
      chequeDepositNumber,
      type
    }
  }
`;

const UPDATE_TRUST_ACCOUNT = gql`
  mutation UpdateTrustAccount(
    $id: String!,
    $name: String,
    $bankName: String,
    $bankId: String, 
    $transitNumber: String,
    $accountNumber: String,
    $institutionNumber: String,
    $incrementChequeNumber: Boolean,
  ) {
    updateTrustAccount(
      id: $id,
      name: $name,
      bankName: $bankName,
      bankId: $bankId,
      transitNumber: $transitNumber,
      institutionNumber: $institutionNumber,
      accountNumber: $accountNumber,
      incrementChequeNumber: $incrementChequeNumber
    ) {
      id,
      name,
      bankName,
      transitNumber,
      accountNumber,
      institutionNumber,
      chequeNumber,
    }
  }
`;

export default function DownloadCheque({chqueId, trustAccountItem, onDownload, disabled, trustAccounts}) {
  const [showDownload, setShowDownload] = useState(false);
  const [updateTrustAccountItemMutation, { loading:updateLoading }] = useMutation(UPDATE_TRUST_ACCOUNT_ITEM);
  const [updateTrustAccountMutation, { loading:updateTrustAccountLoading }] = useMutation(UPDATE_TRUST_ACCOUNT);

  const downloadRef = useRef(null);
  const { data:documentData } = useQuery(GET_DOCUMENT, {
    variables: {id: chqueId, type: "cheque"},
    skip: !showDownload,
    fetchPolicy: 'no-cache',
  });
  const { data:trustAccountData } = useQuery(GET_TRUST_ACCOUNT, {
    variables: {id: trustAccountItem.trustAccountId},
    skip: !showDownload,
    fetchPolicy: 'no-cache',
  });
  const { data:fileData } = useQuery(GET_FILE, {
    variables: {surveyId: trustAccountItem.surveyId, id: trustAccountItem.fileId},
    skip: !showDownload,
    fetchPolicy: 'no-cache',
  });

  const generateBlob = async (payee, bank, chequeNumber) => {
    let data = {
      ...fileData.file.answers, 
      trustAccount: {
        ...trustAccountData.trustAccount, 
        bank: bank || trustAccountData.trustAccount.bank
      }, 
      trustAccounts,
      trustAccountItem: {
        ...trustAccountItem, 
        chequeDepositNumber: chequeNumber || trustAccountItem.chequeDepositNumber,
        payee: payee || trustAccountItem.payee
      }
    };
    const blob = createBlob({...fileData.file, answers: data}, documentData.document.template);
    return blob;
  }
  
  const generateCheque = async (payee, bank, chequeNumber) => {
    const blob = await generateBlob(payee, bank, chequeNumber);
    const url = createDocument(blob);
    downloadRef.current.setAttribute('href', url);
    downloadRef.current.setAttribute('download', _.camelCase(`${documentData.document.name}-${chequeNumber}`)+".rtf");
    downloadRef.current.click();
  }
  const download = async (payee, bank) => {
    let chequeNumber = trustAccountItem.chequeDepositNumber;
    if (payee) {
      // add payee
      await updateTrustAccountItemMutation({variables: {id: trustAccountItem.id, payee}})
    }
    if ((bank || !chequeNumber) && (Number(trustAccountItem.value || 0) < 0)) {
      // add bank
      let res = await updateTrustAccountMutation({
        variables: {
          id: trustAccountItem.trustAccountId, 
          bankId: bank ? bank.id : undefined, 
          bankName: bank ? bank.companyName : undefined,
          incrementChequeNumber: !chequeNumber
        }
      })
      chequeNumber = res.data.updateTrustAccount.chequeNumber;
    }
    chequeNumber = chequeNumber || 1;
    // generateDocument with chequeNumber
    generateCheque(payee, bank, chequeNumber)
    onDownload(chequeNumber.toString())
    setShowDownload(false);
  }

  return (
    <div>
      {showDownload && 
        <ChequeDetailsModal
          onClose={() => setShowDownload(false)}
          onConfirm={download}
          bank={trustAccountData?.trustAccount?.bank}
          payee={trustAccountItem?.payee}
          loading={!documentData?.document || !trustAccountData?.trustAccount || !fileData?.file}
        />
      }
      <ButtonPrimary disabled={disabled} className="text-xxs bg-green text-white" onClick={() => setShowDownload(true)}>Download Cheque</ButtonPrimary>
      <a className="absolute" ref={downloadRef}></a>
    </div>
  )
}
