import React, {useContext, useState, useMemo, useCallback, useRef} from 'react';
import {getCalculatedValue, getSurveyValue, pathMaker} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import Input from 'components/inputs/Input';
import ParentContext from 'contexts/ParentContext';
import locationAutocomplete from 'helpers/locationAutocomplete';
import ButtonPrimary from 'components/common/Button';

const LinkInput = ({ 
  type, 
  questionKey, 
  title, 
  hideLabel, 
  hidden, 
  required,
  calculatedLabel,
  readOnly, 
  url,
  formStyles,
  description
}) => {
  const { file, updateAnswers, updateErrors, updateTotalsAndCalculations } = useContext(SurveyContext);
  const {row, parentPath, prevRows} = useContext(ParentContext);
  const path = useMemo(() => {
    return pathMaker(questionKey, parentPath, row);
  }, [questionKey, parentPath, row]);

  const calculatedLabelValue = useMemo(() => {
      return calculatedLabel && file && file.answers && getCalculatedValue(file, calculatedLabel, prevRows);
  }, [calculatedLabel]);

  return (
      <div className={`flex flex-col justify-between input grow items-end self-end ${hidden ? 'hidden' : ''}`}>
          {/* {!hideLabel && (title || calculatedLabelValue) && (
              description ? 
                  <label>
                      {title || calculatedLabelValue}
                      <Tooltip message={description} />
                  </label>
              :
                  <label>
                      {title || calculatedLabelValue}
                  </label>
          )} */}
          <a href={url} target="_blank">
            <ButtonPrimary>
              {title}
            </ButtonPrimary>
          </a>
      </div>
  )
};

export default LinkInput;