import React from 'react';
import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { useQuery, gql, useMutation } from "@apollo/client";
import {
  httpLink,
  setAuthHeaders,
  saveAuthHeaders,
} from 'middleware';

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL}/graphql?`,
  link: from([
      setAuthHeaders,
      saveAuthHeaders, 
      httpLink,
  ]),
  cache: new InMemoryCache(),
})

export default function useRequest({query}) {
  const [requestMutation, { loading, error, data, reset }] = useMutation(query, {
    client
  });

  return [requestMutation, {
    loading, 
    error,
    data,
    reset
  }]
}
