import React, {useState} from 'react'
import { useQuery, useMutation, gql } from "@apollo/client";
import { Link } from 'react-router-dom';
import Badge from 'components/common/Badge';
import FileItem from './FileItem';
import useFirmChoices from 'hooks/useFirmChoices';
import { List } from 'components/inputs';
import Search from 'components/common/Search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronDown, faSort } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import Loading from 'components/common/Loading';
import LoadingItem from 'components/loading/LoadingItem';

const GET_FILES = gql`
  query GetFiles($surveyId: String!, $firmId: String, $filters: JSONObject, $pageId: String) {
    files(surveyId: $surveyId, firmId: $firmId, filters: $filters, pageId: $pageId) {
      id,
      date,
      surveyId,
      status,
      icon,
      nickname,
      fileNumber,
      completionDate,
      searchMatch
    }
  }
`;

export default function SurveyFiles({survey}) {
  const {firmChoices, firmId, setFirmId} = useFirmChoices();
  const [filters, setFilters] = useState({orderBy: ""});
  const [pageId, setPageId] = useState();
  const [files, setFiles] = useState([]);
  useEffect(() => {
    setFiles([]);
    setPageId();
  },[filters]);
  const { loading, error, data } = useQuery(GET_FILES, {
    variables: {surveyId: survey && survey.id, firmId, filters, pageId},
    fetchPolicy:'no-cache',
    skip: survey ? false : true,
    onCompleted (data) {
      setFiles((files) => ([...files, ...data.files]));
    }
  });

  const changeFirm = (firmId) => {
    setFiles([]);
    setFirmId(firmId);
  }

  const loadMore = () => {
    if (!loading) {
      setPageId(files.at(-1).id);
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <div>
        <div className="flex flex-col md:flex-row gap-4 md:items-end">
          <div className="flex gap-1 flex-col md:flex-row md:items-center">
            <h2>📂{survey.name} Files</h2>
            <Search 
              filters={filters}
              setFilters={setFilters}
              toolTipText={"Search by: file name, file number, pid, supervising lawyer, conveyancer, buyer, seller, purchase address, or lender name."}
            />
          </div>
          {
            firmChoices.length > 1 && <List valueOverride={firmId} onChange={changeFirm} className="text-xs py-0.5" options={firmChoices}/>
          }
          <div className="flex gap-2 items-center">
            <FontAwesomeIcon icon={faSort}/>
            <div className="flex items-center gap-1">
              <input className="cursor-pointer" checked={!filters.orderBy} onClick={() => setFilters({...filters, orderBy: ""})} type="radio" id={"createdAt" + survey.name} name={"createdAt" + survey.name} value=""/>
              <label className="font-normal text-sm cursor-pointer" htmlFor={"createdAt" + survey.name}>Created At</label>
            </div>
            <div className="flex items-center gap-1">
              <input className="cursor-pointer" checked={filters.orderBy === "completionDate"} onClick={() => setFilters({...filters, orderBy: "completionDate"})} type="radio" id={"completionDate" + survey.name} name={"completionDate" + survey.name} value=""/>
              <label className="font-normal text-sm cursor-pointer" htmlFor={"completionDate" + survey.name}>Completion Date</label>
            </div>
          </div>
        </div>
        <hr/>
      </div>
      <div className="flex flex-col gap-2">
        {files.map((file, i) => (
          <FileItem key={i} file={file} search={filters.search}/>
        ))}
        {loading &&
            <>
              <LoadingItem/>
              <LoadingItem/>
              <LoadingItem/>
              <LoadingItem/>
              <Loading/>
            </>
        }
        {
          data && data.files.length > 9 &&
          <div className="text-grey flex justify-center">
            <div onMouseEnter={loadMore} className="cursor-pointer flex flex-col">
              <span className="text-xs">More</span>
              <FontAwesomeIcon icon={faCircleChevronDown}/>
            </div>
          </div>
        }
        {
          files.length < 1 &&
          <small className="text-med-grey">No Files</small>
        }
      </div>
    </div>
  )
}
