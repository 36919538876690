import { PaddedPage } from 'components/common/Layout'
import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import ButtonPrimary from 'components/common/Button';
import ProductPaymentForm from 'components/payments/ProductPaymentForm';
import { List } from 'components/inputs';
import useFirmChoices from 'hooks/useFirmChoices';
import Message from 'components/common/Message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';

const GET_PRODUCTS = gql`
  query GetProducts {
    products {
      id,
      name,
      description,
      metadata,
      default_price {
        id,
        metadata, 
        nickname, 
        unit_amount
      }
    }
  }
`;

export default function All() {
  const [purchase, setPurchase] = useState();
  const [success, setSuccess] = useState();
  const {firmChoices, firmId, setFirmId} = useFirmChoices(true, null, true);
  const { loading, error, data, refetch } = useQuery(GET_PRODUCTS, {
    fetchPolicy:'no-cache'
  });

  const handleSuccess = () => {
    setPurchase();
    setSuccess(true);
  }

  return (
    <PaddedPage noBottom>
        <h1>File Bundles</h1>
        {
          success &&
          <Message attached='bottom' className="mt-4" positive={true}>
            <FontAwesomeIcon className="text-xs text-green-400" icon={faCheck} /> Success, file credits have been added to the account.
          </Message>
        }
        <br/>
        {
          firmChoices.length > 1 && <List valueOverride={firmId} onChange={setFirmId} className="text-xs py-0.5" options={firmChoices}/>
        }
        <div className={`relative ${purchase ? 'min-h-180' : 'min-h-80'}`}>
          <div className="flex flex-col gap-4 items-start">
            {
              data && data.products.map(product => (
                <div className="rounded bg-light-grey p-4 flex gap-4 pl-8">
                  <div className="shrink-0 relative">
                    <FontAwesomeIcon icon={faFileLines} className="text-h1 relative bg-light-grey"/>
                    <FontAwesomeIcon icon={faFileLines} className="text-h1 relative -ml-6 top-6 bg-light-grey"/>
                    <FontAwesomeIcon icon={faFileLines} className="text-h1 relative -ml-6 top-12 bg-light-grey"/>
                    {
                      product.metadata.fileCredits > 50 &&
                      <>
                        <FontAwesomeIcon icon={faFileLines} className="text-h1 absolute -left-5 top-2 bg-light-grey"/>
                        <FontAwesomeIcon icon={faFileLines} className="text-h1 absolute left-1 top-8 bg-light-grey"/>
                        <FontAwesomeIcon icon={faFileLines} className="text-h1 absolute left-7 top-14 bg-light-grey"/>
                      </>
                    }
                  </div>
                  <div className="flex gap-1 flex-col">
                    <h2>
                      {product.name}
                    </h2>
                    <p className="md:w-96">
                      {product.description}
                    </p>
                    <i className="text-primary text-sm">
                      ${product.default_price.unit_amount/100}.00
                    </i>
                    <ButtonPrimary onClick={() => setPurchase(product)}>Purchase</ButtonPrimary>
                  </div>
                </div>
              ))
            }
          </div>
          {
            purchase &&
            <>
              <div 
                className="absolute top-0 bottom-0 left-0 right-0 bg-transparent-white"
                style={{
                  backdropFilter: "blur(1px)"
                }}
              />
              <div
                className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-start"
              >
                <div className="sticky top-10 flex items-center justify-center">
                  <div className="overflow-y-scroll w-160 max-h-payment scroller border">
                    <ProductPaymentForm 
                      priceId={purchase.default_price.id}
                      firmId={firmId}
                      onSuccess={handleSuccess}
                      product={purchase}
                    />
                  </div>
                </div>
              </div>
            </>
          }
        </div>
    </PaddedPage>
  )
}
