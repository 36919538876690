import React, {useContext, useMemo, useState} from 'react'
import AppContext from 'contexts/AppContext';
import { gql, useQuery } from '@apollo/client';

const GET_FIRMS = gql`
  query GetFirms {
    firms {
      id,
      name,
      subdomain,
      settings
    }
  }
`;

export default function useFirmChoices(startSelected, defaultChoice, allowGuest, admin, getSettings, excludeBasic) {
  const { currentUser } = useContext(AppContext);
  const { loading, error, data } = useQuery(GET_FIRMS, {
    skip: !admin && !getSettings
  });

  const savedSelection = useMemo(() => {
    return localStorage.getItem('firm-select');
  }, []);

  const firmChoices = useMemo(() => {
    let firms = currentUser ? currentUser.firms : [];
    if ((admin || getSettings) && data?.firms) {
      firms = data.firms;
    }
    return firms.reduce((acc, curr) => {
      if ((admin || curr.type !== 'guest' || allowGuest) && (!excludeBasic || curr.type !== 'basic')) {
        acc.push({name: curr.name, value: curr.id});
      }
      return acc;
    }, startSelected ? [] : [(defaultChoice || {name: "Mine", value: ""})]);
  }, [currentUser, startSelected, defaultChoice, admin]);
  
  const [firmId, setFirmId] = useState(firmChoices?.find(choice => choice.value === savedSelection)?.value || (startSelected ? firmChoices[0] && firmChoices[0].value : null) );

  const setFirm = (id) => {
    setFirmId(id);
    localStorage.setItem('firm-select', id);
  }

  const settings = useMemo(() => {
    return data?.firms.find(firm => firm?.id === firmId)?.settings || {};
  }, [firmId, data]);

  return { firmChoices, firmId, setFirmId:setFirm, settings};
}
