import React, {useContext} from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import Dropdown from 'components/common/Dropdown';
import AppContext from 'contexts/AppContext';

export default function CommonNav() {
  const {logout, currentUser} = useContext(AppContext);
  return (
    <div className="flex gap-8 text-sm">
        <Link className="no-underline font-bold" to="/files">Files</Link>
        <Link className="no-underline font-bold hidden md:inline" to="/files/new">New File</Link>
        <Link className="no-underline font-bold hidden md:inline" to="/letterheads">Letterheads</Link>
        <Link className="no-underline font-bold hidden md:inline" to="/calendar">Calendar</Link>
        <Link className="no-underline font-bold justify-center items-center gap-1 content-center hidden md:flex" to="/trust-account">Trust Account<div className="text-xxs leading-3 rounded bg-green text-white px-1">BETA</div></Link>
   </div>
  )
}
