import React from 'react';
import ButtonRoot from './Shared/ButtonRoot';

const ButtonPrimaryOutline = ({className, ...rest}) => {
  return (
    <ButtonRoot 
      className={`
        border-2 border-light-grey
        font-semibold 
        rounded 
        hover:no-underline
        ${className && className.includes("bg-") ? "" : "bg-white"}
        ${className}
      `}
      {...rest}
    />
  );
};

export default ButtonPrimaryOutline;
