import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useQuery, gql } from "@apollo/client";

const GET_SURVEY = gql`
  query GetSurvey($id: String!) {
    survey(id: $id) {
      id,
      name,
      linkId,
      order,
      pages {
        title,
        hideTitle,
        hideCalculation,
        questions
      }
    }
  }
`;;

export default function useSurvey(surveyId) {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_SURVEY, {
    variables: { id: surveyId },
    skip: surveyId ? false : true,
    fetchPolicy:'no-cache',
    onError(){
      navigate("/not-found")
    }
  });

  return {
    survey: data && data.survey,
    loading,
    error
  }
}
