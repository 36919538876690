import React, {useContext, useEffect, useState} from 'react'
import { useQuery, gql } from "@apollo/client";
import BugReport from 'components/bugReports/BugReport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronDown, faFilter } from '@fortawesome/free-solid-svg-icons';
import useFirmChoices from 'hooks/useFirmChoices';
import { List } from 'components/inputs';
import ActivePageContext from 'contexts/ActivePageContext';
import Search from 'components/common/Search';
import BugFilters from './BugFilters';
import { FullWidthPage } from 'components/common/Layout';
import Loading from 'components/common/Loading';

const GET_BUGREPORTS = gql`
  query GetBugReports($firmId: String, $filters: JSONObject, $pageId: String) {
    bugReports(firmId: $firmId, filters: $filters, pageId: $pageId) {
      id,
      user {
        firstName, 
        lastName,
        username,
        id
      }
      fileId,
      surveyId,
      email,
      status,
      description,
      page,
      error,
    }
  }
`;

export default function BugReports() {
  const [filters, setFilters] = useState({status: ""});
  const {firmChoices, firmId, setFirmId} = useFirmChoices(true);
  const [pageId, setPageId] = useState();
  const [showFilters, setShowFilters] = useState();
  const [bugReports, setBugReports] = useState([]);
  useEffect(() => {
    setBugReports([]);
    setPageId();
  },[filters]);
  const { loading, error, data } = useQuery(GET_BUGREPORTS, {
    variables: {firmId, filters, pageId},
    fetchPolicy: 'no-cache',
    onCompleted (data) {
      setBugReports((bugReports) => ([...bugReports, ...data.bugReports]));
    }
  });

  const {setActivePage} = useContext(ActivePageContext);
  setActivePage("bug-reports");

  const changeFirm = (firmId) => {
    setBugReports([]);
    setFirmId(firmId);
  }

  const loadMore = () => {
    if (!loading) {
      setPageId(bugReports.at(-1).id);
    }
  }

  return (
    <FullWidthPage bottom className="pt-4 flex flex-col gap-2">
      <div className="flex gap-2 items-end">
        <div className="flex gap-2 items-center">
          <h2>BugReports</h2>
          <FontAwesomeIcon className="cursor-pointer" icon={faFilter} onClick={() => setShowFilters(!showFilters)} />
          <Search filters={filters} setFilters={setFilters}/>
        </div>
        {
          firmChoices.length > 1 && <List valueOverride={firmId} onChange={changeFirm} className="text-xs py-0.5" options={firmChoices}/>
        }
      </div>
      {
        showFilters && <BugFilters filters={filters} setFilters={setFilters} />
      }
      <div className="flex flex-col gap-1">
        {bugReports.map(bugReport => <BugReport key={bugReport.id} bugReport={bugReport}/>)}
        {loading &&
          <Loading/>
        }
        {
          data && data.bugReports.length > 9 &&
          <div className="text-med-grey flex justify-center">
            <div onMouseEnter={loadMore} className="cursor-pointer flex flex-col">
              <span className="text-xs">More</span>
              <FontAwesomeIcon icon={faCircleChevronDown}/>
            </div>
          </div>
        }
        {bugReports.length === 0 && <small className="text-med-grey">No BugReports</small> }
      </div>
    </FullWidthPage>
  )
}
