import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding,faEnvelope } from '@fortawesome/free-solid-svg-icons';

export default function Firm({firm}) {
  return (
    <div className={`rounded cursor-pointer flex border-l-8 border-light-grey shadow-md hover:shadow-lg p-2 items-center gap-2`}>
        <FontAwesomeIcon className="" icon={faBuilding} />
        <strong>{firm.name}</strong>
        <div className="flex items-center gap-2 ml-4">
          {/* <small><FontAwesomeIcon className="" icon={faTag} /> {user.firstName}</small> */}
          <small><FontAwesomeIcon className="" icon={faEnvelope} /> {firm.email}</small>
        </div>
    </div>
  )
}
