import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa, faCcMastercard, faCcAmex } from '@fortawesome/free-brands-svg-icons';
import { faCreditCard, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Confirm from 'components/common/Confirm';

export default function PaymentMethod({ method, selected, setPaymentMethod, removePaymentMethod }) {
  const [confirm, setConfirm] = useState(false);
  
  const cardIcon = () => {
    switch(method.brand) {
    case 'visa':
      return  <FontAwesomeIcon icon={faCcVisa} />;
    case 'mastercard':
      return  <FontAwesomeIcon icon={faCcMastercard} />;
    case 'amex':
      return  <FontAwesomeIcon icon={faCcAmex} />;
    default:
      return  <FontAwesomeIcon icon={faCreditCard} />;
    }
  };

  const handleRemovePaymentMethod = () => {
    setConfirm(false);
    removePaymentMethod && removePaymentMethod(method);
  };


  return (
    <div onClick={() => setPaymentMethod(method.id)} className={`flex bg-grey-50 w-full rounded-md cursor-pointer gap-2 justify-between p-2 ${ selected  === method.id ? 'border-2 border-green-400 bg-gray-50' : 'bg-gray-100' }`}>
      <div className="flex items-center gap-2">
        {selected == method.id &&
          <FontAwesomeIcon className="text-xs text-green" icon={faCheck} />
        }
        {cardIcon()}
        <small><strong className="text-gray-600">{method.brand || method.type} </strong></small>
      </div>
      {method.last4 &&
        <div className="flex items-center">
          {method.exp_month && <small className="text-xs text-gray-400 mr-4">Expires: {method.exp_month}/{method.exp_year}</small>}
          <span>**** **** ****&nbsp;</span><small>{method.last4}</small>
        </div>
      }
      <FontAwesomeIcon 
        onClick={(e) => {
          e.stopPropagation();
          setConfirm(true);
        }} 
        className="ml-2 relative text-sm -top-1.5 text-gray-400 hover:text-gray-600 -right-1" 
        icon={faTimes} 
      />
      <Confirm
        open={confirm}
        text='Are you sure you want to remove this payment method?'
        onCancel={() => setConfirm(false)}
        onConfirm={handleRemovePaymentMethod}
      />
    </div>
  );
}
