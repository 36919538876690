import React, {useContext, useState, useMemo, useCallback, useRef} from 'react';
import {getCalculatedValue, getSurveyValue, pathMaker} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import Input from 'components/inputs/Input';
import ParentContext from 'contexts/ParentContext';
import locationAutocomplete from 'helpers/locationAutocomplete';

const AutoCompleteAddressInput = ({ 
  type, 
  questionKey, 
  title, 
  hideLabel, 
  hidden, 
  query,
  required,
  calculatedLabel,
  readOnly, 
  mapping,
  defaultValue,
  formStyles,
  description
}) => {
  const { file, updateAnswers, updateErrors, updateTotalsAndCalculations } = useContext(SurveyContext);
  const {row, parentPath, prevRows} = useContext(ParentContext);
  const path = useMemo(() => {
    return pathMaker(questionKey, parentPath, row);
  }, [questionKey, parentPath, row]);
  const [ error, setError ] = useState();
  const addressRef = useRef();

  const measuredRef = useCallback(node => {
    if (node !== null) {
      let autocomplete = locationAutocomplete(node, type);
      autocomplete?.addListener("place_changed", (res) => {
        const place = autocomplete.getPlace();
        let addressInfo = {};
        for (let item of place.address_components) {
          if (item.types.includes("route")) {
            addressInfo.addressLineOne = (addressInfo.addressLineOne || "") + item.long_name;
            addressInfo.streetAddress = item.long_name;
          } else if (item.types.includes("street_number")) {
            addressInfo.addressLineOne = item.long_name + " " + (addressInfo.addressLineOne || "");
            addressInfo.streetNumber = item.long_name;
          } else if (item.types.includes("floor", "room", "subpremise")) {
            addressInfo.addressLineTwo = addressInfo.addressLineTwo ? addressInfo.addressLineTwo + " " + item.long_name : item.long_name;
            addressInfo.unitNumber = item.long_name;
          } else if (item.types.includes("postal_code")) {
            addressInfo.postalCode = item.long_name;
          } else if (item.types.includes("administrative_area_level_1")) {
              addressInfo.province = item.long_name;
          } else if (item.types.includes("locality")) {
            addressInfo.city = item.long_name;
          }
        }
        if (addressInfo.unitNumber) {
          addressInfo.addressLineOne += " " + addressInfo.unitNumber;
        }
        updateValue(addressInfo.addressLineOne);
        let newValues = {};
        for (let key in addressInfo) {
          if (mapping[key]) {
            newValues[mapping[key]] = addressInfo[key]
          }
        }
        updateAnswers(Object.keys(newValues).map(key => pathMaker(key, parentPath, row)), Object.values(newValues));
      });
      addressRef.current = node;
    }
  }, []);

  const updateValue = (val) => {
      if (!checkError(val)) {
        console.log("updating value", path, val);
          updateAnswers(path, val);
      }
  }

  const calculatedLabelValue = useMemo(() => {
      return calculatedLabel && file && file.answers && getCalculatedValue(file, calculatedLabel, prevRows);
  }, [calculatedLabel]);

  const checkError = (val) => {
      if (required && !val) {
          setError("Field Required!");
          updateErrors(path, true);
          return true;
      }
      updateErrors(path, false);
      return false;
  }
  
  const surveyValue = useMemo(() => {
      let value = file && getSurveyValue(file.answers, path);
      if (!value && defaultValue && value !== 0) {
          updateValue(defaultValue);
      }
      return value;
  }, [file, path]);


  return (
      <div className={`flex flex-col justify-between input grow-0 items-start ${hidden ? 'hidden' : ''}`}>
          {!hideLabel && (title || calculatedLabelValue) && (
              description ? 
                  <label>
                      {title || calculatedLabelValue}
                      <Tooltip message={description} />
                  </label>
              :
                  <label>
                      {title || calculatedLabelValue}
                  </label>
          )}
          <p>{error}</p>
          <Input 
              forwardRef={measuredRef}
              className={formStyles} 
              type={type} 
              readOnly={readOnly} 
              valueOverride={surveyValue || ""} 
              onBlur={updateValue}
          />
      </div>
  )
};

export default AutoCompleteAddressInput;