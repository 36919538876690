import { faCircleMinus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

export default function LetterheadSelectItem({letterhead, selected, onClick}) {
  return (
    <div 
      onClick={onClick} 
      key={letterhead?.id} 
      className={`flex cursor-pointer rounded shadow-md hover:scale-105 w-full md:w-96 ${!letterhead ? 'h-14 border' : 'border-l-4'} ${selected && 'border-r border-r-green border-y border-y-green'}`}
    >
      {letterhead &&<div className="bg-light-grey flex items-center justify-center pr-1"><span></span></div>}
      <div className="flex items-center justify-between w-full p-2 gap-8">
        {
          letterhead ?
            <>
              <h3 className="-mt-1 font-medium text-primary">{letterhead.name}</h3>
              <img className="h-10" alt="letterhead preview" src={letterhead.previewUrl}/>
            </>
          :
          <span className="text-light-grey m-auto">none</span>
        }
      </div>
    </div>
  )
}
