import { faClock, faNoteSticky, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faChevronUp, faNotesMedical, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonPrimary from 'components/common/Button';
import Input, { IconSelector } from 'components/inputs';
import ColorSelector from 'components/inputs/ColorSelector';
import React, { useState } from 'react'

export default function ChecklistBlock({block, answers, notes, updateNotes, updateAnswers}) {
  const [editNote, setEditNote] = useState({});

  const updateNote = (note, item) => {
    updateNotes(note, item);
  }

  notes = notes || {};
  return (
    <div>
        <div>
            <div className="rounded border p-0.5 flex justify-between items-center" style={{backgroundColor: block.color}}>
                <div className="flex gap-2">
                    <h4 
                    className="font-bold drop-shadow-lg rounded-l px-2 bg-white inline-block"
                    style={{color: block.color}}
                    >
                        {block.title}
                    </h4>
                </div>
            </div>
        </div>
        <div className='flex flex-col gap-0.5 pt-2 items-start'>
            {block.items && block.items.map((item, itemIndex) => (
            <React.Fragment>
                <div 
                key={itemIndex} 
                className='flex gap-0.5'
                >
                <input 
                    className="cursor-pointer" 
                    type="checkbox" 
                    id={item} 
                    name={item} 
                    value={item}
                    checked={answers.includes(item)}
                    onClick={() => updateAnswers(item, itemIndex)}
                />
                <label className="cursor-pointer text-sm" htmlFor={item}>{item}</label>
                {!notes[item] &&
                    <FontAwesomeIcon className="mr-1 text-xs text-bg-dark cursor-pointer" onClick={(e) => (e.stopPropagation() || setEditNote({...editNote, [item]: !editNote[item]}))} icon={faNoteSticky}/>
                }
                </div>
                {(notes[item] || editNote[item]) &&
                <div className="rounded bg-transparent-grey text-xs p-1 px-2">
                    <sup><FontAwesomeIcon className="cursor-pointer" onClick={() => setEditNote({...editNote, [item]: !editNote[item]})} icon={faNoteSticky}/></sup>
                    {editNote[item] ? 
                    <Input className="text-base font-medium" valueOverride={notes[item]} onBlur={(note) => updateNote(note, item)} />
                    :  
                    notes[item]
                    }
                </div>
                }
            </React.Fragment>
            ))}
        </div>
    </div>
  )
}
