import { softSubset } from 'helpers/general';
import React, {useState} from 'react'

export default function Checkboxes({options, valueOverride, onChange}) {
  const changeValue = (val) => {
    let newValue = [...valueOverride];
    if (typeof val === 'object' ? valueOverride.find(value => softSubset(value, val)) : valueOverride.includes(val)) {
      newValue = newValue.filter(value => (typeof val === 'object' ? !softSubset(value, val) : val !== value));
      onChange([...newValue]);
    } else {
      onChange([...newValue, val]);
    }
  }
  return (
    <div className="flex flex-col">
      {options && options.map((option, i) => (
        <div className={`flex items-center gap-1 p-1 rounded ${i%2 && 'bg-transparent-grey'}`} key={i}>
          <input 
            className='cursor-pointer'
            key={i}
            type="checkbox"   
            name={option.name} 
            value={option.value}
            checked={typeof option.value === 'object' ? (valueOverride?.find?.(value => softSubset(value, option.value)) || "") : (valueOverride?.includes(option.value) || "")}
            onChange={() => changeValue(option.value)}
          />
          <label className="text-sm font-medium">{option.name}</label>
        </div>
      ))
      }
    </div>
  )
}
