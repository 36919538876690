import React from 'react'

export default function LetterheadDocPreview({previewImage, type}) {
  return (
    <div className="flex flex-col gap-4">
        <div>
          <h2>Document Preview</h2>
          <hr></hr>
        </div>
        <div className={`flex ${!type ? 'flex-col' : (type == 'footer' ? 'flex-col-reverse' : 'flex-row-reverse')} w-paper items-center h-paper border border-med-grey overflow-hidden`}>
          <img alt="letterhead preview" className={type == 'sidebar' ? 'h-paper' : `max-h-200px`} src={previewImage}/>
          <div  className="h-full flex flex-col gap-4 p-4">
              <p>
              <span className="leading-relaxed text-light-grey bg-light-grey" >Lorem ipsum dolor sit amet, consectetur</span>
              <br></br>
              <span className="leading-relaxed text-light-grey bg-light-grey" >Ut enim ad minim veniam. </span>
              <br></br>
              <span className="leading-relaxed text-light-grey bg-light-grey" >Excepteur sint occaecat cupidatat non proident</span>
              </p>
              <p>
              <span className="leading-relaxed text-light-grey bg-light-grey" >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              </span>
              </p>
              <p>
              <span className="leading-relaxed text-light-grey bg-light-grey" >
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
              </span>
              </p>
              <p>
              <span className="leading-relaxed text-light-grey bg-light-grey" >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              </span>
              </p>
              <br></br>
              <p>
              <span className="leading-relaxed text-light-grey bg-light-grey" >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </span>
              </p>
          </div>
        </div>
    </div>
  )
}
