import React, { useContext } from 'react';
import { PaddedPage } from 'components/common/Layout';
import { Link } from 'react-router-dom';

const NotAuthorized = () => {
  return (
    <PaddedPage className="flex flex-col gap-2">
      <h2>TERMS OF USE</h2>
      <p>This agreement (“Agreement”) constitutes a fully binding agreement between ConveyMe Software Inc. (including its affiliates and subsidiaries, herein after referred to as “ConveyMe”) and you.  Please read this Agreement carefully as it governs your use of ConveyMe’s software (the “Software”) and services, including any phone support, in person support, updates and accompanying written documentation provided to you (the “Services”).</p>
      <p>By using the Software and Services, you hereby agree to this Agreement and all the terms herein. The terms of this Agreement may change from time to time with notice to you by email or indication on our website.  It is your obligation to ensure that you are subscribed to our email notifications and to ensure your email is up to date.  Your continued usage of our Services is deemed acceptance of the changes in terms.  The terms of this Agreement may also vary in situations where other terms have been agreed to in writing with ConveyMe. </p>
      <p>If you do not agree to this Agreement or any of its parts, then you are prohibited and must refrain from using the Software or the Services.</p>
      <p>You acknowledge and agree that ConveyMe may occasionally contact you via email. Please see the ConveyMe’s Privacy Policy, which is incorporated into this Agreement by reference.</p>

      <h3>WHAT IS THE SOFTWARE AND SERVICE</h3>
      <p>The Software is a conveyancing platform used by law firms and offices of notary publics for the purposes of preparing and generating documentation (the “Documentation”) to be used in the conveyances of title to property and interests in property from one party to another. </p>
      <h3>LICENCED PERMITTED USE</h3>
      <p>ConveyMe grants to you, for use in connection with your internal business operations, a limited, non-exclusive, non-transferable, license to the Software, Services and Documentation, subject to the terms set forth in this Agreement. Your rights to use the Software, Services and Documentation are subject to the ongoing payment of applicable per-file transaction fees. Updates provided by us will form part of the Software and Documentation and will be subject to rights granted in this Agreement. You may permit your employees, agents and contractors to use the Software and Documentation for purposes permitted pursuant to this Agreement and you will be responsible for their compliance in accordance with the terms of this Agreement. All rights not expressly granted to you hereunder are reserved by ConveyMe. You acknowledge that the Software may require activation by approval upon signup and from time to time based on certain events, including, without limitation, updates and changes to hardware on which the Software is installed. </p>

      <h3>RESTRICTIONS</h3>
      <p>You agree (a) subject to any non-waivable rights you may enjoy under applicable law, not to decompile, disassemble, reverse engineer, or otherwise attempt to derive the Software’s source code from the object code; (b) not to modify, enhance, change the data structures for or create derivative works from, the Software, (c) not to rent, lease, sell, sublicense or otherwise transfer the Software to third parties; (d) not to make the Software available in any form to any person other than your employees, agents and contractors whose job performance requires such access; and (e) to use reasonable care and protection to prevent the unauthorized use, copying, publication or dissemination of the Software. You shall not allow access to the Software by any service bureau, third party outsourcer, or other similar third party service provider unless ConveyMe consents to such access in writing.</p>
      <h3>COPYRIGHT</h3>
      <p>All intellectual property rights in and to the website, Software, the Services and its database, including copyrights, trademarks, industrial designs, patents and trade secrets – are either the exclusive property of ConveyMe or its affiliates or are exclusively licensed to ConveyMe. This includes but is not limited to: text, graphics, editorial content, data, formatting, graphs, designs, HTML, look and feel, photographs, music, sounds, images, software, videos, designs, typefaces and other content and any suggestions, ideas, enhancement requests, feedback, recommendations, or other information provided by any user or third party relating to the Software or the Service. The Service is protected, among others, by the Canadian Copyright Act, RSC 1985, c C-42, as well as by applicable copyright provisions prescribed by any other law, in North America and elsewhere.</p>
      <h3>INTELLECTUAL PROPERTY – YOUR INPUT</h3>
      <p>ConveyMe acknowledges that any firm privileged or client data entered into the Software by you (the “Data”) is owned by you. During your active use of the Software or within 30 days thereafter, you may request an export of the Data. Upon request, ConveyMe agrees to make reasonable efforts to export said Data in our standard export format. After this export period, ConveyMe may delete the Data in accordance with its standard schedule and procedures. If you elect to proactively delete your account at any time, the Data will be deleted permanently and cannot be retrieved.
      </p><p>ConveyMe further confirms that the Data shall be encrypted upon its transmission by you and securely stored in Canada, and shall not be made available to any other user of the Software not authorized by you, or to any third party without your prior written permission. ConveyMe shall have the right to access, amend, or otherwise change the settings/users found in your Admin section, but shall not have any further rights with regards to any other Data.
      </p><p>When requested by you, you hereby authorize our tech support and client support team access to the Data for the purposes of troublshooting and customer support to assist you.   
      </p>
      <h3>PUBLIC DATABASE</h3>
      <p>The Software provides access to a database, which is populated with publicly available contact information (“Contact Information”) of parties such as, but not limited to: law firms, banks, real estate firms and insurance companies (“Public Database”) which are provided by users of the Software. You have the ability and obligation to maintain your current and correct Contact Information through the Software.  You also have the ability to add entries to the Public Database.  If ConveyMe determines any Contact Information to be inaccurate, ConveyMe reserves the right to update and correct such Contact Information. You must only include publicly available business contact information in any entry in the Public Database and represent and warrant that you shall not provide any information to the Public Database that is not business contact information, including but not limited to any personally identifiable information.
      </p>
      <p>You agree that if you provide any Contact Information to the Software, you represent and agree that you have the right to do so. By providing the Contact Information you confirm that you provide ConveyMe with permission to make such Contact Information publicly available to other users of the Software.
      </p>

      <p>You may only use the Contact Information from the Public Database solely for the purposes of populating forms, fields and documents within the Software, corresponding with other parties whose information is found in the Public Database, transactions conducted using the Software, and exercising your rights and obligations under this Agreement and for no other purpose. You shall not use the Contact Information for the purpose of sending any marketing or advertising materials or any “Commercial Electronic Messages” as defined in Canadian Anti Spam Legislation.
      </p>

      <h3>YOUR RESPONSIBILITIES</h3>
      <p>You shall enter all data required for implementation of the Software and creation of documents and shall be responsible for the integrity of such data.  This also includes the Data as well as Contact Information from the Public Database. ConveyMe shall not have any liability for any of the data, including for data that ConveyMe personnel may enter in an effort to assist you or any errors made in such efforts to assist you.
      </p><p>You shall assume full responsibility for (i) the content in any database that was entered by you, (ii) the selection and implementation of controls on its access and use of the Software and (iii) configuration data associated with the implementation of the Software.
      </p>
      <h3>LICENSE & UPDATES</h3>
      <p>In consideration of the Fees being paid by you in accordance with the terms of this Agreement, ConveyMe will provide to you in a timely manner and without additional charge: 
      </p>
      <ol type="A" className="list-[lower-alpha] pl-4">
        <li>The right to “use” under license, as provided herein, the Software, the Service and Documentation, and related materials; </li>
        <li>All Reasonable efforts to correct any errors reported by you, provided such errors relate to the proper functioning of the Software and have not been caused by negligence on the part of you, a computer malfunction, third party materials or another causes external to the Software, and further provided that you acknowledge and agree that not all errors are capable of being corrected; and</li>
        <li>Regular updates to the Software, Services, and or Documentation to ensure that we provide, in our opinion, optimal service for our clients.</li>
      </ol>
      <h3>INTERFACES</h3>
      <p>Software interfaces to third party vendor systems may be available from time to time. To the extent such third party vendor system interfaces are available, ConveyMe shall only provide access to the third party system via the Software and shall not be responsible for any interactions between you and the third party vendor system. You shall act as a liaison between ConveyMe and any third party vendor(s) with which the Software shall interface. Transactions processed by a third party vendor system may be subject to separate licensing requirements and fees. You acknowledge and agree that you have the sole obligation to obtain, or cause your third party vendor to obtain, any and all such licenses. You also covenant not to do anything or cause any acts to be done that would jeopardize ConveyMe’s relationship with a third party vendor or potentially cause any damage to a third party vendor’s assets.  You also acknowledge and agree that you are responsible for all fees charged by the third party vendor.  
      </p>
      <h3>PAYMENT PROVISIONS</h3>
      <p>You agree to pay ConveyMe all fees as set out below.</p>
      <ol type="A" className="list-[lower-alpha] pl-4">
        <li>You are responsible for the payment of a corresponding file transaction fee (the “Fees”) charged in the course of opening new files in the Software, including, but not limited to, those denoted as purchase, sale and refinance transactions. The Fees are subject to change from time to time at ConveyMe’s discretion, with reasonable notice of any pending change provided to you. </li>
        <li>Additional fees may apply in order to access third party products and services.  These additional fees are subject to change from time to time at the discretion of the applicable third party provider.</li>
        <li>You will only use each file or record in relation to the single transaction or entity for which it was originally purchased. You are required to create a new transaction for each purchase, sale, or mortgage transaction processed using the Software. Non-compliance with this section may result in suspension and/or termination of your access to the Software.</li>
        <li>You will be required to pay for the Fees by credit card during each transaction prior to generating documents.  </li>
      </ol>
      <p>
      You acknowledge that ConveyMe reserves the right to suspend or interrupt your use of the Software for any period during which any fees due in accordance with the terms of this Agreement remain unpaid for seven (7) days after ConveyMe provides advanced written notice (including by way of email) of such unpaid fees to you. In such an event, ConveyMe shall not be precluded from exercising any additional remedies that might be available to under the terms of this Agreement or otherwise.
      </p>
      <h3>NO LEGAL ADVICE</h3>
      <p>The Software, Services and information on this website are provided with the understanding that at no time does ConveyMe review documents for legal sufficiency, draw legal conclusions, provide legal advice or apply the law to the facts of your particular situation, and the Services are not a substitute for the advice of a lawyer. It is your obligation to review and ensure all documents display correct information for your transactional purposes, and that the statement of adjustment and account calculations are correct, as you will be providing the information.  It is also your obligation to seek legal advice to ensure all documents generated by our Software meet with your legal obligations and fulfill your requirements to your clients.  The provision of the Software, Services and information on and through this website is not intended to create, and does not constitute, a lawyer-client relationship. Communications between you and ConveyMe are protected by our Privacy Policy and the terms contained herein.
      </p><p>Furthermore, changes in the law, legislation and legal practice rules may require updates to our base set of Documentation.  We will be notifying you by email when these changes occur. Where you have provided custom templates or created your own templates, it is your obligation to ensure that your customized documents reflect current law, legislation, and legal practice.
      </p>
      <h3>DISCLAIMER</h3>
      <p>YOUR USE OF THE SOFTWARE, SERVICES AND DOCUMENTATION AND ANY CONTENT DOWNLOADED FROM THE CONVEYME WEBSITE IS AT YOUR OWN RISK. THE SOFTWARE, SERVICES AND DOCUMENTATION AND ALL INFORMATION, ARE PROVIDED ON AN “AS IS, AS AVAILABLE” BASIS WITHOUT REPRESENTATION OR WARRANTY OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, ALL OF WHICH ARE DISCLAIMED BY CONVEYME SOFTWARE INC. TO THE FULLEST EXTENT PERMITTED BY LAW. CONVEYME SOFTWARE INC. DOES NOT REPRESENT OR WARRANT: (1) THE QUALITY, ACCURACY OR COMPLETENESS OF ANY CLAIMS, STATEMENTS OR INFORMATION ON OUR WEBSITE OR THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SOFTWARE, SERVICES AND DOCUMENTATION OBTAINED THROUGH OUR WEBSITE; (2) THE SUITABILITY OF ANY OF THE SOFTWARE, SERVICES, AND DOCUMENTATION CONTAINED ON THIS WEBSITE OR OBTAINED THROUGH THIS WEBSITE FOR ANY PURPOSE; (3) THAT THIS WEBSITE OR THE INFORMATION, PRODUCTS AND SERVICES ON OR TO BE OBTAINED THROUGH THIS WEBSITE WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS; AND (4) THAT THIS WEBSITE OR THE INFORMATION, SOFTWARE, SERVICES AND DOCUMENTATION ON OR TO BE OBTAINED THROUGH THIS WEBSITE WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE BASIS.
      </p>
      <h3>LIMITATION OF LIABILITY</h3>
      <p>
      IN NO EVENT SHALL CONVEYME SOFTWARE INC. BE LIABLE FOR ANY DAMAGES WHATSOEVER, WHETHER DIRECT OR INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, FOR ANY LOSS OF BUSINESS, REVENUE, PROFITS, GOODWILL, DATA, DOCUMENTS, PROPERTY, MATERIALS OR EQUIPMENT, OR OTHER ECONOMIC ADVANTAGE OR INTANGIBLE LOSS), ARISING OUT OF OR IN CONNECTION WITH (1) THE USE OF, OR DELAY OR INABILITY TO USE, THIS WEBSITE OR ANY INFORMATION, SOFTWARE OR SERVICES OBTAINED THROUGH THIS WEBSITE; (2) UNAUTHORIZED ACCESS TO, ALTERATION OF, OR INACCURACY, ERROR OR OMISSIONS IN YOUR CONTENT, TRANSMISSIONS OR DATA; (3) SERVICE OUTAGES THAT ARE CAUSED BY CONVEYME SOFTWARE INC.’S MAINTENANCE ON THE TECHNOLOGY THAT UNDERLIES THIS WEBSITE; (4) PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS; (5) FAILURES OF CONVEYME SOFTWARE INC.’S SERVICE PROVIDERS (INCLUDING TELECOMMUNICATIONS, HOSTING, AND POWER PROVIDERS); (6) COMPUTER VIRUSES; (7) NATURAL DISASTERS OR OTHER DESTRUCTION OR DAMAGE OF CONVEYME SOFTWARE INC.’S FACILITIES, ACTS OF NATURE, WAR, CIVIL DISTURBANCE, OR ANY OTHER CAUSE BEYOND CONVEYME SOFTWARE INC.’S REASONABLE CONTROL; OR (8) STATEMENTS OR CONDUCT OF ANY THIRD PARTY; OR OTHERWISE ARISING OUT OF THE USE OF THIS WEBSITE, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, EVEN IF THERE IS A FUNDAMENTAL BREACH OR IF CONVEYME SOFTWARE INC HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. WITHOUT IN ANY WAY LIMITING THE GENERALITY OF THE FOREGOING, CONVEYME SOFTWARE INC IS NOT LIABLE FOR THE ACTS OR OMISSIONS OF ANY THIRD PARTY ACTING ON INSTRUCTIONS FROM CONVEYME SOFTWARE INC.
      </p>
      <h3>TERM AND TERMINATION</h3>
      <ol type="A" className="list-[lower-alpha] pl-4">
        <li>
          <span className="font-bold">Term.</span> This Agreement shall remain in full force and effect until terminated in accordance with the terms herein.
        </li>
        <li>
        <span className="font-bold">Failure to Pay File Transaction Fees.</span> In the event that you fail to pay the applicable file transaction Fees when due, ConveyMe reserves the right to elect to take one of the following courses of action (without limiting ConveyMe’s other available remedies): (i) notify you that this Agreement will immediately expire (or has expired) and remove your access to the Software, Service and Documentation; or (ii) allow this Agreement to continue, in which event, the applicable Fees for all subsequent transactions will continue to be payable; provided, however, that if ConveyMe does not affirmatively notify you that alternative (i) or (ii) has been selected, then alternative (ii) will apply.
        </li>
        <li>
        <span className="font-bold">Termination by ConveyMe.</span> Subject to Section (b) (Failure to Pay File Transaction Fees), ConveyMe has the right to terminate the license granted under this Agreement immediately if you are in default of any term or condition of this Agreement, or if you become insolvent, a receiver, administrator, controller or a liquidator is appointed to you, you assign any of its property for the benefit of creditors or any class of them or any proceedings have been commenced by or against you under any bankruptcy, insolvency or similar laws.  
        </li>
        <li>
        <span className="font-bold">Termination by You.</span> You have the right to terminate this Agreement as set out in this Agreement and by notification to us.    
        </li>
        <li>
        <span className="font-bold">Effect of Termination.</span> Upon termination of this Agreement for any reason, any and all amounts owed to ConveyMe pursuant to this Agreement will be immediately due and payable, and all license rights granted to you hereunder will be immediately revoked and terminated. Following the termination of this Agreement, the Sections titled “Copyright; Intellectual Property - Your Input,” “Payment Provisions,” “Disclaimer,” “Limitation of Liability,” “Governing Law,” and “Dispute Resolution” will continue in full force and effect in accordance with their terms. As soon as possible after Termination, you will cease using the Software, Services and Documentation and confirm the same to ConveyMe in writing by a duly authorized officer.
        </li>
      </ol>
      <p>
      </p>
      <h3>GOVERNING LAW AND JURISDICTION</h3>
      <p>This Agreement, the Software and the Service will be governed solely by the laws of British Columbia, Canada, without giving effect to any conflicts of law principles. Any dispute, claim or controversy arising out of, connected with or relating to this Agreement, the Software, the Service, and or the Documentation will be under the exclusive jurisdiction of the court of competent jurisdiction in the Province of British Columbia, Canada.
      </p>
      <h3>DISPUTE RESOLUTION</h3>
      <p>To expedite resolution and reduce the cost of any dispute, controversy or claim related to, arising from or regarding your use of the Software, Service or use of Documentation, your relationship with ConveyMe, or this Agreement (including previous versions) (“Dispute”), you and ConveyMe agree to first attempt to negotiate any Dispute informally for at least 30 days before initiating any arbitration (if you are a resident of U.S or Canada).
      </p><p>
      PLEASE READ THIS SECTION CAREFULLY — IT AFFECTS YOUR LEGAL RIGHTS AND GOVERNS HOW YOU AND CONVEYME CAN BRING CLAIMS AGAINST EACH OTHER. THIS SECTION WILL, WITH LIMITED EXCEPTIONS, REQUIRE YOU AND CONVEYME TO SUBMIT CLAIMS AGAINST EACH OTHER TO BINDING AND FINAL ARBITRATION ON AN INDIVIDUAL BASIS.
      </p><p>
      Except where prohibited by law, any controversy, claim or dispute arising out of, relating to, or in respect of the Agreement, including the negotiation, validity, existence, breach, termination, construction or application, or the rights, duties or obligations of any party, or the rights, duties or obligations of any party derived from or associated with the Agreement (a “Dispute”), shall be referred to and determined by a single arbitrator in a final and binding arbitration under the British Columbia Arbitration Act, SBC 2020, c 2, and administered under the rules of the Vancouver International Arbitration Centre (“VANIAC”). If the parties have not agreed upon an arbitrator within 14 days, unless otherwise agreed by the parties in writing, the parties shall ask VANIAC to appoint a single arbitrator in accordance with their rules. For greater certainty, neither party shall commence or participate in a class proceeding or other aggregate action in respect of any Dispute, except and to the extent that provincial consumer protection legislation expressly preserves such an entitlement in the context of the particular Dispute.
      </p><p>
      The seat of the arbitration shall be the same as the provincial or territorial law governing this Agreement (i.e. the Province of British of Columbia). The arbitration shall be heard in Vancouver, British Columbia, unless the parties agree otherwise. The costs and expenses of the arbitrator shall be shared equally between the parties. A party to the arbitration has no right of appeal from any award of the Arbitrator, whether characterized as final, interim, interlocutory or partial.
      </p><p>
      All Disputes referred to arbitration (including the scope of the agreement to arbitrate, the law relating to the enforcement of the agreement to arbitrate, any relevant limitation periods, the law governing the procedure of the arbitration, the law relating to available remedies, set-off claims and conflict of laws rules) shall be governed by the law of the seat. Each party hereby irrevocably consents to venue in Vancouver, British Columbia, and to the jurisdiction of competent courts in British Columbia for all litigation that may be brought; however it is agreed and acknowledged that the intention of the parties is to arbitrate the Dispute without recourse to the courts.
      </p><p>
      A party to this Agreement may take such steps as are permitted or required to enforce an award made by an Arbitrator. Except as required by law, and only to the extent that such disclosure is reasonably necessary, or for the purposes of obtaining professional advice, the existence of the arbitration and any element of the arbitration, including any award, shall be confidential and shall not be disclosed to any non-party to the arbitration. No document or other evidence or information prepared for or produced by or on behalf of any party to the arbitration shall be disclosed to any non-party to the arbitration.
      </p>

    </PaddedPage>
  );
};

export default NotAuthorized;
