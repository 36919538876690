import React, {useState, useRef, useEffect, useContext, useMemo} from 'react';
import Input from 'components/inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import AddMortgage from './AddMortgage';
import { useQuery, gql } from "@apollo/client";
import Mortgage from 'components/mortgage/Mortgage';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import {getSurveyValue, pathMaker} from 'selectors/formSelectors';
import ParentContext from 'contexts/ParentContext';

const GET_MORTGAGES = gql`
  query GetMortgages($filters: JSONObject, $firmId: String, $bankId: String) {
    mortgages(filters: $filters, firmId: $firmId, bankId: $bankId) {
      bank {
        companyName
      },
      id,
      interestActStatement,
      assignmentOfRents,
      assignmentOfRentsParagraphNumber,
      assignmentOfRentsPageNumber,
      placeOfPayment,
      floatingChargeOnLand,
      currentOrRunningAcct,
      dfNumber,
      name,
      principal,
      interestRate,
      interestAdjustmentDate,
      interestCalcPeriod,
      paymentDates,
      firstPaymentDate,
      monthlyPayments,
      lastPaymentDate,
      balanceDueDate,
      termType,
      addnlOrModifiedTerms,
      priorEncumbrances,
      documents,
      public,
    }
  }
`;

export default function MortgageInput({hideLabel, label, description, questionKey, multiple, questions}) {
  const [search, setSearch] = useState();
  const { file, updateAnswers, updateErrors } = useContext(SurveyContext);
  const searchRef = useRef();
  const { row, prevRows, parentPath } = useContext(ParentContext);
  const bank = useMemo(() => {
    return (file && file.answers.lender) || {};
  }, [file])
  const { loading, error, data, refetch } = useQuery(GET_MORTGAGES, {
    variables: {filters: {search}, firmId: file && file.firmId},
    skip: !search || search === ' ' ? true : false
  });
  const [selected, setSelected] = useState([]);
  const [showNew, setShowNew] = useState();
  const path = useMemo(() => {
      return pathMaker(questionKey, parentPath, row);
  }, [questionKey, parentPath, row]);

  const handleShowNew = (val) => {
    setShowNew(val);
    if (val) {
      setSearch('');
    } else {
      setSearch(' ');
    }
  }

  const handleSelect = (val, refreshSearch = false) => {
    val = {...val};
    for (let key in val) {
      if (!val[key]) {
        val[key] = "";
      }
    }
    if (multiple) {
      let newValue = [...selected.filter(current => current.id !== val.id), val]
      setSelected(newValue);
      updateAnswers(path, newValue)
    } else {
      setSelected([val]);
      updateAnswers(path, val)
    }
    setShowNew(false);
    setSearch('');
    if (refreshSearch) refetch();
  }
  const handleRemove = (i) => {
    let newValue = null;
    if (multiple) {
      newValue = [...selected];
      newValue.splice(i, 1);
      setSelected(newValue);
    } else {
      setSelected([]);
    }
    updateAnswers(path, newValue);
  }

  const surveyValue = useMemo(() => {
    return file && getSurveyValue(file.answers, path);
  }, [file, path]);

  useEffect(() => {
    setSelected(multiple ? surveyValue && surveyValue.id && (surveyValue|| []) : ((surveyValue && surveyValue.id) ? [surveyValue] : []));
  }, [surveyValue, multiple]);

  useEffect(() => {
    if (!showNew && search === " ") {
      searchRef.current.focus();
    }
  }, [search, showNew])

  const options = data ? 
    data.mortgages.map(mortgage => (
      <div 
        key={mortgage.id}
        className="hover:bg-light-light-grey rounded cursor-pointer p-2"
        onClick={() => handleSelect(mortgage)}
      >
        {mortgage.name}
      </div>
    )) 
    : [];

  return (
    <div className="flex flex-col justify-start gap-2 items-start">
       {!hideLabel && label && (
          description ? 
              <label>
                  {label}
                  <Tooltip message={description} />
              </label>
          :
              <label>
                  {label}
              </label>
      )}
      {
        showNew &&
        <AddMortgage firmId={file.firmId} bank={bank} close={() => handleShowNew(false)} onCreate={handleSelect}/>
      }
      {
        !showNew && (selected.length < 1 || multiple) &&
        <div className={`flex flex-col ${showNew && 'hidden'}`}>
          <div className="relative">
            <Input placeholder={`Find ${bank && bank.companyName ? bank.companyName : ''} Mortgage Product`} valueOverride={search} forwardRef={searchRef} className={`pl-8 ${search && 'rounded-b-none'}`} onChange={setSearch}/>
            <FontAwesomeIcon  className={`absolute top-2 left-1`} icon={faMagnifyingGlass}/>
          </div>
          {search &&
            <div className="flex flex-col border border-t-none bg-white p-4 gap-2 shadow-md rounded-b">
              <div onClick={() => handleShowNew(true)}className="border border-green self-start text-green rounded py-1 px-4 cursor-pointer">New {(bank && bank.companyName) ? bank.companyName + " ": ''}Mortgage Product!</div>
              <hr></hr>
              <div className="flex flex-col max-h-32 overflow-y-scroll scroller">
                {
                  options.length > 0 ?
                  options
                  :
                  <small className="text-light-grey">None Found</small>
                }
              </div>
            </div>
          }
        </div>
      }
      <div className="flex gap-2 flex-wrap">
        {
          selected.map((mortgage, i) => 
            <ParentContext.Provider key={i} value={{row: multiple ? i : null, parentPath: path, prevRows: multiple ? [...prevRows, i] : prevRows}}>
              <Mortgage onUpdate={handleSelect} key={i} mortgage={mortgage} onDeselect={() => handleRemove(i)}>
                {questions}
              </Mortgage>
            </ParentContext.Provider>
          )
        }
      </div>
    </div>
  )
}
