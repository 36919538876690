import React, { useContext, useEffect, useState } from 'react'
import { useQuery, gql } from "@apollo/client";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCirclePlus, faCircleChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import BusinessLineItem from './BusinessLineItem';
import ActivePageContext from 'contexts/ActivePageContext';
import Search from 'components/common/Search';
import { FullWidthPage } from 'components/common/Layout';
import Loading from 'components/common/Loading';
import { Label, List, Radios } from 'components/inputs';


const GET_BUSINESS = gql`
  query GetBusinesses($filters: JSONObject, $type: [String], $pageId: String, $asAdmin: Boolean) {
    businesses(filters: $filters, type: $type, pageId: $pageId, asAdmin: $asAdmin) {
      id,
      companyName,
      email,
      phoneNumber,
      fax,
      type,
      addressLineOne,
      addressLineTwo,
      firmId,
      gstNumber,
      public,
      firm {
        name
      },
      user {
        username
      }
    }
  }
`;

const types = ['', 'firm', 'notary public', 'realtor', 'bank', 'broker', 'strata management company', 'discharge centers'];

export default function Businesses() {
  const {setActivePage} = useContext(ActivePageContext);
  const [filters, setFilters] = useState({strictSearch: true});
  const [type, setType] = useState();
  const [pageId, setPageId] = useState();
  const [businesses, setBusinesses] = useState([]);
  useEffect(() => {
    setBusinesses([]);
    setPageId();
  },[filters, type]);
  const { loading, error, data } = useQuery(GET_BUSINESS, {
    fetchPolicy:'no-cache',
    variables: {filters, pageId, asAdmin: true, type: type && [type]},
    onCompleted (data) {
      setBusinesses((businesses) => ([...businesses, ...data.businesses]));
    }
  });

  const loadMore = () => {
    if (!loading) {
      setPageId(businesses.at(-1).id);
    }
  }

  setActivePage('business');
  return (
    <FullWidthPage bottom className="pt-4 flex flex-col gap-2"> 
      <div className="flex gap-2 items-center">
        <h2>Businesses <Link to="new"><FontAwesomeIcon className="text-green" icon={faCirclePlus} /></Link></h2>
        <Search filters={filters} setFilters={setFilters}/>
        <div>
          <List valueOverride={type} inline onChange={(type) => setType(type)} options={types.map(type => ({name: type || 'all', value: type}))}/>
        </div>
      </div>
      <div  className="flex flex-col gap-1">
        {businesses.map(business => <Link className="no-underline" key={business.id} to={`/admin/businesses/${business.id}`} ><BusinessLineItem business={business}/></Link>)}
        {loading &&
          <Loading/>
        }
        {
          data && data.businesses.length > 9 &&
          <div className="text-med-grey flex justify-center">
            <div onMouseEnter={loadMore} className="cursor-pointer flex flex-col">
              <span className="text-xs">More</span>
              <FontAwesomeIcon icon={faCircleChevronDown}/>
            </div>
          </div>
        }
        {businesses.length === 0 && <small className="text-med-grey">No Businesses</small> }
      </div>
    </FullWidthPage>
  )
}
