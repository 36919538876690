import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import ButtonPrimary from 'components/common/Button';
import Transparent from './Transparent';
import {Input} from 'components/inputs';
import ltsaLogo from "assets/images/survey/ltsalogo.svg";
import SurveyContext from 'contexts/SurveyContext';
import { useMutation, gql } from '@apollo/client';

const GETTOKEN = gql`
  mutation CreateToken($fileId: String!, $username: String!, $password: String!) {
    createToken(fileId: $fileId, username: $username, password: $password) {
      id,
      expiry, 
      type
    }
  }
`;


export default function VerifyLTSA({ onClose, onConfirm}) {
  let [open, setOpen] = useState(true);
  let [confirmTextMatch, setConfirmTextMatch] = useState();
  const { file, updateFile } = useContext(SurveyContext);
  const [ username, setUsername ] = useState();
  const [ password, setPassword ] = useState();
  const [getTokenMutation, { loading, error }] = useMutation(GETTOKEN);

  const valid = () => {
    return username && password;
  }

  const submit = () => {
    if (valid()) {
      getTokenMutation({
        variables: {username, password, fileId: file.id}
      })
      .then(res => {
        updateFile({tokens: {...file.tokens, LTSAtoken: res.data.createToken.expiry}});
        confirm();
      })
    }
  }

  const close = () => {
    setOpen(false);
    onClose();
  }

  const confirm = () => {
    setOpen(false);
    onConfirm();
  }

  return (
    <>
      {
        open &&
        <Transparent>
          <div onClick={close} className="absolute flex justify-center left-0 items-center w-full h-full">
            <div onClick={(e) => e.stopPropagation()} className="bg-white rounded border max-w-7/8 w-80 shadow-md">
              <div className="rounded border p-4 flex flex-col gap-2 bg-light-light-grey border-grey">
                <img className="h-12 self-start" src={ltsaLogo} alt="ltsa logo"/>
                <small>Enter your LTSA credential to allow ConveyMe to create documents on your behalf.</small>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col">
                    <label>Ltsa Username</label>
                    <Input onChange={setUsername}/>
                  </div>
                  <div className="flex flex-col">
                    <label>Password</label>
                    <Input onChange={setPassword} type="password"/>
                  </div>
                </div>
                <ButtonPrimary className="self-end" disabled={loading || !valid()} onClick={submit}>Authorize</ButtonPrimary>
              </div>
            </div>
          </div>
        </Transparent>
      }
    </>
  )
}
