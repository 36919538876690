import { faChevronCircleDown, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useState } from 'react';
import Document from './Document';

export default function DocumentGroup({documents, title, letterhead, sidebar, footer, zip, extraData, errorMessages}) {
  const [hide, setHide] = useState(false);
  let sortedDocuments = documents.sort(document =>{
    let compare = document.category ? document.category.localeCompare(document.category) : 0;
    if (!compare) {
      compare = document.name.localeCompare(document.name)
    }
    return compare;
  });
  return (
    <div className="flex flex-col gap-2">
      <div>
        <div className="flex justify-between cursor-pointer" onClick={() => setHide(!hide)}>
          <h3 className="text-med-grey">{title !== "null" ? title : "Common Documents"}</h3>
          <FontAwesomeIcon icon={hide ? faChevronDown : faChevronUp }/>
        </div>
        <hr></hr>
      </div>
      <div className={`flex flex-col gap-2 ${hide ? "hidden" : ""}`}>
        {
          sortedDocuments.map((document, i) => (
            <Document 
              zip={zip} 
              letterhead={letterhead} 
              sidebar={sidebar} 
              footer={footer} 
              key={i} 
              documentId={document.id} 
              extraData={extraData}
              errorMessages={errorMessages}
            />
          ))
        }
      </div>
    </div>
  )
}
