import React, {useState, useContext} from 'react';
import Input, { Label, Radios } from 'components/inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFileSignature, faTimes } from '@fortawesome/free-solid-svg-icons';
import ButtonPrimary from 'components/common/Button';
import { List } from 'components/inputs';
import SurveyContext from 'contexts/SurveyContext';
import { useMutation, gql } from "@apollo/client";
import { typeToLabel } from 'helpers/general';
import ObjectBuilder from 'components/inputs/ObjectBuilder';

const ADD_MORTGAGE = gql`
  mutation AddMortgage(
    $interestActStatement: String,
    $assignmentOfRents: Boolean,
    $placeOfPayment: String,
    $floatingChargeOnLand: Boolean,
    $currentOrRunningAcct: Boolean,
    $dfNumber: String,
    $name: String,
    $bankId: String,
    $public: Boolean,
    $assignmentOfRentsParagraphNumber: String,
    $assignmentOfRentsPageNumber: String,
    $firmId: String,
    $principal: String,
    $interestRate: String,
    $interestAdjustmentDate: String,
    $interestCalcPeriod: String,
    $paymentDates: String,
    $firstPaymentDate: String,
    $monthlyPayments: String,
    $lastPaymentDate: String,
    $balanceDueDate: String,
    $termType: String,
    $addnlOrModifiedTerms: String,
    $priorEncumbrances: String,
    $documents: JSONObject
  ) {
    createMortgage(
      interestActStatement: $interestActStatement,
      assignmentOfRents: $assignmentOfRents,
      placeOfPayment: $placeOfPayment,
      floatingChargeOnLand: $floatingChargeOnLand,
      currentOrRunningAcct: $currentOrRunningAcct,
      dfNumber: $dfNumber,
      name: $name,
      bankId: $bankId,
      public: $public,
      assignmentOfRentsParagraphNumber: $assignmentOfRentsParagraphNumber, 
      assignmentOfRentsPageNumber: $assignmentOfRentsPageNumber,
      firmId: $firmId,
      principal: $principal,
      interestRate: $interestRate,
      interestAdjustmentDate: $interestAdjustmentDate,
      interestCalcPeriod: $interestCalcPeriod,
      paymentDates: $paymentDates,
      firstPaymentDate: $firstPaymentDate,
      monthlyPayments: $monthlyPayments,
      lastPaymentDate: $lastPaymentDate,
      balanceDueDate: $balanceDueDate,
      termType: $termType,
      addnlOrModifiedTerms: $addnlOrModifiedTerms,
      priorEncumbrances: $priorEncumbrances,
      documents: $documents
    ) {
      id,
      interestActStatement,
      assignmentOfRents,
      assignmentOfRentsParagraphNumber, 
      assignmentOfRentsPageNumber,
      placeOfPayment,
      floatingChargeOnLand,
      currentOrRunningAcct,
      dfNumber,
      name,
      bank {
        companyName
      },
      principal,
      interestRate,
      interestAdjustmentDate,
      interestCalcPeriod,
      paymentDates,
      firstPaymentDate,
      monthlyPayments,
      lastPaymentDate,
      balanceDueDate,
      termType,
      addnlOrModifiedTerms,
      priorEncumbrances,
      documents
    }
  }
`;

const EDIT_MORTGAGE = gql`
  mutation UpdateMortgage(
    $id: String!,
    $interestActStatement: String,
    $assignmentOfRents: Boolean,
    $placeOfPayment: String,
    $floatingChargeOnLand: Boolean,
    $currentOrRunningAcct: Boolean,
    $dfNumber: String,
    $name: String,
    $assignmentOfRentsParagraphNumber: String,
    $assignmentOfRentsPageNumber: String,
    $principal: String,
    $interestRate: String,
    $interestAdjustmentDate: String,
    $interestCalcPeriod: String,
    $paymentDates: String,
    $firstPaymentDate: String,
    $monthlyPayments: String,
    $lastPaymentDate: String,
    $balanceDueDate: String,
    $termType: String,
    $addnlOrModifiedTerms: String,
    $priorEncumbrances: String,
    $documents: JSONObject
  ) {
    updateMortgage(
      id: $id,
      interestActStatement: $interestActStatement,
      assignmentOfRents: $assignmentOfRents,
      placeOfPayment: $placeOfPayment,
      floatingChargeOnLand: $floatingChargeOnLand,
      currentOrRunningAcct: $currentOrRunningAcct,
      dfNumber: $dfNumber,
      name: $name,
      assignmentOfRentsParagraphNumber: $assignmentOfRentsParagraphNumber, 
      assignmentOfRentsPageNumber: $assignmentOfRentsPageNumber,
      principal: $principal,
      interestRate: $interestRate,
      interestAdjustmentDate: $interestAdjustmentDate,
      interestCalcPeriod: $interestCalcPeriod,
      paymentDates: $paymentDates,
      firstPaymentDate: $firstPaymentDate,
      monthlyPayments: $monthlyPayments,
      lastPaymentDate: $lastPaymentDate,
      balanceDueDate: $balanceDueDate,
      termType: $termType,
      addnlOrModifiedTerms: $addnlOrModifiedTerms,
      priorEncumbrances: $priorEncumbrances,
      documents: $documents
    ) {
      id,
      interestActStatement,
      assignmentOfRents,
      assignmentOfRentsParagraphNumber, 
      assignmentOfRentsPageNumber,
      placeOfPayment,
      floatingChargeOnLand,
      currentOrRunningAcct,
      dfNumber,
      name,
      principal,
      interestRate,
      interestAdjustmentDate,
      interestCalcPeriod,
      paymentDates,
      firstPaymentDate,
      monthlyPayments,
      lastPaymentDate,
      balanceDueDate,
      termType,
      addnlOrModifiedTerms,
      priorEncumbrances,
      bank {
        companyName
      },
      documents
    }
  }
`;

export default function AddMortgage({close, onCreate, type, bank, publicMortgage, edit, firmId, readOnly}) {
  const [newMortgage, setNewMortgage] = useState(edit || {type: type && type[0]});
  const {file} = useContext(SurveyContext);
  const [addMortgageMutation, { loading }] = useMutation(ADD_MORTGAGE);  
  const [editMortgageMutation, { loading:editLoading }] = useMutation(EDIT_MORTGAGE);  


  const valid = () => {
    return newMortgage && newMortgage.name && (edit ? true : bank && bank.id);
  }
  const addMortgage = () => {
    if (valid()) {
      // return
      addMortgageMutation({variables: {
        name: newMortgage.name,
        bankId: bank.id,
        interestActStatement: newMortgage.interestActStatement,
        assignmentOfRents: true == newMortgage.assignmentOfRents,
        assignmentOfRentsParagraphNumber: newMortgage.assignmentOfRentsParagraphNumber, 
        assignmentOfRentsPageNumber: newMortgage.assignmentOfRentsPageNumber,
        placeOfPayment: newMortgage.placeOfPayment,
        floatingChargeOnLand: true == newMortgage.floatingChargeOnLand,
        currentOrRunningAcct: true == newMortgage.currentOrRunningAcct,
        dfNumber: newMortgage.dfNumber,
        public: publicMortgage,
        firmId,
        principal: newMortgage.principal,
        interestRate: newMortgage.interestRate,
        interestAdjustmentDate: newMortgage.interestAdjustmentDate,
        interestCalcPeriod: newMortgage.interestCalcPeriod,
        paymentDates: newMortgage.paymentDates,
        firstPaymentDate: newMortgage.firstPaymentDate,
        monthlyPayments: newMortgage.monthlyPayments,
        lastPaymentDate: newMortgage.lastPaymentDate,
        balanceDueDate: newMortgage.balanceDueDate,
        termType: newMortgage.termType,
        addnlOrModifiedTerms: newMortgage.addnlOrModifiedTerms,
        priorEncumbrances: newMortgage.priorEncumbrances,
        documents: (newMortgage.documents && newMortgage.documents.length > 9) ? newMortgage.documents : null,
      }})
      .then(res => {
        onCreate(res.data.createMortgage, true);
      })
    }
  }

  const editMortgage = () => {
    if (valid()) {
      editMortgageMutation({variables: {
        ...newMortgage,
        assignmentOfRents: true == newMortgage.assignmentOfRents,
        floatingChargeOnLand: true == newMortgage.floatingChargeOnLand,
        currentOrRunningAcct: true == newMortgage.currentOrRunningAcct,
      }})
      .then(res => {
        console.log("res",res);
        onCreate(res.data.updateMortgage, true);
      })
    }
  }

  return (
    <div className="p-4 border border-transparent-dark-grey rounded flex flex-col gap-4 bg-transparent-grey">
      <div className="relative">
        <h3> <FontAwesomeIcon className={``} icon={faFileSignature}/> {edit ? "Edit" : "New"} {bank ? bank.companyName + " ": ''} Mortgage</h3>
        {close && <FontAwesomeIcon onClick={close} className={`absolute top-1 right-1 cursor-pointer`} icon={edit ? faTimes : faMagnifyingGlass}/>}
      </div>
      {type && <Radios valueOverride={newMortgage.type} inline onChange={(type) => setNewMortgage({...newMortgage, type})} options={type.map(type => ({name: type, value: type}))}/> }
      <div className="flex flex-col">
        <Label text="Name of Mortgage Product"/>
        <Input readOnly={readOnly} valueOverride={newMortgage.name} onChange={(name) => setNewMortgage({...newMortgage, name})} />
      </div>
      <div className="flex flex-col">
        <Label text="Principal"/>
        <Input readOnly={readOnly} valueOverride={newMortgage.principal} onChange={(principal) => setNewMortgage({...newMortgage, principal})} />
      </div>
      <div className="flex flex-col">
        <Label text="Interest Rate"/>
        <Input readOnly={readOnly} valueOverride={newMortgage.interestRate} onChange={(interestRate) => setNewMortgage({...newMortgage, interestRate})} />
      </div>
      <div className="flex flex-col">
        <Label text="Interest Adjustment Date"/>
        <Input readOnly={readOnly} valueOverride={newMortgage.interestAdjustmentDate} onChange={(interestAdjustmentDate) => setNewMortgage({...newMortgage, interestAdjustmentDate})} />
      </div>
      <div className="flex flex-col">
        <Label text="Interest Calculation Period"/>
        <Input readOnly={readOnly} valueOverride={newMortgage.interestCalcPeriod} onChange={(interestCalcPeriod) => setNewMortgage({...newMortgage, interestCalcPeriod})} />
      </div>
      <div className="flex flex-col">
        <Label text="Payment Date"/>
        <Input readOnly={readOnly} valueOverride={newMortgage.paymentDates} onChange={(paymentDates) => setNewMortgage({...newMortgage, paymentDates})} />
      </div>
      <div className="flex flex-col">
        <Label text="First Payment Date"/>
        <Input readOnly={readOnly} valueOverride={newMortgage.firstPaymentDate} onChange={(firstPaymentDate) => setNewMortgage({...newMortgage, firstPaymentDate})} />
      </div>
      <div className="flex flex-col">
        <Label text="Periodic Payment"/>
        <Input readOnly={readOnly} valueOverride={newMortgage.monthlyPayments} onChange={(monthlyPayments) => setNewMortgage({...newMortgage, monthlyPayments})} />
      </div>
      <div className="flex flex-col">
        <Label text="Last Payment Date"/>
        <Input readOnly={readOnly} valueOverride={newMortgage.lastPaymentDate} onChange={(lastPaymentDate) => setNewMortgage({...newMortgage, lastPaymentDate})} />
      </div>
      <div className="flex gap-2">
        <div className="flex flex-col">
            <Label text="Assignment of Rents which the applicant wants registered?"/>
            <Radios 
              readOnly={readOnly}
              valueOverride={newMortgage.assignmentOfRents} onChange={(assignmentOfRents) => setNewMortgage({...newMortgage, assignmentOfRents})} 
              options={[
                {name: "Yes", value: true},
                {name: "No", value: false},
              ]}
            />
        </div>
      </div>
      {(newMortgage.assignmentOfRents) &&
        <>
          <div className="flex flex-col">
              <Label text="A/R Page Number"/>
              <Input readOnly={readOnly} valueOverride={newMortgage.assignmentOfRentsPageNumber} onChange={(assignmentOfRentsPageNumber) => setNewMortgage({...newMortgage, assignmentOfRentsPageNumber})} />
          </div>
          <div className="flex flex-col">
              <Label text="A/R Paragraph Number"/>
              <Input readOnly={readOnly} valueOverride={newMortgage.assignmentOfRentsParagraphNumber} onChange={(assignmentOfRentsParagraphNumber) => setNewMortgage({...newMortgage, assignmentOfRentsParagraphNumber})} />
          </div>
        </>
      }
      <div className="flex gap-2">
        <div className="flex flex-col">
          <Label text="Interest Act Statement"/>
          <Input readOnly={readOnly} valueOverride={newMortgage.interestActStatement} onChange={(interestActStatement) => setNewMortgage({...newMortgage, interestActStatement})} />
        </div>
        <div className="flex flex-col">
            <Label text="Place of Payment"/>
            <Input readOnly={readOnly} valueOverride={newMortgage.placeOfPayment} onChange={(placeOfPayment) => setNewMortgage({...newMortgage, placeOfPayment})} />
        </div>
      </div>
      <div className="flex flex-col">
        <Label text="Balance Due Date"/>
        <Input readOnly={readOnly} valueOverride={newMortgage.balanceDueDate} onChange={(balanceDueDate) => setNewMortgage({...newMortgage, balanceDueDate})} />
      </div>
      <div className="flex flex-col">
          <Label text="Floating Charge on Land"/>
          <Radios 
            readOnly={readOnly}
            valueOverride={newMortgage.floatingChargeOnLand} onChange={(floatingChargeOnLand) => setNewMortgage({...newMortgage, floatingChargeOnLand})} 
            options={[
              {name: "Yes", value: true},
              {name: "No", value: false},
            ]}
          />
      </div>
      <div className="flex gap-2">
        <div className="flex flex-col">
            <Label text="Current or Running Account"/>
            <Radios 
              readOnly={readOnly}
              valueOverride={newMortgage.currentOrRunningAcct} onChange={(currentOrRunningAcct) => setNewMortgage({...newMortgage, currentOrRunningAcct})} 
              options={[
                {name: "Yes", value: true},
                {name: "No", value: false},
              ]}
            />
        </div>
      </div>
      <div className="flex flex-col">
          <Label text="Term Type"/>
          <Radios 
            valueOverride={newMortgage.termType} onChange={(termType) => setNewMortgage({...newMortgage, termType})} 
            options={[
              {name: 'Prescribed Standard Mortgage Terms', value: 'PresStdMortgageTerm'},
              {name: 'Filed Standard Mortgage Terms', value: 'FiledStdMortgageTerm'},
              {name: 'Express Mortgage Terms', value: 'ExpressMortgageTerm'},
            ]}
          />
      </div>
      <div className="flex flex-col">
          <Label text="df Number"/>
          <Input readOnly={readOnly} valueOverride={newMortgage.dfNumber} onChange={(dfNumber) => setNewMortgage({...newMortgage, dfNumber})} />
      </div>
      <div className="flex flex-col">
          <Label text="Additional Or Modified Terms"/>
          <Input readOnly={readOnly} type="textarea" valueOverride={newMortgage.addnlOrModifiedTerms} onChange={(addnlOrModifiedTerms) => setNewMortgage({...newMortgage, addnlOrModifiedTerms})} />
      </div>
      <div className="flex flex-col">
          <Label text="Prior Encumbrances"/>
          <Input readOnly={readOnly} type="textarea" valueOverride={newMortgage.priorEncumbrances} onChange={(priorEncumbrances) => setNewMortgage({...newMortgage, priorEncumbrances})} />
      </div>
      <div className="flex flex-col">
          <Label text="Documents"/>
          <ObjectBuilder 
            value={newMortgage.documents} 
            onChange={(documents) => setNewMortgage({...newMortgage, documents})} 
            keyName="Document Name"
            valueName="Document Link"
          />
      </div>
      <ButtonPrimary disabled={editLoading || loading || !valid()} onClick={readOnly ? close : edit ? editMortgage :addMortgage} className="self-start">{readOnly ? 'Close' : edit ? "Update" : "New"}</ButtonPrimary>
    </div>)
}
