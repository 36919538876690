import React, {useContext, useState, useEffect, useMemo} from 'react';
import AppContext from 'contexts/AppContext';
import RequestFlagsContext from 'contexts/RequestFlagsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faTimes, faTriangleExclamation, faCircleExclamation, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import ButtonPrimary from 'components/common/Button';
import Transparent from './Transparent';


let colorLookup = {
  error: 'bg-red',
  warning: 'bg-yellow',
  success: 'bg-green'
}

let titleLookup = {
  error: 'Error !',
  warning: 'Warning',
  success: 'Success !'
}

let IconLookup = {
  error: faCircleExclamation,
  warning: faTriangleExclamation,
  success: faCircleCheck
}

export default function Notices() {
  let {success} = useContext(AppContext);
  let {currentError, removeError} = useContext(RequestFlagsContext)
  let [open, setOpen] = useState(false);
  let message = currentError ? currentError[1].message : success && success.message;

  useEffect(() => {
    if (currentError || success) {
      setOpen(true);
    }
  }, [currentError, success]);

  const close = () => {
    setOpen(false);
    if (currentError) {
      removeError(currentError[0]);
    } else if (success) {
      // TODO remove errors or succss messages
      //remove success message
    }
  }

  let type = useMemo(() => {
    if (currentError && ['403', '400'].includes(currentError[0])) {
      return 'error';
    } else if (success) {
      return 'success';
    } else {
      return 'warning';
    }
  }, [currentError, success]);

  return (
    <>
      {
        open &&
        <Transparent priority={true}>
          <div onClick={close} className="absolute flex justify-center items-center w-full h-full">
            <div className="bg-white rounded border max-w-7/8 w-80 shadow-md">
              <div className={`flex justify-between relative px-4 py-2 ${colorLookup[type]}`}>
                <h2 className="text-white">
                  <FontAwesomeIcon onClick={close} className="" icon={IconLookup[type]} />
                  &nbsp;
                  {titleLookup[type]}
                </h2>
                <FontAwesomeIcon onClick={close} className="text-white cursor-pointer absolute top-1 right-2" icon={faTimes} />
              </div>
              <div className="p-4 bg-whiter py-8">
                {message}
              </div>
              <div className="p-4 bg-whiter flex justify-end border-t py-2">
                <ButtonPrimary onClick={close} >Close</ButtonPrimary>
              </div>
            </div>
          </div>
        </Transparent>
      }
    </>
  )
}
