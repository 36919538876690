
const readableDate = (date) => {
  const formattedDate = new Date(date).toLocaleDateString(
    'en-gb',
    {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
  );

  const formattedTime = new Date(date).toLocaleTimeString(
    'en-us',
    {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit'
    }
  );

  return `${formattedDate}, ${formattedTime}`;
};

const readableDay = (date) => {
  const formattedDate = new Date(date).toLocaleDateString(
    'en-gb',
    {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
  );
  return `${formattedDate}`;
};

const readableTime = (date) => {
  const formattedTime = new Date(date).toLocaleTimeString(
    'en-us',
    {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit'
    }
  );

  return `${formattedTime}`;
};

const base64UrlToUint8Array = (base64UrlData) => {
  const padding = '='.repeat((4 - base64UrlData.length % 4) % 4);
  const base64 = (base64UrlData + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = atob(base64);
  const buffer = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    buffer[i] = rawData.charCodeAt(i);
  }

  return buffer;
};

const typeToLabel = (type) => {

  return type && type.join(" or ");
}

const softSubset = (elemOne, elemTwo, excludeNumber, stringIncludes, excludeKeys) => {
  if (Array.isArray(elemTwo) && Array.isArray(elemOne)) {
    if (elemOne.length < elemTwo.length) return false;
    for (let i = 0; i < elemTwo.length; i++) {
      if (!softSubset(elemOne[i], elemTwo[i], excludeNumber, stringIncludes, excludeKeys)) return false;
    }
  } else if (typeof elemTwo === 'object' && typeof elemOne === 'object' && elemTwo !== null && elemOne !== null) {
    for (let key in elemTwo) {
      if((!excludeKeys?.includes(key)) && (key?.[0] !== '_') && !softSubset(elemOne[key], elemTwo[key], excludeNumber, stringIncludes, excludeKeys)) return false;
    }
  } else {
    if (!elemOne && !elemTwo) return true;
    if (excludeNumber && Number((elemOne === "0" ? 0 :elemOne) || 1) && Number((elemTwo === "0" ? 0 :elemTwo) || 1)) return true;
    return stringIncludes ? String(elemTwo)?.includes(elemOne) || String(elemOne)?.includes(elemTwo) : elemOne == elemTwo;
  }
  return true;
}

export {
  readableDate,
  readableDay,
  readableTime,
  base64UrlToUint8Array,
  typeToLabel,
  softSubset
};
