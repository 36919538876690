import React, {useEffect, useState} from 'react'
import Input from './Input';

export default function Radios({options, initialValue, onChange, valueOverride, readOnly, inline, multiple, other}) {
  const [value, setValue] = useState(initialValue || multiple ? {} : '');
  const [useOther, setUseOther] = useState();
  const changeValue = (val, other = false) => {
    let newValue = val;
    if (!other) {
      setUseOther(false);
    }
    if (multiple) {
      newValue = {...value, [val]: !value[val]}
      setValue(newValue);
      onChange(Object.keys(newValue));
    } else {
      setValue(newValue);
      onChange(newValue);
    }
  }
  useEffect(() => {
    if (valueOverride !== undefined || (valueOverride === '' && value)) {
      setValue(valueOverride);
    }
  }, [valueOverride]);

  const selectUseOther = () => {
    setUseOther(true);
    changeValue("", true);
  }

  return (
    <form className={`${inline && 'flex gap-2'}`}>
      {options.map((option) => (
        <div key={option.value} className={`cursor-pointer flex gap-1 items-center`}>
          <input 
            className="cursor-pointer"
            type="radio"
            readOnly={readOnly}
            name={option.name} 
            value={option.value}
            checked={multiple ? value.includes(option.value) : String(value) === String(option.value)}
            onChange={() => !readOnly && changeValue(option.value)}
          />
          <span className="cursor-pointer text-sm" onClick={() => !readOnly && changeValue(option.value)}>{option.name}</span>
        </div>
      ))
      }
      {
        other &&
        <>
          <div className={`cursor-pointer flex gap-1 items-center`}>
            <input 
              className="cursor-pointer"
              type="radio"
              readOnly={readOnly}
              name={"other"} 
              value={true}
              checked={useOther}
              onChange={() => !readOnly && selectUseOther()}
            />
            <span className="cursor-pointer text-sm" onClick={() => !readOnly && selectUseOther()}>other</span>
          </div>
          {
            useOther &&
            <Input 
                readOnly={readOnly} 
                valueOverride={value} 
                onBlur={(val) => changeValue(val, true)}
            />
          }
        </>
      }
    </form>
  )
}
