import React, {useContext, useState} from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faEnvelope, faUser, faTimes, faCircleLeft, faMask } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AppContext from 'contexts/AppContext';
import ReccentSurveyFiles from 'components/survey/ReccentSurveyFiles';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import ButtonPrimary from 'components/common/Button';
import { FullWidthPage } from 'components/common/Layout';

const GET_USER = gql`
  query GetUser($id: String) {
    user(id: $id) {
      id,
      firstName,
      lastName,
      email,
      username,
      type,
      firms {
        id,
        name
      }
    }
  }
`;

const REMOVE_FROM_FIRM = gql`
  mutation RemoveFromFirm($firmId: String!, $email: String!) {
    removeFromFirm(firmId: $firmId, email: $email) {
      id
    }
  }
`;

export default function User({removeUser, currentFirmId}) {
  const {currentUser, impersonate} = useContext(AppContext);
  const [removeFirm, setRemoveFirm] = useState();
  const navigate = useNavigate();
  const [removeFromFirmMutation, { loading:removeLoading }] = useMutation(REMOVE_FROM_FIRM);
  const params = useParams();
  const { loading, error, data, refetch } = useQuery(GET_USER, {
    variables: {id: params.id}
  });
	const goBack = () => {
		navigate(-1);
	}

  const showFirm = (firmId) => {
    if (currentUser && ["admin", "super-admin"].includes(currentUser.type)) return true;
    for (let firm of (currentUser ? currentUser.firms : [])) {
      if (firm.type === "admin" && firm.id === firmId) {
        return true;
      }
    }
  }

  const removeFromFirm = (firmId) => {
    removeFromFirmMutation({variables: {firmId, email: data.user.email}})
      .then(res => {
        if (["admin", "super-admin"].includes(currentUser.type)) {
          refetch();
        } else {
          navigate("/admin/users");
        }
      })
  }

  return (
    data ?
    <FullWidthPage bottom className="pt-4">
      {removeFirm && <ConfirmNotice 
        title={`Are you sure you want to remove ${data.user.firstName} from ${removeFirm.name}?`}
        message={`
          To remove ${data.user.firstName} type "${removeFirm.name}" below. 
          They will no longer be able to create or view firm files.
        `}
        confirmText={removeFirm.name}
        onConfirm={removeFromFirm}
        onClose={() => removeFirm(null)}
      /> }
      <div className="flex flex-col gap-6">
        <div className="flex gap-2 cursor-pointer">
          <FontAwesomeIcon className="text-2xl" onClick={goBack} icon={faCircleLeft} />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <h1><FontAwesomeIcon className="" icon={faUser} /> {data.user.username}
            </h1>
            {data.user.type == "admin" && <div className="rounded bg-red text-white px-3 font-bold">ADMIN</div>}
            {data.user.type == "super-admin" && <div className="rounded bg-red text-white px-3 font-bold">SUPER-ADMIN</div>}
          </div>
          <div className="flex items-center gap-2">
            <small><FontAwesomeIcon className="" icon={faTag} /> {data.user.firstName}  {data.user.lastName}</small>
            <small><FontAwesomeIcon className="" icon={faEnvelope} /> {data.user.email}</small>
          </div>
        </div>
        {["admin", "super-admin"].includes(currentUser.type) &&
          <ButtonPrimary onClick={() => impersonate(data.user.id)} disabled={loading} className="self-start text-white bg-dark-grey"><FontAwesomeIcon className="" icon={faMask} /> Impersonate</ButtonPrimary>
        }
        <div className="flex flex-col gap-2">
          <div>
            <h2>Firms</h2>
            <hr></hr>
          </div>
          <div className="flex items-center gap-2">
            {data.user.firms.map( firm => (
              <div key={firm.id} className={`flex cursor-pointer rounded px-2 bg-primary gap-2 pr-1 text-sm text-white font-bold ${showFirm(firm.id) ? '' : 'hidden'}`}>
                {firm.name}
                <FontAwesomeIcon onClick={() => setRemoveFirm(firm)} className="font-bold" icon={faTimes} />
              </div>
            ))}
          </div>
        </div>
        <ReccentSurveyFiles admin={true} userId={data.user.id}/>
      </div>
    </FullWidthPage>
    :
    <></>
  )
}
