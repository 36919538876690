import { gql, useMutation, useQuery } from '@apollo/client';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullWidthPage } from 'components/common/Layout';
import Input, { Checkbox, List } from 'components/inputs';
import SurveyContext from 'contexts/SurveyContext';
import useExportCSV from 'hooks/useExportCSV';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import { formatCurrency } from 'selectors/formSelectors';
import TrustAccountInput from './TrustAccountInput';
import DownloadCheque from './DownloadCheque';
import useTrustAccountChoices from 'hooks/useTrustAccountChoices';
import DocumentGroup from 'components/documents/DocumentGroup';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import ButtonPrimary from 'components/common/Button';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import Loading from 'components/common/Loading';

// get trust account items for this file
const GET_TRUST_ACCOUNT_ITEMS = gql`
  query GetTrustAccountItems($filters: JSONObject, $firmId: String, $fileId: String, $trustAccountId: String) {
    trustAccountItems(filters: $filters, firmId: $firmId, fileId: $fileId, trustAccountId: $trustAccountId) {
      id,
      firmId,
      name,
      value,
      calculation,
      date,
      chequeDepositNumber,
      surveyId,
      payee,
      fileId,
      trustAccountId,
      type,
      cleared,
      void,
      payee,
      file {
        fileNumber
      }
    }
  }
`;

const UPDATE_TRUST_ACCOUNT_ITEM = gql`
  mutation UpdateTrustAccountItem($id: String!, $trustAccountId: String, $date: String, $chequeDepositNumber: String, $type: String, $cleared: String, $void: Boolean, $value: String, $payee: String, $name: String) {
    updateTrustAccountItem(id: $id, trustAccountId: $trustAccountId, date: $date, chequeDepositNumber: $chequeDepositNumber, type: $type, cleared: $cleared, void: $void, value: $value, payee: $payee, name: $name) {
      id,
      trustAccountId,
      date,
      cleared,
      void,
      payee,
      chequeDepositNumber,
      type
    }
  }
`;

const CREATE_TRUST_ACCOUNT_ITEM = gql`
  mutation CreateTrustAccountItem($surveyId: String, $fileId: String, $firmId: String, $name: String!, $value: String, $trustAccountId: String!, $date: String!, $chequeDepositNumber: String, $type: String, $cleared: String, $void: Boolean, $payee: String) {
    createTrustAccountItem(surveyId: $surveyId, fileId: $fileId, firmId: $firmId, name: $name, value: $value, trustAccountId: $trustAccountId, date: $date, chequeDepositNumber: $chequeDepositNumber, type: $type, cleared: $cleared, void: $void, payee: $payee) {
      id,
      trustAccountId,
      date,
      cleared,
      void,
      payee,
      chequeDepositNumber,
      type
    }
  }
`;

const DELETE_TRUST_ACCOUNT_ITEM = gql`
  mutation DeleteTrustAccountItem($id: String!) {
    deleteTrustAccountItem(id: $id) {
      id,
      trustAccountId,
      date,
      chequeDepositNumber,
      type
    }
  }
`;

const UPDATE_TRUST_ACCOUNT = gql`
  mutation UpdateTrustAccount(
    $id: String!,
    $incrementChequeNumber: Boolean,
  ) {
    updateTrustAccount(
      id: $id,
      incrementChequeNumber: $incrementChequeNumber
    ) {
      id,
      name,
      bankName,
      transitNumber,
      accountNumber,
      institutionNumber,
      chequeNumber,
    }
  }
`;

const GET_DOCUMENTS = gql`
  query GetDocuments($firmId: String, $type: String, $filters: JSONObject) {
    documents(firmId: $firmId, type: $type, filters: $filters) {
      id,
      name,
      category,
      firmId,
      type
    }
  }
`;

export default function TrustAccountTable({fileId, surveyId, firmId, from, to, trustAccountId, setExportCSV, filters, add, documents}) {
  const {trustAccountChoices, trustAccounts} = useTrustAccountChoices(true, firmId);
  const [selectedTrustAccount, setSelectedTrustAccount] = useState();
  const [showConfirmDelete, setShowConfirmDelete] = useState();
  const [newLineItem, setNewLineItem] = useState();
  const { loading, error, data, refetch } = useQuery(GET_TRUST_ACCOUNT_ITEMS, {
    variables: (filters.search ?
      {firmId, filters: {search: filters.search, strictSearch: true}} :
      {firmId, fileId, trustAccountId, filters: {from, to}}),
    fetchPolicy:'no-cache',
    onCompleted: (res) => {
      setSelectedTrustAccount(res.trustAccountItems[0]?.trustAccountId)
    }
  });
  const { loading:loadingDocs, error:errorDocs, data:dataDocs } = useQuery(GET_DOCUMENTS, {
    variables: {firmId, type: "trust", filters: {search: documents}},
    skip: !documents,
    fetchPolicy:'no-cache'
  });
  const trustAccount = useMemo(() => {
    return trustAccounts?.find(trustAccount => trustAccount.id == selectedTrustAccount)
  }, [selectedTrustAccount])

  const {exportToCsv} = useExportCSV();
  const [startingBalance, setStartingBalance] = useState(0);
  const [updateTrustAccountItemMutation, { loading:updateLoading }] = useMutation(UPDATE_TRUST_ACCOUNT_ITEM);
  const [createTrustAccountItemMutation, { loading:createLoading }] = useMutation(CREATE_TRUST_ACCOUNT_ITEM);
  const [deleteTrustAccountItemMutation, { loading:deleteLoading }] = useMutation(DELETE_TRUST_ACCOUNT_ITEM);
  const [updateTrustAccountMutation, { loading:updateTrustAccountLoading }] = useMutation(UPDATE_TRUST_ACCOUNT);


  const updateTrustAccountItem = (item, changes) => {
    updateTrustAccountItemMutation({variables: {id: item.id, ...changes}})
    .then((res) => {
      console.log("updated");
      refetch();
    })
    .catch((e) => {
      console.log(e);
    })
  }
  const createTrustAccountItem = () => {
    createTrustAccountItemMutation({variables: {...newLineItem, name: `'${newLineItem.name}'`, value: newLineItem.value.toString()}})
    .then((res) => {
      console.log("updated");
      setNewLineItem();
      refetch();
    })
    .catch((e) => {
      console.log(e);
    })
  }

  const validLineItem = (item) => {
    return item.name && item.value && item.date;
  }

  const deleteTrustAccountItem = (itemId) => {
    setShowConfirmDelete(false);
    deleteTrustAccountItemMutation({variables: {id: itemId}})
    .then((res) => {
      refetch();
    });
  }

  const selectType = async (item, type) => {
    let chequeDepositNumber = item.chequeDepositNumber;
    if (!chequeDepositNumber && type && (Number(item.value || 0) < 0)) {
      // add bank
      let res = await updateTrustAccountMutation({
        variables: {
          id: trustAccountId || item.trustAccountId, 
          incrementChequeNumber: true
        }
      })
      chequeDepositNumber = res.data.updateTrustAccount.chequeNumber;
    }
    updateTrustAccountItem(item, {type, chequeDepositNumber})
  }

  const filteredItems = useMemo(() => {
    let filteredData = data?.trustAccountItems.filter(item => {
      let filter = false;
      switch(filters?.cleared) {
        case true:
          filter = filter || !item.cleared;
          break;
        case false:
          filter = filter || item.cleared;
          break;
        default:
          break;
      }
      filter = filter || (filters?.inOut ? (filters.inOut === "in" ? item.value < 0 : item.value > 0) : filter);
      filter = filter || (filters?.type ? item.type !== filters?.type : filter); 
      return !filter;
    });

    return filteredData?.sort((a,b) => ((new Date(a.date) - new Date(b.date)) || (b.value - a.value)));
  }, [data, filters]);

  useEffect(() => {
    if (!setExportCSV) return;
    if (filteredItems?.length > 0) {
      setExportCSV({call: () => exportToCsv(filteredItems?.map(({name, date, value, file, cleared, chequeDepositNumber, type}) => ({fileMatter: file?.fileNumber, name, date, cleared: !!cleared, chequeDepositNumber, type: type || "", in: value > 0 ? value : 0,out: value < 0 ? value * -1 : 0})), `${from}${to}-${(trustAccountChoices || []).find(account => account.id === trustAccountId)?.name}`)}, ["name", "value", "date"]);
    } else {
      setExportCSV();
    }
  }, [data]);

  const totals = useMemo(() => {
    return [...(filteredItems || []), newLineItem].reduce((prev, current) => {
      if(!current) return prev;
      if (current.void) {
        prev.void += Number(current.value);
        prev.balances.push((prev.balances.at(-1) || 0));
        prev.totalBalances.push((prev.totalBalances.at(-1) || 0));
        return prev;
      };
      current.value = current.value || 0;
      if (current.value > 0) {
        prev.in += Number(current.value);
        prev.balances.push((prev.balances.at(-1) || 0) + (current.cleared ? Number(current.value) : 0));
        prev.totalBalances.push((prev.totalBalances.at(-1) || 0) + Number(current.value));
      } else {
        prev.out += Number(current.value) * -1;
        if (current.cleared) {
          prev.clearedOut += Number(current.value) * -1;
        }
        prev.balances.push((prev.balances.at(-1) || 0) + (current.cleared ? Number(current.value) : 0));
        prev.totalBalances.push((prev.totalBalances.at(-1) || 0) + Number(current.value));
      }
      if (current.type === 'cheque') {
        prev.cheques += Math.abs(Number(current.value));
        if (current.value < 0) {
          prev.chequesOut += Math.abs(Number(current.value));
        }
      } else if (current.type === 'eft' && current.value < 0) {
        prev.efts += Math.abs(Number(current.value));
      } else if (current.type === 'bank draft') {
        prev.bankDrafts += Math.abs(Number(current.value));
        if (current.value < 0) {
          prev.bankDraftsOut += Math.abs(Number(current.value));
        }
      } else if (current.type === 'transfers' || current.type === 'file transfer') {
        prev.transfers += Math.abs(Number(current.value));
        if (current.value < 0) {
          prev.transfersOut += Math.abs(Number(current.value));
        }
      }
      
      return prev;
    }, {in: 0, out: 0, void: 0, bankDrafts: 0, bankDraftsOut: 0, transfers: 0, transfersOut: 0, efts: 0, cheques: 0, clearedOut: 0, chequesOut: 0, totalBalances: [Number(startingBalance)], balances: [Number(startingBalance)]}) || {in: 0, out: 0, balances: [], totalBalances: []};
  }, [data, startingBalance, newLineItem]);
  return (
    <div className="flex flex-col gap-4 pb-12">
      {showConfirmDelete && <ConfirmNotice
        title={`Are you sure?`}
        message={`This will delete this trust account line item.`}
        onConfirm={() => deleteTrustAccountItem(showConfirmDelete)}
        onClose={() => setShowConfirmDelete(false)}
      /> }
      <div className="flex overflow-x-scroll scroller-min">
        <div className="bg-light-grey rounded p-2 w-full">
          <table className="bg-light-grey border-spacing-2 mr-10 w-full">
            <tbody>
              <tr className="border-b border-dark-grey">
                <th>File Matter</th>
                <th>Description</th>
                <th>Payee</th>
                <th>Date Deposited</th>
                <th>Cleared</th>
                <th>Void/Cancelled</th>
                <th>Cheque/Deposit Number</th>
                <th>Type</th>
                <th>TrustAccountID</th>
                <th>In</th>
                <th>Out</th>
                <th>Balance</th>
                <th></th>
              </tr>
              <tr className="border-t border-med-grey">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="text-right"></td>
                <td className="text-right"></td>
                <td className="text-right flex items-center justify-center">$<Input className="bg-transparent text-right w-24" type="number" valueOverride={startingBalance} onChange={setStartingBalance}/></td>
                <td></td>
              </tr>
              {filteredItems?.map((item, i) => (
                <tr key={i + item.name} className={`group relative ${i%2 === 0 ? 'bg-transparent-grey' : ''}`}>
                  <td className="p-1"><Link to={`/files/${item.surveyId}/${item.fileId}/trust-account`}>{item.file?.fileNumber}</Link></td>
                  <td className="p-1"><Input type="textarea" auto={true} className="bg-transparent" valueOverride={item.name} onBlur={(name) => updateTrustAccountItem(item, {name: `"${name?.replaceAll('"', "'")}"`})}/></td>
                  <td className="p-1 text-center">{(item.value < 0) && (!item.type || ['cheque', 'undefined'].includes(item.type)) && <Input className="bg-transparent" valueOverride={item.payee} onBlur={(payee) => updateTrustAccountItem(item, {payee})}/>}</td>
                  <td className="p-1 text-center"><Input max={item.cleared ? new Date(item.cleared).toISOString().split('T')[0] : undefined} className="bg-transparent" type="date" valueOverride={new Date(item.date).toISOString().split('T')[0]} onBlur={(date) => updateTrustAccountItem(item, {date})}/></td>
                  <td className="p-1 text-center"><Input min={item.date ? new Date(item.date).toISOString().split('T')[0] : undefined} className="bg-transparent" type="date" valueOverride={item.cleared && new Date(item.cleared)?.toISOString().split('T')[0]} onChange={(cleared) => updateTrustAccountItem(item, {cleared})}/></td>
                  <td className="p-1 text-center"><Checkbox key={i + item.void} className="bg-transparent" valueOverride={item.void} initialValue={item.void} onChange={(val) => updateTrustAccountItem(item, {void: val})}/></td>
                  <td className="p-1 text-center"><Input className="w-20 text-center bg-transparent shadow-none" valueOverride={item.chequeDepositNumber || ''} onChange={(chequeDepositNumber) => updateTrustAccountItem(item, {chequeDepositNumber})}/></td>
                  <td className="p-1 text-center">
                    <List
                      key={i}
                      className="bg-transparent"
                      onChange={(type) => selectType(item, type)}
                      options={[
                        {name: "-", value: ''},
                        {name: "Bank Draft", value: 'bank draft'},
                        {name: "Cheque", value: 'cheque'},
                        {name: "EFT", value: 'eft'},
                        {name: "Account Transfer", value: 'transfers'},
                        {name: "File Transfer", value: 'file transfer'},
                        {name: "Float", value: 'float'},
                      ]}
                      valueOverride={item.type}
                    />             
                  </td>
                  <td className="p-1 text-center">
                    <List
                      key={i + item.trustAccountId}
                      className="bg-transparent"
                      onChange={(trustAccountId) => updateTrustAccountItem(item, {trustAccountId})}
                      options={(trustAccountChoices || [])}
                      valueOverride={item.trustAccountId}
                    />
                  </td>
                  <td className={`text-right p-1`}><Input type="number" className={`bg-transparent w-32 text-right ${item.void ? 'line-through' : ''}`} valueOverride={item.value > 0 ?  formatCurrency(item.value) : 0} onBlur={(value) => updateTrustAccountItem(item, {value: value?.toString()})}/></td>
                  <td className={`text-right p-1`}><Input type="number" className={`bg-transparent w-32 text-right ${item.void ? 'line-through' : ''}`} valueOverride={item.value < 0 ? formatCurrency(item.value * -1) : 0} onBlur={(value) => updateTrustAccountItem(item, {value: (-1*Math.abs(Number(value)))?.toString()})}/></td>
                  {/* <td className={`text-right p-1 ${item.void ? 'line-through' : ''}`}>{item.value > 0 ? formatCurrency(item.value) : ''}</td>
                  <td className={`text-right p-1 ${item.void ? 'line-through' : ''}`}>{item.value < 0 ? formatCurrency(item.value * -1) : ''}</td> */}
                  <td className={`text-right ${item.cleared ? '' : 'text-med-grey'}`}>{formatCurrency(totals.balances[i+1])}</td>
                  <td>
                    {(item.value < 0) && (!item.type || ['cheque', 'undefined'].includes(item.type)) && 
                      <DownloadCheque
                        disabled={item.void}
                        trustAccounts={trustAccounts}
                        onDownload={(chequeDepositNumber) => updateTrustAccountItem(item, {chequeDepositNumber, type: 'cheque'})}
                        trustAccountItem={item}
                      />
                    }
                  </td>
                  <td className="invisible group-hover:visible p-2.5 h-full cursor-pointer" onClick={() => setShowConfirmDelete(item.id)}>
                    <FontAwesomeIcon icon={faTimesCircle} className="text-red cursor-pointer"/>
                  </td>
                </tr>
              ))}
              {newLineItem &&
                <tr className={`group relative ${(filteredItems.length+1)%2 === 0 ? 'bg-transparent-grey' : ''}`}>
                  <td className="p-1">{(surveyId && fileId) &&<Link to={`/files/${surveyId}/${fileId}/trust-account`}>{filteredItems?.[0]?.file?.fileNumber}</Link>}</td>
                  <td className="p-1"><Input className="" valueOverride={newLineItem.name} onChange={(name) => setNewLineItem({...newLineItem, name})}/></td>
                  <td className="p-1">{newLineItem.type !== 'float' && <Input className="" valueOverride={newLineItem.payee} onChange={(payee) => setNewLineItem({...newLineItem, payee})}/>}</td>
                  <td className="p-1 text-center"><Input className="bg-transparent" type="date" valueOverride={newLineItem.date && new Date(newLineItem.date).toISOString().split('T')[0]} onChange={(date) => setNewLineItem({...newLineItem, date})}/></td>
                  <td className="p-1 text-center"><Input className="bg-transparent" type="date" valueOverride={newLineItem.cleared && new Date(newLineItem.cleared).toISOString().split('T')[0]} onChange={(cleared) => setNewLineItem({...newLineItem, cleared})}/></td>
                  <td className="p-1 text-center"><Checkbox className="bg-transparent" valueOverride={newLineItem.void} initialValue={newLineItem.void} onChange={(val) => setNewLineItem({...newLineItem, void: val})}/></td>
                  <td className="p-1 text-center"></td>
                  <td className="p-1 text-center">
                    <List
                      className="bg-transparent"
                      onChange={(type) =>setNewLineItem({...newLineItem, type})}
                      options={[
                        {name: "-", value: ''},
                        {name: "Bank Draft", value: 'bank draft'},
                        {name: "Cheque", value: 'cheque'},
                        {name: "EFT", value: 'eft'},
                        {name: "Account Transfer", value: 'transfers'},
                        {name: "File Transfer", value: 'file transfer'},
                        {name: "Float", value: 'float'},
                      ]}
                      valueOverride={newLineItem.type}
                    />             
                  </td>
                  <td className="p-1 text-center">
                    <List
                      className="bg-transparent"
                      onChange={(trustAccountId) => setNewLineItem({...newLineItem, trustAccountId})}
                      options={(trustAccountChoices || [])}
                      valueOverride={newLineItem.trustAccountId}
                    />
                  </td>
                  <td className={`text-right p-1 ${newLineItem.void ? 'line-through' : ''}`}><Input className="text-right" type="number" valueOverride={newLineItem.value > 0 ? newLineItem.value : ''} onChange={(value) => setNewLineItem({...newLineItem, value})}/></td>
                  <td className={`text-right p-1 ${newLineItem.void ? 'line-through' : ''}`}><Input className="text-right" type="number" valueOverride={newLineItem.value < 0 ? -1*newLineItem.value : ''} onChange={(value) => setNewLineItem({...newLineItem, value: -1*Math.abs(value)})}/></td>
                  <td className={`text-right ${newLineItem.cleared ? '' : 'text-med-grey'}`}>{formatCurrency(totals.balances.at(-1))}</td>
                  <td className="text-center">
                    <ButtonPrimary disabled={!validLineItem(newLineItem) || createLoading} className="text-xxs bg-green text-white" onClick={createTrustAccountItem}>Save</ButtonPrimary>
                  </td>
                  <div className="absolute -right-9 invisible group-hover:visible p-2.5">
                    <FontAwesomeIcon icon={faTimesCircle} onClick={() => setNewLineItem()} className="text-red cursor-pointer"/>
                  </div>
                </tr>
              }
              <tr className="border-t border-med-grey">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="text-right pt-2">{formatCurrency(totals.in)}</td>
                <td className="text-right pt-2">{formatCurrency(totals.out)}</td>
                <td className="text-right pt-2">{formatCurrency(totals.balances.at(-1))}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          {
            loading && <div className="flex items-center justify-center"><Loading color='dark-grey'/></div>
          }
        </div>
      </div>
      {
        !newLineItem && add &&
        <div className="text-center w-full cursor-pointer">
          <FontAwesomeIcon onClick={() => setNewLineItem({fileId, surveyId, firmId, trustAccountId: filteredItems?.[0]?.trustAccountId || trustAccountId})} className="text-green" icon={faPlusCircle}/>
        </div>
      }
      {dataDocs && 
        <div className="relative">
          <div className="absolute left-32 -top-2 scale-75">
            <List
              className="bg-transparent"
              onChange={selectedTrustAccount}
              options={(trustAccountChoices || [])}
              valueOverride={selectedTrustAccount}
            />
          </div>
          <DocumentGroup
            // zip={zip} 
            title="Trust Documents" 
            documents={dataDocs?.documents}
            extraData={{
              trustAccount: trustAccount,
              trustAccounts,
              filteredItems: filteredItems,
              trustAccountItems: data?.trustAccountItems,
              trustAccountItemsOut: data?.trustAccountItems.filter(item => item.value < 0),
              trustAccountItemsIn: data?.trustAccountItems.filter(item => item.value > 0),
              totals: totals,
              to: to,
              from: from,
            }}
          />
        </div>
      }
    </div>
  )
}
