import Firms from './Firms';
import Firm from './Firm';
import React from 'react';
import { Route } from 'react-router-dom';
import CreateFirm from './CreateFirm';
import AppAdminRoute from 'components/routes/AppAdminRoute';
import AdminRoute from 'components/routes/AdminRoute';

const routes = [
  <Route key="new" path="firms/new" element={<AppAdminRoute><CreateFirm/></AppAdminRoute>} />,
  <Route key="firm" path="firms/:id" element={<AppAdminRoute><Firm/></AppAdminRoute>} />,
  <Route key="firm" path="firm" element={<AdminRoute><Firm/></AdminRoute>} />,
  <Route key="firms" path="firms" element={<AppAdminRoute><Firms/></AppAdminRoute>} />,

];

export default routes;
