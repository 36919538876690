import React, {useContext, useEffect, useMemo, useState} from 'react';
import {getCalculatedValue, getSurveyValue, pathMaker} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import Checkboxes from 'components/inputs/Checkboxes';
import ParentContext from 'contexts/ParentContext';
import _ from 'lodash';
import { softSubset } from 'helpers/general';

const SurveyCheckList = ({ 
    questionKey, 
    label, 
    options,
    multiple,
    hideLabel, 
    required,
    isReadOnly,
    setUnselected,
    formStyles, // TODO change to input style
    description
}) => {
    const { file, updateAnswers, updateErrors } = useContext(SurveyContext);
    const { row, parentPath, prevRows } = useContext(ParentContext);
    const { error, setError } = useState();
    const [cache, setCache] = useState();
    const path = useMemo(() => {
        return pathMaker(questionKey, parentPath, row);
    }, [questionKey, parentPath, row]);
    const unselectedPath = useMemo(() => {
        return setUnselected && pathMaker(setUnselected, parentPath, row);
    }, [setUnselected, parentPath, row]);
    const surveyValue = useMemo(() => {
        let value = file && getSurveyValue(file.answers, path);
        // if (!value && defaultValue) {
        //     updateValue(defaultValue);
        // }
        return value;
    }, [file, path]);

    const calculatedOptions = useMemo(() => {
        let value = options && file && file.answers && getCalculatedValue(file, options, prevRows);
        value = (Number(value) ? Math.round(value * 100)/100 : value);
        if (_.isEqual(cache, value)) {
            return cache;
        }
        setCache(value);
        return [...value];
    }, [options, file, prevRows]);

    useEffect(() => {
        if (setUnselected && file && calculatedOptions) {
            let value = getSurveyValue(file.answers, unselectedPath);
            if (!value || (value?.length === 0 && surveyValue?.length === 0)) {
                updateAnswers(unselectedPath, calculatedOptions.filter(option => (!(surveyValue || []).includes(option.value))).map(option => option.value));
            }
            return value;
        }
    }, [file, unselectedPath, surveyValue]);

    const checkError = (val) => {
        if (required && !val) {
            setError("Field Required!");
            updateErrors(path, true);
            return true;
        }
        updateErrors(path, false);
        return false;
    }
    const updateValue = (val) => {
        if (!checkError(val)) {
            let newVal = multiple ? val : [val.at(-1)];
            updateAnswers(path, newVal);
            setUnselected && updateAnswers(unselectedPath, calculatedOptions.filter(option => (!newVal.find(newOption => softSubset(newOption, option.value)))).map(option => option.value));
        }
    }
    return (
        <div>
            {!hideLabel && label && (
                description ? 
                    <label>
                        {label}
                        <Tooltip message={description} />
                    </label>
                :
                    <label>
                        {label}
                    </label>
            )}
            <Checkboxes className={formStyles} readOnly={isReadOnly} options={calculatedOptions} valueOverride={surveyValue || []} onChange={(val) => updateValue(val)} />
            {
                (!calculatedOptions || calculatedOptions.length === 0) &&
                <span className="text-sm"> none </span>
            }
            <p>{error}</p>
        </div>
    )
};

export default SurveyCheckList;