import { gql, useQuery } from '@apollo/client';
import { List } from 'components/inputs';
import AppContext from 'contexts/AppContext';
import CalendarContext from 'contexts/CalendarContext';
import React, { useContext } from 'react';

export default function BugFilters({filters, setFilters}) {
  
  return (
    <div className="border flex p-2 gap-4 bg-light-grey border-transparent-dark-grey">
      <div> 
        <strong>Status</strong>
        <hr></hr>
        <div className="flex gap-2">
          <div>
            <input className="cursor-pointer" checked={filters.status === ""} onClick={() => setFilters({...filters, status: ""})} type="radio" id="todo" name="todo" value=""/>
            <label className="font-normal cursor-pointer" htmlFor="todo">To-Do</label>
          </div>
          <div>
            <input className="cursor-pointer" checked={filters.status === "in-progress"} onClick={() => setFilters({...filters, status: "in-progress"})} type="radio" id="inprogress" name="inprogress" value=""/>
            <label className="font-normal cursor-pointer" htmlFor="inprogress">In-Progress</label>
          </div>
          <div>
            <input className="cursor-pointer" checked={filters.status === "complete"} onClick={() => setFilters({...filters, status: "complete"})} type="radio" id="complete" name="complete" value=""/>
            <label className="font-normal cursor-pointer" htmlFor="complete">Complete</label>
          </div>
        </div>
      </div>
    </div>
  )
}
