import React from 'react'
import LoadingText from './LoadingText'

export default function LoadingParagraph({color, className}) {
  return (
    <div className={`flex flex-wrap gap-1 ${className}`}>
      {Math.round(Math.random()*100000000000000000000 + 1).toString().split('').map(i => <LoadingText color={color} random/>)}
    </div>
  )
}
