import React, { useContext } from 'react';
import RequestFlagsContext from 'contexts/RequestFlagsContext';
import useErrors from 'hooks/useErrors';
import BugContext from 'contexts/BugContext';
import { useEffect } from 'react';

const RequestFlagsProvider = ({ children }) => {
  const {errors, currentError, addError, unauthorized, removeError} = useErrors();
  const {updateBugContext} = useContext(BugContext);

  // useEffect(() => {
  //   console.log("test");
  //   if (currentError) {
  //     updateBugContext({error: currentError});
  //   }
  // }, [currentError, updateBugContext]);

  return (
    <RequestFlagsContext.Provider value={{ 
        errors,
        currentError,
        addError,
        unauthorized,
        removeError
    }} >
    {children}
    </RequestFlagsContext.Provider>
  );
};

export default RequestFlagsProvider;
