import React, {useCallback, useEffect, useMemo, useState, useContext} from 'react'
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, gql } from "@apollo/client";
import _ from 'lodash';
import { addSurveyValue, getSurveyCalculations, getSurveyTotals } from 'selectors/formSelectors';
import RequestFlagsContext from 'contexts/RequestFlagsContext';

const GET_FILE = gql`
  query GetFile($id: String!, $surveyId: String!) {
    file(id: $id, surveyId: $surveyId) {
      id,
      firmId,
      letterheadId,
      footerId,
      sidebarId,
      surveyId,
      fileNumber,
      nickname,
      icon,
      date,
      status,
      answers,
      tokens,
      requests,
      survey {
        name
      },
      changeId,
      enabledDocuments
    }
  }
`;

const NEW_FILE = gql`
  mutation NewFile($surveyId: String!, $fileNumber: String!, $nickname: String, $icon: String, $firmId: String, $userId: String) {
    newFile(surveyId: $surveyId, fileNumber: $fileNumber, nickname: $nickname, icon: $icon, firmId: $firmId, userId: $userId) {
      id,
      surveyId,
      firmId,
      date,
      status,
      answers,
      tokens,
      requests,
      enabledDocuments
    }
  }
`;

const UPDATE_FILE = gql`
  mutation UpdateFile($surveyId: String!, $fileId: String!, $fileUpdates: fileUpdateInput, $changeId: Int) {
    updateFile(surveyId: $surveyId, fileId: $fileId, fileUpdates: $fileUpdates, changeId: $changeId) {
      id,
      answers,
      changeId,
    }
  }
`;

export default function useSurveyFile(fileId, survey) {
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [errors, setErrors] = useState({});
  const [totals, setTotals] = useState({});
  const [saveOnNext, setSaveOnNext] = useState();
  const [updateTotalsOnNext, setUpdateTotalsOnNext] = useState();
  const [calculations, setCalculations] = useState({});
  const [createNewFileMutation, { loading:newFileLoading, newFileError }] = useMutation(NEW_FILE);
  const [updateFileMutation, { loading:updateLoading, error:updatError }] = useMutation(UPDATE_FILE);
  const {addError} = useContext(RequestFlagsContext);

  const updateTotalsAndCalculations = useCallback((passedFile) => {
    // TODO add updateTotals and calculations at top of new survey page
    let startFile = passedFile || file;
    let stack = 0;
    const recurseTotalsAndCalculatios = (prevTotals, prevCalculations) => {
      stack ++;
      if (stack > 20) {
        console.log("Error: Calculation & Total loop");
        return;
      }
      let mergedAnswers = _.cloneDeep(startFile.answers);
      _.merge(mergedAnswers, prevCalculations);
      let currentCalculations = getSurveyCalculations(survey.pages, {...startFile, answers: mergedAnswers}, prevTotals);
      let currentTotals = getSurveyTotals(survey.pages, {...startFile, answers:{...currentCalculations,...startFile.answers}});
      if (_.isEqual(prevTotals,currentTotals) && _.isEqual(prevCalculations, currentCalculations)) {
        return [currentTotals, currentCalculations];
      } else {
        return recurseTotalsAndCalculatios(currentTotals, currentCalculations)
      }
    }

    let [updatedTotals, updatedCalculations] = recurseTotalsAndCalculatios();
    setCalculations(updatedCalculations);
    setTotals(updatedTotals);
  }, [file, survey]);

  const onCompleted = useCallback((data) => {
      updateTotalsAndCalculations(data.file);
      document.title = `ConveyMe - ${data.file.fileNumber}`;
      setFile({...data.file, answers: data.file.answers || {}});
  }, [updateTotalsAndCalculations]);

  const { loading:getFileLoading, error, data, refetch:refetchFile, } = useQuery(GET_FILE, {
    variables: { id: fileId, surveyId: survey && survey.id },
    skip: (fileId && survey && survey.id) ? false : true,
    fetchPolicy:'no-cache',
    onError(err){
      // navigate("/not-found")
    },
    onCompleted
  });

  useEffect(() => {
    const handleStaleTab = () => {
      if (document.visibilityState === "visible" && fileId && survey && survey.id) {
        refetchFile()
        .then(res => {
          if (res.data) {
           onCompleted(res.data);
          }
        })
      }
    };
    document.addEventListener("visibilitychange", handleStaleTab);
    return () => {
      document.removeEventListener("visibilitychange", handleStaleTab)
    }
  }, [onCompleted, refetchFile, fileId, survey]);

  const newFile = ({surveyId, nickname, fileNumber, icon, firmId, userId}) => {
    if (surveyId) {
      createNewFileMutation({variables: {surveyId, nickname, fileNumber, icon, firmId, userId}})
        .then(res => {
          navigate(`${surveyId}/${res.data.newFile.id}`)
        })
        .catch(console.log)
    }

  }

  const updateFile = (data, push = false) => {
    // const newFile = {...file, ...data};
    setFile(file => {
      let newFile = {...file, ...data};
      if (push) {
        // PUSH FILE UPDATE TO SERVER
        saveFile(newFile)
      }
      return newFile;
    });
  }

  const updateAnswers = (paths, data, push = true, updateAll = false, sequenceUpdate) => {
    setFile((current) => {
      if (sequenceUpdate) {
        [paths, data] = sequenceUpdate(current);
      }
      if (paths) {
        if (!Array.isArray(paths)) {
          paths = [paths];
          data = [data];
        }
        let answers = current.answers;
        for (let i = 0; i < paths.length; i ++) {
          answers = addSurveyValue(answers || {}, paths[i], data[i]);
        }
        const newFile = {...current, answers};
        // Push update to server
        if (push) {
          setSaveOnNext(curr => ([...(curr || []) , {paths, data}]));
          // saveFile(newFile)
        }
        if (updateAll) {
          setUpdateTotalsOnNext(true);
          // updateTotalsAndCalculations(newFile);
        }
        return newFile;
      }
    })
  }

  const updateCalculation = useCallback((paths, data) => {
    setCalculations((current) => {
      if (paths) {
        if (!Array.isArray(paths)) {
          paths = [paths];
          data = [data];
        }
        let newCalculations = {...current};
        for (let i = 0; i < paths.length; i ++) {
          newCalculations = addSurveyValue((newCalculations || {}), paths[i], data[i]);
        }
        return newCalculations;
      }
    })
  }, []);

  const saveFile = useCallback((fileUpdates, answerUpdates) => {
    updateFileMutation({variables: {
      fileUpdates: {
        answers: answerUpdates ? fileUpdates.answers : undefined,
        enabledDocuments: fileUpdates.enabledDocuments,
        letterheadId: fileUpdates.letterheadId,
        footerId: fileUpdates.footerId,
        sidebarId: fileUpdates.sidebarId,
        nickname: fileUpdates.nickname,
        fileNumber: fileUpdates.fileNumber,
        icon: fileUpdates.icon,
        answerUpdates
      },
      surveyId: survey.id,
      fileId,
      changeId: file.changeId
    }})
    .then(res => {
      // if (res.data.updateFile.changeId - (file.changeId || 0) > 1) {
      //   updateFile({...res.data.updateFile});
      // } else {
      //   setFile((file) => ({...file, changeId: res.data.updateFile.changeId}));
      // }
    });
  }, [fileId, survey, updateFileMutation, file?.changeId]);

  useEffect(() => {
    if (saveOnNext && !updateLoading) {
      saveFile(file, saveOnNext);
      setSaveOnNext();
    }
  }, [saveOnNext, saveFile, file]);

  useEffect(() => {
    if (updateTotalsOnNext) {
      updateTotalsAndCalculations(file);
      setUpdateTotalsOnNext();
    }
  }, [updateTotalsOnNext, updateTotalsAndCalculations, file]);

  const updateErrors = (path, error) => {
    const newErrors = {...errors};
    if (error) {
      newErrors[path] = true;
    } else {
      delete newErrors[path];
    }
    setErrors(newErrors);
  }
  
  const fileWithCalculations = useMemo(() => {
    if (!file) return null;
    let mergedAnswers = _.cloneDeep(file.answers);
    _.merge(mergedAnswers, calculations);
    return file && {...file, answers: mergedAnswers}
  }, [file, calculations]);

  return {
    file: fileWithCalculations,
    updateFile,
    updateAnswers,
    updateTotalsAndCalculations,
    updateCalculation,
    errors,
    updateErrors,
    totals,
    newFile,
    loading: newFileLoading || getFileLoading || updateLoading
  }
}
