import axios from 'axios';
import React, {useMemo, useEffect, useState} from 'react'
import { pdfjs } from 'react-pdf';
import _ from "lodash";

export default function useOcr() {
  
  const parseDocument = async (doc) => {
    const loadingTask = pdfjs.getDocument(doc)
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    let images = [];
    let pdf = await loadingTask.promise;
    let maxPages = pdf._pdfInfo.numPages;
    let countPromises = []; // collecting all page promises
    for (let j = 1; j <= maxPages; j++) {
      countPromises.push(pdf.getPage(j))
    }
    let pages = await Promise.all(countPromises);
    let scale = 4;
    let viewport = pages[1].getViewport({scale});
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    let renderContext = {
        canvasContext: ctx,
        viewport: viewport
    };
        for (const page of pages) {
      let renderTask = await page.render(renderContext).promise;
      images.push(canvas.toDataURL("image/jpeg").split(';base64,')[1]);
    }
    return googleOCR(images);
  }

  let googleOCR = (contents) => {
    const API_KEY = process.env.REACT_APP_GOOGLE_VISION_KEY;
    const ENDPOINT = 'https://vision.googleapis.com/v1/images:annotate?key=' + API_KEY;
    const REQUEST_OPTION = {
      requests: [
        contents.map(content => (
          {
            image: {
              content,
            },
            features: [
              {
                type: 'DOCUMENT_TEXT_DETECTION'
              },
            ],
          }
        ))
      ],
    };
    return axios.post(ENDPOINT, REQUEST_OPTION).then(response => {
      const annotations = response.data.responses[0].textAnnotations
      if (!annotations) {
        console.log('Could not detect any text from the image');
      } else {
        // console.log("success", response.data);
        let totalText = '';
        for (let page of response.data.responses) {
          // let pageItems = _.orderBy(page.textAnnotations.filter(el => !el.locale).map(el => ({ 'x': el.boundingPoly.vertices[0].x, 'y': Math.round((el.boundingPoly.vertices[0].y)/20)*20, 't': el.description })), ['y','x'], ['asc', 'asc'] );
          // const pageText = pageItems.map((item) => item.t.toLowerCase()).join(' ');
          let pageItems = page.textAnnotations.filter(el => !el.locale).map(el => ({ 'x': el.boundingPoly.vertices[0].x, 'y': el.boundingPoly.vertices[0].y, 't': el.description })).sort((a,b) => a.y - b.y);
          const pageText = pageItems.reduce((acc, curr) => {
            if (acc.length === 0) {
              return [[curr]];
            }
            //  +/- 10
            if (Math.abs(curr.y - acc.at(-1)[0].y) <= 20) {
              acc.at(-1).push(curr);
            } else {
              acc.push([curr]);
            }
            return acc;
          }, []).reduce((acc, curr) => {
            return acc + curr.sort((a, b) => a.x - b.x).map(item => item.t.toLowerCase()).join(' ') + " \n ";
          }, '');
          totalText += '\n\n' + pageText;
        }
        return totalText;
      }
    }).catch(error => {
      console.log(error);
    })
  }

  return {
    parseDocument,
  }
}
