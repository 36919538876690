import React, {useContext, useEffect, useState} from 'react';
import { FullWidthPage } from 'components/common/Layout';
import SurveyContext from 'contexts/SurveyContext';
import Documents from 'components/documents';
import PaymentForm from 'components/payments/PaymentForm';
import { gql, useQuery } from '@apollo/client';
import ChecklistTemplate from './ChecklistTemplate';

const GET_CHECKLIST = gql`
  query GetChecklistAnswer($fileId: String, $surveyId: String) {
    checklistAnswer(fileId: $fileId, surveyId: $surveyId) {
      id,
      answers,
      notes,
      template{
        id,
        name,
        default,
        firmId,
        userId,
        phases{
          title,
          dateCalculation,
          icon,
          blocks{
            title, 
            color,
            items
          } 
        }
      },
    }
  }
`;

export default function FileChecklist() {
  const {setSurveyNav, file, surveyId, fileId } = useContext(SurveyContext);
  const [checklist, setChecklist] = useState();
  useEffect(() => {setSurveyNav('checklist')}, [setSurveyNav]);

  const { data } = useQuery(GET_CHECKLIST,{
    variables: {fileId, surveyId},
    skip: !fileId,
    fetchPolicy:'no-cache',
    onCompleted: (data) => {
      setChecklist(data.checklistAnswer);
    }
  });

  const updateAnswers = (answers) => {
    setChecklist({...checklist, answers});
  }

  const updateNotes = (notes) => {
    setChecklist({...checklist, notes});
  }

  const updateTemplate = (template) => {
    setChecklist({...checklist, template});
  }

  return (
    <FullWidthPage bottom className="flex-grow">
      <div className="flex flex-col relative pt-6">
          {file && file.status !== 'paid' ?
            <>
              <div className="min-h-180 flex flex-col gap-2">
                {checklist && <ChecklistTemplate checklist={checklist} updateAnswers={updateAnswers} updateNotes={updateNotes} updateTemplate={updateTemplate}/>}
              </div>
              <div 
                className="absolute top-0 bottom-0 left-0 right-0 bg-transparent-white z-40"
                style={{
                  backdropFilter: "blur(2px)"
                }}
              />
              <div
                className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-start z-40"
              >
                <div className="sticky top-10 mt-20 flex items-center justify-center z-40">
                  <div className="overflow-y-scroll max-h-payment scroller border">
                    <PaymentForm returnUrl={`/files/${surveyId}/${fileId}/documents`}/>
                  </div>
                </div>
              </div>
            </>
            :
            <div className="flex flex-col gap-2">
              {checklist && <ChecklistTemplate checklist={checklist} updateAnswers={updateAnswers} updateNotes={updateNotes} updateTemplate={updateTemplate}/>}
            </div>
          }
      </div>
    </FullWidthPage>
  )
}
