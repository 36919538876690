import Users from './Users';
import User from './User';
import React from 'react';
import { Route } from 'react-router-dom';
import InviteUserPage from './InviteUserPage';

const routes = [
  <Route key="user" path="users/:id" element={<User/>} />,
  <Route key="users" path="users" element={<Users/>} />,
  <Route key="invite-user" path="users/invite" element={<InviteUserPage/>} />,
];

export default routes;
