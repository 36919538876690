import React from 'react'

export default function Message({children, negative, positive, className}) {
  let color = () => {
    if (positive) {
      return 'green'
    }else if (negative) {
      return 'red'
    } else {
      return 'yellow'
    }
  }
  // text-red
  return (
    <div className={`border rounded p-2 ${`border-${color()} bg-light-${(negative ? 'light-' : '') + color()} text-${color()}`} ${className}`}>
        {children}
    </div>
  )
}
