import ParentContext from 'contexts/ParentContext';
import SurveyContext from 'contexts/SurveyContext';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { getCalculatedValue, getSurveyValue, pathMaker } from 'selectors/formSelectors';

export default function HideQuestion({hideCalculation, questionKey, showCalculation, keepDataOnHide, cache, children}) {
  const { file, updateAnswers, updateCalculation } = useContext(SurveyContext);
  const {row, parentPath, prevRows} = useContext(ParentContext);
  const [removed, setRemoved] = useState(false);
  const path = useMemo(() => {
      return pathMaker(questionKey, parentPath, row);
  }, [questionKey, parentPath, row]);

  const value = useMemo(() => {
    return getSurveyValue(file.answers, path)
  }, [file, path]);

  const hide = useMemo(() => {
    if (hideCalculation || showCalculation) {
      let res = hideCalculation ?  getCalculatedValue(file, hideCalculation, prevRows) : !getCalculatedValue(file, showCalculation, prevRows);
      return res ? true : false;
    } else {
      return false;
    }
  },[file, hideCalculation, showCalculation, prevRows]);

  useEffect(()=> {
    if (hide) {
      setRemoved(false);
    }
  },[hide]);

  useEffect(() => {
    if(hide && value && !removed) {
      if (Array.isArray(value)){
        if ( value.length > 0) {
          setRemoved(true);
          !keepDataOnHide && updateAnswers(path, []);
          (!keepDataOnHide || !cache) && updateCalculation(path, [])
        }
      } else {
        setRemoved(true);
        !keepDataOnHide && updateAnswers(path, undefined);
        (!keepDataOnHide || !cache) && updateCalculation(path, undefined)
      }
    }
  }, [hide, updateAnswers, path, value]);

  return (
    <>{!hide && children}</>
  )
}
