import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding,faFileSignature, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';

// companyName,
// email,
// phoneNumber,
// fax,
// type,
// addressLineOne,
// addressLineTwo,
// mortgageId,
export default function MortgageLineItem({mortgage}) {
  return (
    <div className={`rounded cursor-pointer flex border-l-8 border-light-grey shadow-md hover:shadow-lg p-2 items-center gap-2 justify-between`}>
      <div className="flex items-center gap-2">
        <FontAwesomeIcon className="" icon={faFileSignature} />
        <strong>{mortgage.name}</strong>
        <div className="flex items-center gap-2 ml-4">
          {/* <small><FontAwesomeIcon className="" icon={faTag} /> {user.firstName}</small> */}
          <small><FontAwesomeIcon className="" icon={faBuilding} /> {mortgage.bank && mortgage.bank.companyName}</small>
        </div>
      </div>
      <div className="flex items-center gap-2">
        {
          mortgage.firm &&
          <div className={`rounded px-2 border-2 border-primary text-sm text-primary font-bold`}>
            <FontAwesomeIcon className="text-xxs" icon={faBuilding}/> {mortgage.firm.name}
          </div>
        }
        {
          mortgage.user &&
          <div className={`rounded px-2 border-2 border-primary text-sm text-primary font-bold`}>
            <FontAwesomeIcon className="text-xxs" icon={faUser}/> {mortgage.user.username}
          </div>
        }
        {
          mortgage.public &&
          <div className={`rounded px-2 bg-primary text-sm text-white font-bold`}>
            public
          </div>
        }
      </div>
    </div>
  )
}
