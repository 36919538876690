import React, {useContext, useEffect, useState} from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import User from 'components/users/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCirclePlus, faSearch, faCircleChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import useFirmChoices from 'hooks/useFirmChoices';
import { List } from 'components/inputs';
import AppContext from 'contexts/AppContext';
import ActivePageContext from 'contexts/ActivePageContext';
import Search from 'components/common/Search';
import { FullWidthPage } from 'components/common/Layout';
import Loading from 'components/common/Loading';

const GET_USERS = gql`
  query GetUsers($firmId: String, $filters: JSONObject, $pageId: String) {
    users(firmId: $firmId, filters: $filters, pageId: $pageId) {
      id,
      firstName,
      email,
      username,
      type,
      firms {
        id,
        name,
        type
      }
    }
  }
`;

export default function Users() {
  const {currentUser} = useContext(AppContext);
  const [filters, setFilters] = useState({});
  const {firmChoices, firmId, setFirmId} = useFirmChoices(true);
  const [pageId, setPageId] = useState();
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setUsers([]);
    setPageId();
  },[filters]);
  const { loading, error, data } = useQuery(GET_USERS, {
    variables: {firmId, filters, pageId},
    fetchPolicy: 'no-cache',
    onCompleted (data) {
      setUsers((users) => ([...users, ...data.users]));
    }
  });

  const {setActivePage} = useContext(ActivePageContext);
  setActivePage(null);

  const changeFirm = (firmId) => {
    setUsers([]);
    setFirmId(firmId);
  }

  const loadMore = () => {
    if (!loading) {
      setPageId(users.at(-1).id);
    }
  }

  return (
    <FullWidthPage bottom className="pt-4 flex flex-col gap-2">
      <div className="flex gap-2 items-end">
        <div className="flex gap-2 items-center">
          <h2>Users <Link to="invite"><FontAwesomeIcon className="text-green" icon={faCirclePlus} /></Link></h2>
          <Search filters={filters} setFilters={setFilters}/>
        </div>
        {
          firmChoices.length > 1 && <List valueOverride={firmId} onChange={changeFirm} className="text-xs py-0.5" options={firmChoices}/>
        }
      </div>
      <div className="flex flex-col gap-1">
        {users.map(user => <User key={user.id} user={user}/>)}
        {loading &&
          <Loading/>
        }
        {
          data && data.users.length > 9 &&
          <div className="text-med-grey flex justify-center">
            <div onMouseEnter={loadMore} className="cursor-pointer flex flex-col">
              <span className="text-xs">More</span>
              <FontAwesomeIcon icon={faCircleChevronDown}/>
            </div>
          </div>
        }
        {users.length === 0 && <small className="text-med-grey">No Users</small> }
      </div>
    </FullWidthPage>
  )
}
