import React from 'react';
import { Route } from 'react-router-dom';
import Show from './Show';
import New from './New';
import All from './All';

const routes = [
  <Route key="letterheads" path="/letterheads" element={<All/>} />,
  <Route key="letterhead" path="/letterheads/:id" element={<Show />} />,
  <Route key="new-letterhead" path="/letterheads/new" element={<New/>} />
];
export default routes;
