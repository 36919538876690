import React from 'react'
import { Link } from 'react-router-dom';

export default function AnonNav() {
  return (
    <div className="flex gap-4">
        <Link className="no-underline font-medium text-med-grey" to="/login">Log In</Link>
        <Link className="no-underline font-medium" to="/sign-up">Sign Up</Link>
   </div>
  )
}
