import { useMutation } from '@apollo/client';
import { faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonPrimaryOutline, { ButtonPrimary } from 'components/common/Button'
import Loading from 'components/common/Loading';
import Input, { List } from 'components/inputs';
import Transparent from 'components/notices/Transparent'
import gql from 'graphql-tag';
import useUserChoices from 'hooks/useUserChoices';
import React, { useState } from 'react'
import { useNavigate } from 'react-router';

const UPDATE_FILE = gql`
  mutation UpdateFile($surveyId: String!, $fileId: String!, $fileUpdates: fileUpdateInput) {
    updateFile(surveyId: $surveyId, fileId: $fileId, fileUpdates: $fileUpdates) {
      id
    }
  }
`;

export default function AssignFile({file}) {
  const [open, setOpen] = useState();
  const {userChoices, userId, setUserId} = useUserChoices(true, {name: "", value: ""}, file.firmId);
  const [updateFileMutation, { loading, error:updatError }] = useMutation(UPDATE_FILE);


  const close = () => {
    setOpen(false);
  }
  const confirm = () => {
    updateFileMutation({variables: {fileId: file.id, surveyId: file.surveyId, fileUpdates: {userId}}})
    .then((res) => {
      setOpen(false);
    });
  }

  return (
    <>
      {
        open &&
        <Transparent>
          <div onClick={close} className="absolute flex justify-center items-center w-full h-full">
            <div onClick={(e) => e.stopPropagation()} className="bg-white rounded border max-w-7/8 w-80 shadow-md">
              <div className={`flex justify-between relative px-4 py-2 bg-red`}>
                <h2 className="text-white">
                  <FontAwesomeIcon onClick={close} className="" icon={faTriangleExclamation} />
                  &nbsp;
                  Re-assign File?
                </h2>
                <FontAwesomeIcon onClick={close} className="text-white cursor-pointer absolute top-1 right-2" icon={faTimes} />
              </div>
              <div className="p-4 bg-whiter py-8 flex flex-col gap-2">
                <p>
                  Choose the user of your firm to re-assign this file to
                </p>
                <div className="flex flex-col">
                  <label>Assign file to</label>
                  <List onChange={setUserId} valueOverride={userId} className="" options={[...userChoices]}/>
                </div>
              </div>
              <div className="p-4 bg-whiter flex justify-end border-t gap-2 py-2">
                <ButtonPrimary onClick={close} >Close</ButtonPrimary>
                <ButtonPrimary className="border-red text-red" disabled={loading || !userId} onClick={confirm} >{loading ? <Loading/> : 'Assign'}</ButtonPrimary>
              </div>
            </div>
          </div>
        </Transparent>
      }
      <ButtonPrimaryOutline className="text-sm" onClick={() => setOpen(true)}>Re-assign File</ButtonPrimaryOutline>
    </>
  )
}
