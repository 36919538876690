import Mortgages from './Mortgages';
import Mortgage from './Mortgage';
import React from 'react';
import { Route } from 'react-router-dom';
import CreateMortgage from './CreateMortgage';
import AppAdminRoute from 'components/routes/AppAdminRoute';

const routes = [
  <Route key="new" path="mortgages/new" element={<AppAdminRoute><CreateMortgage/></AppAdminRoute>} />,
  <Route key="mortgages" path="mortgages/:id" element={<AppAdminRoute><Mortgage/></AppAdminRoute>} />,
  <Route key="mortgages" path="mortgages" element={<AppAdminRoute><Mortgages/></AppAdminRoute>} />,
];

export default routes;
