import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBuilding, faPhone, faEnvelope, faLocationDot, faFax, faCircleChevronUp, faChevronUp, faChevronDown, faMoneyCheckDollar, faGlobe, faCity, faEnvelopesBulk, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import AddBusiness from 'components/surveyInputs/AddBusiness';

export default function Business({business, onDeselect, children, onUpdate, type, mini, firmId}) {
  const [edit, setEdit] = useState();
  const [expand, setExpand] = useState();

  const onCreate = (value) => {
    onUpdate(value);
    setEdit(false);
  }

  return (
    edit ?
      <AddBusiness firmId={firmId} edit={business} onDelete={onDeselect} readOnly={business.public} type={type} close={() => setEdit(false)} onCreate={onCreate}/>
    :
      <div className={`rounded relative flex flex-col  ${mini ? 'text-xs' : ''}`}>
        <FontAwesomeIcon onClick={() => setEdit(true)} className={`absolute top-1 right-5 cursor-pointer text-white`} icon={business.public ? faMagnifyingGlass : faPenToSquare}/>
        {(onDeselect) && <FontAwesomeIcon onClick={onDeselect} className={`absolute top-1 right-1 cursor-pointer text-white`} icon={faTimes}/>}
        <div className={`flex items-center gap-2 p-3 pr-4 bg-med-grey ${mini ? 'rounded' : 'rounded-t'} text-white pr-10`}>
            <FontAwesomeIcon className={``} icon={faBuilding}/>
            <h3 className="font-bold text-white">{business.companyName}</h3>
        </div>
        <hr></hr>
        {
          !mini &&
          <>
            <div className="flex flex-col gap-2 p-3 bg-white border-x border-t border-med-grey">
              <div className="flex gap-2">
                <small className="font-bold"><FontAwesomeIcon className={``} icon={faEnvelope}/> {business.email}</small>
                <small className="font-bold"><FontAwesomeIcon className={``} icon={faLocationDot}/> {business.addressLineOne && business.addressLineOne + ', '}{business.addressLineTwo}</small>
              </div>
              <div className="flex gap-2">
                <small className="font-bold"><FontAwesomeIcon className={``} icon={faPhone}/> {business.phoneNumber}</small>
                <small className="font-bold"><FontAwesomeIcon className={``} icon={faFax}/> {business.fax}</small>
              </div>
              {children && children.length > 0 &&<div className="text-xs flex flex-col gap-4 mt-2">
                {children}
              </div>}
            </div>
            <div 
              onClick={() => setExpand(!expand)}
              className={`
                bg-light-light-grey flex flex-col items-center justify-center border-x border-b border-med-grey cursor-pointer rounded-b
                text-med-grey
                hover:bg-light-grey
                hover:text-dark-grey
            `}>
              <div className={`
                transition ease-in-out ${expand ? "h-auto" : "hidden"}
                flex flex-col gap-2 bg-white text-dark-grey border-t border-t-light-grey w-full p-3
              `}>
                <small className="font-bold"><FontAwesomeIcon className={``} icon={faCity}/> {business.city}</small>
                <small className="font-bold"><FontAwesomeIcon className={``} icon={faGlobe}/> {business.province}</small>
                <small className="font-bold"><FontAwesomeIcon className={``} icon={faEnvelopesBulk}/> {business.postalCode}</small>
                <small className="font-bold"><FontAwesomeIcon className={``} icon={faMoneyCheckDollar}/> {business.gstNumber}</small>
              </div>
              <hr></hr>
              <FontAwesomeIcon className={`text-xs`} icon={expand ? faChevronUp : faChevronDown}/>
            </div>
          </>
        }
      </div>
  )
}
