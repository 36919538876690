import ButtonPrimary from 'components/common/Button';
import React from 'react';
import { Link } from 'react-router-dom';
import { PaddedPage } from 'components/common/Layout';
import { useQuery, useMutation, gql } from "@apollo/client";
import SurveyFiles from './SurveyFiles';

const GET_SURVEYS = gql`
  query GetSurveys {
    surveys {
      id,
      linkId,
      name
    }
  }
`;

export default function MyFiles() {
  const { loading, error, data } = useQuery(GET_SURVEYS);
  return (
    <PaddedPage noBottom>
      <div className="flex flex-col gap-4 pb-16">
        <h1>My Files</h1>
        <div className="flex flex-col gap-16">
          {data && data.surveys.map(survey => (
            <SurveyFiles key={survey.id} survey={survey}/>
          ))}
        </div>
        <Link to="new">
          <ButtonPrimary>New File +</ButtonPrimary>
        </Link>
      </div>
    </PaddedPage>
  )
}
