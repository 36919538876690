import React, {useContext} from 'react'
import SurveyPage from 'components/survey/SurveyPage';
import SurveyContext from 'contexts/SurveyContext';

export default function SurveyPageLayout() {
  const { pageLength, currentPage } = useContext(SurveyContext);
  return (
    <SurveyPage first={currentPage === 1} last={currentPage === pageLength}/>
  )
}
