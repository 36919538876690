import React, {useContext, useState, useMemo} from 'react'
import { useQuery, gql } from "@apollo/client";
import SurveyContext from 'contexts/SurveyContext';
import LetterheadSelectItem from './LetterheadSelectItem';
import { Link } from 'react-router-dom';
import ButtonPrimary from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import LoadingItem from 'components/loading/LoadingItem';
import LoadingText from 'components/loading/LoadingText';

const GET_LETTERHEADS = gql`
  query GetLetterheads($firmId: String, $type: String) {
    letterheads(firmId: $firmId, type: $type) {
      id,
      name,
      url,
      type,
      previewUrl,
      firmId
    }
  }
`;

export default function LetterheadSelect({type}) {
  const {file, updateFile, loading} = useContext(SurveyContext);
  const [showSelect, setShowSelect] = useState(false);
  const { loading:letterheadLoading, error, data } = useQuery(GET_LETTERHEADS, {
    variables: { firmId: file && file.firmId, type: type || 'letterhead' }, 
    skip: file ? false: true,
    fetchPolicy:'no-cache'
  });

  const handleSelect = (id) => {
    updateFile({[`${type || "letterhead"}Id`]: id || null}, true);
    setShowSelect(false);
  }

  const selectedLetterhead = useMemo(() => {
    for (let letterhead of (data ? data.letterheads : [])) {
      if (file[`${type || "letterhead"}Id`] ? letterhead.id === file.letterheadId : letterhead.default) {
        return letterhead;
      }
    }
    let letterhead = (data?.letterheads || []).find(({id}) => id === file[`${type || "letterhead"}Id`]) ||
    (data?.letterheads || []).find(({defaultFor}) => defaultFor === file.firmId);
    return letterhead;
  }, [data,file]);

  return (
    <div className="flex flex-col items-start w-full md:w-auto gap-4">
      <div className="w-full">
        {(!file || loading) ? <LoadingText/> : <h2>{type ? _.startCase(type) : "Letterhead"}</h2>}
        <hr></hr>
      </div>
      {showSelect ?
        <>
          <div className="flex flex-col w-full gap-2">
            {data && data.letterheads.map(letterhead => (
              <LetterheadSelectItem onClick={() => handleSelect(letterhead.id)} selected={letterhead.id == selectedLetterhead?.id} letterhead={letterhead}/>
            ))}
              <LetterheadSelectItem onClick={() => handleSelect()}/>
            {
              data && data.letterheads.length < 1 &&
              <small className="text-med-grey">No Letterheads</small>
            }
            <Link to="/letterheads/new" clasName="w-full"><ButtonPrimary className="p-1 text-xs bg-green text-white w-full border-none"><FontAwesomeIcon className={``} icon={faPlus}/></ButtonPrimary></Link>
          </div>
        </>
        :
        <div className="w-full">
          {
            (!file || loading) ?
              <LoadingItem/>
            :
            <>
              {selectedLetterhead && <LetterheadSelectItem onClick={() => setShowSelect(true)} letterhead={selectedLetterhead}/>}
              {(data?.letterheads?.length || 0) > 0 && !selectedLetterhead && <LetterheadSelectItem onClick={() => setShowSelect(true)} />}
              {(data?.letterheads?.length || 0) < 1 &&
                <>
                  <small className="text-med-grey">No Letterheads</small>
                  <br></br>
                  <Link to="/letterheads/new"><ButtonPrimary className="p-1 text-xs">Add</ButtonPrimary></Link>
                </>
              }
            </>
          } 
        </div>
      }
    </div>
  )
}
