import React, {useContext, useEffect, useState, useMemo} from 'react';
import {getCalculatedValue, getSurveyValue, pathMaker} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import Input from 'components/inputs/Input';
import ParentContext from 'contexts/ParentContext';

const SurveyInput = ({ 
    type, 
    questionKey, 
    title, 
    hideLabel, 
    hidden, 
    query,
    required,
    collapsible,
    calculatedLabel,
    readOnly, 
    inputSymbol,
    skipPath,
    skipParentPath,
    pattern,
    patternErrorMessage,
    defaultValue,
    formStyles, // TODO change to input style
    description
}) => {
    const { file, updateAnswers, updateErrors, updateTotalsAndCalculations } = useContext(SurveyContext);
    const {row, parentPath, prevRows} = useContext(ParentContext);
    const path = useMemo(() => {
        return pathMaker(skipPath ? '' : questionKey, skipParentPath ? '' : parentPath, row);
    }, [questionKey, parentPath, row]);
    const [ error, setError ] = useState();

    const updateValue = (val) => {
        if (!checkError(val)) {
            updateAnswers(path, val);
            // total && updateTotalsAndCalculations();
        }
    }

    const calculatedLabelValue = useMemo(() => {
        return calculatedLabel && file && file.answers && getCalculatedValue(file, calculatedLabel, prevRows);
    }, [calculatedLabel]);

    const checkError = (val) => {
        if (required && !val) {
            setError("Field Required!");
            updateErrors(path, true);
            return true;
        }
        updateErrors(path, false);
        return false;
    }
    
    const surveyValue = useMemo(() => {
        let value = file && getSurveyValue(file.answers, path);
        if (!value && defaultValue && value !== 0) {
            updateValue(defaultValue);
        }
        return value;
    }, [file, path]);


    return (
        <div className={`flex flex-col justify-between input grow items-start ${hidden ? 'hidden' : ''}`}>
            {!hideLabel && (title || calculatedLabelValue) && (
                description ? 
                    <label>
                        {title || calculatedLabelValue}
                        <Tooltip message={description} />
                    </label>
                :
                    <label>
                        {title || calculatedLabelValue}
                    </label>
            )}
            <p>{error}</p>
            <Input 
                className={formStyles} 
                type={type} 
                rows={type === "textarea" ? surveyValue?.split(/\r\n|\r|\n/).length || 1 : 1}
                pattern={pattern}
                patternErrorMessage={patternErrorMessage}
                readOnly={readOnly} 
                valueOverride={surveyValue || ""} 
                onBlur={updateValue}
            />
        </div>
    )
};

export default SurveyInput;