import React from 'react';
import { Routes } from 'react-router-dom';

import LandingScreens from './landing';
import AuthScreens from './auth';
import SurveyScreens from './survey';
import LetterheadScreens from './letterheads';
import AdminScreens from './admin';
import TermsScreens from './terms';
import NotFoundScreens from './notFound';
import NotAuthorizedScreens from './notAuthorized';
import CalendarScreens from './calendar';
import FAQScreens from './faqs';
import TrustAccountScreens from  './trustAccount';
import ProductScreens from './products';
import UserScreens from './users';
import AboutScreens from './about';
import ToolScreens from './tools';

const Router = () => {
  return (
    <Routes>
      {
        [   
          ...LandingScreens,
          ...AuthScreens,
          ...SurveyScreens,
          ...AdminScreens,
          ...NotAuthorizedScreens,
          ...LetterheadScreens,
          ...TermsScreens,
          ...CalendarScreens,
          ...FAQScreens,
          ...NotFoundScreens,
          ...TrustAccountScreens,
          ...ProductScreens,
          ...UserScreens,
          ...AboutScreens,
          ...ToolScreens
        ]
      }
    </Routes>
  );
};

export default Router;
