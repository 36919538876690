import React, {useContext, useEffect, useState, useMemo} from 'react';
import {getCalculatedValue, getSurveyValue, pathMaker} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import Input from 'components/inputs/Input';
import ButtonPrimary from 'components/common/Button';
import { useMutation, gql } from "@apollo/client";
import fctLogo from "assets/images/survey/fctLogo.png";
import ParentContext from 'contexts/ParentContext';
import AppContext from 'contexts/AppContext';

const CREATETOKEN = gql`
  mutation CreateFCTToken($username: String!, $password: String!) {
    createFCTToken(username: $username, password: $password) {
      userId,
      id,
      tokenUsername,
    }
  }
`;

const DELETETOKEN = gql`
  mutation DeleteFCTToken {
    deleteFCTToken {
      userId,
      id,
      tokenUsername,
    }
  }
`;

const GETTOKEN = gql`
  mutation GetToken($clientId: String) {
    getFCTToken(clientId: $clientId) {
      token {
        userId,
        id,
        tokenUsername,
      },
      message
    }
  }
`;

const FCTcredentialsInput = ({ 
  questionKey, 
  titleInsurancePath
}) => {
    const { file, updateAnswers } = useContext(SurveyContext);
    const {currentUser} = useContext(AppContext);
    const [ username, setUsername ] = useState();
    const [ password, setPassword ] = useState();
    const [ update, setUpdate ] = useState();
    const [message, setMessage] = useState();
    const [getTokenMutation, { loading, error }] = useMutation(GETTOKEN);
    const [token, setToken] = useState();
    const [createTokenMutation, { loading: createLoading, error: createError }] = useMutation(CREATETOKEN);
    const [deleteTokenMutation, { loading: deleteLoading, error: deleteError }] = useMutation(DELETETOKEN);
    const [surveyValue, setSurveyValue] = useState();
    const {row, parentPath, prevRows} = useContext(ParentContext);
    const path = useMemo(() => {
      return pathMaker(questionKey, parentPath, row);
    }, [questionKey, parentPath, row]);

    const valid = () => {
      return username && password;
    }

    useEffect(() => {
      let value = file && getSurveyValue(file.answers, path);
      setSurveyValue(value);
    }, [file, path]);

    const titleInsuranceValue = useMemo(() => {
      let value = file && getSurveyValue(file.answers, titleInsurancePath);
      return value;
    }, [titleInsurancePath]);

    useEffect(() => {
      if (file.answers) {
        let supervisingLawyer = getSurveyValue(file.answers, "supervisingLawyer");
        getTokenMutation({
          variables: {clientId: supervisingLawyer?.id}
        })
        .then(res => {
          let existingToken = getSurveyValue(file.answers, path);
          if (!existingToken || !titleInsuranceValue) {
            updateAnswers(path, res.data.getFCTToken?.token?.id);
            setMessage(res.data.getFCTToken.message)
          } else if (!res.data.getFCTToken?.token?.id) {
            setSurveyValue();
          }
          setToken(res.data.getFCTToken.token);
        })
      }
    }, []);

    const submit = () => {
      if (valid()) {
        createTokenMutation({
          variables: {username, password}
        })
        .then(res => {
          let existingToken = getSurveyValue(file.answers, path);
          if (!existingToken || !titleInsuranceValue) {
            updateAnswers(path, res.data.createFCTToken.id);
          }
        })
      }
    }

    const remove = () => {
      deleteTokenMutation()
      .then(res => {
        updateAnswers(path, null);
      })
    }
    
    return message ?
      <div className="rounded border p-4 bg-light-light-red border-red flex items-center gap-8">
        <img className="h-16 self-start" src={fctLogo} alt="fct logo"/>
        <strong className="text-red"> 
          {message}
        </strong>
      </div>
      : (!surveyValue || update) ?
      (
        <div className="rounded border p-4 flex flex-col gap-2 bg-transparent-grey border-grey">
          <img className="h-16 self-start" src={fctLogo} alt="fct logo"/>
          <div className="flex gap-2">
            <div className="flex flex-col">
              <label>FCT Username</label>
              <Input onChange={setUsername}/>
            </div>
            <div className="flex flex-col">
              <label>Password</label>
              <Input onChange={setPassword} type="password"/>
            </div>
          </div>
          <ButtonPrimary className="self-end" disabled={createLoading || loading || !valid()} onClick={submit}>Authorize</ButtonPrimary>
        </div>
      )
      :
      <div className="rounded border p-4 bg-light-green border-green flex flex-col gap-8">
        <div className=" flex items-center gap-8">
          <img className="h-16 self-start" src={fctLogo} alt="fct logo"/>
          <strong className="text-green"> 
            Authorized for FCT
          </strong>
        </div>
        {currentUser?.id === token?.userId &&
          <div className="flex justify-end gap-2">
            <ButtonPrimary className="self-end text-red border-red" disabled={deleteLoading || loading} onClick={remove}>Remove</ButtonPrimary>
            <ButtonPrimary className="self-end" disabled={createLoading || loading} onClick={() => setUpdate(true)}>Update</ButtonPrimary>
          </div>
        }
      </div>
};

export default FCTcredentialsInput;