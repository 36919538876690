import React, { useEffect } from 'react';

export default function Transparent({children, priority, noScroll}) {

  useEffect(() => {
    if (noScroll) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "scroll";
    }
  }, []);

  return (
    <div className={`fixed top-0 left-0 w-full h-full ${priority ? 'z-40' : 'z-30'}`}>
          <div 
            className="absolute top-0 bottom-0 left-0 right-0"
            style={{
              backdropFilter: "blur(6px)"
            }}
          />
            {children}
    </div>
  )
}
