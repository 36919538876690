import React, {useContext} from 'react';
import AppContext from 'contexts/AppContext';

export default function LoadBar() {
  const {loading} = useContext(AppContext);
  return (
    <>
        {loading && <div className="sticy h-1 z-30 load-bar"/>}
    </>
  )
}
