import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Users from 'screens/admin/user/Users';
import { Link } from 'react-router-dom';
import ButtonPrimary from 'components/common/Button';

export default function User({user, firmId, action, actionName}) {
  let userContent = (
    <div className={`rounded cursor-pointer justify-between flex border-l-8 border-light-grey shadow-md hover:shadow-lg p-2 items-center gap-2`}>
      <div className="flex items-center gap-2">
        <FontAwesomeIcon className="" icon={faUser} />
        <strong>{user.username}</strong>
        <div className="flex items-center gap-2 ml-2">
          <small><FontAwesomeIcon className="" icon={faTag} /> {user.firstName}</small>
          <small><FontAwesomeIcon className="" icon={faEnvelope} /> {user.email}</small>
        </div>
      </div>
      <div className="flex items-center gap-2">
        {
          user.type && 
          <div className={`rounded px-2 border-red text-sm text-red border-2 font-bold`}>
            {user.type.toUpperCase()}
          </div>
        }
        {user.firms?.map( firm => (
          <div className={`rounded px-2 bg-primary text-sm text-white font-bold ${firmId && firmId !== firm.id ? 'hidden' : ''}`}>
            {firm.name} <span className="text-xxs">{(firm.type || 'privileged').toUpperCase()}</span>
          </div>
        ))}
        {
          action && <ButtonPrimary onClick={action}>{actionName}</ButtonPrimary>
        }
      </div>
    </div>
  );
  return (
    action ?
      userContent
      :
      <Link className="no-underline" to={`/admin/users/${user.id}`}>
        {userContent}
      </Link>
  )
}
