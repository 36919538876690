import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

export default function Tooltip({message, color, icon, className, showOverride, left, right, large}) {
  const [show, setShow] = useState(false);
  return (
    <div className={`inline-block ml-1 relative ${large ? 'w-4' : 'w-2'} ${className}`}>
      <div className={`absolute ${large ? '-bottom-1.5' : 'bottom-0'} text-left`}>
        {(showOverride || show) && <div className="relative -left-12 flex w-1/4screen z-30">
          <div className={` text-xxs w-fit flex p-2 rounded  grow-0 bg-white ${color ? `border-${color}` : 'border-blue'} border ${right ? 'relative left-10' : ''} ${left ? 'relative -left-28' : ''}`}>
            <p className="grow min-w-16">
              {message}
            </p>
          </div>
          <div className={`absolute rotate-45 left-12 -bottom-1.5 w-3 h-3 ${color ? `border-${color}` : 'border-blue'} border-r border-b bg-white`}></div>
        </div>}
        <FontAwesomeIcon onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} className={`${color ? `text-${color}` : 'text-blue'} ${large ? '' : 'text-xxs'} top-0 cursor-pointer`} icon={icon || faCircleQuestion}/>
      </div>
    </div>
  )
}
