import React, {useContext, useEffect, useState, useMemo} from 'react';
import {getCalculatedValue, getSurveyValue} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import Input from 'components/inputs/Input';
import ButtonPrimary from 'components/common/Button';
import { useMutation, gql } from "@apollo/client";
import ltsaLogo from "assets/images/survey/ltsalogo.svg";

const GETTOKEN = gql`
  mutation CreateToken($fileId: String!, $username: String!, $password: String!) {
    createToken(fileId: $fileId, username: $username, password: $password) {
      id,
      expiry, 
      type
    }
  }
`;

const LTSAcredentialsInput = ({ 
  forRequests, 
}) => {
    const { file, updateFile } = useContext(SurveyContext);
    const [ username, setUsername ] = useState();
    const [ password, setPassword ] = useState();
    const [getTokenMutation, { loading, error }] = useMutation(GETTOKEN);
    const required = useMemo(() => {
      if (file && file.tokens["LTSAtoken"] && (new Date(Date.now()) < new Date(file.tokens["LTSAtoken"]))) return false;
      let requestComplete = true;
      for (let request of forRequests || []) {
        if (!getCalculatedValue(file, `requests.${request}`)) {
          requestComplete = false;
        }
      }
      // for now always show ltsa login if no stored credentials
      return true;
      if (requestComplete) return false;
    }, [file, forRequests]);

    const valid = () => {
      return username && password;
    }

    const submit = () => {
      if (valid()) {
        getTokenMutation({
          variables: {username, password, fileId: file.id}
        })
        .then(res => {
          updateFile({tokens: {...file.tokens, LTSAtoken: Number(res.data.createToken.expiry)}})
        })
      }
    }
    
    return required ?
      (
        <div className="rounded border p-4 flex flex-col gap-2 bg-transparent-grey border-grey">
          <img className="h-12 self-start" src={ltsaLogo} alt="ltsa logo"/>
          <div className="flex gap-2">
            <div className="flex flex-col">
              <label>Ltsa Username</label>
              <Input onChange={setUsername}/>
            </div>
            <div className="flex flex-col">
              <label>Password</label>
              <Input onChange={setPassword} type="password"/>
            </div>
          </div>
          <ButtonPrimary className="self-end" disabled={loading || !valid()} onClick={submit}>Authorize</ButtonPrimary>
        </div>
      )
      :
      <div className="rounded border p-4 bg-light-green border-green flex items-center gap-8">
        <img className="h-12 self-start" src={ltsaLogo} alt="ltsa logo"/>
        <strong className="text-green"> 
          Authorized for LTSA
        </strong>
      </div>
};

export default LTSAcredentialsInput;