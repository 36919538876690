import React, { useState, useEffect, useMemo } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Input from 'components/inputs';
import PaymentMethod from './PaymentMethod';
import ButtonPrimary from 'components/common/Button';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from 'components/inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Loading from 'components/common/Loading';
import Message from 'components/common/Message';

const appearance = {
  style: {
    base: {
      margin: '5px 15px',
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '1em',
      '::placeholder': {
        color: 'rgba(0,0,0,.4)'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};


function StripeCardSetupForm({ paymentIntent, paymentMethods, setPaymentMethods, handleSuccess, file, returnUrl, submitLoading, code, setCode }) {
  const [paymentInfo, setPaymentInfo] = useState(false);
  const [showCode, setShowCode] = useState(0);
  const [showNewPayment, setShowNewPayment] = useState(paymentMethods ? false : true);
  const [savedPaymentMethod, setSavedPaymentMethod] = useState(paymentMethods ? paymentMethods[0].id : null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  useEffect(() => {
    if (paymentMethods) {
      setSavedPaymentMethod(paymentMethods[0].id);
      setShowNewPayment(false);
    }
  }, [paymentMethods]);

  useEffect(() => {
    // request to get cost of file 
    // then setCost()
    // history.push('/not-authorized');
  }, [file]);

  const validateForm = () => {
    return !loading && !message.success && paymentInfo.terms && ( showNewPayment || savedPaymentMethod);
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    if (code) {
      setLoading(false);
      handleSuccess();
    }
    setLoading(true);
    if (!stripe || !elements || !paymentIntent) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setMessage({ success: false, content: 'Payment could not be processed.' });
      return;
    }

    if (!showNewPayment && savedPaymentMethod) {
      setLoading(false);
      return handleSuccess(savedPaymentMethod);
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
      // Uncomment below if you only want redirect for redirect-based payments
      redirect: 'if_required',
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      setMessage({ success: false, content: result.error.message });
      setLoading(false);
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      setMessage({
        success: true, 
        content: 'Success, payment method recieved.'
      });
      setLoading(false);
      handleSuccess(result.payment_method);
    }
  };

  const handleRemovePaymentMethod = (method) => {
    // TODO remove a payment using method.id
    // then setShow
    // removePaymentMethod(method.id)
    //   .then(() => {
    //     if (savedPaymentMethod === method.id) {
    //       setSavedPaymentMethod(null);
    //       setPaymentMethods(current => {
    //         let filtered = current.filter(currentMethod => currentMethod.id !== method.id);
    //         if (filtered.length < 1) {
    //           setShowNewPayment(true);
    //           return null;
    //         }
    //         return filtered;
    //       }
    //       );
    //     }
    //   });
  };

  let priceInfo = useMemo(() => {
    let base = (paymentIntent.amount/1.12)/100;
    let info = {price: paymentIntent.amount/100.0, base, pst: Math.round(base * 0.07 * 100)/100, gst: Math.round(base * 0.05 * 100)/100};
    if (paymentIntent.metadata.discountAmount) {
      info.originalPrice = info.price + (Number(paymentIntent.metadata.discountAmount)/100.0);
    } else if (paymentIntent.metadata.discountPercentage) {
      info.originalPrice = (info.price * 100.0/Number(paymentIntent.metadata.discountAmount));
    }
    return info;
  }, [paymentIntent]);

  return (
    <>
      <div className="flex flex-col gap-4">
        <h2>Proceed to Payment</h2>
        <div className="font-bold">Payment <span onClick={() => setShowCode(showCode + 1)}>Summary</span></div>
        {
          showCode > 5 &&
          <div className="flex flex-col">
            <small>Code</small>
            <Input type="password" valueOverride={code} onChange={setCode}></Input>
          </div>
        }
        <div className="flex flex-col gap-2 p-2 bg-light-light-grey rounded">
          <div><small>
            <strong>
              Generation of any compatible documents for file #{file && file.fileNumber} for the lifetime of ConveyMe
            </strong>
            <br></br>
            Payment unlocks document generation and calendar tracking for this file. 
          </small></div>
          <div className="flex flex-col">
            <div className={`${priceInfo.originalPrice ? 'line-through' : ''} flex justify-between`}>
              Price: <span>{((priceInfo.originalPrice || priceInfo.price) - priceInfo.gst - priceInfo.pst).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
            </div>
            {
              priceInfo.originalPrice &&
              <div className='font-medium flex justify-between'>
                <div>
                  Discounted Price: {paymentIntent.metadata.discountName && <span className="bg-green rounded text-xs ml-1 text-white px-1">{paymentIntent.metadata.discountName}</span>}
                </div>
                <span>{(priceInfo.price - priceInfo.gst - priceInfo.pst).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
              </div>
            }
            <div className="flex justify-between">
              GST: <span>{priceInfo.gst.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
            </div>
            <div className="flex justify-between border-b">
              PST: <span>{priceInfo.pst.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
            </div>
            <div className='font-medium flex justify-between'>
              Total: <span>{ code ? '$0.00' : priceInfo.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
            </div>
          </div>
          <div>
            {
              paymentIntent.fileCredits &&
              <div>
                <span className="font-medium text-primary">
                  {paymentIntent.fileCredits} Credits remaining
                </span>
              </div>
            }
          </div>
          </div>
        {!code && !paymentIntent.fileCredits &&
        <>
          <div className="flex justify-between items-center">
            <strong>Card</strong>
            { paymentMethods && <ButtonPrimary size="mini" onClick={() => setShowNewPayment(!showNewPayment)}>{ showNewPayment ? 'Saved Card' : 'New Card' }</ButtonPrimary> }
          </div>
          {
            paymentMethods && !showNewPayment ?
              <div className="flex flex-column gap-2 mb-3">
                {paymentMethods.map((method, index) => <PaymentMethod key={index} method={method} selected={savedPaymentMethod} setPaymentMethod={setSavedPaymentMethod} removePaymentMethod={handleRemovePaymentMethod}/>)}
              </div>
              :
              <>
                <div>
                  <PaymentElement id="card-element" />
                </div>
              </>
          }
        </>
        }
        <Checkbox
          name="terms"
          checked={paymentInfo.terms}
          className="mr-2"
          onChange={() => {
            setPaymentInfo({ ...paymentInfo, terms: !paymentInfo.terms });
          }}
          text={
            <label>
              I have read and agree to the&nbsp; 
              <a href={'/terms-and-conditions'} rel="noreferrer" target="_blank">Terms and Conditions</a>,&nbsp; 
            </label>
          }
          required
        />
        <ButtonPrimary
          margin
          primary basic
          disabled={!validateForm()}
          onClick={handleSubmit}
        >
          {loading || submitLoading || !paymentIntent ?
            <Loading/>
            : paymentIntent.fileCredits ? 'Use Credit' : 'Pay Now'
          }
        </ButtonPrimary>
      </div>
      {
        message.content &&
        <Message attached='bottom' negative={!message.success} positive={message.success}>
          {message.success ? 
            <FontAwesomeIcon className="text-xs text-green-400" icon={faCheck} />
            :
            <FontAwesomeIcon className="text-xs text-green-400" icon={faTimes} />
          }
          {message.content}
        </Message>
      }
    </>
  );
}

export default StripeCardSetupForm;

