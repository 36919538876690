import React, {useState} from 'react';
import { useMutation, gql } from "@apollo/client";
import { Input, List, Radios } from 'components/inputs';
import ButtonPrimary from 'components/common/Button';
import { FullWidthPage, PaddedPage } from 'components/common/Layout';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { Table } from 'components/surveyInputs';
import BusinessInput from 'components/surveyInputs/BusinessInput';
import AddBusiness from 'components/surveyInputs/AddBusiness';

export default function CreateBusiness() {
	const navigate = useNavigate();
  const [type, setType] = useState();
  
  const onComplete = () => {
		navigate("/admin/businesses")
	}

  return (
    <FullWidthPage bottom className="pt-4">
      <div className="flex flex-col gap-2 items-start">
        <Link to="/admin/businesses"><FontAwesomeIcon className="text-2xl" icon={faCircleLeft} /></Link>
        <Table
          title="Create Business"
        >
          <AddBusiness onCreate={onComplete} type={['firm', 'notary public', 'realtor', 'bank', 'broker', 'strata management company', 'discharge centers']} publicBusiness={true}/>
        </Table>
      </div>
    </FullWidthPage>
  )
}
