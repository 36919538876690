import { gql, useQuery } from '@apollo/client';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import { formatCurrency } from 'selectors/formSelectors';
import ActivePageContext from 'contexts/ActivePageContext';
import useExportCSV from 'hooks/useExportCSV';

// get trust account items for this file
const GET_FILES = gql`
  query GetFiles($filters: JSONObject, $firmId: String) {
    files(filters: $filters, firmId: $firmId) {
      id,
      fileNumber,
      nickname,
      createdAt,
      paidAt,
      surveyId,
      surveyName,
      completionDate, 
      status,
      stripePaymentIntent {
        amount,
        chargedAt,
        status
      }
    }
  }
`;

export default function Payments() {
  const {from, to, filters, firmId, setExportCSV} = useContext(ActivePageContext);
  const {exportToCsv} = useExportCSV();
  const { loading, error, data, refetch } = useQuery(GET_FILES, {
    variables: {firmId, filters: {
      from: filters.filterByCreated === "created" ? from : undefined, 
      to: filters.filterByCreated === "created" ? to : undefined, 
      closingFrom: filters.filterByCreated ? undefined: from, 
      closingTo: filters.filterByCreated ? undefined: to,
      paidFrom: filters.filterByCreated === "paid" ? from : undefined, 
      paidTo: filters.filterByCreated === "paid" ? to : undefined, 
      page: 'all',
    }},
    fetchPolicy:'no-cache',
  });

  const files = useMemo(() => {
    return data?.files?.filter(file => {
      return file.paidAt || ( (new Date(from) < new Date(file.stripePaymentIntent?.chargedAt*1000 || Number(file.createdAt))) && ((new Date(to) > new Date(Number(file.stripePaymentIntent?.chargedAt)*1000 || Number(file.createdAt)))) );
    }) || [];
  }, [data, from, to])

  useEffect(() => {
    if (!setExportCSV) return;
    if (files?.length > 0) {
      setExportCSV({call: () => exportToCsv(files?.map(({fileNumber, fileNickname, stripePaymentIntent, createdAt, surveyName, completionDate}) => ({
        fileMatter: `${fileNumber}/${fileNickname}`, 
        chargeDate: stripePaymentIntent?.chargedAt ? new Date(stripePaymentIntent?.chargedAt * 1000).toLocaleString('default', {month: 'long', day: "numeric", year: "numeric"} ) : "credit used", 
        createdAt: new Date(Number(createdAt)).toLocaleString('default', {month: 'long', day: "numeric", year: "numeric"}),
        closing: completionDate && new Date(completionDate + 'T00:00').toLocaleString('default', {month: 'long', day: "numeric", year: "numeric"}),
        type: surveyName,
        amount: `${stripePaymentIntent?.chargedAt ? "Credit @(" : ''}${formatCurrency((stripePaymentIntent?.amount || 4900)/100)}${stripePaymentIntent?.chargedAt ? ")" : ''}`
      })), `conveyMe Purchases ${from} ${to}`)})
    } else {
      setExportCSV();
    }
  }, [files]);

  const total = useMemo(() => {
    return files.reduce((prev, current) => {
      return prev + Number((current.stripePaymentIntent?.amount || 4900)) || 0;
    }, 0);
  }, [files])

  return (
    <div className="bg-light-grey rounded p-2">
      <table className="w-full bg-light-grey border-spacing-2">
        <tbody>
          <tr className="border-b border-dark-grey">
            <th></th>
            <th>File Matter</th>
            <th>Charge Date</th>
            <th>Created At</th>
            <th>Closing</th>
            <th>Type</th>
            <th>amount</th>
          </tr>
          {files.map((file, i) => (
            <tr key={i} className={`group relative ${i%2 === 0 ? 'bg-transparent-grey' : ''}`}>
              <td className="p-1 text-center">{i+1}</td>
              <td className="p-1 text"><Link to={`/files/${file.surveyId}/${file.id}`}>{file.fileNumber}/{file.nickname}</Link></td>
              <td className="p-1 text-center">{file.stripePaymentIntent?.chargedAt ? new Date(file.stripePaymentIntent?.chargedAt * 1000).toLocaleString('default', {month: 'long', day: "numeric", year: "numeric"} ) : "credit used"}</td>
              <td className="p-1 text-center">{ new Date(Number(file.createdAt)).toLocaleString('default', {month: 'long', day: "numeric", year: "numeric"})}</td>
              <td className="p-1 text-center">{ file.completionDate && new Date(file.completionDate + 'T00:00').toLocaleString('default', {month: 'long', day: "numeric", year: "numeric"})}</td>
              <td className="p-1 text-center">{file.surveyName}</td>
              <td className="p-1 text-right">{file.stripePaymentIntent?.chargedAt && "Credit @("}{formatCurrency((file.stripePaymentIntent?.amount || 4900)/100)}{file.stripePaymentIntent?.chargedAt && ")"}</td>
            </tr>
          ))}
          <tr className="border-t border-med-grey">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="text-right pt-2">{formatCurrency(total/100)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
