import ButtonPrimary from 'components/common/Button';
import React, {useContext, useState} from 'react';
import SurveyContext from 'contexts/SurveyContext';
import Input, { IconSelector, List, Radios } from 'components/inputs';
import { useNavigate } from 'react-router-dom';
import { useQuery, gql } from "@apollo/client";
import { PaddedPage } from 'components/common/Layout';
import useFirmChoices from 'hooks/useFirmChoices';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import Tooltip from 'components/common/Tooltip';
import useUserChoices from 'hooks/useUserChoices';

const GET_SURVEYS = gql`
  query GetSurveys {
    surveys {
      name,
      id,
      linkId
    }
  }
`;

const GET_FILENUMBERS = gql`
  query GetFileNumbers($surveyId: String!, $firmId: String, $partialNumber: String) {
    fileNumbers(surveyId: $surveyId, firmId: $firmId, partialNumber: $partialNumber)
  }
`;

export default function NewFile() {
  const [surveyTypeId, setSurveyTypeId] = useState();
  const {firmChoices, firmId, setFirmId} = useFirmChoices(true, {name: "", value: ""}, true);
  const {userChoices, userId, setUserId} = useUserChoices(true, {name: "", value: ""}, firmId);
  const [fileInfo, setFileInfo] = useState({});
  const [assignFile, setAssignFile] = useState(false);
  const {newFile, loading} = useContext(SurveyContext);
  const navigate = useNavigate();
  const [showNumberSuggestion, setShowNumberSuggestions] = useState();
  const { error, data } = useQuery(GET_SURVEYS);
  const options = data && data.surveys ? data.surveys.map(survey => ({name: survey.name == 'Refinance' ? "Refinance & Family Transfers" : survey.name, value: survey.id})) : [];
  const { loadingFileNumbers, data:fileNumbersData } = useQuery(GET_FILENUMBERS, {
    variables: {
      partialNumber: fileInfo.fileNumber,
      firmId: firmId || "",
      surveyId: surveyTypeId || (options[0] && options[0].value), 
    },
    fetchPolicy: 'no-cache',
    skip: !(surveyTypeId || (options[0] && options[0].value))
  });
  const createFile = () => {
    if (valid()) {
      newFile({
        surveyId: surveyTypeId || options[0].value, 
        fileNumber: fileInfo.fileNumber, 
        nickname: fileInfo.nickname,
        userId: userId,
        icon: fileInfo.icon,
        firmId: firmId || undefined
      });
    }
  }

  const valid = () => {
    return surveyTypeId && fileInfo.nickname && fileInfo.fileNumber ? true : false;
  }
  return (
    <PaddedPage noBottom className="pb-20 grow">
      <div className="flex flex-col gap-2 items-start">
        <h1>What Type of File Do you need to Create?</h1>
        <Radios options={options} valueOverride={surveyTypeId} onChange={setSurveyTypeId}/>
        <hr></hr>
        <div className="flex flex-col gap-4 items-start">
          <div className="flex flex-col">
            <div className="flex">
              <label>File #</label>
              <Tooltip 
                icon={faCircleQuestion} 
                color="blue" 
                message={<span>
                  Type a building prefix to get # suggestions. ex. type CONVEY for CM00012-CONVEY0001.
                  <br/>
                  Prefixs should not end in numbers.
                  <br/>
                  Go to user or firm settings to edit your base prefix.
                </span>}
              /> 
            </div>
            <Input 
              onSelect={() => setShowNumberSuggestions(true)} 
              onBlur={() => setShowNumberSuggestions(false)} 
              onChange={(fileNumber) => setFileInfo({...fileInfo, fileNumber})} 
              valueOverride={fileInfo.fileNumber}
            />
            {
              showNumberSuggestion &&
              <div className="border-2 rounded-b border-light-grey p-2 pt-3 relative border-t-transparent -top-0.5">
                {
                  fileNumbersData && fileNumbersData.fileNumbers.map(num => 
                    <div onMouseDown={() => console.log("click") || setFileInfo({...fileInfo, fileNumber:num})} className="cursor-pointer hover:font-bold">
                      {num}
                    </div>  
                  )
                }
              </div>
            }
          </div>
          <div className="flex flex-col">
            <label>File Name</label>
            <Input onChange={(nickname) => setFileInfo({...fileInfo, nickname})} />
          </div>
          <div className="flex flex-col">
            <label>File Icon</label>
            <IconSelector onChange={(icon) => setFileInfo({...fileInfo, icon})}/>
          </div>
          <div className="flex flex-col gap-4">
            {
              firmChoices.length > 0 && 
              <div className="flex flex-col">
                <label>Firm</label>
                <List onChange={setFirmId} valueOverride={firmId} className="" options={[...firmChoices, {value: "", name: "none"}]}/>
              </div>
            }
            {
              firmId && 
              <div className="flex flex-col">
                <label>Assign file to another user in your firm?</label>
                <Radios onChange={val => setAssignFile(val)} valueOverride={assignFile} options={[{ name: 'yes', value: true }, { name: 'no', value: false }]} />
              </div>
            }
            {
              assignFile && 
              <div className="flex flex-col">
                <label>Assign file to</label>
                <List onChange={setUserId} valueOverride={userId} className="" options={[...userChoices, {value: "", name: "none"}]}/>
              </div>
            }
          </div>
        </div>
        <br></br>
        <ButtonPrimary disabled={loading || !valid()} onClick={createFile}>Create File +</ButtonPrimary>
      </div>
    </PaddedPage>
  )
}
