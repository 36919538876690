import React from 'react';
import SurveyProvider from 'providers/SurveyProvider';
import { useParams } from 'react-router-dom';

export default function Survey({children}) {
  const params = useParams();
  const fileId = params.fileId;
  const surveyId = params.surveyId;
  const currentPage = Number(params.pageNumber);

  return (
      params.fileId ?
      <SurveyProvider surveyId={surveyId} fileId={fileId} currentPage={currentPage}>
        {children}
      </SurveyProvider>
      :
      <SurveyProvider key="File" surveyId={null} fileId={null} currentPage={null}>
        {children}
      </SurveyProvider>
  )
}
