import React, { useState, useContext, useEffect } from 'react';
import { PaddedPage } from 'components/common/Layout';
import { ButtonPrimary } from 'components/common/Button';
import { useParams, useNavigate } from 'react-router-dom';
import AppContext from 'contexts/AppContext';
import Message from 'components/common/Message';
import { useQuery, gql, useMutation } from "@apollo/client";
import Login from './Login';
import SignUp from './Signup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEnvelopeOpenText} from '@fortawesome/free-solid-svg-icons';

const GET_INVITE = gql`
  query GetInvite($id: String!) {
    invite(id: $id) {
      id,
      firmId,
      firm {
        name
      },
      fromUser {
        firstName,
        lastName,
        email
      }
      type,
      email,
      used,
      existingUser
    }
  }
`;

const ACCEPT_INVITE = gql`
  mutation AcceptInvite($id: String!) {
    acceptInvite(id: $id) {
      id
    }
  }
`;

const Invited = () => {
  const { currentUser, logout } = useContext(AppContext);
  const [success, setSuccess] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const [acceptInviteMutation, { loading: acceptLoading }] = useMutation(ACCEPT_INVITE);
  const { loading, error, data } = useQuery(GET_INVITE, {
    variables: {id: params.id},
    skip: params.id ? false : true
  });

  useEffect(() => {
    if (currentUser && data && (!data.invite.firmId || data.invite.email !== currentUser.email)) {
      logout();
      window.location.reload();
    } 
  }, [currentUser, logout, data]);

  const handleAccept = () => {
    acceptInviteMutation({variables: {id: data && data.invite.id}})
      .then(res => {
        setSuccess(true);
      })
  }
  const handleDecline = () => {
    navigate("/");
  }

  return (
    <>
      <PaddedPage className="flex justify-center" noBottom={true}>
        <div className="flex flex-col justify-center gap-4">
          {success && 
            <>
              <h1>Success!</h1>
              <h2>You are now a member of {(data.invite.firmId) ? `${data.invite.firm.name} on ` : ''} 
              ConveyMe.</h2>
            </>
          }
          {!success && data && 
            (data.invite.used ?
              <>
                <h1>This invite has already been used by {data.invite.email}.</h1>
              </>
            :
              <>
                <div>
                  <h2 className="mb-2 font-bold">
                    <FontAwesomeIcon icon={faEnvelopeOpenText}/>&nbsp;
                    {currentUser ? currentUser.firstName : data.invite.email}, 
                  </h2>
                  <p className="">
                    You have been invited to join&nbsp;
                    {(data.invite.firmId) ? `${data.invite.firm.name} on ` : ''} 
                    ConveyMe by {data.invite.fromUser.firstName}.
                  </p>
                  {
                    data.invite.existingUser && !currentUser &&
                    <p className="">Sign up below.</p>
                  }
                </div>
                {data.invite.existingUser && currentUser && <div className="flex gap-2">
                  <div className="flex w-full justify-center gap-2">
                    <ButtonPrimary disabled={acceptLoading} onClick={handleDecline}>Decline</ButtonPrimary>
                    <ButtonPrimary disabled={acceptLoading} onClick={handleAccept}>Accept</ButtonPrimary>
                  </div>
                </div>}
              </>
            )
          }
        </div>
      </PaddedPage>
      {/* <hr></hr> */}
      {data && !data.invite.used && data.invite.existingUser && !currentUser && <Login inviteId={data.inviteId}/> }
      {data && !data.invite.used && !data.invite.existingUser && <SignUp inviteId={data.invite.id}/>}
    </>
  );
};

export default Invited;
