import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBuilding, faPhone, faEnvelope, faLocationDot, faFax, faCircleChevronUp, faChevronUp, faChevronDown, faMoneyCheckDollar, faGlobe, faCity, faEnvelopesBulk, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import AddTrustAccount from './AddTrustAccount';

export default function TrustAccount({trustAccount, onDeselect, onUpdate, valueOverride, type}) {
  const [edit, setEdit] = useState();
  const [expand, setExpand] = useState();

  const onCreate = (value) => {
    onUpdate(value);
    setEdit(false);
  }

  return (
    edit ?
      <AddTrustAccount edit={trustAccount} type={type} close={() => setEdit(false)} onCreate={onCreate}/>
    :
      <div className="rounded relative flex flex-col bg-white">
        <FontAwesomeIcon onClick={() => setEdit(true)} className={`absolute top-1 right-5 cursor-pointer text-white`} icon={faPenToSquare}/>
        {!valueOverride && <FontAwesomeIcon onClick={onDeselect} className={`absolute top-1 right-1 cursor-pointer text-white`} icon={faTimes}/>}
        <div className="flex items-center gap-2 p-3 pr-4 bg-med-grey rounded-t text-white pr-10">
            <FontAwesomeIcon className={``} icon={faFileInvoiceDollar}/>
            <h3 className="font-bold text-white">{trustAccount.name}</h3>
        </div>
      </div>
  )
}
