import React, {useEffect, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useQuery, gql } from "@apollo/client";
import SurveyBadge from 'components/survey/SurveyBadge';

const GET_SURVEYS = gql`
  query GetSurveys {
    surveys {
      id,
      name
    }
  }
`;

export default function SurveySelector({valueOverride, onChange}) {
  const [value, setValue] = useState(valueOverride || []);
  const { loading, error, data } = useQuery(GET_SURVEYS);
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    if (valueOverride) {
      setValue(valueOverride);
    }
  }, [valueOverride])

  const addValue = (val) => {
    setValue((prev) => {
      let newValue = [...prev, val];
      onChange(newValue);
      setShowOptions(false);
      return newValue;
    });
  }

  const removeValue = (val) => {
    setValue((prev) => {
      let newValue = [...prev].filter(survey => survey.id !== val.id);
      onChange(newValue);
      setShowOptions(true);
      return newValue;
    });
  }

  let options = data && data.surveys ? data.surveys.filter(survey => !value.map(survey => survey.id).includes(survey.id)) : [];
  return (
    <div>
        <div className={`
          flex flex-col items-start rounded border pr-3 relative 
          ${showOptions ? "" : "shadow-md"}
          ${showOptions && 'rounded-b-none'}
          `} 
          onClick={() => setShowOptions(!showOptions)}
        >
            <div className="flex p-2 gap-2">
              {value.map(survey => <SurveyBadge key={survey.id} survey={survey} onRemove={removeValue}/>)}
              {value.length < 1 && <SurveyBadge blank/>}
              <FontAwesomeIcon className="absolute right-1 bottom-1 text-base cursor-pointer" icon={faCaretDown} />
            </div>
        </div>
        {
          showOptions && 
          <div className={`
            p-1 flex flex-col items-center gap-1 rounded border shadow-md
            ${showOptions && 'rounded-t-none border-t-0'}
          `}>
            {options.map((survey) => (
                  <SurveyBadge
                    key={survey.id}
                    selectable
                    onClick={() => {
                      addValue(survey);
                    }}
                    survey={survey}
                  />
              ))
            }
            {options.length < 1 && <SurveyBadge blank/>}
          </div>
        }
    </div>
  )
}
