import React from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Loading({color}) {
  return (
    <div className="flex items-center justify-center">
      <FontAwesomeIcon className={`animate-spin text-${color ? color : 'light-grey'}`} icon={faSpinner} />
    </div>
  )
}
