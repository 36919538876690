import { FullWidthPage } from 'components/common/Layout'
import React from 'react'
import InviteUser from './InviteUser'

export default function InviteUserPage() {
  return (
    <FullWidthPage bottom className='pt-4'>
        <InviteUser/>
    </FullWidthPage>
  )
}
