import AnonRoute from 'components/routes/AnonRoute';
import UserRoute from 'components/routes/UserRoute';

import React from 'react';
import { Route } from 'react-router-dom';
import HomePage from './HomePage';
import LandingPage from './LandingPage';

const routes = [
  <Route key="landing" path="/landing" element={<LandingPage/>} />,
  <Route key="home" path="/home" element={<UserRoute><HomePage/></UserRoute>} />,
  <Route key="index" path="/" exact element={<AnonRoute><LandingPage/></AnonRoute>} />
];

export default routes;
