import React from 'react'

export default function DocumentErrors({errors, requestError}) {
  return (
    <div className="text-sm bg-light-light-red border-t-red border-t p-2 flex flex-col">
      {
      requestError && <p className="mb-2">{requestError}</p>
      }
      Missing fields:&nbsp;
      {
      errors.map((error, i) => <strong key={error + i}>{error}, </strong>)
      }
      make sure to fill out all survey fields.
    </div>
  )
}
