import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { faWindowRestore } from '@fortawesome/free-regular-svg-icons';

export default function FAQuestion({question, startOpen, children}) {
    const [open, setOpen] = useState(startOpen || window.location.href.includes(question.replaceAll(' ', '-')));
    return (
        <div className="flex flex-col gap-2">
            <a href={'#' + question.replaceAll(' ', '-')} className="no-underline" id={question.replaceAll(' ', '-')}>
                <div style={{cursor: "pointer"}} className="flex bg-light-grey justify-between p-1 px-3" onClick={() => setOpen(!open)}>
                    <h3 className="w-75">{question}</h3>
                    {open ?
                        <FontAwesomeIcon className="text-primary" icon={faMinus} size='lg'></FontAwesomeIcon>
                        :
                        <FontAwesomeIcon className="text-primary" icon={faPlus} size='lg'></FontAwesomeIcon>
                    }
                </div>
            </a>
            {open && <div className="w-75 px-3">
                {children}
            </div>}
        </div>
    )
}
