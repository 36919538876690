import React, {useState, useContext, useEffect, useMemo} from 'react';
import {getCalculatedValue, getSurveyValue, pathMaker} from 'selectors/formSelectors';
import Tooltip from 'components/common/Tooltip';
import {Input, Radios} from 'components/inputs';
import SurveyContext from 'contexts/SurveyContext';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import ParentContext from 'contexts/ParentContext';
import _ from 'lodash';

const displayFormat = (val) => {
    if (Number(val) || val === 0 || val === '0') {
        return Number(val).toFixed(2)?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }
    return val;
}


// max-w-32 min-h-24 min-h-32 text-right
const CalculatedInput =  ({ calculation, title, hideLabel, total, writable, questionKey, description, formStyles, cache, softCache, skipPath, skipParentPath, span, type, options}) => {
    const { file, updateAnswers, updateCalculation, updateTotalsAndCalculations } = useContext(SurveyContext);
    const {row, prevRows, parentPath} = useContext(ParentContext);
    const [localCache, setLocalCache] = useState();
    const path = useMemo(() => {
        return pathMaker(skipPath ? '' : questionKey,  skipParentPath ? '' : parentPath, row);
    }, [questionKey, parentPath, row]);

    const calculatedValue = useMemo(() => {
        let value;
        if (writable) {
            value = getSurveyValue(file && file.answers, path);
        }
        value = value || (calculation && file && file.answers && getCalculatedValue(file, calculation, prevRows));
        value = ((Number(value) && value !== true) ? Math.round(value * 100)/100 : value);
        if (_.isEqual(localCache, value)) {
            return localCache;
        }
        setLocalCache(value);
        return value;
    }, [calculation, file, prevRows, writable]);

    const displayValue = useMemo(() => {
        return calculatedValue?.toString()?.replaceAll('\\u8217  ', "'").replaceAll('\\u8217', "'")
    }, [calculatedValue]);

    useEffect(() => {
        if (cache || softCache || writable) {
            if (!parentPath || getSurveyValue(file?.answers, parentPath)) {
                updateAnswers(path, calculatedValue, (calculatedValue || !parentPath));
                if (softCache) {
                    updateCalculation(path, calculatedValue);
                }
            } else if (parentPath) {
                updateCalculation(path, calculatedValue);
            }
        }
        if (!cache && !writable) {
            updateCalculation(path, calculatedValue);
        }
    }, [calculatedValue, path, updateCalculation]);

    const updateValue = (val) => {
        if (val != calculatedValue) {
            updateAnswers(path, val);
        }
    }
    // w-48
    return (
        <div className={`flex flex-col items-start ${formStyles}`}>
            {
                !hideLabel && title && (
                <label>
                    {title}
                    {description && <Tooltip message={description} />}
                    <Tooltip icon={faCalculator} className="text-orange border-orange" color="orange" message="This is a calculated value. No input required."/>
                </label>
                )
            }
            {(!span && (writable || (prevRows && prevRows.length > 0))) ?
                (type == 'List' ?
                    <Radios
                        className={formStyles} 
                        readOnly={!writable} 
                        options={options} 
                        valueOverride={writable ? displayValue : displayFormat(displayValue)} 
                        onChange={(val) => updateValue(val)} 
                    />
                :
                    <Input
                        valueOverride={writable ? displayValue : displayFormat(displayValue)}
                        type={type || "text"}
                        readOnly={!writable}
                        onBlur={updateValue}
                        className={formStyles}
                    />
                )
                :
                <span
                    className={`
                        border border-light-grey p-1 rounded shadow-md
                        bg-white
                        ${!writable && 'pointer-events-none cursor-default'}
                    `} 
                    role="textbox" 
                >
                    {displayFormat(displayValue)}
                </span>
            }
        </div>
    )
}

export default CalculatedInput;