import React, {useMemo} from 'react'
import { getCalculatedValue } from 'selectors/formSelectors';

export default function useSurveyPages(survey, file) {

  const filteredPages = useMemo(() => {
    if (survey && survey.pages && file) {
      return survey.pages.filter((page) => !page.hideCalculation || !getCalculatedValue(file, page.hideCalculation));
    }
  }, [file, survey]);

  return {
    pages: filteredPages
  }
}
