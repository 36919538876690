import React, {useEffect, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useQuery, gql } from "@apollo/client";
import SurveyBadge from 'components/survey/SurveyBadge';
import useMortgageChoices from 'hooks/useMortgageChoices';

export default function MortgageSelector({valueOverride, onChange}) {
  const {mortgageChoices, mortgageIds, setMortgageIds, settings} = useMortgageChoices(true, false);
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    if (valueOverride) {
      setMortgageIds(valueOverride);
    }
  }, [valueOverride])

  const addValue = (val) => {
    setMortgageIds((prev) => {
      let newValue = [...prev, val];
      onChange(newValue);
      setShowOptions(false);
      return newValue;
    });
  }

  const removeValue = (val) => {
    setMortgageIds((prev) => {
      let newValue = [...prev].filter(survey => survey.value !== val.value);
      onChange(newValue);
      setShowOptions(true);
      return newValue;
    });
  }

  return (
    <div>
        <div className={`
          flex flex-col items-start rounded border pr-3 relative 
          ${showOptions ? "" : "shadow-md"}
          ${showOptions && 'rounded-b-none'}
          `} 
          onClick={() => setShowOptions(!showOptions)}
        >
            <div className="flex p-2 gap-2">
              {mortgageIds.map(mortgage => <SurveyBadge key={mortgage.value} survey={mortgage} onRemove={removeValue}/>)}
              {mortgageIds.length < 1 && <SurveyBadge blank/>}
              <FontAwesomeIcon className="absolute right-1 bottom-1 text-base cursor-pointer" icon={faCaretDown} />
            </div>
        </div>
        {
          showOptions && 
          <div className={`
            p-1 flex flex-col items-center gap-1 rounded border shadow-md
            ${showOptions && 'rounded-t-none border-t-0'}
          `}>
            {mortgageChoices.map((mortgage) => (
                  <SurveyBadge
                    key={mortgage.value}
                    selectable
                    onClick={() => {
                      addValue(mortgage);
                    }}
                    survey={mortgage}
                  />
              ))
            }
            {mortgageChoices.length < 1 && <SurveyBadge blank/>}
          </div>
        }
    </div>
  )
}
