import React from 'react';
import 'styles/App.css';
import RequestFlagsProvider from 'providers/RequestFlagsProvider';
import ApolloClientProvider from 'providers/ApolloClientProvider';
import AppProvider from 'providers/AppProvider';
import NavBar from 'components/NavBar';
import Footer from 'components/footer';
import ScrollToTop from 'components/common/ScrollToTop';
import Notices from 'components/notices';
// routes
import Screens from 'screens';
import BugProvider from 'providers/BugProvider';

const App = () => {

  return (
    <BugProvider>
      <RequestFlagsProvider>
        <ApolloClientProvider>
          <AppProvider>
            <div className="min-h-screen flex flex-col justify-start">
              <NavBar />
              <Notices/>
              <Screens/>
              <Footer />
            </div>
            <ScrollToTop/>
          </AppProvider>
        </ApolloClientProvider>
      </RequestFlagsProvider>
    </BugProvider>
  );
};

export default App;
