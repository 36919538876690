import React, { useState } from 'react';
import { PaddedPage } from 'components/common/Layout';
import { Input, Label } from 'components/inputs';
import { ButtonPrimary } from 'components/common/Button';
import { Link } from 'react-router-dom';
import { useMutation, gql } from "@apollo/client";

const CREATE_PASSWORD_RESET = gql`
  mutation CreatePasswordReset($usernameOrEmail: String!) {
    createPasswordReset(usernameOrEmail: $usernameOrEmail) {
      message
    }
  }
`;

const ForgotPassword = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState();
  const [success, setSuccess] = useState(false);
  const [createPasswordResetMutation, { loading: createResetLoading }] = useMutation(CREATE_PASSWORD_RESET);

  const handleCreateReset = () => {
    createPasswordResetMutation({variables: {usernameOrEmail}})
    .then(res => {
      setSuccess(true);
    });
  }

  const valid = () => {
    return usernameOrEmail ? true : false;
  }

  return (
    <PaddedPage className="flex justify-center">
      <div className="flex flex-col justify-center gap-4 max-w-84">
      {
        success ?
        <>
          <h1>Success!</h1>
          <p>An email has been sent for you to reset your password</p>
        </>
        :
        <>
          <h1>Forgot your Password?</h1>
          <p>
            Give us your email or username and we will send you instructions by email.
          </p>
          <div className="flex flex-col text-left">
            <Label text="Username or Email" />
            <Input onChange={setUsernameOrEmail} />
          </div>
          <ButtonPrimary disabled={createResetLoading || !valid()} onClick={handleCreateReset}>Send Password Reset</ButtonPrimary>
        </>
      }
      </div>
    </PaddedPage>
  );
};

export default ForgotPassword;
