import React, {useContext, useEffect} from 'react';
import { FullWidthPage } from 'components/common/Layout';
import SurveyContext from 'contexts/SurveyContext';
import Documents from 'components/documents';

export default function FileDocuments() {
  const {setSurveyNav } = useContext(SurveyContext);
  useEffect(() => {setSurveyNav('documents')}, [setSurveyNav]);

  return (
    <FullWidthPage bottom>
      <Documents/>
    </FullWidthPage>
  )
}
