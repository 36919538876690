import React, {useContext, useState} from 'react'
import { FullWidthPage } from 'components/common/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faFileInvoiceDollar, faFileLines, faFolderOpen, faGear, faListCheck, faLock, faPaste } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import SurveyContext from 'contexts/SurveyContext';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import Input, { Checkbox, IconSelector } from 'components/inputs';
import AppContext from 'contexts/AppContext';
import LoadingText from 'components/loading/LoadingText';
import LoadingIcon from 'components/loading/LoadingIcon';

export default function SurveyPageNav({children}) {
  const [editNickname, setEditNickname] = useState(false);
  const { surveyId, fileId, surveyNav, file, updateFile, setShowPaths, loading } = useContext(SurveyContext);
  const {currentUser} = useContext(AppContext);
  const [showMenu, setShowMenu] = useState();
  const admin = ["admin", "super-admin"].includes(currentUser?.type);

  const handleNicknameChange = (nickname) => {
    updateFile({nickname}, true);
    setEditNickname(false);
  }
  const handleFileNumberChange = (fileNumber) => {
    updateFile({fileNumber}, true);
    setEditNickname(false);
  }
  const handleIconChange = (icon) => {
    updateFile({icon}, true);
    setEditNickname(false);
  }

  return (
    <>
      <div className="border-b md:mb-2 md:sticky md:-top-13 lg:-top-13 xl:-top-16 2xl:-top-2 bg-white z-30">
          <FullWidthPage className="flex flex-col gap-2 md:gap-4" top>
          <div className="flex justify-between">
              {
                (!file) ?
                  <h2 className="animate-pulse text-light-grey">
                    <LoadingIcon/>&nbsp;
                    <span className="text-primary"> <LoadingText/></span>
                    &nbsp;/&nbsp;
                    <LoadingText/>
                  </h2>
                :
                <h2 className="flex items-center">
                  <div className="inline">{editNickname ? <IconSelector small initialValue={file.icon} onChange={handleIconChange}/> : file.icon}</div>&nbsp;
                  {editNickname ? <span className="text-primary">#<Input className="text-primary" valueOverride={file.fileNumber} onBlur={handleFileNumberChange}/></span>: <span className="text-primary">#{file.fileNumber}</span>}
                  &nbsp;/&nbsp;
                  {editNickname ? <Input valueOverride={file.nickname} onBlur={handleNicknameChange}/> : file.nickname}
                  <sup><FontAwesomeIcon onClick={() => setEditNickname(!editNickname)}className="ml-1 text-xs cursor-pointer"icon={faPenToSquare}/></sup>
                </h2>
              }
              {
                admin &&
                <div className="gap-1 hidden md:flex">
                  <FontAwesomeIcon icon={faLock} />
                  <Checkbox text="show paths" onChange={setShowPaths}/>
                </div>
              }
          </div>
          <div className="hidden md:flex flex-col md:flex-row gap-2 md:gap-4">
              <Link className={`pb-3 text-sm no-underline ${surveyNav === 'survey' ? 'border-b border-dark-grey' : 'text-med-grey'}`} to={`/files/${surveyId}/${fileId}/1`}>
                <FontAwesomeIcon icon={faPaste} />
                &nbsp;Survey
              </Link>
              <Link className={`pb-3 text-sm no-underline ${surveyNav === 'summary' ? 'border-b border-dark-grey' : 'text-med-grey'}`} to={`/files/${surveyId}/${fileId}/summary`}>
                <FontAwesomeIcon icon={faFileLines} />
                &nbsp;Summary
              </Link>
              <Link className={`pb-3 text-sm no-underline ${surveyNav === 'documents' ? 'border-b border-dark-grey' : 'text-med-grey'}`} to={`/files/${surveyId}/${fileId}/documents`}>
                <FontAwesomeIcon icon={faFolderOpen} />
                &nbsp;Documents
              </Link>
              <Link className={`pb-3 text-sm no-underline ${surveyNav === 'checklist' ? 'border-b border-dark-grey' : 'text-med-grey'}`} to={`/files/${surveyId}/${fileId}/checklist`}>
                <FontAwesomeIcon icon={faListCheck} />
                &nbsp;Checklist
              </Link>
              <Link className={`pb-3 text-sm no-underline flex items-center md:justify-center content-center ${surveyNav === 'trust-account' ? 'border-b border-dark-grey' : 'text-med-grey'}`} to={`/files/${surveyId}/${fileId}/trust-account`}>
                <FontAwesomeIcon icon={faFileInvoiceDollar} />
                &nbsp;Trust Account&nbsp;<div className="text-xxs font-bold leading-3 rounded bg-green text-white px-1">BETA</div>
              </Link>
          </div>
          <div onClick={() => setShowMenu(!showMenu)} className="flex md:hidden flex-col md:flex-row md:gap-4">
              <Link className={`py-2 flex justify-between w-full items-center px-1 text-sm no-underline ${surveyNav === 'survey' ? 'order-first border-b border-dark-grey' : `hover:text-dark-grey bg-light-grey text-med-grey ${showMenu ? '' : 'hidden'}`}`} to={`/files/${surveyId}/${fileId}/1`}>
                <div>
                  <FontAwesomeIcon icon={faPaste} />
                  &nbsp;Survey
                </div>
                {surveyNav === 'survey'  && <FontAwesomeIcon onClick={(e) => e.stopPropagation() || e.preventDefault() || setShowMenu(!showMenu)} icon={faBars}/>}
              </Link>
              <Link className={`py-2 flex justify-between w-full items-center px-1 text-sm no-underline ${surveyNav === 'summary' ? 'order-first border-b border-dark-grey' : `hover:text-dark-grey bg-light-grey text-med-grey ${showMenu ? '' : 'hidden'}`}`} to={`/files/${surveyId}/${fileId}/summary`}>
                <div>
                  <FontAwesomeIcon icon={faFileLines} />
                  &nbsp;Summary
                </div>
                {surveyNav === 'summary'  && <FontAwesomeIcon icon={faBars}/>}
              </Link>
              <Link className={`py-2 flex justify-between w-full items-center px-1 text-sm no-underline ${surveyNav === 'documents' ? 'order-first border-b border-dark-grey' : `hover:text-dark-grey bg-light-grey text-med-grey ${showMenu ? '' : 'hidden'}`}`} to={`/files/${surveyId}/${fileId}/documents`}>
                <div>
                  <FontAwesomeIcon icon={faFolderOpen} />
                  &nbsp;Documents
                </div>
                {surveyNav === 'documents'  && <FontAwesomeIcon icon={faBars}/>}
              </Link>
              <Link className={`py-2 flex justify-between w-full items-center px-1 text-sm no-underline ${surveyNav === 'checklist' ? 'order-first border-b border-dark-grey' : `hover:text-dark-grey bg-light-grey text-med-grey ${showMenu ? '' : 'hidden'}`}`} to={`/files/${surveyId}/${fileId}/checklist`}>
                <div>
                  <FontAwesomeIcon icon={faListCheck} />
                  &nbsp;Checklist
                </div>
                {surveyNav === 'checklist'  && <FontAwesomeIcon icon={faBars}/>}
              </Link>
              <Link className={`py-2 flex justify-between w-full items-center px-1 text-sm no-underline ${surveyNav === 'trust-account' ? 'order-first border-b border-dark-grey' : `hover:text-dark-grey bg-light-grey text-med-grey ${showMenu ? '' : 'hidden'}`}`} to={`/files/${surveyId}/${fileId}/trust-account`}>
                <div className="flex items-center md:justify-center content-center">
                  <FontAwesomeIcon icon={faFileInvoiceDollar} />
                  &nbsp;Trust Account&nbsp;<div className="text-xxs font-bold leading-3 rounded bg-green text-white px-1">BETA</div>
                </div>
                {surveyNav === 'trust-account' && <FontAwesomeIcon icon={faBars}/>}
              </Link>
          </div>
          </FullWidthPage>
      </div>
      {children}
    </>
  )
}
