import Businesses from './Businesses';
import Business from './Business';
import React from 'react';
import { Route } from 'react-router-dom';
import CreateBusiness from './CreateBusiness';
import AppAdminRoute from 'components/routes/AppAdminRoute';

const routes = [
  <Route key="new" path="businesses/new" element={<AppAdminRoute><CreateBusiness/></AppAdminRoute>} />,
  <Route key="business" path="businesses/:id" element={<AppAdminRoute><Business/></AppAdminRoute>} />,
  <Route key="businesses" path="businesses" element={<AppAdminRoute><Businesses/></AppAdminRoute>} />,
];

export default routes;
