import React, {useContext, useEffect, useState, useMemo, useRef, useCallback} from 'react';
import {getSurveyValue, getCalculatedValue, pathMaker} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import Input from 'components/inputs/Input';
import _ from "lodash";
import ButtonPrimary from 'components/common/Button';
import SurveyList from './SurveyList';
import { List } from 'components/inputs';
import ParentContext from 'contexts/ParentContext';
import { softSubset } from 'helpers/general';
const CategorizerInput = ({ 
    label, 
    hideLabel, 
    description,
    item,
    questionKey,
    mapping,
    options
}) => {
    const { file, updateAnswers, updateCalculation } = useContext(SurveyContext);
    const {prevRows, parentPath, subscribeCleanup, row} = useContext(ParentContext);
    const [surveyValue, setSurveyValue] = useState();
    const [mappedLookup, setMappedLookup] = useState();
    const [cachedDestination, setCachedDestination] = useState();
    const [cachedMapped, setCachedMapped] = useState();
    const [skipDestinationUpdate, setSkipDestinationUpdate] = useState();
    const [skipSourceUpdate, setSkipSourceUpdate] = useState();

    const fileRef = useRef();
    fileRef.current = file;

    const path = useMemo(() => {
        return pathMaker(questionKey, parentPath, row);
    }, [questionKey, parentPath, row]);

    const fileAnswers = useMemo(() => {
        return file && file.answers;
    }, [file]);

    // cache surveyValue
    useEffect(() => {
        let value = fileAnswers && getSurveyValue(fileAnswers, path);
        if (!_.isEqual(value, surveyValue)) {
            setSurveyValue(value);
        } else {
            if (value) {
                let newCachedDestination = getSurveyValue(fileAnswers, surveyValue.value);
                if (!_.isEqual(newCachedDestination, cachedDestination?.value)) {
                    setCachedDestination({key: surveyValue.value, value: newCachedDestination});
                } 
            } else {
                setCachedDestination();
            }
        }
    }, [fileAnswers, path]);

    // cache surveyValueKey
    let surveyValueKey = useMemo(() => {
        return (surveyValue && (surveyValue.name + surveyValue.value) )|| '';
    }, [surveyValue]);
    
    // cache mappedLookup
    // store categorize options in map for fast lookup
    useEffect(() => {
        let newMappedLookup = {};
        newMappedLookup.undefined = getCalculatedValue(file, mapping, prevRows);
        for (let option of options) {
            if (option.mapping) {
                newMappedLookup[option.mapping] = getCalculatedValue(file, option.mapping, prevRows);
            }
        }
        // value of categorizor item has been changed update value in destination
        if (!_.isEqual(newMappedLookup, mappedLookup)) {
            setMappedLookup(newMappedLookup);
            mappedLookup && surveyValue && updateDestination(surveyValue, mappedLookup[surveyValue.mapping], newMappedLookup[surveyValue.mapping]);
        }
    }, [fileAnswers, mapping, options, prevRows]);

    useEffect(() => {
        // Trigger on: categorize value change
        // check if updating destination is needed
        if (surveyValue) {
            let destination = getSurveyValue(fileAnswers, surveyValue.value);
            if (!_.isEqual(destination, cachedDestination?.value)) {
                setCachedDestination({key: surveyValue.value, value: destination});
            }
            if(!(destination || []).find(elem => softSubset(elem, mappedLookup[surveyValue.mapping]))) {
                let oldMapped = null;
                // if mapped and survey value changed at same time (by template) 
                // need to pass old mapped so it is removed properly
                // if (softSubset(cachedMapped, mappedLookup[surveyValue.mapping], true)) {
                    //     oldMapped = surveyValue.mapped;
                    // }
                    // pushing value to destination
                // console.log("update destination 1");
                setSkipSourceUpdate(true);
                updateDestination(surveyValue, oldMapped);
            };
        } else if (mappedLookup && !skipDestinationUpdate) {
            // console.log("update destination 2");
            setCachedDestination();
            updateDestination();
        }
        setSkipDestinationUpdate();
    }, [surveyValueKey]);

    useEffect(() => {
        // remove dropdown seleciton when value removed from destination
        // if cachedDestination is destination of current selection & destination does not have mapping remove selection
        if (!skipSourceUpdate && surveyValue && (surveyValue.value === cachedDestination?.key)) {
            if (Array.isArray(cachedDestination.value)){
                if(!cachedDestination.value?.find(elem => softSubset(elem, mappedLookup[surveyValue.mapping]))) {
                    // console.log("remove selection deleted elsewhere");
                    setValue(null, true);
                    setSkipDestinationUpdate(true);
                };
            }
        }
        setSkipSourceUpdate();
    }, [cachedDestination]);

    // newselect: name+value, 
    const updateDestination = useCallback((newSelect, oldMapped, newMapped) => {
        // new select {name, value, mapping}
        updateAnswers(null, null, true, true, (current) => {
            let newPath = newSelect && newSelect.value;
            let newDestination;
            let paths = [];
            let values = [];
            if (newSelect) newDestination = _.cloneDeep(getSurveyValue(current.answers, newPath)) || [];
            for (let option of options) {
                // remove old value
                let oldDestination = (newPath === option.value) ? newDestination : _.cloneDeep(getSurveyValue(current.answers, option.value)) || [];
                let filteredOldDestination = oldDestination.filter(val => !softSubset(val, oldMapped || mappedLookup[option.mapping], true));
                if ((newPath === option.value) && Array.isArray(newDestination)) {
                    newMapped = newMapped || mappedLookup[newSelect.mapping];
                    filteredOldDestination.push(newMapped);
                }
                if ((newPath === option.value) || oldDestination.length !== filteredOldDestination.length) {
                    paths.push(option.value);
                    values.push(filteredOldDestination);
                }
            }
            return [paths, values];
        });
    }, [mappedLookup, updateAnswers, options]);

    const setValue = useCallback((val, push = true) => {
        updateAnswers(path, val && {...val, mapped: mappedLookup[val.mapping]}, push);
    }, [path, updateAnswers]);

    useEffect(() => {
        if (subscribeCleanup) {
            subscribeCleanup(`(${prevRows.at(-1)})${questionKey}`,() => {
                setValue(null, false);
                // console.log("update destination 3");
                updateDestination();
            })
            return () => {
                subscribeCleanup(`(${prevRows.at(-1)})${questionKey}`);
            }
        }
    }, [subscribeCleanup, setValue, prevRows, questionKey]);

    return (
        <div className="flex flex-col justify-between input grow-0 items-start">
            {!hideLabel && label && (
                description ? 
                    <label>
                        {label}
                        <Tooltip message={description} left/>
                    </label>
                :
                    <label>
                        {label}
                    </label>
            )}
            <List 
                options={[{name: '', value: ''}, ...options.map(option => ({name: option.name, value: option.name+option.value}))]}  
                valueOverride={surveyValueKey}
                readOnly={!options.find(option => option.value)}
                onChange={(val) => setValue(options.find(opt => opt.name+opt.value == val), false)} 
            />
        </div>
    )
};

export default CategorizerInput;