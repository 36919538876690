import React, {useContext, useState, useMemo, useCallback, useRef} from 'react';
import {getCalculatedValue, getSurveyValue, pathMaker} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import ParentContext from 'contexts/ParentContext';
import _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClipboard, faCopy } from '@fortawesome/free-regular-svg-icons';
import { pdfjs } from 'react-pdf';
import useOcr from 'hooks/useOCR';
import Loading from 'components/common/Loading';
import { faWarning } from '@fortawesome/free-solid-svg-icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${'3.6'}/build/pdf.worker.min.js`;
const MLSContractUpload = ({ 
  type, 
  questionKey, 
  title, 
  hideLabel, 
  hidden, 
  required,
  calculatedLabel,
  readOnly, 
  info,
  formStyles,
  description,
  value
}) => {
  const { file, updateAnswers, updateErrors, updateTotalsAndCalculations } = useContext(SurveyContext);
  const {row, parentPath, prevRows} = useContext(ParentContext);
  const path = useMemo(() => {
    return pathMaker(questionKey, parentPath, row);
  }, [questionKey, parentPath, row]);
  const [pdfFile, setPdfFile] = useState();
  const [pdfText, setPdfText] = useState();
  const [parsedData, setParsedData] = useState();
  const {parseDocument} = useOcr();
  const [loading, setLoading] = useState();

  const calculatedLabelValue = useMemo(() => {
      return calculatedLabel && file && file.answers && getCalculatedValue(file, calculatedLabel, prevRows);
  }, [calculatedLabel]);

  const surveyValue = useMemo(() => {
    return file && getSurveyValue(file.answers, path);
  }, [file, path]);

  const existingData = useMemo(() => {
    return [
      getSurveyValue(file.answers, 'properties[0].property.addressDetails.newPurchaseAddress'),
      getSurveyValue(file.answers, 'properties[0].property.addressDetails.newPurchaseProvince'),
      getSurveyValue(file.answers, 'properties[0].property.addressDetails.newPurchasePostalCode'),
      getSurveyValue(file.answers, 'properties[0].property.addressDetails.newPurchaseCity'),
      getSurveyValue(file.answers, 'properties[0].property.pid'),
      getSurveyValue(file.answers, 'properties[0].property.purchasePriceTotal'),
      getSurveyValue(file.answers, 'deposit'),
      getSurveyValue(file.answers, 'completionDate'),
      getSurveyValue(file.answers, 'possessionDate'),
      getSurveyValue(file.answers, 'adjustmentDate'),
      getSurveyValue(file.answers, 'buyerGroups'),
      getSurveyValue(file.answers, 'sellers'),
      getSurveyValue(file.answers, 'typeOfSellers'),
    ]
  }, [file]);

  const onFileChange = (event) => {
    setLoading(true);
		setPdfFile(event.target.files[0]);
    // parsePdf(URL.createObjectURL(event.target.files[0]));
    parsePdfOCR(URL.createObjectURL(event.target.files[0]));
	}

  const parsePdfOCR = async (doc) => {
    let totalText = await parseDocument(doc);
    setPdfText(totalText);
    textToData(totalText);
  }

  const parsePdf = (doc) => {
    const loadingTask = pdfjs.getDocument(doc)
    loadingTask.promise
      .then(pdf => {
        var maxPages = pdf._pdfInfo.numPages;
        var countPromises = []; // collecting all page promises
        for (let j = 1; j <= maxPages; j++) {
          countPromises.push(pdf.getPage(j))
        }
        return Promise.all(countPromises)
      })
      .then(pages => {
        let extractedText = '';
        let contentPromises = [];
        for (const page of pages) {
          contentPromises.push(page.getTextContent());
        }
        return Promise.all(contentPromises);
      })
      .then(pages => {
        let totalText = '';
        for(let page of pages) {
          let pageItems = _.orderBy(page.items.map(el => ({ 'x': el.transform[4], 'y': el.transform[5], 't': el.str })), ['y','x'], ['desc', 'asc'] );
          const pageText = pageItems.map((item) => item.t.toLowerCase()).join(' ');
          totalText += '\\n\\n' + pageText;
        }
        setPdfText(totalText);
        textToData(totalText);
      })
      .catch(err => {
        console.log("err", err);
      })
  }

  const textToData = (text) => {
    let data = {};
    // property address
    let index = text.indexOf('property :');
    let indexEnd = text.indexOf('unit no .');
    data.newPurchaseAddress = _.capitalize(text.substring(index + 10, indexEnd)?.trim() || ''); 
    data.addressDetails = {};
    data.addressDetails.newPurchaseProvince = 'British Columbia';
    index = text.indexOf('city / town / municipality');
    let group = text.substring(indexEnd -26, index)?.trim();  
    data.addressDetails.newPurchasePostalCode = group.slice(-8).trim()?.toUpperCase();
    indexEnd = text.indexOf('address of property');
    data.addressDetails.newPurchaseCity = _.capitalize(text.substring(indexEnd + 19, index - 10)?.trim() || ''); 
    // PID
    index = text.indexOf('postal code');
    data.pid = text.substring(index + 11 , index + 25)?.trim()?.split(" ")?.[0]; 
    // Purchase Price
    index = text.indexOf('the purchase price of the property will be');
    indexEnd = text.indexOf('( purchase price )');
    data.purchasePriceTotal = Number(text.substring(index + 42 , indexEnd).replaceAll('\n', '').split(' ').find(item => item && Number(item.replaceAll(',', '')))?.trim().replaceAll(',', '')); 
    // Deposit
    index = text.indexOf('a deposit of');
    index = index > 0 ? index : text.indexOf('deposit of $');

    indexEnd = text.indexOf('which will form');
    data.deposit = Number(text.substring(index + 12 , indexEnd).split(' ').find(item => Number(item.replaceAll(',', '').replace('$', '')))?.trim().replaceAll(',', '')); 
    // Completion Date
    index = text.indexOf('the sale will be completed on');
    indexEnd = text.indexOf('( completion date )');
    data.completionDate = new Date(text.substring(index + 29 , indexEnd)?.replace('yr .', '')?.replace('yr ', '')?.replace('nd', '')?.replace('th', '').replace(',', '').replaceAll('_', '').trim());
    if (!isNaN(data.completionDate)) {
      data.completionDate = data.completionDate.toISOString().split('T')[0]
    } 
    // Possession Date
    index = text.indexOf('possession : the');
    indexEnd = text.indexOf('( possession date )');
    group = text.substring(index + 16 , indexEnd);
    data.possessionDate = new Date(group.slice(group.indexOf(' on \n') + 6)?.replace('yr .', '')?.replace('yr ', '')?.replace('nd', '')?.replace('th', '').replace(',', '').replaceAll('_', '').trim());
    if (!isNaN(data.possessionDate)) {
      data.possessionDate = data.possessionDate.toISOString().split('T')[0]
    }  
    // Adjustment Date
    index = text.indexOf('nature will be made as of');
    indexEnd = text.indexOf('( adjustment date )');
    data.adjustmentDate = new Date(text.substring(index + 25 , indexEnd)?.replace('yr .', '')?.replace('yr ', '')?.replace('nd', '')?.replace('th', '').replace(',', '').trim());
    if (!isNaN(data.adjustmentDate)) {
      data.adjustmentDate = data.adjustmentDate.toISOString().split('T')[0]
    } 
    // buyer names/address'
    // Seller 1 Name
    let sellerBuyerBlock = text.slice(text.indexOf('prepared by :'));
    let sellersFirst = sellerBuyerBlock.indexOf('seller :') < sellerBuyerBlock.indexOf('buyer :');
    let name = sellerBuyerBlock.match(/(?<=seller :)(.*)(?=\n)/)?.[0]?.trim().split("buyer :")[0]?.trim().split(" ");
    data.sellerOne = {};
    data.sellerOne.firstName = _.capitalize(name?.slice(0, -1)?.join(' '));
    data.sellerOne.lastName = _.capitalize(name?.at(-1));
    // Buyer 1 Name
    name = sellerBuyerBlock.match(/(?<=buyer :)(.*)(?=\n)/)?.[0]?.trim().split("seller :")[0]?.trim().split(" ");
    data.buyerOne = {};
    data.buyerOne.firstName = _.capitalize(name?.[0]);
    data.buyerOne.lastName = _.capitalize(name?.[1]);
    // Seller 2 Name
    data.sellerTwo = {};
    sellerBuyerBlock = sellersFirst ? sellerBuyerBlock.slice(sellerBuyerBlock.indexOf('buyer :') + 7) : sellerBuyerBlock.slice(sellerBuyerBlock.indexOf('seller :') + 8);
    name = sellerBuyerBlock.match(/(?<=seller :)(.*)(?=\n)/)?.[0]?.trim().split("buyer :")[0]?.trim().split(" ");
    data.sellerTwo.firstName = _.capitalize(name?.slice(0, -1)?.join(' '));
    data.sellerTwo.lastName = _.capitalize(name?.at(-1));
    // Buyer 2 Name
    data.buyerTwo = {};
    name = sellerBuyerBlock.match(/(?<=buyer :)(.*)(?=\n)/)?.[0]?.trim().split("seller :")[0]?.trim().split(" ");
    data.buyerTwo.firstName = _.capitalize(name?.slice(0, -1)?.join(' '));
    data.buyerTwo.lastName = _.capitalize(name?.at(-1));

    data.sellerAddress = {province: 'British Columbia'};
    data.buyerAddress = {province: 'British Columbia'};
    // Seller addressLineOne
    data.sellerAddress.addressLineOne = _.capitalize(sellerBuyerBlock.match(/(?<=address :)(.*)(?=address :)/)?.[0]?.trim() || '');
    // buyer addressLineOne
    sellerBuyerBlock = sellerBuyerBlock.slice(sellerBuyerBlock.indexOf('address :') + 8);
    let addressLineTwo = sellerBuyerBlock.match(/(?<=\n)(.*)(?=\n)/)?.[0]?.trim()
    data.buyerAddress.addressLineOne = _.capitalize(sellerBuyerBlock.match(/(?<=address :)(.*)(?=\n)/)?.[0]?.trim() || '');
    sellerBuyerBlock = sellerBuyerBlock.slice(sellerBuyerBlock.indexOf('\n') + 2);
    // seller city
    index = 0;
    indexEnd = sellerBuyerBlock.indexOf('pc :');
    data.sellerAddress.city = _.capitalize((sellerBuyerBlock.substring(index , indexEnd)?.trim().split(" ")?.slice(0,2)?.join(' '))?.replace(',', '') || ''); 
    // seller postal code
    data.sellerAddress.postalCode = sellerBuyerBlock.match(/(?<=pc :)(.*)(?=pc :)/)?.[0]?.slice(0,7)?.trim()?.toUpperCase();
    // buyer city
    sellerBuyerBlock = sellerBuyerBlock.slice(indexEnd + 4)?.trim();
    indexEnd = sellerBuyerBlock.indexOf('pc :');
    index = 0;
    data.buyerAddress.city = _.capitalize((sellerBuyerBlock.substring(Math.min(index + 7, indexEnd), indexEnd)?.trim() || addressLineTwo.split(" ")?.slice(-2)?.join(' '))?.replace(',', '') || ''); 
    // buyer postal code
    data.buyerAddress.postalCode = sellerBuyerBlock.match(/(?<=pc :)(.*)(?=\n)/)?.[0]?.trim().toUpperCase(); 

    if (!sellersFirst) {
      let buyerAddress = {...data.buyerAddress};
      data.buyerAddress = data.sellerAddress;
      data.sellerAddress = buyerAddress;
    }
    
    let buyers = [{...data.buyerOne, ...data.buyerAddress}];
    if (data.buyerTwo.firstName) buyers.push({...data.buyerTwo, ...data.buyerAddress});
    let sellers = [{...data.sellerOne, ...data.sellerAddress}];
    if (data.sellerTwo.firstName) sellers.push({...data.sellerTwo, ...data.sellerAddress});

    updateAnswers(
      [
        path,
        'properties[0].property.addressDetails.newPurchaseAddress',
        'properties[0].property.addressDetails.newPurchaseProvince',
        'properties[0].property.addressDetails.newPurchasePostalCode',
        'properties[0].property.addressDetails.newPurchaseCity',
        'properties[0].property.pid',
        'properties[0].property.purchasePriceTotal',
        'deposit',
        'completionDate',
        'possessionDate',
        'adjustmentDate',
        'buyerGroups',
        'sellers',
        'typeOfSellers',
      ],
      [
        data,
        existingData[0] || data.newPurchaseAddress,
        existingData[1] || data.addressDetails.newPurchaseProvince,
        existingData[2] || data.addressDetails.newPurchasePostalCode,
        existingData[3] || data.addressDetails.newPurchaseCity,
        existingData[4] || data.pid,
        existingData[5] || data.purchasePriceTotal,
        existingData[6] || data.deposit,
        existingData[7] || data.completionDate,
        existingData[8] || data.possessionDate,
        existingData[9] || data.adjustmentDate,
        (existingData[10]?.length > 0) ? existingData[10] : [
          {group: {
            typeOfBuyers: 'individual',
            buyers: buyers
          }}
        ],
        (existingData[11]?.length > 0) ? existingData[11] : null,
        (existingData[11]?.length > 0) ? existingData[12] : 'individual'
      ]
    );
    setLoading(false);
    setParsedData(data);
  }

  return (
      <div className={`flex flex-col justify-between input py-4 gap-1 grow-0 ${hidden ? 'hidden' : ''}`}>
          {!hideLabel && (title || calculatedLabelValue) && (
              description ? 
                  <label className="flex items-center gap-1">
                      {title || calculatedLabelValue}
                      <Tooltip message={description} />
                      <div className="text-xxs font-bold leading-3 rounded bg-green text-white px-1">BETA</div>
                  </label>
              :
                  <label className="flex items-center gap-1">
                      {title || calculatedLabelValue}
                      <div className="text-xxs font-bold leading-3 rounded bg-green text-white px-1">BETA</div>
                  </label>
          )}
          <div className="border border-yellow rounded text-yellow flex p-2 items-center gap-2 bg-light-yellow">
            <FontAwesomeIcon icon={faWarning}/>
            <p className="text-dark-yellow">
              The document scan works best with clear PDFs. 
              If your MLS contract has handwriting or artifacts from scanning it can lead to incorrect data.
              <br/>
              Make sure to double-check all data pulled from the file.
            </p>
          </div>
          <div className="flex gap-2 items-center">
            <input name="file" id="mlsFile" className="hidden" type="file" onChange={onFileChange} />
            <label className="border-2 border-light-grey font-semibold rounded bg-white hover:no-underline cursor-pointer px-2 py-1" for="mlsFile">{loading ? <Loading/> : 'Choose MLS PDF'}</label>
            {pdfFile && <h3 className="text-green">{pdfFile.name} <FontAwesomeIcon icon={faCheckCircle}/></h3>}
          </div>
          {surveyValue && <div className="flex flex-col gap-3 rounded bg-white p-2">
            <div>
              <h3>PDF Data</h3>
              <hr></hr>
              </div>
              {
                surveyValue && Object.entries(surveyValue).map(([key, value]) => <p>
                  <span className="font-medium">{_.startCase(key)}: </span> 
                  {(typeof value === 'object') ?
                    <div className="border p-2 rounded flex flex-col gap-2">
                      {
                        Object.entries(value || {}).map(([key, value]) => (
                          <p>
                            <span className="font-medium">{_.startCase(key)}: </span> 
                            <i>{value === '' ? 'no' : value?.toString()}</i>
                          </p>
                        ))
                      }
                    </div>
                    :
                    <i>{value === '' ? 'no' : value}</i>
                  }
                </p>)
              }
            </div>
          }
      </div>
  )
};

export default MLSContractUpload;