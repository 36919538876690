import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import ButtonPrimary from './Button'
import Loading from './Loading';
import Tooltip from './Tooltip';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

export default function Search({filters, setFilters, onClick, loading, toolTipText}) {
  let [search, setSearch] = useState(filters.search);
  let handleClick = () => {
    console.log('test', search);
    onClick({...filters, search})
  }
  let handleChange = (e) => {
    setSearch(e.target.value);
    setFilters && setFilters({...filters, search: e.target.value});
  }
  return (
    <div className="relative flex gap-2">
      <input className="rounded border p-1 pl-8 border-2" value={search} onChange={handleChange}/>
      <FontAwesomeIcon icon={faSearch} className="absolute left-2 top-2" />
      {toolTipText &&
        <Tooltip
          icon={faQuestionCircle} 
          className={"self-start -ml-2 mt-1"}
          message={toolTipText}
        /> 
      }
      {onClick && <ButtonPrimary disabled={loading} onClick={handleClick}>{loading ? <Loading/> : 'Search'}</ButtonPrimary>}
    </div>
  )
}
