import Documents from './Documents';
import Document from './Document';
import React from 'react';
import { Route, Outlet } from 'react-router-dom';
import CreateDocument from './CreateDocument';
import AppAdminRoute from 'components/routes/AppAdminRoute';

const routes = [
  <Route key="new" path="documents/new" element={<AppAdminRoute><CreateDocument/></AppAdminRoute>} />,
  <Route key="document" path="documents/:id" element={<AppAdminRoute><Document/></AppAdminRoute>} />,
  <Route key="documents" path="documents" element={<AppAdminRoute><Documents/></AppAdminRoute>} />,

];

export default routes;
