import React from 'react'
import ltsaLogo from "assets/images/survey/ltsalogo.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faFilePdf } from '@fortawesome/free-regular-svg-icons';

export default function DocumentOption({document, onClick}) {
  return (
    <div className="shadow-md p-2 border hover:bg-light-green rounded flex gap-2 items-center px-2 py-1 bg-white cursor-pointer  w-72" onClick={() => onClick(document)}>
      {
        document.firmId &&
        <FontAwesomeIcon icon={faBuilding} />
      }
      {
        document.type == "ltsa" &&
        <img src={ltsaLogo} alt="LTSA logo" className="h-4"/>
      }
      {
        ["mortgage", "pdf"].includes(document.type) &&
        <FontAwesomeIcon className="" icon={faFilePdf} />
      }
      <span className=" tight font-medium text-sm">{document.name}</span>
    </div>
  )
}
