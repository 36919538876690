import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUser, faPhone, faEnvelope, faLocationDot, faCakeCandles, faBriefcase, faEarthAmericas } from '@fortawesome/free-solid-svg-icons';
import AddClient from 'components/surveyInputs/AddClient';
import { faIdCard, faPenToSquare } from '@fortawesome/free-regular-svg-icons';

export default function Client({client, onDeselect, children, onUpdate, mini, copyAddress, copyPropertyAddress, type, firmId}) {
  const [edit, setEdit] = useState();

  const onCreate = (value) => {
    onUpdate(value);
    setEdit(false);
  }

  return (
    edit ?
      <AddClient firmId={firmId} type={type} copyAddress={copyAddress} copyPropertyAddress={copyPropertyAddress} edit={client} onDeselect={onDeselect} close={() => setEdit(false)} onCreate={onCreate}/>
    :
      <div className={`rounded relative flex flex-col ${mini ? 'text-xs' : ''}`}>
        <FontAwesomeIcon onClick={() => setEdit(true)} className={`absolute top-1 right-5 cursor-pointer text-white`} icon={faPenToSquare}/>
        {onDeselect && <FontAwesomeIcon onClick={onDeselect} className={`absolute top-1 right-1 cursor-pointer text-white`} icon={faTimes}/>}
        <div className={`flex items-center gap-2 p-3 pr-4 bg-med-grey ${mini ? 'rounded' : 'rounded-t'} text-white pr-10`}>
            <FontAwesomeIcon className={client?.linkedUser ? 'text-green' : ''} icon={faUser}/>
            <h3 className="font-bold text-white">{client.title} {client.firstName} {client.lastName}</h3>
        </div>
        <hr></hr>
        {
          !mini &&
          <div className="flex flex-col gap-2 p-3 border bg-white rounded-b border-med-grey">
            {(client.email || client.phoneNumber) && <div className="flex gap-2">
              {client.email && <small className="font-bold"><FontAwesomeIcon className={``} icon={faEnvelope}/> {client.email}</small>}
              {client.phoneNumber && <small className="font-bold"><FontAwesomeIcon className={``} icon={faPhone}/> {client.phoneNumber}</small>}
            </div>}
            {client.country && <div className="flex gap-2">
              <small className="font-bold"><FontAwesomeIcon className={``} icon={faEarthAmericas}/> {client.country}</small>
            </div>}
            {client.addressLineOne && <div className="flex gap-2">
              <small className="font-bold"><FontAwesomeIcon className={``} icon={faLocationDot}/> {client.addressLineOne}, {client.city}</small>
            </div>}
            {client.dateOfBirth && <div className="flex gap-2">
              <small className="font-bold"><FontAwesomeIcon className={``} icon={faCakeCandles}/> {client.dateOfBirth}</small>
            </div>}
            {client.occupation && <div className="flex gap-2">
              <small className="font-bold"><FontAwesomeIcon className={``} icon={faBriefcase}/> {client.occupation}</small>
            </div>}
            {client.sin && <div className="flex gap-2">
              <small className="font-bold"><FontAwesomeIcon className={``} icon={faIdCard}/> SIN: {client.sin}</small>
            </div>}
            <div className="text-xs flex flex-col gap-4 mt-2">
              {children}
            </div>
          </div>
        }
      </div>
  )
}
