import React, { useState, useContext } from 'react';
import { PaddedPage } from 'components/common/Layout';
import { Input, Label } from 'components/inputs';
import { ButtonPrimary } from 'components/common/Button';
import { Link, useSearchParams } from 'react-router-dom';
import AppContext from 'contexts/AppContext';
import Message from 'components/common/Message';
import { useMutation, gql } from "@apollo/client";

const RESEND = gql`
  mutation ResendConfirmEmail($username: String!) {
    resendConfirmEmail(username: $username) {
      message
    }
  }
`;

const Login = ({inviteId}) => {
  const { login, loading } = useContext(AppContext);
  const [search] = useSearchParams();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [confirmSend, setConfirmSent] = useState();
  const [resendMutation, { loading:loadingNewConfirm }] = useMutation(RESEND);

  const handleLogin = (e) => {
    e.preventDefault();
    login(username, password);
  }

  const handleResendConfirm = () => {
    if (username) {
      resendMutation({variables: { username: username}})
        .then(res => {
          setConfirmSent(true);
        })
    }
  }

  return (
    <PaddedPage className="flex justify-center self-center">
      <form className="flex flex-col justify-center gap-4 max-w-84" onSubmit={handleLogin}>
        <h1>Login</h1>
        {!inviteId && 
          <p>
            Dont have an account? Sign Up <Link to="/sign-up">Here</Link>
          </p>
        }
        {search.get('confirm') == "true" &&
          <Message positive>
            Email Confirmed!
          </Message>
        }
        {search.get('confirm') == "false" &&
          <Message >
            {confirmSend ?
              "Confirmation email sent!"
            :
            <div className="flex flex-col items-center gap-2">
              Resend Confirmation Email?
              <ButtonPrimary disabled={!username || loadingNewConfirm} onClick={handleResendConfirm}>Resend</ButtonPrimary>
            </div>
            }
          </Message>
        }
        {search.get('reset') &&
          <Message positive>
            Password Reset!
            Login with your new credentials.
          </Message>
        }
        <div className="flex flex-col">
          <Label text="username" />
          <Input onChange={setUsername} />
        </div>
        <div className="flex flex-col">
          <Label text="password" />
          <Input type="password" onChange={setPassword} />
          <Link className="self-end text-sm" to="/forgot-password">Forgot Password?</Link>
        </div>
        <ButtonPrimary disabled={loading || !username || !password}>Submit</ButtonPrimary>
      </form>
    </PaddedPage>
  );
};

export default Login;
