import { useMutation } from '@apollo/client';
import { faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonPrimaryOutline, { ButtonPrimary } from 'components/common/Button'
import Loading from 'components/common/Loading';
import Input from 'components/inputs';
import Transparent from 'components/notices/Transparent'
import gql from 'graphql-tag';
import React, { useState } from 'react'
import { useNavigate } from 'react-router';

const DUPLICATE_FILE = gql`
  mutation DuplicateFile($fileId: String!, $surveyId: String!, $quantity: Int!, $pattern: String, $nickname: String) {
    duplicateFile(fileId: $fileId, surveyId: $surveyId, quantity: $quantity, pattern: $pattern, nickname: $nickname)
  }
`;

export default function DuplicateFile({file}) {
  const [open, setOpen] = useState();
  const [quantity, setQuantity] = useState(1);
  const [pattern, setPattern] = useState(file.fileNumber);
  const [groupName, setGroupName] = useState(file.nickname);
  const navigate = useNavigate();
  const [duplicateFile, { loading }] = useMutation(DUPLICATE_FILE);

  const close = () => {
    setOpen(false);
  }
  const confirm = () => {
    duplicateFile({variables: {fileId: file.id, surveyId: file.surveyId, quantity: Number(quantity), pattern, nickname: groupName}})
    .then((res) => {
      navigate("/files");
    });
  }

  return (
    <>
      {
        open &&
        <Transparent>
          <div onClick={close} className="absolute flex justify-center items-center w-full h-full">
            <div onClick={(e) => e.stopPropagation()} className="bg-white rounded border max-w-7/8 w-80 shadow-md">
              <div className={`flex justify-between relative px-4 py-2 bg-red`}>
                <h2 className="text-white">
                  <FontAwesomeIcon onClick={close} className="" icon={faTriangleExclamation} />
                  &nbsp;
                  Duplicate File?
                </h2>
                <FontAwesomeIcon onClick={close} className="text-white cursor-pointer absolute top-1 right-2" icon={faTimes} />
              </div>
              <div className="p-4 bg-whiter py-8 flex flex-col gap-2">
                <p>
                  This will duplicate file <i>#{file.fileNumber}</i>, doing so will copy all current file data creating x new files with the group nickname and following the same file number pattern.
                </p>
                <div className="flex flex-col">
                  <label>File Group Name</label>
                  <Input onChange={setGroupName} valueOverride={groupName}/>
                </div>
                <div className="flex flex-col">
                  <label>File Number Pattern</label>
                  <Input onChange={setPattern} valueOverride={pattern}/>
                </div>
                <div className="flex flex-col">
                  <label>Quantity</label>
                  <Input type="number" onChange={setQuantity} valueOverride={quantity}/>
                </div>
              </div>
              <div className="p-4 bg-whiter flex justify-end border-t gap-2 py-2">
                <ButtonPrimary onClick={close} >Close</ButtonPrimary>
                <ButtonPrimary className="border-red text-red" disabled={loading || !quantity} onClick={confirm} >{loading ? <Loading/> : 'Duplicate'}</ButtonPrimary>
              </div>
            </div>
          </div>
        </Transparent>
      }
      <ButtonPrimaryOutline className="text-sm" onClick={() => setOpen(true)}>Duplicate File</ButtonPrimaryOutline>
    </>
  )
}
