import React, {useEffect, useMemo, useState} from 'react'
import { getSurveyErrorMessages } from 'selectors/formSelectors';

export default function useSurveyErrors(pages, file) {
  const errorMessages = useMemo(() => {
    return (pages && file) ? getSurveyErrorMessages(pages, file) : {};
  }, [pages, file])

  return { errorMessages };
}
