import { gql, useMutation } from '@apollo/client';
import { faChevronDown, faChevronUp, faFileLines, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import { faPerson } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/common/Tooltip';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import MiniChecklist from 'components/survey/MiniChecklist';
import AppContext from 'contexts/AppContext';
import CalendarContext from 'contexts/CalendarContext';
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const DELETE_CALENDAR_ITEM = gql`
  mutation DeleteCalendarItem($id: String!) {
    deleteCalendarItem(id: $id) {
      id
    }
  }
`;

export default function CalendarItem({event}) {
  const {removeEvent, colorMode} = useContext(CalendarContext);
  const { currentUser } = useContext(AppContext);
  const {refetch} = useContext(CalendarContext);
  const [expand, setExpand] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteCalendarItemMutation, { loading }] = useMutation(DELETE_CALENDAR_ITEM);


  useEffect(() => {
    if (!event.fileId || !event.surveyId) {
      setExpand(false);
    }
  }, [event.fileId, event.surveyId]);

  const handleRemoveItem = () => {
    deleteCalendarItemMutation({variables: {id: event.id}})
    .then((res) => {
      removeEvent(event.id);
      setShowConfirm(false);
    });
  }

  return (
    <>
      {showConfirm && <ConfirmNotice
        title={`Are you sure?`}
        message={`This will delete the event from all calendars.`}
        onConfirm={handleRemoveItem}
        onClose={() => setShowConfirm(false)}
      /> }
      <div className="flex flex-col">
        <div 
          className={`rounded flex shadow-md hover:shadow-lg bg-white items-center w-full ${expand ? 'border border-dark-grey' : ''}`}
        > 
          <div 
            className="w-3 h-full rounded-l"
            style={{border: `1px solid ${event.colorOne}`, backgroundImage: `linear-gradient(${colorMode ? event.user.color : event.colorOne} ${(Number(event.percentage) || Number(event.percentage) === 0) ? 100 - event.percentage*100 : '100'}%, ${colorMode ? event.user.color : (event.colorTwo || event.colorOne)})`}}
          ></div>
          <div
            className={`flex p-2 pl-1 items-center justify-between gap-2 w-full`}
          >
            <div className="flex items-center gap-2">
              <p>{event.icon}</p> 
              <div className="relative -mt-1">
                <p className="font-bold">{event.name}</p>
                <span className="absolute text-primary text-xxs -bottom-3 font-bold whitespace-nowrap">#{event.tag}</span>
              </div> 
              <small>{event.subText}</small>
              {event.percentage ? <small className="text-xs font-bold">{Math.round(event.percentage*100)}%</small> : ''}
            </div>
            <div className="flex gap-2 items-center">
              {event.fileId &&<a target="_blank" rel="noreferrer" href={`files/${event.surveyId}/${event.fileId}/summary`}>
                <FontAwesomeIcon className="cursor-pointer" icon={faFileLines}/>
              </a>}
              {
                ["admin", "super-admin"].includes(currentUser.type) ?
                  <Link to={`/admin/users/${event.userId}`}>
                    <Tooltip 
                      icon={faUser} 
                      className={"self-start"}
                      large
                      color="dark-grey" 
                      message={<span>
                        {event.user?.username}
                      </span>}
                    /> 
                  </Link>
                :
                  <Tooltip 
                    icon={faUser} 
                    className={"self-start"}
                    large
                    color="dark-grey" 
                    message={<span>
                      {event.user.username}
                    </span>}
                  /> 
              }
              {event.fileId &&
                <FontAwesomeIcon className="cursor-pointer text-primary" onClick={() => setExpand(!expand)} icon={expand ? faChevronUp : faChevronDown}/>
              }
              {
                event.id &&
                <FontAwesomeIcon onClick={() => setShowConfirm(true)} className="cursor-pointer text-red" icon={faTimes}/>
              }
            </div>
          </div>
        </div>
        {expand &&
          <div className={`rounded-b p-2 pt-3 bg-light-light-grey border-dark-grey border-t-0 -mt-1 flex shadow-md border hover:shadow-lg w-full`}>
            <MiniChecklist phase={event.subText} surveyId={event.surveyId} fileId={event.fileId} refetch={refetch}/>
          </div>
        }
      </div>
    </>
  )
}
