import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding,faEnvelope, faList, faLocationDot, faUser } from '@fortawesome/free-solid-svg-icons';

// companyName,
// email,
// phoneNumber,
// fax,
// type,
// addressLineOne,
// addressLineTwo,
// businessId,
export default function BusinessLineItem({business}) {
  return (
    <div className={`rounded cursor-pointer flex justify-between border-l-8 border-light-grey shadow-md hover:shadow-lg p-2 items-center gap-2`}>
        <div className="flex items-center gap-2">
          <FontAwesomeIcon className="" icon={faBuilding} />
          <strong>{business.companyName}</strong>
          <div className="flex items-center gap-2 ml-4">
            {/* <small><FontAwesomeIcon className="" icon={faTag} /> {user.firstName}</small> */}
            <small><FontAwesomeIcon className="" icon={faLocationDot} /> {business.addressLineOne}</small>
          </div>
          <div className="flex items-center gap-2 ml-4">
            {/* <small><FontAwesomeIcon className="" icon={faTag} /> {user.firstName}</small> */}
            <small><FontAwesomeIcon className="" icon={faList} /> {business.type}</small>
          </div>
        </div>
        <div className="flex items-center gap-2">
          {
            business.firm &&
            <div className={`rounded px-2 border-2 border-primary text-sm text-primary font-bold`}>
              <FontAwesomeIcon className="text-xxs" icon={faBuilding}/> {business.firm.name}
            </div>
          }
          {
            business.user &&
            <div className={`rounded px-2 border-2 border-primary text-sm text-primary font-bold`}>
              <FontAwesomeIcon className="text-xxs" icon={faUser}/> {business.user.username}
            </div>
          }
          {
            business.public &&
            <div className={`rounded px-2 bg-primary text-sm text-white font-bold`}>
              public
            </div>
          }
        </div>
    </div>
  )
}
