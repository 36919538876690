import React from 'react';
import { Outlet, Route } from 'react-router-dom';
import All from './All';
import TAFReport from 'components/trustAccount/TAFReport';
import TrustAccountNav from './TrustAccountNav';
import TrustLiability from 'components/trustAccount/TrustLiability';
import TrustAccountList from 'components/trustAccount/TrustAccountList';
import FinalTrustReconciliation from 'components/trustAccount/FinalTrustReconciliation';
import OutstandingCheques from 'components/trustAccount/OutstandingCheques';
import ChequesCleared from 'components/trustAccount/ChequesCleared';
import GSTPSTReport from 'components/trustAccount/GSTPSTReport';

const routes = [
  <Route key="trustAccount" path="/trust-account" element={<TrustAccountNav><Outlet/></TrustAccountNav>} >
    <Route path="" element={<All/>} />
    <Route path="taf" element={<TAFReport/>} />
    <Route path="cheques-cleared" element={<ChequesCleared/>} />
    <Route path="outstanding-cheques" element={<OutstandingCheques/>} />
    <Route path="list" element={<TrustAccountList/>} />
    <Route path="liability" element={<TrustLiability/>} />
    <Route path="reconciliation" element={<FinalTrustReconciliation/>} />
    <Route path="gstpst-report" element={<GSTPSTReport/>} />
  </Route>,
];
export default routes;
