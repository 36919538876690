import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug, faCheck, faMoneyCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import ButtonPrimary from 'components/common/Button';
import Transparent from 'components/notices/Transparent';
import {Input, Radios} from 'components/inputs';
import Loading from 'components/common/Loading';
import { gql, useMutation } from '@apollo/client';
import BusinessInput from 'components/surveyInputs/BusinessInput';
import ClientInput from 'components/surveyInputs/ClientInput';


export default function ChequeDetailsModal({onClose, onConfirm, bank, payee, loading}) {
  let [open, setOpen] = useState(true);
  let [newPayee, setNewPayee] = useState();
  let [newBank, setNewBank] = useState();
  let [confirmLoading, setConfirmLoading] = useState();

  const close = () => {
    setOpen(false);
    onClose();
  }

  const confirm = () => {
    setConfirmLoading(true);
    onConfirm(newPayee, newBank)
      .then(res => {
        setConfirmLoading(false);
      })
  }
  const valid = () => {
    return !loading && (payee || newPayee) && (bank || newBank);
  }

  return (
    <>
      {
        open &&
        <Transparent>
          <div onClick={close} className="absolute flex justify-center items-center w-full h-full">
            <div onClick={(e) => e.stopPropagation()} className="bg-white rounded border md:min-w-60  max-w-7/8 max-h-9/10 overflow-y-scroll scroller-min shadow-md">
              <div className={`flex justify-between relative px-4 py-2 bg-green`}>
                <h2 className="text-white">
                  <FontAwesomeIcon onClick={close} className="" icon={faMoneyCheck} />
                  &nbsp;
                  Create Cheque
                </h2>
                <FontAwesomeIcon onClick={close} className="text-white cursor-pointer absolute top-1 right-2" icon={faTimes} />
              </div>
              <div className="p-4 bg-whiter py-8 flex flex-col gap-2">
                <h2>Cheque Details</h2>
                <small>
                  information for cheque
                </small>
                {
                  !bank &&
                  <div>
                    <BusinessInput label="Trust Account Bank" onSelect={setNewBank} description="Trust Account for which Bank" type={["bank", "lender"]}/>
                  </div>
                }
                <div className="flex flex-col">
                  <label>Payee</label>
                  <Input 
                    onChange={setNewPayee} 
                    valueOverride={newPayee || (payee)}
                  />
                </div>
              </div>
              <div className="p-4 bg-whiter flex justify-end border-t gap-2 py-2">
                <ButtonPrimary onClick={close} >Close</ButtonPrimary>
                <ButtonPrimary className="border-red text-red" disabled={!valid()} onClick={confirm} >{(confirmLoading || loading) ? <Loading/> : "Create"}</ButtonPrimary>
              </div>
            </div>
          </div>
        </Transparent>
      }
    </>
  )
}
