import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import SurveyContext from 'contexts/SurveyContext';
import SelectedDocument from './SelectedDocument';
import { useQuery, gql } from "@apollo/client";
import DocumentOption from './DocumentOption';
import Fuse from 'fuse.js';
import {Input } from 'components/inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faCaretDown, faCaretUp, faSort, faFilter } from '@fortawesome/free-solid-svg-icons';
import DocumentGroupTempltes from './DocumentGroupTempltes';
import LoadingItem from 'components/loading/LoadingItem';
import Loading from 'components/common/Loading';

export default function DocumentSelector({
  loadMore,
  filters,
  setFilters,
  documents,
  documentsLoading,
  mortgageDocuments,
  mortgageDocumentsLoading,
  selectedDocuments,
  selectedDocumentsLoading
}) {
  const {file, fileId, surveyId, updateFile, loading} = useContext(SurveyContext);
  const [dropdownOpen, setDropdownOpen] = useState('fresh');

  useEffect(() => {
    if (file && dropdownOpen === 'fresh') {
      if (file.enabledDocuments.length > 0) {
        // setDropdownOpen(false);
      } else {
        setDropdownOpen(true);
      }
    }
  }, [file]);

  const options = documents ? documents : [];
  
  const updateEnabled = useCallback(({id}) => {
    // setSearch('');
    if (file && file.enabledDocuments.includes(id)) {
      return;
    }
    const enabledDocuments = [...file.enabledDocuments, id];
    updateFile({enabledDocuments}, true);
  }, [file]);

  const setEnabled = (enabledDocuments) => {
    // setSearch('');
    updateFile({enabledDocuments}, true);
  }

  const removeDocument = ({id}) => {
    const enabledDocuments = [...file.enabledDocuments].filter(x => x !== id);
    updateFile({enabledDocuments}, true);
  }

  const categories = useMemo(() => {
    return[...new Set(documents.map(document => document.category))].filter(category => category);
  }, [documents]);

  const handleSetSearch = (search) => {
    setDropdownOpen(true);
    setFilters({...filters, search});
  }

  let documentOptions = useMemo(() => {
    return options.map((option, i) => (
      <DocumentOption key={i} index={i} document={option} onClick={updateEnabled}/>
    ))
  }, [options, file, filters]);

  let reccentDocumentOptions = useMemo(() => (options.filter(option => option.reccent && file && !file.enabledDocuments.includes(option.id)).map((option, i) => (
    <DocumentOption key={i} index={i} document={option} onClick={updateEnabled}/>
  ))), [options, file]);

  let mortgageDocumentOptions = useMemo(() => ((mortgageDocuments || []).map((option, i) => (
    <DocumentOption key={i} index={i} document={option} onClick={updateEnabled}/>
  ))), [mortgageDocuments, updateEnabled]);

  const handleScroll = (e) => {
    const bottom =Math.ceil((e.target.scrollHeight - e.target.scrollTop) / 5) * 5  === Math.ceil(e.target.clientHeight / 5) * 5;
    if (bottom) { 
      loadMore()
     }
  }

  return (
    <div>
        <h2>Select Documents</h2>
        <hr></hr>
        <div className="p-2 my-4 flex flex-col bg-light-light-grey rounded gap-8">
          {mortgageDocumentOptions.length > 0 &&
            <div className="flex flex-col gap-4">
              <small className="text-primary">Mortgage Documents</small>
              <div className="flex flex-wrap gap-2">
                {mortgageDocumentOptions}
                {mortgageDocumentsLoading && 
                  <div className="w-full py-2">
                    <Loading/>
                  </div>
                }
              </div>
            </div>
          }
          <div className="flex flex-col gap-4">
            <small className="text-primary">Search All Documents</small>
            <div className="relative flex flex-col md:flex-row gap-2 md:items-center">
              <div className="relative flex gap-1 items-center">
                <Input className="pl-8" onChange={handleSetSearch} valueOverride={filters?.search}/>
                <FontAwesomeIcon className="absolute left-2 top-2" icon={faMagnifyingGlass} />
                <FontAwesomeIcon className="text-xl cursor-pointer" icon={dropdownOpen ? faCaretDown : faCaretUp} onClick={() => setDropdownOpen(!dropdownOpen)}/>
              </div>
              <div className="flex flex-col md:flex-row gap-2 md:items-center">
                {/* <FontAwesomeIcon icon={faFilter}/> */}
                <div className="flex items-center gap-1">
                  <input className="cursor-pointer" checked={!filters.category} onClick={() => setFilters({...filters, category: ""})} type="radio" id={"all"} name={"all"} value=""/>
                  <label className="font-normal text-sm cursor-pointer" htmlFor={"all"}>All</label>
                </div>
                {
                  categories.map(category => (
                    <div className="flex items-center gap-1">
                      <input className="cursor-pointer" checked={filters.category === category} onClick={() => setFilters({...filters, category: category})} type="radio" id={category} name={category} value=""/>
                      <label className="font-normal text-sm cursor-pointer" htmlFor={category}>{category}</label>
                    </div>
                  ))
                }
              </div>
            </div>
            <div onScroll={handleScroll} className={`flex flex-wrap gap-2 border overflow-y-scroll bg-white p-2 rounded max-h-33 scroller ${dropdownOpen ? 'visible' : 'hidden'}`}>
              {
                (!file || (documentsLoading && options.length === 0)) ?
                  <>
                    <LoadingItem mini/>
                    <LoadingItem mini/>
                    <LoadingItem mini/>
                    <LoadingItem mini/>
                    <LoadingItem mini/>
                    <LoadingItem mini/>
                    <LoadingItem mini/>
                  </>
                :
                documentOptions
              }
              {documentsLoading && 
                <div className="w-full py-2">
                  <Loading/>
                </div>
              }
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <small className="text-green font-bold">Selected</small>
            <div className="flex flex flex-wrap gap-2">
              {
                (!file || (selectedDocumentsLoading && (selectedDocuments || []).length === 0)) ?
                  <>
                    <LoadingItem mini dark/>
                    <LoadingItem mini dark/>
                    <LoadingItem mini dark/>
                    <LoadingItem mini dark/>
                    <LoadingItem mini dark/>
                    <LoadingItem mini dark/>
                    <LoadingItem mini dark/>
                  </>
                :
                (selectedDocuments || []).map((documentId, i) => (
                  <SelectedDocument key={i} document={documentId} onCancel={removeDocument}/>
                ))
              }
              {selectedDocumentsLoading && 
                <div className="w-full py-2">
                  <Loading/>
                </div>
              }
            </div>
          </div>
        </div>
        <DocumentGroupTempltes
          setEnabled={setEnabled}
          enabled={file?.enabledDocuments || []}
          surveyId={surveyId}
          firmId={file?.firmId}
        />
    </div>
  )
}
