import { getCalculatedValue, getSurveyValue } from "selectors/formSelectors";
import { PDFDocument } from 'pdf-lib';
const cache = {}

const evaluateTemplate = (data, template) => {
    let errors = [];
    let pointer = 0;
    let finalTemplate = '';
    let loop = 0;
    while (pointer >= 0) {
        if (loop > 200) throw new Error("Too many variables");
        loop++;
        pointer = 0;
        let nextClose = template.slice(pointer).indexOf(']$&*');
        let nextOpen = template.slice(pointer).indexOf('*&$[');
        if (nextOpen < 0) {
            return {template: finalTemplate + template, errors};
        }
        pointer = nextOpen + 4;
        let openStack = [nextOpen];
        let closeStack = [nextClose];
        let reccentClose;
        let reccentOpen;
        while (openStack.length > 0) {
            nextOpen = pointer + template.slice(pointer).indexOf('*&$[');
            if (nextOpen < pointer || nextOpen > closeStack.at(-1)) {
                reccentClose = closeStack.pop();
                reccentOpen = openStack.pop();
                pointer = reccentClose + 4;
                if (openStack.length > 0) {
                    let nextClose = template.slice(pointer).indexOf(']$&*');
                    if (nextClose < 0) {
                        console.log('Ivalid substring',template.slice(openStack.at(-1)));
                        throw new Error("invalid rtf template");
                    }
                    closeStack.push(pointer + nextClose);
                }
            } else {
                openStack.push(nextOpen);
                pointer = nextOpen + 4;
            }
        }
        if (reccentOpen !== undefined) {
            let evaluable = template.slice(reccentOpen + 4, reccentClose).replaceAll('*&$[', '${');
            evaluable = evaluable.replaceAll(']$&*', '}').replace(/\\/g, "\\\\");
            let evaluated = getCalculatedValue(data, evaluable);
            if (evaluated === undefined) {
                const regex =  new RegExp(/\*\&\$\[.*?\]\$\&\*/, 'g');
                let matches =  template.slice(reccentOpen + 4, reccentClose).match(regex);
                if (matches) {
                    errors.push(matches.map(match => match.slice(4, -4)));
                } else {
                    errors.push(evaluable);
                }
            } else if (Array.isArray(evaluated)) {
                evaluated = evaluated.join("");
            }
            finalTemplate += template.slice(0, reccentOpen) + toRTFValue(evaluated);
            template = template.slice(reccentClose + 4);
        } else {
            break;
        }
    }
    return {template: finalTemplate, errors};
}

const toRTFValue = (value) => {
    if (Number(value) !== 0 && !value) {
        return '';
    } else {
        return value;
    }
}

export const validInputs = (data, template) => {
    // const errors = [];
    if (!data || !template) return ["No data"];
    try {
        let {errors, template: evaluatedTemplate} = evaluateTemplate(data, template);
        if (errors.length > 0) {
            return errors;
        } else {
            return false;
        }
    } catch (err) {
        console.log("err", err);
        return ["Invalid RTF template"];
    }
}

export const validLTSAInputs = (data, variables, errorOnEmpty = false) => {
    const errors = [];
    if (!data || !variables) return ["No data"];
    try {
        for (let variable of variables) {
            if (errorOnEmpty ? !getCalculatedValue(data, variable) : undefined === getCalculatedValue(data, variable)) {
                errors.push(variable);
            }
        }
        if (errors.length > 0) {
            return errors;
        } else {
            return false;
        }
    } catch (err) {
        console.log("err", err);
        return ["Invalid RTF template"];
    }
}

export const validFCTInputs = (data, variables) => {
    const errors = [];
    const calculatedData = []
    if (!data || !variables) return ["No data"];
    try {
        for (let variable of variables) {
            calculatedData.push(getCalculatedValue(data, variable));
            if (!calculatedData.at(-1)) {
                errors.push(variable);
            }
        }
        return {errors, data: calculatedData};
    } catch (err) {
        console.log("err", err);
        return ["Invalid RTF template"];
    }
}

const highlightOpen = `}{\\rtlch\\fcs1 \\af43\\afs18 \\ltrch\\fcs0 \\f43\\fs18\\highlight7\\insrsid5061152\\charrsid13069786 \\hich\\af43\\dbch\\af31505\\loch\\f43`;
const highlightClose = `}{\\rtlch\\fcs1 \\af43\\afs18 \\ltrch\\fcs0 \\f43\\fs18\\insrsid5061152`;

export const createBlob = (data, template, surveyVars) => {
    if (!data && !surveyVars) return;
    // let documentText = template;
    let {errors, template: evaluatedTemplate} = evaluateTemplate(data, template);
    // const regex =  new RegExp(/\*\&\$\[.*?\]\$\&\*/, 'g');
    // let matches =  template.match(regex);
    // if (matches) {
    //     for (let match of matches) {
    //         let path = match.slice(4, -1);
    //         if (!cache[path]) {
    //             cache[path] = surveyVars ? highlightOpen + (surveyVars[path] ? surveyVars[path].displayName : "NOTFOUND") + highlightClose : getSurveyValue(data, path);
    //         }
    //         const regex =  new RegExp('\\*\\&\\$\\['+path.replace('.', '\\.')+'\\]', 'g');
    //         documentText = documentText.replaceAll(regex, cache[path]);
    //     }
    // }
    const blob = new Blob([evaluatedTemplate], {type: "application/rtf"});
    return blob;
}

const createDocument = (blob) => {
    const url = URL.createObjectURL(blob);
    return url;
}

const typeLookup = {
    png: "pngblip",
    jpeg: "jpegblip",
    jpg: "jpegblip"
}
export const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;   
        resolve({base64: base64data.split(',').pop(), type: typeLookup[url.split('?')[0].split('.').pop()]});
      }
    });
}

export const base64ToHex = (str) => {
    const raw = atob(str);
    let result = '';
    for (let i = 0; i < raw.length; i++) {
      const hex = raw.charCodeAt(i).toString(16);
      result += (hex.length === 2 ? hex : '0' + hex);
    }
    return result.toUpperCase();
}

export const getImageDimensions = (base64) => {
    const header = atob(base64.slice(0, 50)).slice(16,24)
    const uint8 = Uint8Array.from(header, c => c.charCodeAt(0))
    const dataView = new DataView(uint8.buffer)
  
    return {
      w: dataView.getInt32(0),
      h: dataView.getInt32(4)
    }
  }
  

export default createDocument;