
function getFirstDayOfWeek(selectedDay) {
    const date = new Date(selectedDay);
    const newDate = date.setDate(selectedDay.getDate() - selectedDay.getDay() );
    return new Date(newDate);
}

let dayOfWeekFromOffset = (selectedDate, offset) => {
    const date = new Date(selectedDate);
    const newDate = date.setDate(selectedDate.getDate() + offset );
    return new Date(newDate);
}

function getFirstDayOfMonth(selectedDay) {
    const date = new Date(selectedDay);
    const newDate = date.setDate(1);
    return new Date(newDate);
}

function sameDay(d1, d2) {
    d1 = new Date(d1);
    d2 = new Date(d2);
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
}


const monthsBetween = (d1, d2) => {
    let yearDifference = d1.getFullYear() - d2.getFullYear();
    let month1 = d1.getMonth();
    let month2 = d2.getMonth();
    if (yearDifference > 0) {
        month1 += 11;
    } else if (yearDifference < 0) {
      month2 += 11;
    }
    return month1 - month2;
}

const addMonths = (change, date) => {
    let newMonth = date.getMonth() + change;
    if (newMonth > 11) {
      return new Date(date.getFullYear() + 1, newMonth%12, 1);
    } else if (newMonth < 0) {
      return new Date(date.getFullYear() - 1, 12 + newMonth, 1);
    } else {
      return new Date(date.getFullYear(), newMonth, 1);            
    }
}

const firstBusinessDay = (year, month) => {
  var d = new Date();
  d = new Date(Number(year)  || d.getFullYear(), Number(month) || d.getMonth() + 1, 1);
  let res = d.getDay() % 6 ? d : d.setDate((2 + d.getDay()) % 5);
  return d;
}

export {
    getFirstDayOfWeek,
    getFirstDayOfMonth,
    dayOfWeekFromOffset,
    sameDay,
    monthsBetween,
    addMonths,
    firstBusinessDay
}