import React, {useState} from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCircleLeft, faSquarePen, faLocationDot, faLocationPin, faEnvelope, faBuilding, faGlobe, faPhone, faFax, faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import Input, { Checkbox, Label, Radios } from 'components/inputs';
import ButtonPrimary from 'components/common/Button';
import InviteUser from '../user/InviteUser';
import { FullWidthPage } from 'components/common/Layout';

const GET_BUSINESS = gql`
  query GetBusiness($id: String!) {
    business(id: $id) {
      id,
      companyName,
      email,
      phoneNumber,
      fax,
      type,
      addressLineOne,
      addressLineTwo,
      city,
      province,
      postalCode,
      incorporationNumber,
      gstNumber,
      pstNumber,
      firmId,
      public
    }
  }
`;

const DELETE_BUSINESS = gql`
  mutation DeleteBusiness($id: String!) {
    deleteBusiness(id: $id) {
      id
    }
  }
`;

const UPDATE_BUSINESS = gql`
  mutation UpdateBusiness(
    $id: String!,
    $companyName: String,
    $email: String,
    $phoneNumber: String,
    $fax: String,
    $type: String,
    $addressLineOne: String,
    $addressLineTwo: String,
    $city: String,
    $province: String,
    $postalCode: String,
    $incorporationNumber: String,
    $firmId: String,
    $gstNumber: String,
    $pstNumber: String,
    $public: Boolean
  ) {
    updateBusiness(
      id: $id,
      companyName: $companyName,
      email: $email,
      phoneNumber: $phoneNumber,
      fax: $fax,
      type: $type,
      addressLineOne: $addressLineOne,
      addressLineTwo: $addressLineTwo,
      city: $city,
      province: $province,
      postalCode: $postalCode,
      incorporationNumber: $incorporationNumber,
      firmId: $firmId,
      gstNumber: $gstNumber,
      pstNumber: $pstNumber,
      public: $public
    ) {
      id,
      companyName,
      email,
      phoneNumber,
      fax,
      type,
      addressLineOne,
      addressLineTwo,
      city,
      province,
      postalCode,
      incorporationNumber,
      gstNumber,
      pstNumber,
      firmId,
      public
    }
  }
`;

const types = ['firm', 'notary public', 'realtor', 'bank', 'broker', 'strata management company', 'discharge centers'];

export default function Business() {
  const navigate = useNavigate();
  const params = useParams();
  const [showDelete, setShowDelete] = useState(false);
  const [edit, setEdit] = useState(false);
  const [updateInfo, setUpdateInfo] = useState({});
  const { loading, error, data, refetch } = useQuery(GET_BUSINESS, {
    variables: { id: params.id },
  });
  const [deleteBusinessMutation, { loading: deleteLoading, error:deleteError }] = useMutation(DELETE_BUSINESS);
  const [updateBusinessMutation, { loading: updateLoading, error:updateError }] = useMutation(UPDATE_BUSINESS);

  const handleDelete = () => {
    setShowDelete(false);
    deleteBusinessMutation({variables: {id: params.id}})
      .then(() => navigate("/admin/businesses"));
  }

  const handleUpdate = () => {
    setEdit(false);
    updateBusinessMutation({variables: {
      id: params.id,
      ...updateInfo
    }})
      .then(() => refetch());
  }

  const valid = () => {
		return updateInfo;
	}

  return (
    data ?
    <FullWidthPage bottom className="pt-4">
      {showDelete && <ConfirmNotice 
        title={`Are you sure you want to delete ${data.business.companyName}?`}
        message={`To delete this business type "${data.business.companyName}" below`}
        confirmText={data.business.companyName}
        onConfirm={handleDelete}
        onClose={() => setShowDelete(false)}
      /> }
      <div className="flex flex-col gap-6 items-start">
        <div className="flex gap-2 items-center">
          <Link to="/admin/businesses"><FontAwesomeIcon className="text-2xl" icon={faCircleLeft} /></Link>
          <FontAwesomeIcon onClick={() => setEdit(!edit)}  className="text-xl text-green cursor-pointer" icon={faSquarePen} />
          <FontAwesomeIcon onClick={() => setShowDelete(true)} className="text-xl text-red cursor-pointer" icon={faCircleXmark} />
        </div>
        <div className="flex flex-col items-center justify-center gap-2">
          <div className="flex gap-2 items-center">
            <h1>
              <FontAwesomeIcon className="" icon={faBuilding} />&nbsp;
              {edit ? <Input className="text-2xl font-medium w-160" valueOverride={data.business.companyName} onChange={(companyName) => setUpdateInfo({...updateInfo, companyName})} /> : data.business.companyName}
            </h1>
            {data.business.public && <div className="rounded bg-primary text-white px-3 font-bold">PUBLIC</div>}
          </div>
        </div>
        <div className="border border-transparent-dark-grey bg-transparent-grey rounded flex flex-col gap-2 p-4">
          {/* <div className="flex flex-col">
              <Label text="Company Name"/>
              <Input placeholder="Start typing a company name" valueOverride={data.business.companyName} onChange={(companyName) => setUpdateInfo({...updateInfo, companyName})} />
          </div> */}
          <div>
            <Label text="Type"/>
            <Radios readOnly={!edit} valueOverride={data.business.type} inline onChange={(type) => setUpdateInfo({...data.business, type})} options={types.map(type => ({name: type || 'none', value: type}))}/>
          </div>
          <div className="flex gap-2"> 
            <div className="flex flex-col">
                <Label text="Phone Number"/>
                <Input disabled={!edit} valueOverride={data.business.phoneNumber} onChange={(phoneNumber) => setUpdateInfo({...updateInfo, phoneNumber})} />
            </div>
            <div className="flex flex-col">
                <Label text="Fax"/>
                <Input disabled={!edit} valueOverride={data.business.fax} onChange={(fax) => setUpdateInfo({...updateInfo, fax})} />
            </div>
          </div>
          <div className="flex flex-col">
              <Label text="Email"/>
              <Input disabled={!edit} valueOverride={data.business.email} onChange={(email) => setUpdateInfo({...updateInfo, email})} />
          </div>
            <div className="flex flex-col gap-2">
            <div className="flex flex-col">
                <Label text="Address"/>
                <Input disabled={!edit} valueOverride={data.business.addressLineOne} onChange={(addressLineOne) => setUpdateInfo({...updateInfo, addressLineOne})} />
            </div>
            <div className="flex flex-col">
                <Label text="Second line of address"/>
                <Input disabled={!edit} valueOverride={data.business.addressLineTwo} onChange={(addressLineTwo) => setUpdateInfo({...updateInfo, addressLineTwo})} />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="flex flex-col">
                <Label text="City"/>
                <Input disabled={!edit} valueOverride={data.business.city} onChange={(city) => setUpdateInfo({...updateInfo, city})} />
            </div>
            <div className="flex flex-col">
                <Label text="Province"/>
                <Input disabled={!edit} valueOverride={data.business.province} onChange={(province) => setUpdateInfo({...updateInfo, province})} />
            </div>
            <div className="flex flex-col">
                <Label text="Postal Code"/>
                <Input disabled={!edit} valueOverride={data.business.postalCode} onChange={(postalCode) => setUpdateInfo({...updateInfo, postalCode})} />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="flex flex-col">
                <Label text="GST Number"/>
                <Input pattern="^\d{9}$" patternErrorMessage="Invalid GST Number Format try #########" disabled={!edit} valueOverride={data.business.gstNumber} onChange={(gstNumber) => setUpdateInfo({...updateInfo, gstNumber})} />
            </div>
            <div className="flex flex-col">
                <Label text="PST Number"/>
                <Input disabled={!edit} valueOverride={data.business.pstNumber} onChange={(pstNumber) => setUpdateInfo({...updateInfo, pstNumber})} />
            </div>
            <div className="flex flex-col">
                <Label text="Incorporation Number"/>
                <Input disabled={!edit} valueOverride={data.business.incorporationNumber} onChange={(incorporationNumber) => setUpdateInfo({...updateInfo, incorporationNumber})} />
            </div>
          </div>
          {
            edit &&
            <div>
              <Checkbox readOnly={!edit} text="Public" initialValue={data.business.public} onChange={(val) => setUpdateInfo({...updateInfo, public: val})} />
            </div>
          }
        </div>
        {
          edit && <ButtonPrimary disabled={updateLoading || deleteLoading || !valid()} onClick={handleUpdate}>Update</ButtonPrimary>
        }
      </div>
    </FullWidthPage>
    :
    <></>
  )
}
