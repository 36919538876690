import { gql, useMutation, useQuery } from '@apollo/client';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullWidthPage } from 'components/common/Layout';
import Input, { Checkbox, List } from 'components/inputs';
import SurveyContext from 'contexts/SurveyContext';
import useExportCSV from 'hooks/useExportCSV';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import { formatCurrency } from 'selectors/formSelectors';
import TrustAccountInput from './TrustAccountInput';
import ActivePageContext from 'contexts/ActivePageContext';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import DocumentGroup from 'components/documents/DocumentGroup';

// get trust account items for this file
const GET_TRUST_ACCOUNT_ITEMS = gql`
  query GetTrustAccountItems($filters: JSONObject, $firmId: String) {
    trustAccountItems(filters: $filters, firmId: $firmId) {
      id,
      name,
      value,
      calculation,
      date,
      chequeDepositNumber,
      surveyId, 
      fileId,
      trustAccountId,
      trustAccount {
        name
      }
      type,
      cleared,
      void,
      file {
        fileNumber,
        completionDate,
        surveyName
      }
    }
  }
`;

const GET_DOCUMENTS = gql`
  query GetDocuments($firmId: String, $type: String, $filters: JSONObject) {
    documents(firmId: $firmId, type: $type, filters: $filters) {
      id,
      name,
      category,
      firmId,
      type
    }
  }
`;

let startAndEndOfMonth = () => {
  const date = new Date();
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  const month = date.getMonth() + 1;
  const monthFormat = `${date.getFullYear()}-${month.length > 1 ? month : '0' + month}`
  return [monthFormat + `-01`, monthFormat + `-${lastDay}`];
}

export default function TrustLiability({filters}) {
  const {setActivePage, from, to, firmId, setExportCSV} = useContext(ActivePageContext);
  setActivePage("liability")
  const {exportToCsv} = useExportCSV();
  const [accounts, setAccounts] = useState({});
  const { loading, error, data, refetch } = useQuery(GET_TRUST_ACCOUNT_ITEMS, {
    variables: {firmId, filters: {from, to}},
    fetchPolicy:'no-cache',
    onCompleted: (data) => {
      if (data?.trustAccountItems?.length > 0) {
        let accounts = {};
        for (let item of data?.trustAccountItems) {
          if (!accounts[item.trustAccount.name]) {
            accounts[item.trustAccount.name] = {open: 0, cheques: 0, void: 0, reciepts: 0};
          }
          if (item.void) {
            accounts[item.trustAccount.name].void += Math.abs(Number(item.value));
          } else if (item.value < 0) {
            accounts[item.trustAccount.name].cheques += Math.abs(Number(item.value));
          } else if (item.value > 0 || item.type === "reciept") {
            accounts[item.trustAccount.name].reciepts += Math.abs(Number(item.value));
          }
        }
        setAccounts(accounts);
      }
    }
  });

  const { loading:loadingDocs, error:errorDocs, data:dataDocs } = useQuery(GET_DOCUMENTS, {
    variables: {firmId, type: "trust", filters: {search: ['liability']}},
    fetchPolicy:'no-cache'
  });

  useEffect(() => {
    if (!setExportCSV) return;
    if (Object.entries(accounts)?.length > 0) {
      setExportCSV({
        call: () => exportToCsv(Object.entries(accounts).map(([name, account], i) => {
          return {name, openBalance: account.open, cheques: account.cheques, cancelledVoid: 0, reciepts: account.reciepts, closingBalance: account.open - account.cheques + account.reciepts}
        }), `Trust Liability ${from} - ${to}`)
      })
    } else {
      setExportCSV();
    }
  }, [data, accounts]);

  const totals = useMemo(() => {
    return Object.entries(accounts).reduce((prev, current) => {
      prev.open += Number(current[1].open);
      prev.void += Number(current[1].void);
      prev.cheques += Number(current[1].cheques);
      prev.reciepts += Number(current[1].reciepts);
      return prev;
    }, {open: 0, cheques: 0, reciepts: 0, void: 0});
  }, [accounts])

  return (
    <div className='flex flex-col gap-4'>
    <div className="bg-light-grey rounded p-2">
      <table className="w-full bg-light-grey border-spacing-2">
        <tbody>
          <tr className="border-b border-dark-grey">
            <th>No</th>
            <th>Account</th>
            <th>Open Balance</th>
            <th>Cheques</th>
            <th>Cancelled/Voided</th>
            <th>Receipts</th>
            <th>Closing Balance</th>
          </tr>
          {Object.entries(accounts).map(([name, account], i) => (
            <tr key={i} className={`group relative ${i%2 === 0 ? 'bg-transparent-grey' : ''}`}>
              <td className="p-1 text-center">{i+1}</td>
              <td className="p-1 ">{name}</td>
              <td className="p-1 text-right">$<Input className="bg-transparent text-right w-24" type="number" valueOverride={account.open} onChange={open =>setAccounts({...accounts, [name]: {...accounts[name], open}})}/></td>
              <td className="p-1 text-right">{formatCurrency(account.cheques)}</td>
              <td className="p-1 text-right">{formatCurrency(account.void)}</td>
              <td className="p-1 text-right">{formatCurrency(account.reciepts)}</td>
              <td className="p-1 text-right">{formatCurrency(account.open - account.cheques + account.reciepts)}</td>
            </tr>
          ))}
          <tr className="border-t border-med-grey">
            <td></td>
            <td></td>
            <td className="text-right pt-2">{formatCurrency(totals.open)}</td>
            <td className="text-right pt-2">{formatCurrency(totals.cheques)}</td>
            <td className="text-right pt-2">{formatCurrency(totals.void)}</td>
            <td className="text-right pt-2">{formatCurrency(totals.reciepts)}</td>
            <td className="text-right pt-2">{formatCurrency(totals.open - totals.cheques + totals.reciepts)}</td>
          </tr>
        </tbody>
      </table>
    </div>
      {dataDocs && 
        <div className="relative">
          <DocumentGroup
            // zip={zip} 
            title="Trust Documents" 
            documents={dataDocs?.documents}
            extraData={{
              accounts,
              totals,
              to: to,
              from: from,
            }}
          />
        </div>
      }
    </div>
  )
}
