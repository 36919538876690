import React from 'react'

export default function index({text, type}) {
  return (
    <div className={`
      px-3 py-1 text-sm 
      rounded font-bold self-start inline-block border-2
      ${type == 'success' && 'text-green border-green'}
      ${type == 'warning' && 'text-yellow border-yellow'}
      ${type == 'error' && 'text-light-red border-light-red'}
      `}
    >
        {text}
    </div>
  )
}
