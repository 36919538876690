import React, {useContext, useState} from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faEnvelope, faTimes, faCircleLeft, faMask, faBug } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AppContext from 'contexts/AppContext';
import { faFile, faUser } from '@fortawesome/free-regular-svg-icons';
import StatusToggle from './StatusToggle';
import { FullWidthPage } from 'components/common/Layout';

const GET_BUGREPORT = gql`
  query GetBugReport($id: String) {
    bugReport(id: $id) {
      id,
      user {
        firstName, 
        lastName,
        id
      }
      fileId,
      surveyId,
      email,
      status,
      description,
      page,
      error,
    }
  }
`;

export default function BugReport({removeBugReport}) {
  const params = useParams();
  const { loading, error, data, refetch } = useQuery(GET_BUGREPORT, {
    variables: {id: params.id}
  });

  return (
    data ?
    <FullWidthPage bottom className="pt-4">
      <div className="flex flex-col gap-6">
        <div className="flex gap-2">
          <Link to="/admin/bug-reports"><FontAwesomeIcon className="text-2xl" icon={faCircleLeft} /></Link>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <h1><FontAwesomeIcon className="" icon={faBug} /> 
              {data.bugReport.id}
            </h1>
            <StatusToggle bugReport={data.bugReport}/>
          </div>
          <div className="flex items-center gap-2 font-bold">
            <small><FontAwesomeIcon className="" icon={faUser} /> {data.bugReport.user.firstName}  {data.bugReport.user.lastName}</small>
            <small><FontAwesomeIcon className="" icon={faEnvelope} /> {data.bugReport.email}</small>
            {data.bugReport.fileId && 
              <Link className="no-underline font-bold flex items-center" to={`/files/${data.bugReport.surveyId}/${data.bugReport.fileId}/summary`}><small><FontAwesomeIcon className="" icon={faFile} /> File</small></Link>
            }
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div>
            <label>Description</label>
            <p>{data.bugReport.description}</p>
          </div>
          <div>
            <label>Error</label>
            <p>{data.bugReport.error}</p>
          </div>
          <div>
            <label>Page</label>
            <p>{data.bugReport.page}</p>
          </div>
        </div>
      </div>
    </FullWidthPage>
    :
    <></>
  )
}
