import React, {useContext} from 'react'
import SurveyContext from 'contexts/SurveyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ButtonPrimary from 'components/common/Button';
import { Link } from 'react-router-dom';
import SurveyChapterItem from './SurveyChapterItem';
import LoadingText from 'components/loading/LoadingText';

export default function SurveyPageChapters({first, last}) {
  const { fileId, surveyId, currentPage, pages, errors } = useContext(SurveyContext);

  return (
    <div className="flex grow flex-col max-w-24 w-1/4 min-w-24 pb-4 hidden md:flex">
        <div className="flex flex-col z-10 sticky md:top-13 top-16 lg:top-16 xl:top-18 2xl:top-32 divide-y border scroller overflow-y-scroll max-h-3/4 mb-10 border-dark rounded">
          {pages ? pages.map((page, i) => (
            <SurveyChapterItem key={i} pageNumber={i} currentPage={currentPage} page={page}/>
            ))
          :
          <>
            <LoadingText className="p-2"/>
            <LoadingText className="p-2"/>
            <LoadingText className="p-2"/>
            <LoadingText className="p-2"/>
            <LoadingText className="p-2"/>
            <LoadingText className="p-2"/>
            <LoadingText className="p-2"/>
          </>
          }
        </div>
        <div className="relative grow flex flex-col justify-end">
          <div className="sticky bottom-4 flex justify-end">
              {!first && <Link className="justify-self-start" to={`/files/${surveyId}/${fileId}/${currentPage - 1}`} disabled={errors}>
              <ButtonPrimary>
                  <FontAwesomeIcon icon={faArrowLeft} />
              </ButtonPrimary>
              </Link>}
              <Link className="justify-self-end" to={last ? `/files/${surveyId}/${fileId}/summary` : `/files/${surveyId}/${fileId}/${currentPage + 1}`}>
              <ButtonPrimary>
                  <FontAwesomeIcon icon={faArrowRight} />
              </ButtonPrimary>
              </Link>
          </div>
        </div>
    </div>
  )
}
